import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { generateQR, getInvoice } from '../../../../../services';
import Invoice from '../Invoice';

const QRScan = ({ invoice, setPrintInvoice, qr, setQr }) => {
  const { invoice_id } = useParams();

  const handleGenerateQR = async () => {
    const res = await generateQR(invoice_id);
    if (res && res.QR) {
      setQr(res.QR);
    }
  };

  const checkInvoiceStatus = async () => {
    const res = await getInvoice(invoice_id);
    if (res && res?.invoice_data?.status === 1) {
      // handleUpdateInvoice();
      setPrintInvoice(true);
      localStorage.setItem('evoice', true)
    }
  };

  useEffect(() => {
    checkInvoiceStatus();
    const intervalId = setInterval(() => checkInvoiceStatus(), 3000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect
    (() => {
      if (invoice_id) {
        handleGenerateQR();
      }
    }, [invoice_id]);

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col items-center justify-center gap-3'>
        {/* <div className="flex flex-col items-center gap-2">
              <p className="leading-8 text-2xl font-semibold" style={{ color: "#262626" }}>
                {invoice?.name}
              </p>
              <p className="leading-6 text-base font-semibold" style={{ color: "#8C8C8C" }}>
                {invoice?.phone_number}
              </p>
            </div> */}
        {qr && (
          <div
            className='p-3 bg-white flex items-end justify-center'
            style={{
              widows: "180px",
              height: "180px",
              border: "1px solid #262626",
              borderRadius: '14px'
            }}
          >
            <img className='w-full h-full object-cover' src={qr} alt='' />
          </div>
        )}
      </div>
      <Invoice invoice={invoice} />
    </div>
  );
}

export default QRScan