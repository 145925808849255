
import appClient from "./axios/app-client";
import { INVENTORY_MANAGER } from "./endpoint";

export const inventoryManagerServices = {
  getListInventory: (params) => {
    return appClient.get(INVENTORY_MANAGER, { params });
  },

  createInventory: (params) => {
    return appClient.post(INVENTORY_MANAGER, { params });
  },

  updateInventory: ({
    rule_name = "",
    card_type = "",
    issuer = "",
    acquirer = "",
  }) => {
    return appClient.put(
      `/metadata/rule?rule_name=${rule_name}&card_type=${card_type}&issuer=${issuer}&acquirer=${acquirer}`
    );
  },

  deleteInventory: (params) => {
    return appClient.delete(INVENTORY_MANAGER, params);
  },
};

const API_URL = process.env.REACT_APP_API_ENDPOINT_STORE;
const API_KEY = localStorage.getItem("accessToken");
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${API_KEY}`,
};

export const getProducts = async () => {
  const res = await appClient.get(`${API_URL}/inventory/product`, {
    headers: headers,
  });
  return res.data;
};

export const createOrder = async (params) => {
  const res = await appClient.post(`${API_URL}/order`, params, {
    headers: headers,
  });
  return res;
};

export const getOrder = async (order_id) => {
  const res = await appClient.get(`${API_URL}/order/${order_id}`, {
    headers: headers,
  });
  return res.data;
};

export const updateOrder = async (params) => {
  const res = await appClient.put(`${API_URL}/order/product`, params, {
    headers: headers,
  });
  return res.data;
};

export const confirmOrder = async (params) => {
  const res = await appClient.put(`${API_URL}/order/confirm`, params, {
    headers: headers,
  });
  return res.data;
};

export const removeOrderProduct = async (order_id, product_id) => {
  const res = await appClient.delete(
    `${API_URL}/order/${order_id}/${product_id}`,
    {
      headers: headers,
    }
  );
  return res.data;
};

export const updateCustomerOfOrder = async (order_id, customer_id) => {
  const res = await appClient.put(
    `${API_URL}/order/${order_id}/${customer_id}`,
    {
      headers: headers,
    }
  );
  return res;
};

// get list table
export const getTable = async () => {
  const res = await appClient.get(`${API_URL}/fnb/table`, {
    headers: headers,
  });
  return res.tables;
};

// create new table
export const createTable = async (params) => {
  const res = await appClient.post(`${API_URL}/fnb/table`, params, {
    headers: headers,
  });
  return res;
};

export const getTableDetail = async (table_id) => {
  const res = await appClient.get(`${API_URL}/fnb/table/${table_id}`, {
    headers: headers,
  });
  return res;
};

export const updateTable = async (table_id, params) => {
  const res = await appClient.put(
    `${API_URL}/fnb/table/${table_id}`,
    params,
    {
      headers: headers,
    }
  );
  return res;
};

export const removeTable = async (table_id) => {
  const res = await appClient.delete(`${API_URL}/fnb/table/${table_id}`, {
    headers: headers,
  });
  return res;
};

export const createCustomer = async (params) => {
  try {
    return await appClient.post(`${API_URL}/customer`, params, {
      headers: headers,
    });
  } catch (e) {
    return e;
  }
};

export const getCustomer = async (params) => {
  const res = await appClient.get(
    `${API_URL}/customer?q=${params.phone}&limit=${params.limit || 10}&offset=${params.offset || 0
    }`,
    {
      headers: headers,
    }
  );
  return res;
};

//
export const getInvoice = async (invoice_id) => {
  const res = await appClient.get(
    `${API_URL}/invoice/${invoice_id}?is_orderid=false`,
    {
      headers: headers,
    }
  );
  return res.data;
};

export const createInvoice = async (params) => {
  const res = await appClient.post(`${API_URL}/invoice`, params, {
    headers: headers,
  });
  return res;
};

export const updateInvoice = async (params) => {
  const res = await appClient.put(`${API_URL}/invoice`, params, {
    headers: headers,
  });
  return res;
};

//
export const generateQR = async (invoice_id) => {
  const res = await appClient.get(`${API_URL}/payment/qr/${invoice_id}`, {
    headers: headers,
  });
  return res;
};

export const getProvinces = async () => {
  const res = await appClient.get(`${API_URL}/region/provinces`, {
    headers: headers,
  });
  return res.data;
};

export const getDistricts = async (province_id) => {
  const res = await appClient.get(
    `${API_URL}/region/districts/${province_id}`,
    {
      headers: headers,
    }
  );
  return res.data;
};

export const getWards = async (district_id) => {
  const res = await appClient.get(`${API_URL}/region/wards/${district_id}`, {
    headers: headers,
  });
  return res.data;
};

export const getMerchant = async (district_id) => {
  const res = await appClient.get(`${API_URL}/saas/merchant`, {
    headers: headers,
  });
  return res.data;
};
