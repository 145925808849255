import React, { useEffect, useState } from "react";

import CustomButton from "../buttons/CustomButton";
import iconUpload from "../../assets/images/onboarding/upload.png";
import { history } from "../../helpers";
import { Bounce, toast } from "react-toastify";
import { getListIndustry } from "../../actions/industry/industry.action";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../actions/metadata/metadata.actions";

const LogoUpload = () => {
  let initLogo = localStorage?.getItem("logo");
  const [logo, setLogo] = useState(initLogo || "");

  const handleRemoveLogo = () => {
    localStorage.removeItem("logo");
    setLogo(null);
  };

  const dispatch = useDispatch();

  const [dataIndustry, setDataIndustry] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getListIndustry({}));
        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data;

          setDataIndustry(data);
        }
      } catch (err) {}
    };
    getData();
  }, []);

  let dataOnboarding = JSON.parse(localStorage.getItem("dataOnboarding"));

  const industry = dataIndustry.find(
    (item) => item?.name === dataOnboarding?.industry
  );

  const onChangeImage = async (e) => {
    const file = e.target.files[0];

    if ((file.size / 1024 / 1024).toFixed(2) > 50) {
      toast.error(
        "File is too large! Please select a file smaller than or equal to 50MB.",
        {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          transition: Bounce,
        }
      );
      e.target.value = null;
      return;
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif"
    ) {
    } else {
      toast.error("File type is invalid! Please  select png/jpeg/gif file.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        transition: Bounce,
      });
      e.target.value = null;
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogo(event.target.result);
        localStorage.setItem("logo", event.target.result);
      };
      reader.readAsDataURL(file);
    }

    const fd = new FormData();
    fd.append("file", e.target.files[0]);
    fd.append("industry_code", industry?.code);
    fd.append(
      "representative_phone",
      dataOnboarding?.reprensentative_phone_number
    );
    fd.append("representative_name", "vanduc");
    fd.append("ref_code", dataOnboarding?.referral_code);
    fd.append("tax_no", dataOnboarding?.tax_code);

    const res = await dispatch(uploadImage(fd));
    if (res?.payload?.data) {
      //localStorage.setItem("logo", res?.payload?.data);
    } else {
      e.target.value = null;
      return;
    }
  };
  return (
    <div
      className="flex   justify-between "
      style={{
        height: "100vh",
        minHeight: "900px",
        backgroundColor: "#FAFAFA",
      }}
    >
      <div
        className="flex flex-col h-full w-full   "
        style={{
          width: "62%",
          marginTop: "60px",
          gap: "60px",
        }}
      >
        <div className="w-full">
          <div
            className="flex w-full items-center justify-end "
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              color: "#6E6D7A",
            }}
          >
            <button
              onClick={() => {
                history.push(`/dashboard`);
                localStorage.removeItem("dataOnboarding");
                localStorage.removeItem("crypto");
                localStorage.removeItem("fiat");
                localStorage.removeItem("logo");
              }}
              className="text-sm font-sans "
              style={{ fontWeight: "700" }}
            >
              Skip the tour
            </button>
          </div>
        </div>
        <div
          className="w-full"
          style={{ paddingLeft: "60px", paddingRight: "60px" }}
        >
          <div className="w-full flex flex-col gap-6">
            <div
              className="text-2xl"
              style={{ fontWeight: "700", color: "#202124" }}
            >
              Upload your company logo
            </div>
            <div
              className="text-sm font-sans"
              style={{ fontWeight: "400", color: "#4D4D50" }}
            >
              Personalize your invoices with your company logo. Your invoices
              will contain your logo which will make it easier for clients to
              identify you.
            </div>
          </div>
          <div
            style={{ marginTop: "120px" }}
            className=" flex flex-col items-center justify-center"
          >
            {logo ? (
              <div className="flex flex-col items-center justify-center gap-6">
                <div
                  className="relative"
                  style={{
                    height: "240px",
                    width: "240px",
                    borderRadius: "100%",
                  }}
                >
                  <img
                    src={logo}
                    alt="logo upload"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                  />
                </div>

                <button
                  onClick={handleRemoveLogo}
                  className="relative text-sm  leading-[21px] "
                  style={{ color: "#D93F21", fontWeight: "500" }}
                >
                  Remove Logo
                </button>
              </div>
            ) : (
              <>
                <label
                  htmlFor="logo"
                  className="flex flex-col items-center justify-center gap-4 rounded-full  hover:opacity-80"
                  style={{
                    height: "240px",
                    width: "240px",
                    cursor: "pointer",
                    background: "#E9E9E9",
                  }}
                >
                  <div className="h-12 w-12">
                    <img src={iconUpload} alt="icon upload" />
                  </div>
                  <div
                    className="text-center text-base  "
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    Add your Logo here
                  </div>
                  <input
                    type="file"
                    id="logo"
                    onChange={(e) => onChangeImage(e)}
                    accept=".png, .gif, .jpeg"
                    className="h-0 w-0"
                  />
                </label>
                <div
                  style={{ fontWeight: "500", color: "#6A6A6C" }}
                  className="text-base mt-2"
                >
                  PNG, JPEG, GIF (50MB)
                </div>
              </>
            )}
          </div>
          <div
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              marginTop: "120px",
            }}
            className="flex justify-between "
          >
            <button
              onClick={() => {
                const url = `/onboarding?step=1`;
                window.location.href = url;
              }}
              className="flex items-center justify-center rounded-md"
              style={{
                border: "1px solid #1B92DB",
                width: "180px",
                height: "44px",
              }}
            >
              <div
                style={{ color: "#1B92DB", fontWeight: "600" }}
                className="text-sm"
              >
                Back
              </div>
            </button>
            <div>
              <CustomButton
                //disabled={disabled || loading}
                disabled={!Boolean(logo)}
                type="submit"
                onClick={() => {
                  const url = `/onboarding?step=3`;
                  window.location.href = url;
                }}
                style={{
                  width: "182px",
                  background:
                    "linear-gradient(36.25deg, #2B3DB9 2.93%, #0CD6F7 102.74%)",
                }}
                className="px-8 py-3 "
                title="Continue"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex h-full w-full justify-center  border-solid   p-[60px] "
        style={{
          width: "38%",
          boxShadow: "0px 4px 20px 0px rgba(96, 111, 134, 0.04)",

          border: "#DEDEDE",
        }}
      >
        <div
          className="flex h-full w-full flex-col items-center "
          style={{ paddingTop: "120px" }}
        >
          <div
            className="flex w-full justify-end "
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              borderRadius: "100%",
            }}
          >
            <div
              className="relative"
              style={{ width: "240px", height: "240px", borderRadius: "100%" }}
            >
              {logo ? (
                <div
                  style={{
                    borderRadius: "100%",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <img
                    src={logo}
                    alt="logo image"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                  />
                </div>
              ) : (
                <div
                  className=" rounded-full "
                  style={{
                    width: "240px",
                    height: "240px",
                    background: "#EBEBEB",
                    borderRadius: "100%",
                  }}
                ></div>
              )}
            </div>
          </div>
          <div
            className="flex w-full flex-col items-end px-8"
            style={{
              marginTop: "100px",
              gap: "24px",
              paddingRight: "60px",
            }}
          >
            <div
              style={{
                height: "60px",
                width: "240px",
                borderRadius: "12px",
                background: "#EBEBEB",
              }}
            ></div>
            <div
              style={{
                height: "60px",
                width: "428px",
                borderRadius: "12px",
                background: "#EBEBEB",
              }}
            ></div>
            <div
              className=" w-full  "
              style={{
                height: "60px",

                borderRadius: "12px",
                background: "#EBEBEB",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoUpload;
