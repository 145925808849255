import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as actions from "../actions";
import { theme } from "../configTheme";
import { LocalStorageService, history } from "../helpers";
import IcCheck from "../assets/images/signup/check.svg";
import IcInfo from "../assets/images/signup/info.svg";

import md5 from "md5";
import CustomButton from "../components/buttons/CustomButton";
import InputField from "../components/fields/InputField";
import InputFieldPw from "../components/fields/InputFieldPw";
import useWindowSize from "../hooks/useWindowSize";
import { CustomToastMessage } from "../utils/customToastMsg";

const FORM = {
  EMAIL: "email",
  FIRSTNAME: "first_name",
  LASTNAME: "last_name",
  PASSWORD: "password",
  CONFIRM_PASSWORD: "confirm_password",
};

const CHECK_PW = [
  "at least 8-35 characters in length",
  "have at least one lowercase character",
  "have at least one number",
  "have at least one non-alphanumeric character",
];

const passwordTests = [
  (value) => value && value.length >= 8 && value.length <= 35,
  (value) => /[a-z]/.test(value),
  (value) => /[0-9]/.test(value),
  (value) => /[^a-zA-Z0-9]/.test(value),
];

const schema = yup.object().shape({
  [FORM.EMAIL]: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address",
    ),
  [FORM.FIRSTNAME]: yup
    .string()
    .required("First name is required")
    .matches(/\S/, "Must not have spaces")
    .matches(/^[a-zA-Z\s]+$/, "Invalid first name"),

  [FORM.LASTNAME]: yup
    .string()
    .required("Last name is required")
    .matches(/\S/, "Must not have spaces")
    .matches(/^[a-zA-Z\s]+$/, "Invalid last name"),

  [FORM.PASSWORD]: yup
    .string()
    .matches(/\S/, "Must not have spaces")
    .min(8, CHECK_PW[0])
    .max(35, CHECK_PW[0])
    .test("lowercase", CHECK_PW[1], (value) => /[a-z]/.test(value))
    .test("number", CHECK_PW[2], (value) => /[0-9]/.test(value))
    .test("non-alphanumeric", CHECK_PW[3], (value) => /[^a-zA-Z0-9\s]/.test(value)),

  [FORM.CONFIRM_PASSWORD]: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref(FORM.PASSWORD), null], "Confirm Password does not match. Please enter again"),
});

const SignUp = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validationStatus, setValidationStatus] = useState(new Array(CHECK_PW.length).fill(false));
  const { isLoading, data, error } = useSelector((state) => state.verify.verifySendEmail);

  console.log("🚀 ~ SignUp ~ data:", data);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [FORM.EMAIL]: "",
      [FORM.FIRSTNAME]: "",
      [FORM.LASTNAME]: "",
      [FORM.PASSWORD]: "",
      [FORM.CONFIRM_PASSWORD]: "",
    },
  });
  const disabledConfirmPw = !watch(FORM.PASSWORD);

  const onFormSubmit = async (value) => {
    delete value?.[FORM.CONFIRM_PASSWORD];

    const formatData = {
      ...value,
      password: md5(value.password),
    };
    try {
      setLoading(true);
      const res = await dispatch(actions.authRegister(formatData));
      if (res?.payload?.data) {
        const params = {
          email: value.email,
        };
        // actions.verifySendEmail(params, dispatch);
        history.push("/verify-email?email=" + getValues(FORM.EMAIL));
      } else {
        const msg = res?.payload?.msg || res?.payload?.error_code || "Bad request";
        CustomToastMessage.error(msg);
      }
    } catch (err) {
      console.log(err);
      CustomToastMessage.error(err + "");
    } finally {
      setLoading(false);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(FORM.EMAIL) || !watch(FORM.FIRSTNAME) || !watch(FORM.LASTNAME) || !watch(FORM.PASSWORD);

  const validatePassword = async () => {
    for (let i = 0; i < CHECK_PW.length; i++) {
      const testResult = passwordTests[i](watch(FORM.PASSWORD));
      setValidationStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[i] = testResult;
        return newStatus;
      });
    }
  };

  useEffect(() => {
    validatePassword();
  }, [watch(FORM.PASSWORD)]);

  useEffect(() => {
    dispatch(actions.authLogout());
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     history.push("/verify-email?email=" + getValues(FORM.EMAIL));
  //   } else if (error) {
  //     CustomToastMessage.error(error.msg);
  //   }
  // }, [data, getValues, error]);

  return (
    <>
      {/* Image */}
      <img src={theme.loginBg} alt="image" width={569} className="hidden lg:block m-auto" />
      {/* Form */}
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.40)",
          backdropFilter: "blur(50px)",
        }}
        className="h-screen flex flex-col items-center rounded-tl-2xl rounded-bl-2xl overflow-auto"
      >
        <div
          style={{ padding: "60px 60px 24px 60px" }}
          className="flex w-full items-center justify-end"
        >
          <div className="flex items-center justify-center gap-2">
            <span style={{ color: "#202124" }} className="text-lg leading-7">
              Have an account?
            </span>
            <Link
              to="/login"
              style={{ color: "#2B4896" }}
              className="font-semibold text-sm leading-5"
            >
              Login
            </Link>
          </div>
        </div>

        <div style={{ marginBottom: 40, width: 523 }} className=" flex flex-col items-center gap-8">
          <img src={theme.loginLogo} alt="logo" style={{ height: 48 }} className="w-auto" />
          <div style={{ color: "#202124" }} className="leading-6 text-base text-center ">
            Merchant Portal POS25 helps you with the full cycle from tracking transactions to the
            Quản lý of all devices, partners.
          </div>
        </div>

        <form
          style={{ width: 420 }}
          onSubmit={handleSubmit(onFormSubmit, onErrors)}
          className="flex flex-col justify-center items-center "
        >
          <div className="w-full space-y-6">
            <InputField
              type="email"
              variant=""
              isRequired
              setValue={setValue}
              placeholder="Email *"
              id={FORM.EMAIL}
              name={FORM.EMAIL}
              value={watch(FORM.EMAIL)}
              extra=""
              register={register}
              errors={errors}
            />

            <div className="grid grid-cols-2 gap-4">
              <InputField
                variant=""
                isRequired
                setValue={setValue}
                placeholder="First name *"
                id={FORM.FIRSTNAME}
                name={FORM.FIRSTNAME}
                value={watch(FORM.FIRSTNAME)}
                extra=""
                register={register}
                errors={errors}
              />

              <InputField
                variant=""
                isRequired
                setValue={setValue}
                placeholder="Last name *"
                id={FORM.LASTNAME}
                name={FORM.LASTNAME}
                value={watch(FORM.LASTNAME)}
                extra=""
                register={register}
                errors={errors}
              />
            </div>

            <InputFieldPw
              variant=""
              isRequired
              setValue={setValue}
              placeholder="Password *"
              id={FORM.PASSWORD}
              name={FORM.PASSWORD}
              value={watch(FORM.PASSWORD)}
              extra=""
              register={register}
              // errors={errors}
            />

            <div>
              <InputFieldPw
                readOnly={disabledConfirmPw}
                variant=""
                isRequired
                setValue={setValue}
                placeholder="Confirm Password *"
                id={FORM.CONFIRM_PASSWORD}
                name={FORM.CONFIRM_PASSWORD}
                value={watch(FORM.CONFIRM_PASSWORD)}
                extra=""
                register={register}
                // errors={errors}
              />

              {errors?.[FORM.CONFIRM_PASSWORD] && (
                <p className="text-xs font-normal mt-2 text-error">
                  {errors?.[FORM.CONFIRM_PASSWORD]?.message}
                </p>
              )}
            </div>
          </div>

          <div className="mt-4 mb-10">
            <div>Password must have:</div>
            <div className="space-y-2 mt-2">
              {CHECK_PW.map((item, index) => {
                const checked = validationStatus[index];

                return (
                  <div key={index} style={{ color: "#98999A" }} className="flex items-center gap-2">
                    <img src={checked ? IcCheck : IcInfo} alt="icon" className="w-4 h-4" />
                    {item}
                  </div>
                );
              })}
            </div>
          </div>

          <CustomButton
            disabled={disabled || loading || isLoading}
            isLoading={loading || isLoading}
            type="submit"
            className="w-full "
            title="Create your account"
          />

          <div
            style={{ width: 263, lineHeight: "21px", color: "#98999A" }}
            className="mt-10 text-sm font-medium text-center "
          >
            By continuing, you agree to our <br />{" "}
            <Link to="/" className="underline">
              Terms & Conditions
            </Link>{" "}
            and{" "}
            <Link to="/" className="underline">
              Privacy Policy
            </Link>
            .
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUp;
