import React, { useEffect, useMemo, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import {
  getListStaff,
  getListStore,
} from "../../actions/metadata/metadata.actions";
import { createPChannel } from "../../actions/payment-channel";
import { selectListBankVam } from "../../reducers/bank/bank.reducer";
import {
  selectListStaff,
  selectListStore,
} from "../../reducers/metadata/metadata.reducer";
import { selectIsLoading } from "../../reducers/payment-channel.reducer";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { formatBankAccount } from "../../utils/lib";

import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/buttons/CustomButton";
import SelectInPaymentField from "../../components/fields/SelectInPaymentField";
import { getTableBank } from "../../actions";

const CreatePChannel = ({ setShowForm, getListPChannel }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Store");
  const [storeName, setStoreName] = useState(null);
  const [vaType, setVaType] = useState("STORE");
  const [bankAcc, setBankAcc] = useState("");
  const [storeId, setStoreId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [channel, setChannel] = useState("");
  const [checkField, setCheckField] = useState(false);
  const [docElem, setDocElem] = useState(null);

  const dataListBank = useSelector(selectListBankVam);
  const dataListStore = useSelector(selectListStore);
  const dataListStaff = useSelector(selectListStaff);
  const isLoading = useSelector(selectIsLoading);

  const listBank = useMemo(() => {
    if (!dataListBank?.data?.data) return [];
    return dataListBank?.data?.data.map((bank) => ({
      label: `${bank.bank_code} - ${bank.holder_name} - ${formatBankAccount(
        bank.account_no
      )}`,
      value: bank.account_no,
    }));
  }, [dataListBank]);
  const listStore = useMemo(() => {
    if (!dataListStore?.data) return [];
    return dataListStore?.data?.map((store) => ({
      label: store.name,
      value: store.id,
      storeName: store.name,
    }));
  }, [dataListStore]);
  const listStaff = useMemo(() => {
    if (!dataListStaff?.data) return [];
    return dataListStaff?.data.map((staff) => ({
      label: staff.full_name,
      value: staff.staff_id,
      storeName: staff.store_name,
    }));
  }, [dataListStaff]);
  
  const listChannel = [
    { label: "Website", value: "WEBSITE" },
    { label: "Kiosk", value: "KIOSK" },
    { label: "Mobile app", value: "MOBILE" },
    { label: "Tại quầy", value: "IN_STORE" },
    { label: "Call center", value: "CALL_CENTER" },
  ];

  useEffect(() => {
    dispatch(getTableBank({ page_size: 100000 }));
    dispatch(getListStore({ page_size: 100000 }));
    dispatch(getListStaff({ page_size: 100000 }));
  }, [dispatch]);

  const isValidate = () => {
    setCheckField(true);
    return !(
      !bankAcc ||
      (vaType == "STORE" && !storeId) ||
      (vaType == "STAFF" && !staffId) ||
      (vaType == "BILL" && (!staffId || !channel))
    );
  };

  const handleSubmit = async () => {
    const payload = new FormData();
    payload.append("bank_account_no", bankAcc);
    payload.append("type", vaType);
    if (vaType == "STORE") {
      payload.append("store_id", storeId);
      payload.append("store_name", storeName);
    } else {
      payload.append("staff_id", staffId);
      if (vaType == "BILL") payload.append("channel", channel);
    }
    // console.log('payload create VA: ', payload)
    const res = await dispatch(createPChannel(payload));
    if (res?.payload?.data) {
      setShowForm(false);
      CustomToastMessage.success(
        "Added payment channel successfully",
        `You have just successfully added payment channel ${storeName}.`
      );
      dispatch(getListPChannel());
    } else {
      setShowForm(false);
      CustomToastMessage.error("Adding payment failed", "");
    }
  };

  const fields = [
    {
      title: "Store",
      selectField: (
        <SelectInPaymentField
          data={listStore}
          placeHolder="Choose a store"
          setValue={setStoreId}
          setStoreName={setStoreName}
          docElem={docElem}
          checkField={checkField}
        />
      ),
    },
    {
      title: "Staff",
      selectField: (
        <SelectInPaymentField
          data={listStaff}
          placeHolder="Choose a staff"
          setValue={setStaffId}
          setStoreName={setStoreName}
          docElem={docElem}
          checkField={checkField}
        />
      ),
    },
    {
      title: "Sales Channel",
      selectField: (
        <SelectInPaymentField
          data={listChannel}
          placeHolder="Choose a Sale Channel"
          setValue={setChannel}
          docElem={docElem}
          checkField={checkField}
        />
      ),
    },
  ];

  document.onclick = (e) => setDocElem(e.target);

  return (
    <div className="fixed z-50 inset-0 bg-blur overflow-auto flex justify-center items-center">
      <div
        style={{ color: "#313131", maxWidth: 436, height: "min-content" }}
        className="bg-white rounded-2xl p-10 w-full relative"
      >
        <h3 className="text-2xl leading-8 font-bold text-center">
          Add payment channel
        </h3>

        <IoCloseSharp
          size={24}
          onClick={() => setShowForm(false)}
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
          }}
        />
        {/* Tabs */}
        <div
          className="grid grid-cols-3"
          style={{ marginTop: 32, borderBottom: "0.5px solid #262626" }}
        >
          {["Store", "Staff", "Invoice"].map((tab, index) => {
            return (
              <div
                onClick={() => {
                  setVaType(
                    tab == "Store" ? "STORE" : tab == "Staff" ? "STAFF" : "BILL"
                  );
                  setActiveTab(tab);
                  setCheckField(false);
                }}
                className={`flex justify-center items-center text-base leading-6 font-semibold 
                    ${activeTab === tab && " border-primary"}`}
                key={index}
                style={{
                  cursor: "pointer",
                  padding: "10px 16px",
                  borderBottomWidth: 2,
                  color: activeTab === tab ? "#262626" : "#B1BECF",
                }}
              >
                {tab}
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-4 mt-8">
          <div className="flex flex-col gap-2">
            <p
              style={{ fontSize: 14, color: "#505B76" }}
              className="leading-5 font-semibold"
            >
              Bank<span style={{ color: "#ED1C24" }}>*</span>
            </p>
            <SelectInPaymentField
              data={listBank}
              placeHolder="Choose a bank"
              setValue={setBankAcc}
              docElem={docElem}
              checkField={checkField}
            />
          </div>
          {fields.map((field, index) => (
            <div
              style={{
                display:
                  (activeTab == "Store" && (index == 1 || index == 2)) ||
                  (activeTab == "Staff" && (index == 0 || index == 2)) ||
                  (activeTab == "Invoice" && index == 0)
                    ? "none"
                    : "block",
              }}
              key={index}
              className="space-y-2"
            >
              <p
                style={{ fontSize: 14, color: "#505B76" }}
                className="leading-5 font-semibold"
              >
                {field.title}
                <span style={{ color: "#ED1C24" }}>*</span>
              </p>
              {field.selectField}
            </div>
          ))}
          {vaType == "BILL" && (
            <div className="space-y-2">
              <p
                style={{ fontSize: 14, color: "#505B76" }}
                className="leading-5 font-semibold"
              >
                Invoice ID/Order ID
              </p>
              <input
                style={{ border: "1px solid #D9DBE9" }}
                placeholder="Enter Invoice ID/Order ID"
                className="w-full p-3 rounded-lg text-sm leading-5 font-medium"
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4" style={{ marginTop: 42 }}>
          <CustomButton
            title="Cancel"
            outline={true}
            onClick={() => setShowForm(false)}
          />
          <CustomButton
            title="Add"
            disabled={isLoading}
            onClick={() => {
              if (isValidate()) handleSubmit();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePChannel;
