import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cart from "../../../../assets/images/product/cart.svg";
import success from "../../../../assets/images/product/success.gif";
import SectionTitle from "../../../../components/section-title";
import { theme } from "../../../../configTheme";
import {
  confirmOrder,
  getOrder,
  getProducts,
  getTableDetail,
} from "../../../../services/inventory-manager.service";
import { formatCurrency } from "../../../../utils/lib";
import { ButtonBorderRed, ButtonRed, Popup, Search } from "../Common";
import Payment from "../Table/Payment";
import ProductItem from "./ProductItem";

const Products = () => {
  const [showCart, setShowCart] = useState(false);
  const [products, setProducts] = useState([]);
  const [resultSearch, setResultSearch] = useState([]);
  const [pickedItem, setPickedItem] = useState([]);
  const { order_id, table_id } = useParams();
  const [inputSearch, setInputSearch] = useState("");
  const [table, setTable] = useState();
  const [total, setTotal] = useState(0);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [order, setOrder] = useState();

  const getTotalPrice = () => {
    var t = 0;
    if (pickedItem?.length > 0) {
      pickedItem.map((item) => {
        t += item.price * item.amount;
      });
    }
    return t;
  };

  const fetchTable = async () => {
    const res = await getTableDetail(table_id);
    if (res) {
      setTable(res);
    }
  };

  const fetchProduct = async () => {
    const res = await getProducts();
    if (res) {
      const data = res.filter((item) => item.status === 0);
      setProducts(data);
    }
  };

  const handleSearch = () => {
    if (inputSearch !== "") {
      const result = products.filter(
        (item) =>
          item.name.toUpperCase().search(inputSearch.toUpperCase()) !== -1
      );
      setResultSearch(result);
    } else {
      setResultSearch(products);
    }
  };

  const getOrderProduct = async () => {
    const res = await getOrder(order_id);
    if (res) {
      setOrder(res);
      setPickedItem(res.products);
    }
  };

  const handleConfirmOrder = async () => {
    await confirmOrder({
      order_id: order_id,
    });
    setShowPopupSuccess(true);
    getOrderProduct();
    setTimeout(() => setShowPopupSuccess(false), 3000);
  };

  useEffect(() => {
    fetchProduct();
  }, [order_id]);

  useEffect(() => {
    if (products) {
      handleSearch();
    }
  }, [products, inputSearch]);

  useEffect(() => {
    if (order_id) {
      getOrderProduct();
      if (table_id) {
        fetchTable();
      }
    }
  }, [order_id, table_id]);

  useEffect(() => {
    setTotal(getTotalPrice);
  }, [pickedItem]);

  return (
    <div className="w-full h-full relative">
      <div className="flex flex-col gap-10 relative h-full overflow-y-scroll">
        <div className="pb-1" style={{ backgroundColor: "#FAFAFA" }}>
          <SectionTitle title="Sales Manager" subtitle="List sản phẩm" />
          {table?.table_name && (
            <p
              className="mb-3 text-black text-sm font-normal leading-5"
              style={{ marginTop: "14px" }}
            >
              Tên bàn:{" "}
              <span className="font-semibold">{table?.table_name}</span>
            </p>
          )}
          <Search
            content={"Tìm kiếm (nhập tên món)"}
            setInputSearch={setInputSearch}
          />
          {/* <SearchProductParams
                        setSearchQuery={setSearchQuery}
                        id="search-bank-input"
                        extraClass="flex-1 bg-white shadow-lg"
                        placeholder="Tìm theo mã sản phẩm, tên sản phẩm"
                        /> */}
        </div>
        <div className="w-full">
          <div className="" style={{ paddingBottom: "88px" }}>
            {resultSearch.length > 0 ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-6">
                {resultSearch
                  ?.filter((i) => i.status === 0)
                  .map((item, index) => (
                    <ProductItem
                      key={index}
                      item={item}
                      pickedItem={pickedItem}
                      getOrderProduct={getOrderProduct}
                    />
                  ))}
              </div>
            ) : (
              <p className="w-full text-center">Không sản phần nào</p>
            )}
          </div>
        </div>
      </div>
      <div
        className="absolute left-0  w-full bottom-0 pb-4"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div
          className="flex flex-wrap gap-3 md:gap-0 justify-between items-center bg-white"
          style={{
            boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)",
            padding: "10px 16px 10px 16px",
          }}
        >
          <div
            className="flex gap-4 items-center cursor-pointer"
            onClick={() => setShowCart(true)}
          >
            <button
              className="p-3 rounded"
              style={{ backgroundColor: theme.primary }}
            >
              <img src={cart} alt="" />
            </button>
            <div>
              <p className="text-[#595959] text-base font-medium">
                Chi tiết order
              </p>
              <p
                className="text-[#262624] text-xl font-semibold"
                style={{ marginTop: "-2px" }}
              >
                Bạn đã Add {order?.order_info.product_amount} phần
              </p>
            </div>
          </div>
          <div className="flex gap-3 h-[48px]">
            {total > 0 && (
              <div
                className="w-[143px] h-[48px]"
                onClick={() => setShowCart(true)}
              >
                <ButtonBorderRed content={formatCurrency(total)} />
              </div>
            )}
            {order?.order_info.status === 2 ? (
              <button
                className={`w-[195px] h-[48px] ${
                  pickedItem.length === 0 ? "opacity-75" : "opacity-100"
                }`}
                onClick={() => handleConfirmOrder()}
                disabled={pickedItem.length === 0}
              >
                <ButtonRed content={"Xác nhận đơn hàng"} />
              </button>
            ) : (
              <button
                className={`w-[195px] h-[48px] ${
                  pickedItem.length === 0 ? "opacity-75" : "opacity-100"
                }`}
                onClick={() => setShowCart(true)}
                disabled={pickedItem.length === 0}
              >
                <ButtonRed content={"Thanh toán"} />
              </button>
            )}
          </div>
        </div>
      </div>
      {
        <Payment
          showCart={showCart}
          setShowCart={setShowCart}
          pickedItem={pickedItem}
          setPickedItem={setPickedItem}
          total={total}
          tableName={table?.table_name}
        />
      }
      {showPopupSuccess && (
        <Popup
          icon={success}
          showBtn={false}
          content={"Xác nhận đơn hàng Success"}
          setShowPopup={setShowPopupSuccess}
          handleClickBtn={() => setShowPopupSuccess(false)}
        />
      )}
    </div>
  );
};
export default Products;
