/**
 * * Common
 */
// export const ROUTE = "portal";

/**
 * * Transaction
 */
export const NFT_ENDPOINT = "transaction";
export const NFT_LIST_ITEMS = `/${NFT_ENDPOINT}`;

export const GET_URL_EXPORT_TRANSACTION = `/export_transaction`;
export const GET_MERCHANT_KEY_PORTAL = `/reepay`;
export const GET_LIST_TRANSACTION = `/installment`;
export const TRANSACTIONS_MOMO = "/momo";
export const TRANSACTIONS_ECOM = "/ecom";
export const TRANSACTIONS_QR = "/qr";
export const TRANSACTIONS_VA = "/va";
export const TRANSACTIONS_CASH = "/cash";
export const TRANSACTIONS_CARD = "card";

/**
 * * Dashboard
 */

export const DASHBOARD_ENDPOINT = "dashboard_report";
export const GET_DASHBOARD_REPORT = `/${DASHBOARD_ENDPOINT}`;

/**
 * * Password
 */

export const ForgotPassword_ENDPOINT = "user/forgot-password";
export const Forgot_Password = `/${ForgotPassword_ENDPOINT}`;
export const reset_Password = `/${ForgotPassword_ENDPOINT}`;

/**
 * * Url Payment
 */

export const UrlPayment_ENDPOINT = "/signature/sacombank";
export const UrlPayment = `${UrlPayment_ENDPOINT}`;

/**
 * * Common
 */
export const ROUTE_STORAGE = "storage";
export const GET_DOWNLOAD_IMAGE = `${ROUTE_STORAGE}/download_image`;

/**
 * * Order
 */
export const ORDER_ENDPOINT = "checkout";
export const ORDER_LIST_ITEMS = `${ORDER_ENDPOINT}/order/merchant`;
export const GET_MERCHANT_URL = `${ORDER_ENDPOINT}/merchant`;

/**
 * * BANK
 */
export const GET_TABLE_BANK = "/bank/account";
export const GET_SELECT_BANK = "/metadata/list-bank";
export const SEND_OTP_ADD_BANK = "/bank/account";
export const VALIDATE_OTP_ADD_BANK = "bank/account";
export const GET_HOLDER_NAME = "/bank/holder-name";

/**
 * * METADATA
 */
export const GET_LIST_STORE = "/metadata/store";
export const GET_LIST_STAFF = "/metadata/list-staff";
export const GET_LIST_EDC = "/metadata/edc";
export const GET_LIST_LINKED_VA = "/metadata/list-va-to-link";
export const SYNC_DATA = "/metadata/rule/sync";
export const GET_LIST_INDUSTRY = "/metadata/list-industry";
export const GET_LIST_CHAIN = "/metadata/list-chain";
export const GET_LIST_NETWORK = "/metadata/list-asset";
export const GET_LIST_BANK = "/metadata/list-bank";

/**
 * * USER
 */
export const UPLOAD_IMAGE = "/user/update-account";
export const POST_CRYPTO = "/fund-setting";
export const POST_FIAT = "/fund-setting";

/**
 * * PAYMENT CHANNEL
 */
export const PAYMENT_CHANNEL = "/channel-payment";

/**
 * * PROFILE
 */
export const BUSINESS = "/business";

export const EMPLOYEE = "/staff";

/**
 * * SETTING TRANSACTION
 */
export const GET_LIST_SETTING_TX = "/metadata/rule";
export const GET_LIST_CARD_SETTING_TX = "/metadata/list-card-type";
export const GET_LIST_BANK_ACCQUIRE_SETTING_TX = "/metadata/list-accquire";
export const DELETE_SETTING_TX = "/metadata/rule";

/**
 * * STORE MANAGER
 */
export const INVENTORY_MANAGER = "/inventory/table";
export const PRODUCT_MANAGER = "/inventory/product";
export const CUSTOMER_MANAGER = "/customer";
// export const INVOICE_MANAGER = "/point_of_sell/product";
export const INVOICE_MANAGER = "/invoice";
export const ORDER_MANAGER = "/order";
export const CATEGORY_MANAGER = "/category/";
export const NOTIFICATION = "/notification";
export const REGION = "/region";

/**
 * * FEEDBACK
 */
export const FEEDBACK = "/feedback";

/**
 * * STORE
 */
export const STORE = "/store";

/**
 * * SELL_REGISTER
 */
export const SELL_REGISTER = "/saas";

// CATEGORY

//ONBOARDING
