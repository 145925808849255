import axiosClient2 from "./client_axios2";
import { CUSTOMER_MANAGER, REGION } from "./endpoint";

export const customerManagerServices = {
  getListCustomer: (params) => {
    return axiosClient2.get(`${CUSTOMER_MANAGER}?${params}`);
  },

  getCustomerById: (id) => {
    return axiosClient2.get(`${CUSTOMER_MANAGER}/${id}`);
  },

  createCustomer: (params) => {
    return axiosClient2.post(CUSTOMER_MANAGER, params);
  },

  updateCustomer: (params) => {
    return axiosClient2.put(`${CUSTOMER_MANAGER}/${params.customer_id}`, params);
  },

  deleteCustomer: (params) => {
    return axiosClient2.delete(`${CUSTOMER_MANAGER}/${params}`);
  },

  getListProvince: (params) => {
    return axiosClient2.get(`${REGION}/provinces`);
  },

  getListDistrict: (province_code) => {
    return axiosClient2.get(`${REGION}/districts/${province_code}`);
  },

  getListWard: (district_code) => {
    return axiosClient2.get(`${REGION}/wards/${district_code}`);
  },
};
