import React, { useRef } from "react";
import { formatDateFromDate, getDaysDifference } from "../../../utils/lib";

const SelectDateConfig = ({
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  handleClose,
  setShowContentConfig,
}) => {
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  return (
    <div className="p-4 ">
      <div className="text-blackgray text-base font-semibold leading-5">Custom</div>
      <div className="text-blackgray text-14px font-semibold leading-5 mt-3 mb-4">
        {formatDateFromDate(startTime)} - {formatDateFromDate(endTime)}
      </div>

      <div className="flex gap-4 items-center mt4">
        <div className="flex flex-col gap-1">
          <label className="text-blackgray font-semibold">Start</label>
          <div className="relative cursor-pointer">
            <div
              onClick={() => startTimeRef.current.showPicker()}
              style={{
                width: "75%",
                top: "1px",
                bottom: "1px",
                left: "1px",
                padding: "10px 16px",
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
              }}
              className="absolute text-blackgray font-medium bg-white z-10 left-0 flex items-center "
            >
              {formatDateFromDate(startTime) || 'dd/mm/yyyy'}
            </div>
            <input
              ref={startTimeRef}
              type="date"
              name={"start_time"}
              id={"start_time"}
              defaultValue={startTime}
              max={endTime}
              value={startTime}
              onChange={(e) => {
                setStartTime(e.target.value);
              }}
              style={{ padding: "10px 16px" }}
              className="border rounded-lg border-gray-primary"
            />
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-blackgray font-semibold">End</label>
          <div className="relative cursor-pointer">
            <div
              onClick={() => endTimeRef.current.showPicker()}
              style={{
                width: "75%",
                top: "1px",
                bottom: "1px",
                left: "1px",
                padding: "10px 16px",
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
              }}
              className="absolute text-blackgray font-medium bg-white z-10  w-24 flex items-center"
            >
              {formatDateFromDate(endTime) || 'dd/mm/yyyy'}
            </div>
            <input
              ref={endTimeRef}
              type="date"
              name={"end_time"}
              id={"end_time"}
              defaultValue={endTime}
              min={startTime}
              max={endTime}
              value={endTime}
              onChange={(e) => {
                setEndTime(e.target.value);
              }}
              style={{ padding: "10px 16px" }}
              className="border rounded-lg border-gray-primary"
            />
          </div>
        </div>
      </div>

      <div className="text-blackgray text-14px font-medium leading-5 my-4">
        Selected {getDaysDifference(startTime, endTime)} days
      </div>

      <div className="flex items-center gap-6 w-full justify-end">
        <button
          className="text-blackgray text-14px font-medium leading-5 hover:opacity-75"
          onClick={() => {
            setShowContentConfig(false);
          }}
        >
          Cancel
        </button>
        <button
          className={` text-primary text-14px font-medium leading-5 hover:opacity-75`}
          onClick={handleClose}
        >
          Agree
        </button>
      </div>
    </div>
  );
};

export default SelectDateConfig;
