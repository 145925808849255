import React from "react";

const ModalWrapper = ({ children, className = "", style = {} }) => {
  return (
    <div style={{ zIndex: 42 }} className="fixed inset-0 bg-blur flex justify-center items-center">
      <div
        style={style}
        className={`bg-white relative rounded-lg flex flex-col py-6 items-center justify-center ${
          className ? className : " px-8 "
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
