import React, { useEffect, useState } from "react";
import { sellSetting } from "../../../assets";
import { handleToastErr } from "../../../utils/lib";
import axios from "axios";

const UploadImage = ({
  id = "uploadImage",
  initValue = null,
  setValue,
  register,
  name,
  isRequired,
}) => {
  const [file, setFile] = useState(initValue);
  const [scale, setScale] = useState(1);

  const handleResetValue = (value) => {
    if (file) URL.revokeObjectURL(file);

    const formData = new FormData();
    formData.append("file", value);

    axios.post("https://api-dev.wesap.vn/image", formData).then((res) => {
      setValue(name, res.data);
    });

    setFile(URL.createObjectURL(value));
  };

  const handleFileChange = (e) => {
    handleResetValue(e.target.files[0]);
    e.target.value = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setScale(1.1);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleResetValue(e.dataTransfer.files[0]);
    setScale(1);
  };

  useEffect(() => {
    setFile(initValue);
  }, [initValue]);

  return (
    <div
      style={{
        width: 382,
        height: 160,
        paddingBottom: 22,
        backgroundColor: "#F3F3F3",
        transform: `scale(${scale})`,
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      {...register(name, {
        required: {
          value: isRequired,
          message: handleToastErr(name),
        },
      })}
      className="drop-zone upload-file transition relative overflow-hidden rounded cursor-pointer border border-dashed border-grey-6"
    >
      {file && (
        <img src={file} alt="file" style={{ height: 160 }} className="object-contain mx-auto" />
      )}
      <div className={`${file ? "content" : "flex "} flex-col items-center justify-center pt-3`}>
        <img src={sellSetting.download} alt="download" width={32} height={32} />
        <p className="mt-2 text-grey-8 font-semibold leading-5 text-sm">
          Kéo và thả ảnh để tải lên
        </p>
        <p className="mb-2 text-grey-8 leading-4 text-xs">(Recommend 764x320px)</p>

        <label htmlFor={id}>
          <input
            id={id}
            type="file"
            className="hidden"
            onChange={(e) => handleFileChange(e)}
            accept=".png, .jpeg, .jpg"
          />
          <div className="bg-primary hover:opacity-75 border-primary text-white flex items-center justify-center gap-3 rounded border text-center px-8 py-3 text-14px font-semibold hover:opacity-70 whitespace-no-wrap">
            Chọn ảnh
          </div>
        </label>
      </div>
    </div>
  );
};

export default UploadImage;
