import React, { useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getProfile } from "../../actions/profile.action";
import IcAvatar from "../../assets/images/IcAvatar.png";
import down from "../../assets/images/icon/chevrondown.svg";
import search from "../../assets/images/icon/search.svg";
import { theme } from "../../configTheme";
import "../../css/components/navbar.css";
import bell from "../../assets/images/icon/bell.svg";
import { formatName } from "../../utils/lib";

const Navbar = ({ setShowSidebar }) => {
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual,
  );
  let { rightSidebar, collapsed } = { ...config };
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const name = formatName(data?.full_name);

  useEffect(() => {
    const getDataProfile = async () => {
      const res = await dispatch(getProfile());
      if (res.meta.requestStatus === "fulfilled" && res.payload.data) {
        const data = res?.payload?.data;
        setData(data);
      } else setData({});
    };
    getDataProfile();
  }, [dispatch]);

  const { pathname } = useLocation();

  return (
    <div className="">
      {pathname !== "/e-invoice" && (
        <div
          className="navbar navbar-1  px-3 md:pl-12 md:pr-6"
          style={{ paddingTop: 6, paddingBottom: 6 }}
        >
          <div
            className="navbar-inner w-full flex gap-6 items-center justify-end relative"
            style={{ height: 56 }}
          >
            {theme.discountWeb && (
              <a
                href={theme.discountWeb}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-2xl overflow-hidden hidden md:block"
                style={{ height: 80, flex: 1 }}
              >
                <img
                  src={theme.banner}
                  alt="banner"
                  style={{ width: "100%", height: 80, objectFit: "cover" }}
                />
              </a>
            )}
            <div style={{ flex: 1 }} className="block md:hidden">
              <FiMenu onClick={() => setShowSidebar(true)} size={24} />
            </div>
            <div className="flex items-center gap-4">
              <img src={search} alt="search" className="w-6 cursor-pointer" />
              <img src={bell} alt="bell" />
              {/* <Dropdown1 /> */}
              <div className="flex items-center gap-2 py-1 px-3 rounded-lg border border-gray-primary cursor-pointer">
                <span className="text-xs leading-5 font-semibold">ENG</span>
                <img src={down} alt="down" width={16} />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <img
                className="w-10 h-10 rounded-full shadow cursor-pointer"
                src={IcAvatar}
                alt="avatar"
              />
              <span className="text-sm leading-5 font-semibold text-blue-primary">{name}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
