import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListEDC } from "../../actions/edc/edc.actions";
import { selectListEDC } from "../../reducers/edc/edc.reducer";

import SectionTitle from "../../components/section-title";
import Datatable from "../../components/tsdatatable/datatables";
import IntergratePChannel from "./IntergratePChannel";
import { useHistory } from "react-router-dom";
import trash from "../../assets/images/payment/trash.svg";

import moreInfo from "../../assets/images/payment/moreInfo.svg";
import CustomButton from "../../components/buttons/CustomButton";
import ModalDelete from "../../components/modals/ModalDelete";

const DataTableComponent = ({
  filters,
  numOfPage,
  data,
  setFilters,
  setShowIntergrate,
  setSelectedEDC,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "EDC code",
        accessor: "serial_number",
        Cell: ({ value }) => <div>{value || ""}</div>,
      },
      {
        Header: "TID",
        Cell: ({ row }) => (
          <div>
            {row.original.aggregated_data.map((item, index) => (
              <div key={index}>{`${item.tid[0]} ${item.tid[1]}`}</div>
            ))}
          </div>
        ),
      },
      {
        Header: "MID",
        Cell: ({ row }) => (
          <div>
            {row.original.aggregated_data.map((item, index) => (
              <div key={index}>{`${item.mid[0]} ${item.mid[1]}`}</div>
            ))}
          </div>
        ),
      },
      {
        Header: "VA Code",
        accessor: "va_account_no",
        Cell: ({ value, row }) => {
          if (value)
            return (
              <div className="flex flex-col gap-2 items-start">
                <p>{value}</p>
                <p className="text-blue-2 font-medium italic">
                  {row.original.store_name}
                </p>
              </div>
            );
          else return "";
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setShowIntergrate(true);
                  setSelectedEDC(row.original);
                }}
              />
              <img
                src={trash}
                alt="trash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  // setSelectedChannel(row.original);
                  // setShowFormDelete(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Datatable
      columns={columns}
      data={data}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const EDC = () => {
  const dispatch = useDispatch();
  const [showIntergrate, setShowIntergrate] = useState(false);
  const [selectedEDC, setSelectedEDC] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [numOfPage, setNumOfPage] = useState("1");
  const [showFormDelete, setShowFormDelete] = useState(false);

  const history = useHistory();
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const data = useSelector(selectListEDC);

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);

  useEffect(() => {
    dispatch(getListEDC(filters));
  }, [dispatch, filters]);

  return (
    <>
      <div>
        <div className="flex w-full justify-between items-center">
          <SectionTitle title="EDC management" subtitle="EDC management" />

          <CustomButton
            type="add"
            outline
            title={"Add"}
            onClick={() => history.push("/edc/create")}
          />
        </div>
        <div className="mt-6">
          <DataTableComponent
            data={customData}
            filters={filters}
            setFilters={setFilters}
            numOfPage={numOfPage}
            setShowIntergrate={setShowIntergrate}
            setSelectedEDC={setSelectedEDC}
          />
        </div>
        {showIntergrate && (
          <IntergratePChannel
            selectedEDC={selectedEDC}
            setShow={setShowIntergrate}
            getListEDC={getListEDC}
          />
        )}

        {isOpenModal && (
          <ModalDelete
            setIsOpenModal={setIsOpenModal}
            title="Delete EDC"
            // desc={`Are you sure you want to delete the EDC ${selectedEDC?.full_name} with the employee code ${selectedEDC?.} ?`}
            desc={`Are you sure you want to delete the EDC  ?`}
            item={selectedEDC}
            //handleAction={handleDelete}
          />
        )}
      </div>

      {/* {showFormDelete && (
        <ModalDelete
          title="Xóa ShopQR"
          desc={`Bạn có chắc chắn muốn xóa ShopQR ${selectedChannel.store_name}?`}
          setIsOpenModal={setShowFormDelete}
          item={selectedChannel}
          handleAction={handleDelete}
        />
      )} */}
    </>
  );
};

export default EDC;
