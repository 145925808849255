import { createAsyncThunk } from "@reduxjs/toolkit";
import { storeServices, vamServices } from "../../services";

export const createStore = createAsyncThunk(
  "store/createStore",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.createStore(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCountry = createAsyncThunk(
  "store/getCountry",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.getCountry(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCity = createAsyncThunk(
  "store/getCity",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.getCity(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDistrict = createAsyncThunk(
  "store/getDistrict",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.getDistrict(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWard = createAsyncThunk(
  "store/getWard",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.getWard(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPresentative = createAsyncThunk(
  "store/getPresentative",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.getPresentative(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStore = createAsyncThunk(
  "store/deleteStore",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await storeServices.deleteStore(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
