import React from "react";

const TableWrapper = ({ children }) => {
  return (
    <div
      style={{ boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)" }}
      className="mt-8 p-10 bg-white rounded-2xl flex flex-col gap-6 w-full"
    >
      {children}
    </div>
  );
};

export default TableWrapper;
