import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { TbFilterDollar } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../../components/section-title";
import Datatable, { ReceiptCell, StatusCell } from "../../components/tsdatatable/datatables";
import { formatCurrency, formatDollarsCurrency } from "../../utils/lib";
import ic_eye from "../../assets/images/payment/eye.svg";
import upload from "../../assets/images/payment/upload.svg";
import CustomButton from "../../components/buttons/CustomButton";
import Filters from "../../components/filters/Filters";
import useWindowSize from "../../hooks/useWindowSize";
import { selectListOnchainTx } from "../../reducers/report/onchain-tx.reducer";
import { getTransactionOnchain } from "../../actions/report/onchain-tx.action";

const DatatableComponent = ({ customData, filters, numOfPage, data, total_amount, setFilters }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Time/Store/ID",
        accessor: "order_info",
        Cell: ({ value, row }) => {

          return (
            <div style={{ width: 240 }}>
              <p>
                <span className="font-semibold">Time: </span>
                <span>{value.created_at}</span>
              </p>
              <p className="my-1">
                <span className="font-semibold">Store: </span>
                <span>{value.store_name}</span>
              </p>
              <p>
                <span className="font-semibold">ID: </span>
                <span>{value.order_id}</span>
              </p>
            </div>
          )
        }
        // Cell: ({ value, row }) => <p>{formatDateTxn(value)}</p>
      },
      {
        Header: "Transaction Hash",
        accessor: "transaction_hash",
        Cell: ({ value, row }) => {
          return (
            <div>
              <p style={{ color: "#313131", fontSize: "14px" }}>
                <span className="font-medium mr-1">From:</span>
                <span className="font-normal">
                  {value.add_from.slice(0, 5) + "..." + value.add_from.slice(-5)}
                </span>
              </p>
              <p>
                <span className="font-medium mr-1">Tx Hash:</span>
                <a
                  href={value.tx_scan}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-normal underline text-blue-blue-primary"
                >
                  {value.tx_hash.slice(0, 5) + "..." + value.tx_hash.slice(-5)}
                </a>
              </p>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value, row }) => <span>{formatDollarsCurrency(value)}</span>,
      },
      {
        Header: "Asset",
        accessor: "asset",
        Cell: ({ value, row }) => (
          <div className="flex items-center space-x-2">
            <img src={value.asset_logo} alt="logo" width={20} height={20} />
            <span>{value.asset_amount}</span>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => <StatusCell data={row} />,
      },
      {
        Header: "Network",
        accessor: "network",
        Cell: ({ value, row }) => (
          <div className="flex items-center space-x-2">
            <img src={value.network_logo} alt="logo" width={20} height={20} />
            <span>{value.network_name}</span>
          </div>
        ),
      },
      {
        Header: "Channel",
        accessor: "payment_platform",
        Cell: ({ value, row }) => <p className="text-center capitalize">{value}</p>,
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <ReceiptCell data={row} />
          )
        },
      },
    ],
    [],
  );

  return (
    <Datatable
      styleWrapperTable={{
        width: 1600,
      }}
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
      total_amount={total_amount}
    />
  );
};

const TxOnchain = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectListOnchainTx);
  const [showFilter, setShowFilter] = useState(false);
  const [numOfPage, setNumOfPage] = useState("1");
  const { isMd } = useWindowSize();

  // GMT +7
  const currentTime = new Date();
  const [y, m, d] = [currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate()];
  const startTime = new Date(y, m, d);
  const endTime = new Date(y, m, d, 23, 59, 59);

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    status: "all",
    start_time: '',
    end_time: '',
    bill_id: "",
    store_id: "",
  });

  const [tempFilters, setTempFilters] = useState({
    page: 1,
    limit: 10,
    status: "all",
    start_time: '',
    end_time: '',
    bill_id: "",
    store_id: "",
  });

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page);
    return data?.data;
  }, [data?.data, data?.total_page]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
            "HH:mm:ss DD-MM-YYYY",
          ),
        };
      })
      : [];
  };

  useEffect(() => {
    dispatch(getTransactionOnchain(filters));
  }, [dispatch, filters]);

  return (
    <div className="relative">
      <div className="flex items-center justify-between">
        <SectionTitle title="Report" subtitle="On-chain transactions" />
        <CustomButton
          id="button-filter"
          onClick={() => setShowFilter(!showFilter)}
          className="block md:hidden"
          outline
          leftIcon={<TbFilterDollar size={16} />}
        />
      </div>
      <div
        id="filter"
        style={{
          border: "1px solid #F5F7FA",
          boxShadow: "0px 4px 20px 0px rgba(96, 111, 134, 0.06)",
          top: !isMd && 32,
          zIndex: showFilter || isMd ? 10 : -10,
        }}
        className="absolute right-0 md:relative flex gap-4 md:gap-0 flex-col md:flex-row md:justify-between items-end bg-white rounded-xl mt-6 p-6"
      >
        <Filters hasSelectPayment filters={tempFilters} setFilters={setTempFilters} />
        <div style={{ width: 280 }} className="grid grid-cols-2 w-full md:w-auto gap-4">
          <CustomButton
            filters={filters}
            setFilters={(params) => {
              setFilters(params);
              setTempFilters(params);
            }}
            type="reset"
            title={"Reset"}
          />
          <CustomButton
            filters={tempFilters}
            setFilters={setFilters}
            type="apply"
            title={"Apply"}
          />
        </div>
      </div>

      <div className="mt-6">
        <DatatableComponent
          customData={formatData(customData)}
          // customData={[]}
          filters={filters}
          setFilters={setFilters}
          numOfPage={numOfPage}
          data={[]}
          total_amount={data.total_amount}
        />
      </div>
    </div>
  );
};
export default TxOnchain;
