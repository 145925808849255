import React, { useState } from 'react'
import OrderDetail from '../OrderDetail/OrderDetail'
import PopupAddCustomer from '../AddCustomer/page';

const Payment = ({showCart,setShowCart,pickedItem,setPickedItem,total,tableName}) => {
    const [customer,setCustomer] = useState()
    const [showAddCustomer, setShowAddCustomer] = useState(false);
    const handleBack = () => {
        setShowAddCustomer(false)
        setShowCart(true)
    }
    const handleNext = () => {
        setShowAddCustomer(true)
        setShowCart(false)
    }

    return (
        <>
            {showCart&&<OrderDetail 
                pickedItem={pickedItem}
                setPickedItem={setPickedItem}
                total={total}
                setShowCart={setShowCart}
                handleNext={handleNext}
                customer={customer}
                tableName={tableName}
            />}
            {showAddCustomer&&<PopupAddCustomer
                showPopUp={setShowAddCustomer} 
                setCustomer={setCustomer}
                handleBack={handleBack}
            />}
        </>
    )
}

export default Payment
