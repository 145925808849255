import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness } from "../../actions/business.actions";
import { selectBusiness } from "../../reducers/business.reducer";

import SectionTitle from "../../components/section-title";
import SectionWrapper from "./SectionWrapper";
import Session from "./Session";
import TableWrapper from "./TableWrapper";
import { CellBank } from "../../components/tsdatatable/datatables";

export default function CompanyProfile() {
  const dispatch = useDispatch();
  const business = useSelector(selectBusiness);

  useEffect(() => {
    dispatch(getBusiness());
  }, [business.position, dispatch]);

  return (
    <div className="w-full">
      <SectionTitle title="BUSINESS INFORMATION" subtitle="Business Profile" />

      {/* Table */}
      <TableWrapper>
        <SectionWrapper title={`1. Business information`}>
          <Session title="Company's name">{business?.name}</Session>
          <Session title="Brand name">{business?.trademark}</Session>
          <Session title="Business">{business?.business}</Session>
          <Session title="Tax code">{business?.tax_no}</Session>
          <Session title="Address">{business?.address}</Session>
          <Session title="Phone number">{business?.phone}</Session>
          <Session title="Mobile number">{business?.mobile}</Session>
          <Session title="Email">{business?.email}</Session>
          <Session title="Website">{business?.website}</Session>
          <Session title="Processing bank">
            {business?.acquirer_bank?.logo_url !== "" && (
              <CellBank title={business?.acquirer_bank?.name}>
                <div
                  style={{ width: 60, height: 20 }}
                  className="flex items-center justify-start overflow-hidden"
                >
                  <img
                    src={business?.acquirer_bank?.logo_url}
                    alt="bank"
                    className="w-full h-full object-contain"
                  />
                </div>
              </CellBank>
            )}
          </Session>
        </SectionWrapper>
        <SectionWrapper title={`2. Representative information`}>
          <Session title="Representative name">
            {business?.representative_name}
          </Session>
          <Session title="Position">{business?.position}</Session>
          <Session title="ID CMND/ID/Passport number">
            {business?.indentification_no}
          </Session>
        </SectionWrapper>
      </TableWrapper>
    </div>
  );
}
