import React from 'react'

const EInvoice = () => {
    return (
        <div className="h-screen">
            <iframe
                zIndex={10}
                width="100%"
                height="100%"
                src="https://hddt-v2-uat.bizzi.vn"
            ></iframe>
        </div>
    )
}

export default EInvoice