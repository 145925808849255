import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getSelectBank } from "../../actions/bank/bank.actions";
import { BANK_CODE_DEFAULT } from "../../utils/constants";
import SearchField from "./SearchField";
import SelectFieldContent from "./select/SelectFieldContent";
import SelectFieldItem from "./select/SelectFieldItem";
import SelectFieldWrapper from "./select/SelectFieldWrapper";
import "./style.css";

const SelectBankField = (props) => {
  const { register, name, setValue, errors, extra, state, isRequired } = props;

  const [list, setList] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const handleSelectBox = (data) => {
    setCurrentItem(data);
    setValue(name, data?.bank_code);
    setShowContent(false);
  };

  // Function to filter the array based on the 'name' ,'bank_code' and 'full_name' property
  function filterByName() {
    if (searchValue.trim().length === 0) return list;

    const normalizedFilter = searchValue.toLowerCase().trim();
    return list.filter(
      (bank) =>
        bank.name.toLowerCase().includes(normalizedFilter) ||
        bank.bank_code.toLowerCase().includes(normalizedFilter) ||
        bank.full_name.toLowerCase().includes(normalizedFilter),
    );
  }

  const CurrentItemComp = () => (
    <div style={{ gap: 10 }} className="flex items-center ">
      <div className="w-20">
        <img src={currentItem?.logo_url} alt="logo" className={`object-cover`} />
      </div>

      <span className="text-14px leading-5">{currentItem?.name}</span>
    </div>
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getSelectBank());
        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data?.data;
          const bank = data.find((item) => item?.bank_code === BANK_CODE_DEFAULT);
          setValue(name, bank?.bank_code);
          setCurrentItem(bank);
          setList(data);
        }
      } catch (err) {}
    };
    getData();
  }, [dispatch, name, setValue]);

  return (
    <SelectFieldWrapper
      {...props}
      showContent={showContent}
      setShowContent={setShowContent}
      label={"Bank"}
      initialText="Select bank"
      currentValue={currentItem}
      currentItemComp={<CurrentItemComp />}
    >
      <SearchField
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        id="search-bank-input"
        extraClass="m-4 mb-1"
      />
      <SelectFieldContent>
        {filterByName().map((item, index) => {
          const active = currentItem?.bank_code === item?.bank_code;
          return (
            <SelectFieldItem
              key={`${item?.bank_code}-${index}`}
              onClick={() => handleSelectBox(item)}
              active={active}
            >
              {item?.name}
            </SelectFieldItem>
          );
        })}
      </SelectFieldContent>
    </SelectFieldWrapper>
  );
};

export default SelectBankField;
