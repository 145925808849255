import axiosClient2 from "./client_axios2";
import { ORDER_MANAGER } from "./endpoint";

export const orderManagerServices = {
  getListOrder: (params) => {
    return axiosClient2.get(`${ORDER_MANAGER}?${params}`);
  },

  getOrderById: (id) => {
    return axiosClient2.get(`${ORDER_MANAGER}/${id}`);
  },

  createOrder: (params) => {
    return axiosClient2.post(ORDER_MANAGER, params);
  },

  updateOrder: (params) => {
    return axiosClient2.put(`${ORDER_MANAGER}/${params.code}`, params);
  },

  deleteOrder: (params) => {
    return axiosClient2.delete(`${ORDER_MANAGER}/${params}`);
  },
};
