
import appClient from "../axios/app-client";
import { EMPLOYEE } from "../endpoint";

export const employeeServices = {
  getEmployeeById: (id) => {
    return appClient.get(`${EMPLOYEE}?id=${id}`);
  },

  createEmployee: (params) => {
    return appClient.post(EMPLOYEE, params);
  },

  updateEmployee: (params) => {
    return appClient.put(EMPLOYEE, params);
  },

  deleteEmployeeById: (params) => {
    return appClient.delete(EMPLOYEE, params);
  },
};
