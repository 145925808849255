import axiosClient2 from "./client_axios2";
import { CATEGORY_MANAGER } from "./endpoint";

export const categoryServices = {
  getListCategory: (params) => {
    return axiosClient2.get(CATEGORY_MANAGER, { params });
  },
  createCategory: (params) => {
    return axiosClient2.post(CATEGORY_MANAGER, params);
  },
  getCategoryById: (id) => {
    return axiosClient2.get(`${CATEGORY_MANAGER}/${id}`);
  },
  deleteCategory: (id) => {
    return axiosClient2.delete(`${CATEGORY_MANAGER}/${id}`);
  },
};
