import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchListTransInstallment, fetchListTransaction, getListTransCard, getListTransCash, getListTransEcom, getListTransMoMo, getListTransQRCode, getListTransVA } from "../actions";
import { TSService } from "../services/report/transaction.service";

const initialState = {
  items: null,
  pending: false,
  transVA: [],
  transEcom: [],
  transQR: [],
  transCard: [],
  transCash: [],
  transInstallment: []
};

const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListTransCard.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(getListTransCard.fulfilled, (state, action) => {
        state.transCard = action.payload.data;
        state.pending = false;
      })
      .addCase(getListTransCard.rejected, (state, action) => {
        state.items = [];
        state.pending = false;
      })
      .addCase(getListTransMoMo.fulfilled, (state, action) => {
        state.transMoMo = action.payload.data;
      })
      .addCase(getListTransEcom.fulfilled, (state, action) => {
        state.transEcom = action.payload.data;
      })
      .addCase(getListTransQRCode.fulfilled, (state, action) => {
        state.transQR = action.payload.data.items;
      })
      .addCase(getListTransVA.fulfilled, (state, action) => {
        state.transVA = action.payload.data
      })
      .addCase(getListTransCash.fulfilled, (state, action) => {
        state.transCash = action.payload.data;
      })
      .addCase(fetchListTransInstallment.fulfilled, (state, action) => {
        state.transInstallment = action.payload.data;
      }),


});

export const getListTS = createAsyncThunk("transaction/getListTS", async (data, { dispatch }) => {
  const _data = await TSService.getListTransaction(data);
  return _data;
});

export default transactionSlice.reducer;

export const { } = transactionSlice.actions;

export const selectTSSliceDashboard = (state) => state.transaction.items;
export const selectTransMoMo = (state) => state.transaction.transMoMo;
export const selectTransEcom = (state) => state.transaction.transEcom;
export const selectTransQR = (state) => state.transaction.transQR;
export const selectTransCard = (state) => state.transaction.transCard;
export const selectTransVA = (state) => state.transaction.transVA;
export const selectTransCash = (state) => state.transaction.transCash;
export const selectTransInstallment = (state) => state.transaction.transInstallment;




