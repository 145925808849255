import React, { useState } from "react";
import close from "../../../../assets/images/product/close.svg";
import CustomButton from "../../../../components/buttons/CustomButton";
import { createTable } from "../../../../services/inventory-manager.service";

const PopupCreateTable = ({ setShowPopup, setShowPopupSuccess }) => {
  const [tableName, setTableName] = useState("");
  const table = {
    table_name: "string",
    max_slot: 0,
    available_slot: 0,
    customer_id: 0,
    is_available: true,
  };

  const handleCreate = async () => {
    const res = await createTable({ ...table, table_name: tableName });
    if (res) {
      setShowPopupSuccess(true);
      setShowPopup(false);
    }
  };

  return (
    <div
      className="w-full h-full flex flex-col justify-center items-center fixed inset-0"
      style={{ backgroundColor: "rgba(0,0,0,0.70)", zIndex: "60" }}
    >
      <div className="px-8 py-6 w-fit h-fit rounded-3xl bg-white flex flex-col gap-6 items-center relative">
        <img
          src={close}
          alt=""
          className="absolute cursor-pointer"
          style={{ top: "16px", right: "16px" }}
          onClick={() => setShowPopup(false)}
        />
        <p className="text-black text-base font-semibold leading-5">Add bàn</p>
        <div className="w-full flex flex-col gap-1">
          <p
            className="text-sm font-semibold leading-5"
            style={{ color: "#262626" }}
          >
            Tên bàn
          </p>
          <input
            placeholder="Nhập tên bàn"
            className="rounded-lg create-form"
            style={{
              border: "1px solid #E8E8E8",
              width: "300px",
              padding: "10px 16px 10px 16px",
            }}
            onChange={(e) => setTableName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleCreate();
            }}
          />
        </div>
        <CustomButton
          title={"Lưu"}
          onClick={handleCreate}
          disabled={tableName === ""}
          className={"w-full"}
        />
      </div>
    </div>
  );
};

export default PopupCreateTable;
