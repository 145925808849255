import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { createQR } from '../../actions/payment-channel';
import { toPng } from 'html-to-image';
import { IoCloseSharp } from 'react-icons/io5';

import CustomButton from '../../components/buttons/CustomButton';

const ShowQR = ({ store, setShow }) => {
    const [qr, setQR] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        const getQR = async (va_account_no) => {
            const res = await dispatch(createQR({ va_account_no }))
            // console.log('>>> check qr: ', res)
            if (res?.payload?.data?.qr_base64) setQR(res?.payload?.data?.qr_base64)
            else console.log(res?.payload?.errors)
        }
        getQR(store.va_account_no)
    }, [])

    const ref = useRef(null)

    const handleDownloadQR = useCallback(() => {
        if (ref.current === null) return
        toPng(ref.current, { cacheBust: true })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = "qrcode.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => console.log(err));
    }, [ref]);

    return (
        <div className='fixed z-50 inset-0 bg-blur overflow-scroll flex justify-center items-center py-8'>
            <div className='relative flex flex-col gap-8 items-center bg-white rounded-2xl p-10'>
                {/* Icon Close */}
                <IoCloseSharp onClick={() => setShow(false)} style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }} size={24} />

                {/* QR Wrap */}
                <div ref={ref} className='p-6 space-y-4 rounded-2xl border border-hint flex flex-col items-center'>
                    <div style={{ width: 176, height: 176 }}>
                        <img src={`data:image/png;base64,${qr}`} alt='qr' className='w-full h-full' />
                    </div>
                    <p className='text-black text-sm leading-5 font-semibold text-center'>{store.name}</p>
                </div>

                {/* Buttons */}
                <div className='grid grid-cols-2 gap-4'>
                    <CustomButton title='Đóng' outline onClick={() => setShow(false)} />
                    <CustomButton title='Tải xuống QR' onClick={handleDownloadQR} />
                </div>
            </div>
        </div>
    )
}

export default ShowQR