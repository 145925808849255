/**
 * The `formatCurrency` function formats a number as Vietnamese Dong currency (VND) with a maximum of 0
 * decimal places and a maximum of 13 significant digits.
 * @param number - The `number` parameter is the value that you want to format as currency. It can be
 * any numerical value.
 * @returns The function `formatCurrency` returns a formatted string representing the given number in
 * Vietnamese Dong (VND) currency format.
 */
export function formatCurrency(number, useGrouping = true) {
  // if (typeof number != 'number') return ''
  if (!number) return "0 VND";
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    currencyDisplay: "code",
    useGrouping: useGrouping,
    maximumFractionDigits: 0,
    // maximumSignificantDigits: 13,
  });

  // const numberSlice = number.toString().length > 13 ? number.toString().slice(0, 13) : number;

  return formatter.format(number).replace(/\./g, ",");
}

/**
 * The `formatDollarsCurrency` function formats a given amount as a currency with the specified locale
 * and currency.
 * @param amount - The `amount` parameter in the `formatDollarsCurrency` function represents the
 * numerical value that you want to format as a currency. It is the amount of money that you want to
 * display in a specific currency format.
 * @param [locale=en-US] - The `locale` parameter in the `formatDollarsCurrency` function is used to
 * specify the locale for formatting the currency. It determines how the currency symbol, decimal
 * separator, and thousands separator are displayed based on the specified region or language. For
 * example, "en-US" represents English language and
 * @param [currency=USD] - The `currency` parameter in the `formatDollarsCurrency` function is used to
 * specify the currency code that you want the amount to be formatted in. In the provided function, the
 * default currency is set to "USD" (United States Dollar), but you can pass a different currency code
 * as
 * @returns The function `formatDollarsCurrency` returns a formatted string representation of the
 * `amount` parameter as a currency value based on the specified `locale` and `currency`. The
 * formatting includes the currency symbol, grouping separators, and decimal points according to the
 * locale settings.
 */

export const formatDollarsCurrency = (
  amount,
  maximumFractionDigits,
  locale = "en-US",
  currency = "USD",
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: maximumFractionDigits ? maximumFractionDigits : amount % 2 !== 0 ? 5 : 0,
  });

  return formatter.format(amount);
};

export const getSubDomain = () => {
  let subDomain = window.location.host.split(".")[0];
  if (window.location.host.split(".")) {
    if (process.env.NODE_ENV === "development") {
      //   subDomain = "atom-m-75-mms"
    }
    return subDomain;
  }
  return null;
};

export const getFileName = (fileRefix, fileType) => {
  let d = new Date();
  let dformat = `${d.getDate()}${d.getMonth() + 1}${d.getFullYear()}`;
  return `${fileRefix}_${dformat}.${fileType}`;
};

export const getStatus = (status) => {
  switch (status.toLowerCase()) {
    case "success":
      return "Success";
    case "fail":
      return "Error";
    case "pending":
      return "Đang xử lý";
    case "PAID":
      return "Paid";
    case "WAITING_FOR_PAID":
      return "Waiting";
    default:
      return "";
  }
};

export const handleToastErr = (key) => {
  if (key === "name") return "Vui lòng nhập tên đăng nhập";
  if (key === "password") return "Vui lòng nhập mật khẩu";
  if (key === "bank_code") return "Vui lòng chọn ngân hàng";
  if (key === "tax_no") return "Mã số thuế không tồn tại";
  if (key === "channel-name") return "Vui lòng nhập tên ShopQR";
  if (key === "prefix") return "Vui lòng nhập tiền tố đơn hàng";
  return "This field is required";
};

export const formatBankAccount = (accountNumber) => {
  if (accountNumber?.length < 4) return accountNumber;

  const lastFourDigits = accountNumber?.slice(-4);
  return "***" + lastFourDigits;
};

export const formatVaType = (vaType) => {
  if (!vaType) return "";
  if (vaType == "STORE") return "Cửa hàng";
  if (vaType == "STAFF") return "Nhân viên";
  if (vaType == "BILL") return "Hoá đơn";
};

export const formatChannel = (channel) => {
  if (!channel) return "";
  if (channel == "WEBSITE") return "Website";
  if (channel == "KIOSK") return "Kiosk";
  if (channel == "MOBILE") return "Mobile";
  if (channel == "IN_STORE") return "Tại quầy";
  if (channel == "CALL_CENTER") return "Call center";
};

export const formatPaymentMethod = (methodCode) => {
  if (typeof methodCode != "number") return " ";
  switch (methodCode) {
    case 0:
      return "Mã QR";
    case 1:
      return "Tiền mặt";
    case 2:
      return "Thanh toán thẻ";
    default:
      break;
  }
  //return methodCode == 0 ? 'QR' : methodCode == 1 ? 'Tiền mặt' : 'Thanh toán thẻ'
};

export const getCardTypeLogo = (cardType) => {
  const card = cardType.toString().toLowerCase();
  return `/images/card-type/${card || "master"}.svg`;
};

export const getBankLogo = (bankCode) => {
  const bank = bankCode.toString().toUpperCase();
  return `https://api.vietqr.io/img/${bank || "OCB"}.png`;
};

export const formatEmail = (email) => {
  const [local, domain] = email.split("@");
  const formattedLocal = local.slice(0, 2) + "..." + local.slice(-2);
  // Concatenate the formatted local part with the domain
  return formattedLocal + "@" + domain;
};

export const formatName = (fullname) => {
  if (!fullname) return "";
  if (fullname.includes(",")) {
    const resultArray = String(fullname)?.split(", ");
    return resultArray[1];
  }
  return fullname;
};

export const formatDateFromDate = (value) => {
  if (!value) return "";
  const arr = value?.toString()?.split("-");
  return arr?.reverse()?.join("/");
};

export const formatDateFromMilliseconds = (milliseconds) => {
  if (!milliseconds) return "--";
  const d = new Date(milliseconds * Math.pow(10, 3));
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return d.toLocaleDateString("vi-VN", options);
};

export const formatDateToMilliseconds = (date) => {
  return Date.parse(date) / Math.pow(10, 3);
};

export const formatDateTxn = (value, hasHHMM = true) => {
  const [date, time] = value.split(" ");
  // const arr = date?.split("-");
  // return arr?.reverse()?.join("/");
  const newDate = date.split("-").reverse().join("/");
  const [h, m, s] = time.split(":");
  if (hasHHMM) return newDate + " " + h + ":" + m;
  return newDate;
};

export function getDaysDifference(date1, date2) {
  // Create Date objects for the input dates
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Convert the time difference to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference ? daysDifference.toString() : "-- - --";
}

export const getToday = () => {
  let currentDate = new Date();
  // Set the start of the day (0:00:00)
  let startOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    0,
    0,
    0,
    0,
  );
  // Set the end of the day (23:59:59)
  let endOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59,
    999,
  );

  return [startOfDay, endOfDay];
};

export const getYesterday = () => {
  let currentDate = new Date();

  // Set the start of yesterday (0:00:00)
  let startOfYesterday = new Date(currentDate);
  startOfYesterday.setDate(currentDate.getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);

  // Set the end of yesterday (23:59:59)
  let endOfYesterday = new Date(currentDate);
  endOfYesterday.setDate(currentDate.getDate() - 1);
  endOfYesterday.setHours(23, 59, 59, 999);

  return [startOfYesterday, endOfYesterday];
};

export const getSevenDayAgo = () => {
  let currentDate = new Date();

  // Set the start of 7 days ago (0:00:00)
  let startOf7DaysAgo = new Date(currentDate);
  startOf7DaysAgo.setDate(currentDate.getDate() - 7);
  startOf7DaysAgo.setHours(0, 0, 0, 0);

  // Set the start of 7 days ago (0:00:00)
  let endOf7DaysAgo = new Date(currentDate);
  startOf7DaysAgo.setDate(currentDate.getDate() - 7);
  endOf7DaysAgo.setHours(23, 59, 59, 999);

  return [startOf7DaysAgo, endOf7DaysAgo];
};

export const getThisMonth = () => {
  let currentDate = new Date();

  // Set the start of the first day of the current month (0:00:00)
  let firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  firstDayOfCurrentMonth.setHours(0, 0, 0, 0);

  // Set the end of today (23:59:59)
  let endOfToday = new Date(currentDate);
  endOfToday.setHours(23, 59, 59, 999);
  return [firstDayOfCurrentMonth, endOfToday];
};

export const getLastMonth = () => {
  let currentDate = new Date();

  // Set the start of the first day of the last month (0:00:00)
  let firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  firstDayOfLastMonth.setHours(0, 0, 0, 0);

  // Set the end of the last day of the last month (23:59:59)
  let lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  lastDayOfLastMonth.setHours(23, 59, 59, 999);

  return [firstDayOfLastMonth, lastDayOfLastMonth];
};

export const getThisQuarter = () => {
  let currentDate = new Date();
  // Determine the current quarter
  const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);

  // Set the start of the first day of the current quarter (0:00:00)
  let firstDayOfCurrentQuarter = new Date(currentDate.getFullYear(), (currentQuarter - 1) * 3, 1);
  firstDayOfCurrentQuarter.setHours(0, 0, 0, 0);

  return [firstDayOfCurrentQuarter, currentDate];
};

export const isValidPhoneNumber = (p) => {
  const phoneRe = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
  const digits = p.replace(/\D/g, "");
  return phoneRe.test(digits);
};
