import React from 'react'
import Invoice from '../Invoice';
import Refund from './Refund';
import { useSelector } from 'react-redux';
import { selectAmount, selectTotalPrice } from '../../../../../reducers/paymentMethod.reducer';
import { getMerchant, updateInvoice } from '../../../../../services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { theme } from '../../../../../configTheme';

const Cash = ({invoice,setPrintInvoice}) => {
    const amount = useSelector(selectAmount);
    const { invoice_id } = useParams();
    const totalPrice = useSelector(selectTotalPrice);

    const handleUpdateInvoice = async () => {
        const merchant = await getMerchant()
        const res = await updateInvoice({
          invoice_id: invoice_id,
          payment_method: 1,
          receive_amount: Number(amount),
          return_amount: Number(amount) - totalPrice,
          merchant_id: merchant?.id
        });
        if (res) {
          setPrintInvoice(true);
          localStorage.setItem('evoice',true);
        }
      };
    
    return (
        <>
          <div className='flex flex-col items-center gap-4'>
            <p className='text-base font-semibold leading-6 text-black text-center'>
              Thanh toán tiền mặt
            </p>
            {/* <p
              className="text-sm font-medium leading-5 text-center max-w-full"
              style={{ color: "#8C8C8C" }}
            >
              Bạn đang chọn phương thức thanh toán tiền mặt. Khi thanh toán xong, bạn vui lòng bấm vào
              nút “Hoàn tất thanh toán” để hoàn thành.
            </p> */}
          </div>
          <Invoice invoice={invoice} />
          <Refund totalAmount={invoice.total_price} />
          <div className='w-full flex justify-center'>
            <button
              disabled={Number(amount) < Number(totalPrice) ||!amount}
              className={`${(Number(amount) < Number(totalPrice) || !amount) ? "opacity-50 cursor-auto" : 'cursor-pointer'} 
              w-64 px-6 py-3 flex items-center justify-center rounded-md text-base font-semibold leading-6 text-white`}
              style={{ backgroundColor: theme.primary}}
              onClick={() => handleUpdateInvoice()}
            >
              <span>Hoàn tất thanh toán</span>
            </button>
          </div>
        </>
      );
    }

export default Cash