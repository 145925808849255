import React from "react";
import Title from "./Title";

const ID = {
  RADIO1: "radio_1",
  RADIO2: "radio_2",
};

const Step1 = ({ name, setPayload }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col w-full mb-10">
      <Title
        title="Bắt đầu thôi. Câu nào sau đây mô tả chính xác bạn nhất?"
        subTitle="Chúng tôi sẽ hỗ trợ bạn hoàn thành việc thiết lập dựa trên nhu cầu kinh doanh của bạn."
      />

      <div className="flex justify-between items-center gap-4 mt-6 w-full ">
        <label
          style={{ border: "1px solid #DEDEDE", borderRadius: 6 }}
          className="flex-1 px-6 py-4 flex gap-3 items-center cursor-pointer hover:opacity-75 transition"
        >
          <input type="radio" name={name} onChange={handleChange} value={ID.RADIO1} />
          Tôi mới bắt đầu
        </label>

        <label
          style={{ border: "1px solid #DEDEDE", borderRadius: 6 }}
          className="flex-1 px-6 py-4 flex gap-3 items-center cursor-pointer hover:opacity-75 transition"
        >
          <input type="radio" name={name} onChange={handleChange} value={ID.RADIO2} />
          Tôi đã bán hàng trực tuyến hoặc trực tiếp tại cửa hàng
        </label>
      </div>
    </div>
  );
};

export default Step1;
