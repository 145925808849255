import React, { useEffect, useState } from "react";
import info from "../../../../assets/images/product/info-circle.svg";
import user from "../../../../assets/images/product/user-circle.svg";
import { ButtonBorderRed, ButtonRed } from "../Common";
import {
  getCustomer,
  updateCustomerOfOrder,
} from "../../../../services/inventory-manager.service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDebounce from "../OrderDetail/useDebounce";

const Step1 = ({
  step,
  phoneNumber,
  setPhoneNumber,
  setStep,
  setCustomer,
  handleBack,
}) => {
  const { order_id } = useParams();
  const [customers, setCustomers] = useState([]);
  const debounceReceive = useDebounce(phoneNumber, 500);

  const handleUpdateCustomer = async (item) => {
    const res = await updateCustomerOfOrder(order_id, item.customer_id);
    if (res) {
      setCustomer(item);
      handleBack();
    }
  };

  const handleSearchCustomer = async () => {
    const res = await getCustomer({
      phone: phoneNumber,
    });
    if (res.customers) {
      setCustomers(res.customers);
    }
  };

  useEffect(() => {
    if (step === 0) {
      handleSearchCustomer();
    }
  }, [debounceReceive, step]);

  return (
    <>
      <p className="text-black text-base font-semibold leading-5 text-center">
        Nhập thông tin customer
      </p>
      <div className="flex flex-col gap-4">
        <p className="text-black text-sm font-semibold leading-5">
          Số điện thoại
        </p>
        <input
          type="tel"
          name="phone"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          placeholder="Nhập số điện thoại"
          className="create-form outline-none text-sm font-medium leading-5 rounded-2 mt-2 w-full"
          style={{
            padding: "10px 16px 10px 16px",
            border: "1px solid #E8E8E8",
            width: "336px",
          }}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        {phoneNumber !== "" ? (
          customers.length === 0 ? (
            <div>
              <img src={info} alt="" className="mx-auto" />
              <div
                className="mt-2"
                style={{ color: "#8C8C8C", maxWidth: "313PX" }}
              >
                <p className="text-sm font-normal leading-5 text-center">
                  Số điện thoại “
                  <span
                    className="text-base font-semibold leading-6"
                    style={{ color: "#262626" }}
                  >
                    {phoneNumber}
                  </span>
                  ” này chưa được sử dụng.
                </p>
                <p className="text-sm font-normal leading-5 text-center">
                  Chọn “
                  <span
                    className="text-base font-semibold leading-6"
                    style={{ color: "#262626" }}
                  >
                    Add customer
                  </span>
                  ” để Add số điện thoại này.
                </p>
              </div>
            </div>
          ) : (
            <>
              <p className="text-black text-sm font-semibold">
                Thông tin customer
              </p>
              <div className="overflow-y-scroll" style={{ maxHeight: "200px" }}>
                <div className="flex flex-col gap-2">
                  {customers.map((item, index) => (
                    <button
                      className="flex gap-4"
                      key={index}
                      onClick={() => handleUpdateCustomer(item)}
                    >
                      <div
                        className="p-2"
                        style={{
                          backgroundColor: "#EEE",
                          borderRadius: "9.6px",
                        }}
                      >
                        <img src={user} alt="" />
                      </div>
                      <div className="flex flex-col gap-[4px] items-start">
                        <p className="text-black text-base font-semibold">
                          {item.name}
                        </p>
                        <p className="text-black text-sm font-normal leading-5">
                          customer
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </>
          )
        ) : (
          <></>
        )}
      </div>
      <div className="flex gap-2 h-12">
        <div className="w-full" onClick={() => handleBack()}>
          <ButtonBorderRed content={"Quay lại"} />
        </div>
        <button className="w-full" onClick={() => setStep(1)}>
          <ButtonRed content={"Tạo customer"} />
        </button>
      </div>
    </>
  );
};

export default Step1;
