import React from 'react';
import { useHistory } from "react-router-dom";
import chef_big from '../../../../assets/images/create-table/chef-big.svg';
import chef_small_green from '../../../../assets/images/create-table/chef-small-green.svg';
import reload from '../../../../assets/images/create-table/reload.svg';
import qr from '../../../../assets/images/product/img-qr.svg';
import { theme } from '../../../../configTheme';
import { createOrder } from '../../../../services/inventory-manager.service';
import { formatCurrency } from '../../../../utils/lib';
import { ButtonBorderRed } from '../Common';

export const TableItem = ({item,setTableUpdate,setShowPopupQR}) => {

    let navigate = useHistory();
    const handleCreateOrderId = async() => {
        const res = await createOrder({
            customer_id:0,  
            table_id:item.table_id
        });
        if(res.code === 201){
            navigate.push(`/sell-table/order/${item.table_id}/${res.data.order_id}`)
        }
    }

    return(
        <div
            className={`rounded-xl overflow-hidden flex flex-col justify-between items-center gap-4 pb-6`}
            style={{boxShadow: '0px 8px 11px -3px rgba(0, 0, 0, 0.08)'}}
        >
            <div className='w-full flex justify-between items-center'
                style={{
                    backgroundColor: item.order_id ? '#2EB553':'#003A6E',
                    padding: '10px 16px 10px 16px'
                }}
            >
                <span className="text-white text-sm font-semibold leading-5">
                    {item.table_name}
                </span>
                {!item.order_id? <button
                    className='py-1 px-2 rounded-3xl flex items-center'
                    style={{border: '1px dashed #94CDFF',gap: '6px'}}
                    onClick={() => setTableUpdate(item)}
                >
                    <img src={reload} width={14} alt="" />
                    <span className={`text-sm font-semibold leading-5`} style={{color: '#94CDFF'}}>
                        Cập nhật
                    </span>
                </button>
                :<button
                    className='py-1 px-2 rounded-3xl flex items-center'
                    style={{gap: '6px'}}
                    onClick={() => navigate.push(`/sell-table/order/${item.table_id}/${item.order_id}`)}
                >
                    <img src={chef_small_green} width={14} alt="" />
                    <span className={`text-white text-sm font-semibold leading-5`}>
                    {` ${item?.product_amount ? item?.product_amount:"0" } món`}
                    </span>
                </button>}
            </div>

            <div className='w-full flex justify-between items-center px-6'>
                <button 
                    className="bg-white rounded-xl flex items-center justify-center" 
                    style={{width:'40px', height:'40px'}}
                    onClick={() => setShowPopupQR(item)}
                > 
                    <img src={qr} alt='' />
                    {/* <QRCodeCanvas value={`https://menupayment-stag.esollabs.com/order/${item?.table_id}/${item?.order_id}`} size={40} /> */}
                </button>

                {!item.order_id? 
                    <img src={chef_big} alt="" width={40}/> 
                    :<div className="flex gap-6 items-center justify-center">
                        <span className="text-sm font-semibold leading-5" style={{color:'#262626'}}>Tổng hoá đơn:</span>
                        <span className="text-base font-semibold leading-6" style={{color:theme.primary}}>
                            {formatCurrency(item?.total_price)}
                        </span> 
                    </div>
                }
                <div className='w-10 h-10'></div>
            </div>

            {!item.order_id?
                <div className='w-full h-10 px-6' onClick={() => handleCreateOrderId()}>

                    <ButtonBorderRed content={'Tạo đơn'} className={'w-full'} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.53125 2.375H8.46875C8.55208 2.375 8.59375 2.41667 8.59375 2.5V13.5C8.59375 13.5833 8.55208 13.625 8.46875 13.625H7.53125C7.44792 13.625 7.40625 13.5833 7.40625 13.5V2.5C7.40625 2.41667 7.44792 2.375 7.53125 2.375Z" fill={theme.primary}/>
                            <path d="M2.75 7.40625H13.25C13.3333 7.40625 13.375 7.44792 13.375 7.53125V8.46875C13.375 8.55208 13.3333 8.59375 13.25 8.59375H2.75C2.66667 8.59375 2.625 8.55208 2.625 8.46875V7.53125C2.625 7.44792 2.66667 7.40625 2.75 7.40625Z" fill={theme.primary}/>
                        </svg>
                    </ButtonBorderRed>
                </div>
                :<div className='w-full h-10 px-6' onClick={() => navigate.push(`/sell-table/order/${item.table_id}/${item.order_id}`)}>
                <ButtonBorderRed content={'Xem chi tiết'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M12.4892 8.61587L12.7946 5.80397L12.8244 5.52944C12.8641 5.1634 12.5996 4.83445 12.2336 4.7947L9.14714 4.45956" stroke={theme.primary} strokeLinecap="round"/>
                        <path d="M10.8179 6.53793L5.10303 11.1333" stroke={theme.primary} strokeLinecap="round"/>
                    </svg>
                </ButtonBorderRed>
            </div>
            }
        </div>
    )
}
