import appClient from "../axios/app-client";
import axiosClient2 from "../clientAxios2";
import {
  GET_DASHBOARD_REPORT,
  GET_DOWNLOAD_IMAGE,
  GET_LIST_TRANSACTION,
  GET_URL_EXPORT_TRANSACTION,
  NFT_LIST_ITEMS,
  TRANSACTIONS_CARD,
  TRANSACTIONS_CASH,
  TRANSACTIONS_ECOM,
  TRANSACTIONS_MOMO,
  TRANSACTIONS_QR,
  TRANSACTIONS_VA
} from "../endpoint";

export const TSService = {
  getListTransaction: (params) => {
    return appClient.get(NFT_LIST_ITEMS, { params });
  },
  getListTransactionCard: (params) => {
    return appClient.get(`${NFT_LIST_ITEMS}/${TRANSACTIONS_CARD}`, { params });
  },

  getListTransactionMoMo: (params) => {
    return appClient.get(`${NFT_LIST_ITEMS}/${TRANSACTIONS_MOMO}`, { params });
  },

  getListTransactionEcom: (params) => {
    return appClient.get(`${NFT_LIST_ITEMS}/${TRANSACTIONS_ECOM}`, { params });
  },

  getListTransactionQRCode: (params) => {
    return appClient.get(`${NFT_LIST_ITEMS}/${TRANSACTIONS_QR}`, { params });
  },
  getListTransactionVA: (params) => {
    return appClient.get(`${NFT_LIST_ITEMS}/${TRANSACTIONS_VA}`, { params });
  },
  getListTransactionCash: (params) => {
    return appClient.get(`${NFT_LIST_ITEMS}/${TRANSACTIONS_CASH}`, { params });
  },
  getListTransactionInstallment: (params) => {
    return appClient.get(`${NFT_LIST_ITEMS}/${GET_LIST_TRANSACTION}`, { params });
  },


  getDashboardReport: (params) => {
    return appClient.get(GET_DASHBOARD_REPORT, { params });
  },

  getDownloadImage: (params) => {
    return axiosClient2.get(GET_DOWNLOAD_IMAGE, { params });
  },

  getExportURLTransaction: (params) => {
    return appClient.get(GET_URL_EXPORT_TRANSACTION, { params, responseType: 'blob' });
  },

  exportTxnCSV: (params) => {
    return appClient.get(NFT_LIST_ITEMS + '/' + params.type_txn + '?export=True', { params });
  },

  getListTransInstallment: (params) => {
    return appClient.get(GET_LIST_TRANSACTION, { params })
  }

};
