import React, { useEffect } from "react";

const URL_METABASES =
  "https://metabase-stag.pos25.app/public/dashboard/bda8b2da-052c-4941-a473-8a4f6ce70fce";

const Dashboard = () => {
  return (
    <div className="h-screen">
      <iframe
        title="Dashboard"
        width="100%"
        height="100%"
        zIndex={10}
        src={URL_METABASES}
      ></iframe>
    </div>
  );
}

export default Dashboard