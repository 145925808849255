/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { history } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { getListStore } from "../../actions/metadata/metadata.actions";
import { getListEDC } from "../../actions/edc/edc.actions";
import { selectListStore } from "../../reducers/metadata/metadata.reducer";
import { selectListEDC } from "../../reducers/edc/edc.reducer";
import useCheck from "./useCheck";
import useEDC from "./useEDC";
import useOther from "./useOther";

import SectionTitle from "../../components/section-title";
import CustomButton from "../../components/buttons/CustomButton";
import SelectInPaymentField from "../../components/fields/SelectInPaymentField";
import UploadField from "../../components/fields/upload/UploadField";
import useWindowSize from "../../hooks/useWindowSize";

const CreateFeedback = () => {
  const dispatch = useDispatch();
  const temp = () => {};
  const { tab } = useParams();
  const vieTab =
    tab == "check" ? "Tra soát" : tab == "edc" ? "Báo lỗi EDC" : "Vấn đề khác";
  const check = useCheck({ temp, temp, temp });
  const edc = useEDC({ temp, temp, temp });
  const other = useOther({ temp, temp, temp });
  const tabList = [check, edc, other];
  const activeTab = tabList.find((item) => item.type == tab);
  const { isMd } = useWindowSize();

  const [payload, setPayload] = useState({
    store_id: "",
    content: "",
    description: "",
    serial_number: "",
    images: [],
  });

  const dataListStore = useSelector(selectListStore);
  const dataListEDC = useSelector(selectListEDC);

  const listStore = useMemo(() => {
    if (!dataListStore?.data) return [];
    return dataListStore?.data.map((store) => ({
      label: store.name,
      value: store.id,
    }));
  }, [dataListStore]);

  const listEDC = useMemo(() => {
    if (!dataListEDC?.data) return [];
    return dataListEDC?.data.map((edc) => ({
      label: edc.serial_number,
      value: edc.serial_number,
    }));
  }, [dataListEDC]);

  useEffect(() => {
    dispatch(getListStore());
    dispatch(getListEDC());
  }, [dispatch]);

  const [listField, setListField] = useState([]);

  useEffect(() => {
    const listField = [
      {
        title: "Cửa hàng",
        data: listStore,
        placeHolder: "Chọn cửa hàng",
        setValue: (store_id) => setPayload({ ...payload, store_id }),
      },
      {
        title: "Nội dung",
        data: activeTab.listContent,
        placeHolder: "Chọn nội dung",
        setValue: (content) => setPayload({ ...payload, content }),
      },
      {
        title: "Mã EDC",
        data: listEDC,
        placeHolder: "Chọn mã EDC",
        setValue: (serial_number) => setPayload({ ...payload, serial_number }),
      },
    ];
    // if (tab === "check") listField.splice(2, 1);
    if (tab === "other") listField.splice(1, 1);
    setListField(listField);
  }, [tab, listStore, listEDC, payload]);

  const { store_id, description, images, content, serial_number } = payload;
  const disabled =
    !store_id ||
    !description ||
    images.length == 0 ||
    (!content && tab != "other") ||
    (!serial_number && tab != "check");
  const handleSubmit = () => activeTab.handleCreate(payload);
  // const handleSubmit = () => console.log(payload);

  return (
    <div>
      <SectionTitle
        title="Error"
        subtitle={`Add ${tab == "other" ? "problem" : "request"}`}
        rightTitle={vieTab}
      />

      <div className="mt-8 space-y-6">
        {/* Tab List */}
        <div
          className="bg-white rounded-xl flex"
          style={{
            width: isMd ? "min-content" : "100%",
            boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)",
            padding: 10,
          }}
        >
          {tabList.map((item) => (
            <button
              key={item.type}
              style={{
                width: isMd ? 163 : "100%",
                transition: "all 0.1s linear",
              }}
              onClick={() => history.push(`/feedback/${item.type}`)}
              className={`px-8 py-3 rounded text-sm leading-5 font-semibold text-center
              ${item.type == tab ? "bg-primary text-white" : "text-blackgray"}`}
            >
              {item.type == "check"
                ? "Invest"
                : item.type == "edc"
                ? "Report EDC error"
                : "Other problems"}
            </button>
          ))}
        </div>

        {/* Form */}
        <div
          style={{ boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)" }}
          className="bg-white p-10 rounded-2xl"
        >
          <div className="w-full grid md:grid-cols-2 gap-6">
            <div className="w-full flex flex-col gap-6">
              {listField.map((field) => (
                <div key={field.title} className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    {field.title}
                    <span className="text-error">*</span>
                  </p>
                  <SelectInPaymentField
                    data={field.data}
                    placeHolder={field.placeHolder}
                    setValue={field.setValue}
                    // resetFlag={resetFilters}
                    // docElem={docElem}
                  />
                </div>
              ))}
              {listField.length == 2 && (
                <div className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    Describe<span className="text-error">*</span>
                  </p>
                  <input
                    onChange={(e) =>
                      setPayload({ ...payload, description: e.target.value })
                    }
                    style={{ padding: "10px 16px" }}
                    placeholder="Enter a description"
                    className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint"
                  />
                </div>
              )}
            </div>

            <div className="w-full flex flex-col gap-6">
              {/* Image Upload */}
              <UploadField
                label="Image"
                isRequired
                filesPayload={payload.images}
                setFilesPayload={(files) =>
                  setPayload({ ...payload, images: files })
                }
              />
              {listField.length == 3 && (
                <div className="space-y-2">
                  <p className="text-blackgray text-sm leading-5 font-semibold">
                    Mô tả<span className="text-error">*</span>
                  </p>
                  <input
                    onChange={(e) =>
                      setPayload({ ...payload, description: e.target.value })
                    }
                    style={{ padding: "10px 16px" }}
                    placeholder="Enter a description"
                    className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint"
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{ width: isMd ? 380 : "100%" }}
            className="grid grid-cols-2 gap-4 ml-auto mt-6"
          >
            <CustomButton
              title="Back"
              outline
              onClick={() => history.push(`/feedback/${tab}`)}
            />
            <CustomButton
              disabled={disabled}
              title="Send"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFeedback;
