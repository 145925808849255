import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getHolderName, verifyBank } from "../../actions/bank/bank.actions";
import CustomButton from "../../components/buttons/CustomButton";
import InputField from "../../components/fields/InputField";
import SelectBankField from "../../components/fields/SelectBankField";
import ModalOTP from "../../components/modals/otp/ModalOTP";
import { history } from "../../helpers";
import useDebounce from "../../hooks/useDebounce";
import { toastConfirmFormBankFail, toastMessage } from "../../utils/constants";
import { ERROR_CODE } from "../../utils/errorCode";

const FORM = {
  BANK_CODE: "bank_code",
  BANK_ACCOUNT_NO: "bank_account_no",
  HOLDER_NAME: "holder_name",
};

const CreateBank = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const debounce = useDebounce(watch(FORM.BANK_ACCOUNT_NO));

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(verifyBank(value));
      console.log(res);
      if (res?.meta?.requestStatus === "fulfilled") {
        setIsOpen(true);
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          toastConfirmFormBankFail();
          return;
        }
        toastMessage.error(msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onErrors = (errors) => console.error(errors);

  const handleClose = () => {
    setIsOpen(false);
  };

  const getDataHolderName = async () => {
    try {
      const res = await dispatch(
        getHolderName({
          bank_code: watch(FORM.BANK_CODE),
          bank_account_no: debounce,
        }),
      );
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data !== "") {
        setValue(FORM.HOLDER_NAME, res?.payload?.data?.trim());
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!debounce.trim()) {
      setValue(FORM.HOLDER_NAME, "");
      return;
    }
    getDataHolderName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  return (
    <>
      <ModalOTP isOpen={isOpen} onClose={handleClose} />
      <div className="mx-auto mt-10 w-full md:w-128 rounded-2xl bg-white p-12 shadow-[0_8px_11px_-3px_rgba(0,0,0,0.08)] lg:w-fit ">
        <h2 className="mb-8 text-center text-2xl font-bold text-dark-primary">
          Add a bank account
        </h2>
        <form onSubmit={handleSubmit(onFormSubmit, onErrors)} className="col flex flex-col">
          <div className="flex flex-col space-y-4">
            <SelectBankField
              isRequired
              register={register}
              name={FORM.BANK_CODE}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              isRequired
              setValue={setValue}
              type="number"
              label="Account number"
              placeholder="Select account number"
              id={FORM.BANK_ACCOUNT_NO}
              name={FORM.BANK_ACCOUNT_NO}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              readOnly
              isRequired
              setValue={setValue}
              value={watch(FORM.HOLDER_NAME)}
              defaultValue={watch(FORM.HOLDER_NAME)}
              label="Account name"
              placeholder="--"
              id={FORM.HOLDER_NAME}
              name={FORM.HOLDER_NAME}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>

          <div style={{ marginTop: "42px" }} className="grid grid-cols-2 gap-4">
            <CustomButton onClick={() => history.push("/bank")} title="Cancel" outline />

            <CustomButton
              type="submit"
              disabled={!watch(FORM.BANK_ACCOUNT_NO) || !watch(FORM.HOLDER_NAME)}
              title="Connect"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateBank;
