
import appClient from "../axios/app-client";
import {
  GET_HOLDER_NAME,
  GET_SELECT_BANK,
  GET_TABLE_BANK,
  SEND_OTP_ADD_BANK,
  VALIDATE_OTP_ADD_BANK,
} from "../endpoint";

export const bankServices = {
  getTableBank: (params) => {
    return appClient.get(GET_TABLE_BANK, { params });
  },

  getSelectBank: () => {
    return appClient.get(GET_SELECT_BANK);
  },

  verifyBank: (params) => {
    return appClient.post(SEND_OTP_ADD_BANK, params);
  },

  verifyBankWithOTP: (params) => {
    return appClient.put(VALIDATE_OTP_ADD_BANK, params);
  },

  getHolderName: ({ bank_account_no = "", bank_code = "" }) => {
    return appClient.get(
      `${GET_HOLDER_NAME}?bank_account_no=${bank_account_no}&bank_code=${bank_code}`
    );
  },

  deleteBankVam: (params) => {
    return appClient.delete(GET_TABLE_BANK, params);
  },
};
