import appClient from "../axios/app-client";


export const authService = {
  login: (params) => {
    return appClient.post(`/user/login`, params);
  },
  register: (params) => {
    return appClient.post(`/user/create-account`, params);
  },
  verifySendEmail: (params) => {
    return appClient.get(`/user/verify-send-mail`, { params });
  },
  verifyAccount: (params) => {
    return appClient.get(`/user/verify-account`, { params });
  },
};
