/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import CustomButton from "../../components/buttons/CustomButton";

import { useForm } from "react-hook-form";
// import Alert from '../alerts'
import { useDispatch, useSelector } from "react-redux";
import {
  SetForgotPassword,
  SetPassword,
  Getstatus,
  resetState,
  Getpending,
} from "../../reducers/password.reducer";
import { history } from "../../helpers";
import { theme } from "../../configTheme";

const Index = () => {
  const dispatch = useDispatch();
  const { handleSubmit, errors, register, watch } = useForm();

  const [data, onSubmit] = useState(null);
  const [message, setMessage] = useState();

  const call = useSelector(SetForgotPassword);
  const status = useSelector(Getstatus);
  const pending = useSelector(Getpending);

  const onSubmitFn = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  const onErrors = (errors) => console.error(errors);

  //reset state
  const reset = () => {
    dispatch(resetState());
  };

  // hidden email
  function maskPII(str) {
    const indexOfAt = str.indexOf("@");
    if (indexOfAt <= 2) return str;
    return str[0] + "*".repeat(indexOfAt - 2) + str.substring(indexOfAt - 1);
  }

  //send email
  useEffect(() => {
    if (data != null) {
      dispatch(SetPassword(data));
    }
  }, [data]);

  //error alert
  useEffect(() => {
    if (!status && status != null) {
      setMessage(call?.message);
    }
  }, [status]);

  useEffect(() => {
    setMessage("");
  }, [errors["email"]]);

  //reset state when change page
  useEffect(() => reset, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div
        style={{
          backgroundColor: "#FFFFFF66",
        }}
        className="relative max-w-modal p-8 flex items-center justify-center rounded-2xl "
      >
        {!status ? (
          <div style={{ width: 399 }} className="flex flex-col justify-center items-center ">
            <div className="flex flex-col items-center text-login-color-5">
              <div className="w-8 h-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="40"
                  viewBox="0 0 34 40"
                  fill="none"
                >
                  <path
                    d="M26.3436 14.8961V10.6021C26.3436 5.57609 22.2676 1.50009 17.2416 1.50009C12.2156 1.47809 8.12356 5.53409 8.10156 10.5621V10.6021V14.8961"
                    stroke={theme.primary}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.866 38.4999H9.584C5.396 38.4999 2 35.1059 2 30.9159V22.3379C2 18.1479 5.396 14.7539 9.584 14.7539H24.866C29.054 14.7539 32.45 18.1479 32.45 22.3379V30.9159C32.45 35.1059 29.054 38.4999 24.866 38.4999Z"
                    stroke={theme.primary}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.2266 24.4062V28.8482"
                    stroke={theme.primary}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p
                style={{
                  color: "#262626",
                  fontSize: "20px",
                }}
                className=" mt-2 font-bold"
              >
                Forgot password?
              </p>
              <p
                style={{
                  color: "#262626",
                }}
                className="text-sm mt-6 text-center"
              >
                Enter your email and Merchant Portal POS25 will send you a link to access your
                account again.
              </p>
            </div>

            <form
              onSubmit={handleSubmit(onSubmitFn, onErrors)}
              className="form w-full flex flex-col mt-3"
            >
              <div className="relative flex justify-center items-center ">
                <input
                  ref={register({ required: true })}
                  name="email"
                  type="email"
                  style={{}}
                  className={`w-full rounded pr-10 px-4 py-2  ${
                    errors["email"] ? "border-red-500" : ""
                  }`}
                  placeholder="Please enter email"
                />
                <div className={`lds-dual-ring pr-4 ${pending ? "inline-block" : "hidden"}`} />
              </div>
              <div className="form-error text-red-500 h-4 mt-2">
                {errors["email"] ? "Please enter a valid email" : message}
              </div>

              <div className="gap-4 flex flex-col mt-6">
                <input
                  type="submit"
                  disabled={!watch("email")}
                  style={{
                    background: "linear-gradient(36.25deg, #2B3DB9 2.93%, #0CD6F7 102.74%)",
                  }}
                  value="Send link"
                  className="btn btn-default font-semibold text-white rounded-lg py-3 bg-primary"
                />

                <button
                  type="button"
                  onClick={history.goBack}
                  style={{
                    background: "#fff",
                    borderColor: "#2B3DB9",
                  }}
                  className="btn btn-default  font-semibold rounded-lg text-primary border border-primary"
                >
                  <span className="text-gradient">Back</span>
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div style={{ width: 399 }} className="flex flex-col justify-center items-center ">
            <div className="w-full flex flex-col items-center text-text-primary">
              <svg
                width="39"
                height="32"
                viewBox="0 0 39 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 8L17.28 18.52C17.9373 18.9586 18.7098 19.1926 19.5 19.1926C20.2902 19.1926 21.0627 18.9586 21.72 18.52L37.5 8M5.5 30H33.5C34.5609 30 35.5783 29.5786 36.3284 28.8284C37.0786 28.0783 37.5 27.0609 37.5 26V6C37.5 4.93913 37.0786 3.92172 36.3284 3.17157C35.5783 2.42143 34.5609 2 33.5 2H5.5C4.43913 2 3.42172 2.42143 2.67157 3.17157C1.92143 3.92172 1.5 4.93913 1.5 6V26C1.5 27.0609 1.92143 28.0783 2.67157 28.8284C3.42172 29.5786 4.43913 30 5.5 30Z"
                  stroke={theme.primary}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p className="text-xl leading-7 font-bold mt-3">Đã gửi email</p>
              <p className="text-sm leading-5 font-normal mt-6 text-center">
                ATOM has sent an email to{" "}
                <span className="font-semibold">{maskPII(data?.email)}</span> Attached is a link for
                you to access your account again. Please check your email
              </p>
            </div>

            <div className="gap-4 flex flex-col mt-10">
              <CustomButton title="Resend the login link" type="submit" onClick={reset} />
              <CustomButton title="Back" type="submit" onClick={history.goBack} outline />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
