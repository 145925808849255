import { createSlice } from "@reduxjs/toolkit";

const verifySlice = createSlice({
  name: "verify",
  initialState: {
    verifySendEmail: {
      loading: false,
      error: null,
      data: null,
    },
    verifyAccount: {
      loading: false,
      error: null,
      data: null,
    },
  },
  reducers: {
    verifySendEmailRequest: (state) => {
      state.verifySendEmail.loading = true;
    },
    verifySendEmailSuccess: (state, action) => {
      state.verifySendEmail.loading = false;
      state.verifySendEmail.data = action.payload;
      state.verifySendEmail.error = null;
    },
    verifySendEmailFailure: (state, action) => {
      state.verifySendEmail.loading = false;
      state.verifySendEmail.error = action.payload;
    },
    verifyAccountRequest: (state) => {
      state.verifyAccount.loading = true;
    },
    verifyAccountSuccess: (state, action) => {
      state.verifyAccount.loading = false;
      state.verifyAccount.data = action.payload;
      state.verifyAccount.error = null;
    },
    verifyAccountFailure: (state, action) => {
      state.verifyAccount.loading = false;
      state.verifyAccount.error = action.payload;
    },
  },
});

export const {
  verifySendEmailRequest,
  verifySendEmailSuccess,
  verifySendEmailFailure,
  verifyAccountRequest,
  verifyAccountSuccess,
  verifyAccountFailure,
} = verifySlice.actions;
export default verifySlice.reducer;
