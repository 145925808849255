import { createAsyncThunk } from "@reduxjs/toolkit";
import { edcServices } from "../../services/edc/edc.service";

export const mapEDC = createAsyncThunk(
  "edc/mapEDC",
  async (data, { dispatch }) => {
    try {
      const _data = await edcServices.mapEDC(data);
      return _data;
    } catch (error) {
      return error;
    }
  }
);

export const getListEDC = createAsyncThunk(
  "edc/getListEDC",
  async (data, { dispatch }) => {
    const _data = await edcServices.getListEDC(data);
    return _data;
  }
);

// export const deleteEDCById = createAsyncThunk(
//   "ecd/deleteEDC",
//   async (data, { dispatch, getState, rejectWithValue }) => {
//     try {
//       const _data = await edcServices.deleteEDCById(data);
//       return _data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );
