import React from 'react'
import { history } from '../../helpers';
import { SlArrowLeft } from 'react-icons/sl';
import { theme } from '../../configTheme';

const Empty = ({ children }) => {
  return (
    <div className='relative'>
      <div
        // style={{ zIndex: 41, left: "100%", top: "50%", transform: "translateY(-50%)" }}
        onClick={() => {
          history.push("/dashboard");
        }}
        className="absolute cursor-pointer w-8 h-8 border border-primary bg-white rounded-lg flex justify-center items-center"
      >
        <SlArrowLeft
          size={16}
          color={theme.primary}
          // style={{ transition: "all 0.3s linear", transform: !open && "rotate(180deg)" }}
        />
      </div>
      {children}
    </div>
  )
}

export default Empty
