import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    receiveMoney: 0,
    totalPrice: 0,
}

const paymentMethodSlice = createSlice({
    name: 'paymentMethod',
    initialState,
    reducers: {
        setAmountReceive: (state, action) => {
            console.log(action)
            state.receiveMoney = action.payload.receiveMoney;
            state.totalPrice = action.payload.totalPrice;
        }
    },
})

export default paymentMethodSlice.reducer;
export const selectAmount = (state) => state.paymentMethod.receiveMoney;
export const selectTotalPrice = (state) => state.paymentMethod.totalPrice;

export const { setAmountReceive } = paymentMethodSlice.actions;
// export const selectTitleModal = (state) => state.purchaseNoti.title



