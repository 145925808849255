/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { history } from "../../../../helpers";
import { getInvoice } from "../../../../services/inventory-manager.service";
import PrintInvoice from "../../order-manager/PrintInvoice";
import PaymentMethod from "./PaymentMethod";

import ic_tooltip from "../../../../assets/images/tooltip/ic_tooltip.svg";
import ic_tooltipActive from "../../../../assets/images/tooltip/tooltipActive.svg";
import { theme } from "../../../../configTheme";
import CustomButton from "../../../../components/buttons/CustomButton";
import arrow_left from "../../../../assets/images/product/arrow_left.svg";

const PAYMENTMETHOD = [
  {
    id: 0,
    name: "Mã QR",
    type: "QR",
  },
  {
    id: 1,
    name: "Tiền Mặt",
    type: "Cash",
    icon: ic_tooltip,
    iconActive: ic_tooltipActive,
    tooltip:
      "Bạn đang chọn phương thức thanh toán tiền mặt. Khi thanh toán xong, bạn vui lòng bấm vào nút “Hoàn tất thanh toán” để hoàn thành.",
  },
  {
    id: 2,
    name: "Thanh toán thẻ",
    type: "Credit",
    // icon: ic_tooltip,
    // iconActive: ic_tooltipActive,
    // tooltip: "Nếu kết nối máy pos không được vui lòng liên hệ “1900 3353”",
  },
];

const PaymentPage = () => {
  const { invoice_id } = useParams();
  const [invoice, setInvoice] = useState({});
  const [printInvoice, setPrintInvoice] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [qr, setQr] = useState("");

  const myAudio = useRef();

  const handleGetInvoice = async () => {
    const res = await getInvoice(invoice_id);
    if (res) {
      setInvoice(res);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("print-content"),
  });

  useEffect(() => {
    if (localStorage.getItem("evoice") === "true" && printInvoice) {
      myAudio.current.play();
      localStorage.removeItem("evoice");
    }
  }, [printInvoice]);

  useEffect(() => {
    if (invoice_id) {
      handleGetInvoice();
    }
  }, [invoice_id]);

  return (
    <div className="relative" style={{ padding: "54px 0" }}>
      <audio ref={myAudio}>
        <source src={process.env.REACT_APP_URL_VOICE} type="audio/mpeg" />
      </audio>

      {!printInvoice ? (
        <div className="flex w-full justify-center" style={{ gap: 116 }}>
          <div
            className="font-svn-gilroy flex flex-col"
            style={{ width: 528, gap: 30 }}
          >
            <div
              className="flex gap-3 items-center absolute cursor-pointer"
              style={{ top: "34px", left: "0px" }}
              onClick={() =>
                history.push(
                  `/sell-table/order/${invoice?.invoice_data.table_id}/${invoice?.invoice_data.order_id}`
                )
              }
            >
              <img src={arrow_left} alt="" />
              <p
                className="text-lg font-medium leading-6"
                style={{ color: "#262626" }}
              >
                Trở về
              </p>
            </div>

            <div
              className="flex rounded-xl"
              style={{ height: "44px", border: "2px solid #E8E8E8" }}
            >
              {PAYMENTMETHOD.map((item) => {
                return (
                  <button
                    key={item.id}
                    className={`relative w-full flex space-x-2 justify-center items-center border-l border-r first:border-0 first:rounded-l-xl last:border-0 last:rounded-r-xl outline-none focus:outline-none active:outline-none`}
                    style={{
                      // backgroundColor: paymentMethod === item.id ? "#DD0B0C" : "#FFF",
                      background:
                        paymentMethod === item.id ? theme.primary : "#FFF",

                      // border: "1px solid #E8E8E8",
                    }}
                    onClick={() => setPaymentMethod(item.id)}
                  >
                    <p
                      className={`text-sm font-semibold leading-3`}
                      style={{
                        color: item.id === paymentMethod ? "#fff" : "#434C76",
                      }}
                    >
                      {item.name}
                    </p>
                    {item.icon && (
                      <div className="group">
                        <div className="tooltip">
                          <img
                            src={
                              item.id === paymentMethod
                                ? item.iconActive
                                : item.icon
                            }
                            alt="icon"
                          />
                          <span className="tooltiptext">{item.tooltip}</span>
                        </div>
                        {/* <div style={{ background:"#262626",width: '350px', height: 60,top:'-100%',left:'50%',zIndex:10,transform: 'translateX(-50%)' }} className="rounded-lg absolute group-hover:block">
                          <span style={{
                            color: "#fff",
                            fontSize: 16,
                            fontWeight: 500,
                            lineHeight: '24px'
                          }}>{item.tooltip}</span>
                        </div> */}
                      </div>
                    )}
                  </button>
                );
              })}
            </div>
            <PaymentMethod
              qr={qr}
              setQr={setQr}
              type={paymentMethod}
              invoice={invoice?.invoice_data}
              onPaymentSuccess={() => setPrintInvoice(true)}
              setPrintInvoice={setPrintInvoice}
            />
          </div>
          {invoice?.invoice_data?.invoice_id && (
            <div className="flex flex-col gap-6 items-center">
              <PrintInvoice data={invoice} qr={qr} />
              <CustomButton
                title="In đơn để thanh toán"
                style={{ width: 240 }}
                onClick={handlePrint}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full flex flex-col items-center my-8">
          <p className="text-base leading-6 text-primary font-semibold mb-4">
            Thanh toán Success
          </p>
          <PrintInvoice
            data={{
              ...invoice,
              invoice_data: {
                ...invoice.invoice_data,
                payment_method: paymentMethod,
                status: 1,
              },
            }}
          />
          <div
            style={{ width: 308, gridTemplateColumns: "1.63fr 1fr" }}
            className="grid w-full gap-4 mt-8"
          >
            <button
              onClick={() => history.push("/sell-table")}
              className="text-center text-sm leading-5 font-semibold py-4 rounded-lg text-primary border border-primary"
            >
              Trở về màn hình chính
            </button>
            <button
              onClick={handlePrint}
              className="text-center text-sm leading-5 font-semibold py-4 rounded-lg text-white bg-primary"
            >
              In đơn
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
