import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { history } from "../../../helpers/history.js";
import { formatCurrency } from "../../../utils/lib.js";
import { selectListCustomer } from "../../../reducers/customer-management.reducer.js";
import useSearchParams from "./useSearchParams.js";

import SectionTitle from "../../../components/section-title/index.js";
import Datatable from "../../../components/tsdatatable/datatables.js";
import ModalDelete from "../../../components/modals/ModalDelete.js";
import SearchFieldParams from "../../../components/fields/SearchFieldParams.js";
import CustomButton from "../../../components/buttons/CustomButton.js";

import IcPen from "../../../assets/images/employee/ic_pen.svg";

const DataTableComponent = ({
  customData,
  filters,
  setFilters,
  numOfPage,
  setShowModalDelete,
  setItem,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Customer code",
        accessor: "code",
      },
      {
        Header: "Customer name",
        accessor: "name",
      },
      {
        Header: "Phone number",
        accessor: "phone_number",
      },
      {
        Header: "Total spending",
        accessor: "total_spending",
        Cell: ({ value }) => formatCurrency(value) || "0 VND",
      },
      {
        Header: "Reward point",
        accessor: "point",
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: "Total order",
        accessor: "total_orders",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    `/sell-customer/update?id=${row?.original?.customer_id}`
                  );
                }}
              />
              {/* <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row?.original);
                  setShowModalDelete(true);
                }}
              /> */}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const TableCustomer = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");
  const { updatePagination, setSearchQuery, filtersParams } = useSearchParams();
  const [filters, setFilters] = useState(filtersParams);

  const data = useSelector(selectListCustomer);

  const customData = useMemo(() => {
    const result = data?.customers;

    if (!result) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);

    // if (searchValue.trim() !== "")
    //   return result?.filter(
    //     (item) =>
    //       item?.customer_id?.toString()?.toLowerCase().includes(searchValue.toLowerCase()) ||
    //       item?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
    //       item?.phone_number?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
    //   );

    return result;
  }, [data]);

  // useEffect(() => {
  //   if (!debounce.trim()) {
  //     setFilters((prev) => ({
  //       ...prev,
  //       q: "",
  //     }));
  //   } else {
  //     setFilters((prev) => ({
  //       ...prev,
  //       q: debounce,
  //     }));
  //   }
  // }, [debounce]);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [filters, updatePagination]);

  const handleDelete = async () => {
    // try {
    //   const res = await dispatch(deleteCustomer(selectedCustomer?.code));
    //   if (res?.payload) {
    //     CustomToastMessage.success(
    //       "Thành công",
    //       `Bạn đã ngừng kinh doanh thành công sản phẩm với mã sản phẩm ${selectedCustomer?.code}`
    //     );
    //     dispatch(getListCustomer(filters));
    //     setShowModalDelete(false);
    //   } else CustomToastMessage.error("Thất bại");
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title="management bán hàng" subtitle="List customer" />
      </div>

      <div className="mt-4 mb-2 w-full flex justify-between items-center gap-4">
        <SearchFieldParams
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          id="search-bank-input"
          extraClass="flex-1 bg-white shadow-lg"
          placeholder="Tìm theo mã customer, tên, SĐT customer"
        />
        <CustomButton
          type="add"
          // outline
          title={"Add customer"}
          onClick={() => history.push("/sell-customer/create")}
        />
      </div>

      <div className="mt-6">
        <DataTableComponent
          customData={customData}
          filters={filters}
          numOfPage={numOfPage}
          data={data}
          setFilters={setFilters}
          setItem={setSelectedCustomer}
          setShowModalDelete={setShowModalDelete}
        />
      </div>

      {showModalDelete && (
        <ModalDelete
          setIsOpenModal={setShowModalDelete}
          title="Ngừng kinh doanh"
          desc={`Bạn có chắc muốn ngừng kinh doanh ${selectedCustomer?.name} ?`}
          item={selectedCustomer}
          handleAction={handleDelete}
          titleBtnLeft="Không"
          titleBtnRight="Có"
        />
      )}
    </section>
  );
};

export default TableCustomer;
