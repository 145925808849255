export const ERROR_CODE = {
  UNKNOWN_ERROR: "Lỗi không xác định.",
  AUTHEN_FAIL: "Lỗi xác thực người dùng.",
  LOGIN_FAIL: "Tên đăng nhập hoặc Mật khẩu không hợp lệ.",
  TOKEN_EXPIRED: "Token hết hạn.",
  BANK_INCORRECT: "Tài khoản ngân hàng không hợp lệ.",
  BANK_EXIST: "Tài khoản ngân hàng đã tồn tại.",
  BANK_NOT_EXIST: "Tài khoản ngân hàng không tồn tại",
  DELETE_BANK_ERROR:
    "Vui lòng xóa ShopQR liên kết với ngân hàng trước khi xóa ngân hàng.",
  USER_INCORRECT: "Tài khoản người dùng không đúng.",
  EMAIL_IS_EXIST: "Vui lòng nhập email chưa đăng ký.",
  TAX_NO_INCORECT: "Mã số thuế không đúng với đăng ký",
  TAX_NO_IS_EXST: "Vui lòng nhập mã số thuế chưa đăng ký.",
  TAX_NO_NOT_EXIST: "Mã số thuế không tồn tại.",
  HOLDER_NAME_NOT_MATCH:
    "Tài khoản ngân hàng không khớp với Mã số thuế. Vui lòng thử lại.",
  PERFIX_EXIST: "Tiền tố đơn hàng đã tồn tại.",
  CHANNEL_EXIST: "ShopQR đã tồn tại.",
  CHANNEL_NOT_FOUND: "Không tìm thấy ShopQR.",
  PERMISSION_DENY: "Người dùng không có quyền truy cập, chỉnh sửa.",
  VA_NOT_FOUND: "Không tìm thấy tài khoản VA.",
  TXN_SAVE_ERROR: "Lưu giao dịch lỗi.",
  BIN_CODE_EXPIRE: "Mã pin hết hạn. Vui lòng thử lại.",
  E_ADD_DEVICE: "Lỗi Add thiết bị.",
  SUBSCRIBE_NOTY_ERROR: "Lỗi đăng ký nhận thông báo.",
  DELETE_DEVICE_ERROR: "Xóa thiết bị thất bại. Vui lòng kiểm tra lại.",
  PASSWORD_NOT_MATCH: "Sai mật khẩu hiện tại. Vui lòng thử lại.",
  TOKEN_INCORRECT: "Mã xác thực đã hết hạn.",
  ACCOUNT_EXIST: "The email already exists in the system.",
  OTP_EXPIRE: "Mã OTP hết hạn. Vui lòng thử lại.",
  SEND_EMAIL_ERROR: "Lỗi xảy ra trong quá trình gửi email.",
  REQUIRED: "Lỗi yêu cầu phải nhập một field đã bắt buộc.",
  PASSWORD_DUPLICATE: "Trùng mật khẩu.",
  STAFF_EXIST: "Please enter an unregistered employee code.",
  STORE_NOT_EXIST: "Cửa hàng không tồn tại",
  LINK_EDC_VA_FAIL: "Vui lòng chọn ShopQR khác để tích hợp.",
  BAD_REQUEST: "Bad request",
};
