import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListStore } from "../../actions/metadata/metadata.actions";
import { selectListStore } from "../../reducers/metadata/metadata.reducer";
import { Optionfield, Searchfield } from "../tsfillter/fillter";
import SelectDateField from "../fields/select-date/SelectDateField";

const InsightFilter = (props) => {
  const dispatch = useDispatch();
  const dataListStore = useSelector(selectListStore);
  const optionTsStore = useMemo(() => {
    if (!dataListStore?.data) return [];
    return [{ label: "All", value: "all" }].concat(
      dataListStore?.data.map((store) => ({
        label: store.name,
        value: store.id,
      }))
    );
  }, [dataListStore]);

  const optionTsStatus = [
    { label: "All", value: "all" },
    { label: "Processing", value: "pending" },
    { label: "Successful", value: "success" },
    { label: "Fail", value: "fail" },
    // { label: "Void", value: "void" },
  ];

  // const optionTsStore = [
  //     { label: "All", value: "all" },
  //     // { label: "Success", value: "success" },
  //     // { label: "Lỗi", value: "error" },
  //     // { label: "Void", value: "void" },
  // ];

  const optionTStype = [
    { label: "All", value: "all" },
    { label: "Sale", value: "sale" },
    // { label: "void", value: "void" },
    { label: "Pre-auth", value: "pre_auth" },
    { label: "Complete Pre-auth", value: "complete_pre_auth" },
  ];

  const optionStatus = [
    { label: "Select field", value: "" },
    { label: "Invoice code", value: "transaction_id" },
    {
      label: "Card number (first 6 numbers - last 4 numbers)",
      value: "card_number",
    },
    { label: "All", value: "all" },
  ];

  useEffect(() => {
    dispatch(getListStore());
  }, [dispatch]);

  const { filters, setFilters, disabledStatus, hasSearchStore } = props;

  return (
    <>
      <form
        style={{
          width: "73%",
          maxWidth: 960,
          // width: 'auto',
          // maxWidth: '100%',
          // overflowX: 'scroll'
        }}
        //   onSubmit={methods.handleSubmit(onSubmit)
        className="grid grid-cols-4 gap-3 min-1620:gap-10"
      >
        <SelectDateField
          label="Time"
          filters={filters}
          setFilters={setFilters}
        />
        {/* <Datetime label="Khung thời gian" field="from_date" field2="to_date" /> */}
        <Optionfield
          label="Status"
          option={optionTsStatus}
          field="status"
          disabled={disabledStatus}
        />
        <Optionfield
          label="Store name"
          option={optionTsStore}
          field="store"
          hasSearch={hasSearchStore}
        />
        <Searchfield
          label="Invoice code"
          inputwidth="w-full"
          filters={filters}
          field="query_value"
        />
      </form>
    </>
  );
};

export default InsightFilter;
