import React from "react";
import bgCardStartup from "../../../assets/images/bill-digital/startup.png";
import ic_startup from "../../../assets/images/bill-digital/ic_startup.svg";
import Price from "../Price";
import BtnBuy from "../BtnBuy";
import Feature from "../Feature";

const list = [
  " No time limit",
  "The easiest software to use: completely online, no installation required",
  "Secure invoice data",
  "Easily integrates with any business's accounting/sales software",
];
const CardStartup = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgCardStartup})`,
        maxWidth: 360,
        height: "100%",
        padding: 40,
        margin: "auto",
        backgroundSize: "100% 100%",
      }}
      className=""
    >
      <div className="flex items-center flex-col">
        <img
          style={{ width: 72, height: 72 }}
          src={ic_startup}
          alt="icon gift"
        />
        <button
          style={{
            color: "#8D9094",
            fontSize: 16,
            fontWeight: 600,
            leading: 24,
            border: "solid 2px #9FA4B1",
            background: "linear-gradient(99deg, #EFEFF2 1.1%, #C5C8CC 53.52%)",
            backdropFilter: "blur(50px)",
            borderRadius: 8,
            padding: "8px 16px",
            width: 224,
            height: 48,
          }}
          className="mt-4"
        >
          Start Up 300 hoá đơn
        </button>
      </div>
      <div className="my-6 flex flex-col space-y-6">
        <Price price={"300.000"} />
        <BtnBuy />
      </div>
      <div className="mt-10">
        <Feature list={list} type="startup" />
      </div>
    </div>
  );
};

export default CardStartup;
