import { createAsyncThunk } from "@reduxjs/toolkit";
import { industryServices } from "../../services/industry/industry.service";

export const getListIndustry = createAsyncThunk(
  "industry/getListIndustry",
  async (data, { dispatch }) => {
    const _data = await industryServices.getListIndustry(data);
    return _data;
  }
);
