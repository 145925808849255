import axiosClient2 from "./client_axios2";
import axiosClient from "./axios/app-client";
import { NOTIFICATION } from "./endpoint";

export const notificationServices = {
  getListNoti: (params) => {
    return axiosClient2.get(NOTIFICATION, { params });
  },

  createNoti: (params) => {
    return axiosClient2.post(NOTIFICATION, params);
  },

  updateNoti: (params) => {
    return axiosClient2.put(NOTIFICATION, params);
  },
};
