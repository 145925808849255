import React from "react";

const CheckBoxField = ({
  type = "checkbox",
  onChange,
  title,
  subTitle,
  checked,
  className,
  id,
  icon,
}) => {
  return (
    <label
      style={{ border: "1px solid #DEDEDE", borderRadius: 6 }}
      className={`flex-1 px-6 py-4 cursor-pointer hover:opacity-75 transition ${className}`}
      htmlFor={id}
    >
      <div style={{ height: 66 }} className="justify-between flex gap-3 items-center">
        <div className="flex items-center gap-3">
          <input type={type} onChange={onChange} checked={checked} id={id} name={id} value={id} />

          <div style={{ gap: 6 }} className="flex flex-col">
            <p className="text-blackgray text-base font-semibold">{title}</p>
            {subTitle && <p className="text-grey-6 text-sm font-medium leading-5">{subTitle}</p>}
          </div>
        </div>

        {icon && (
          <div style={{ gap: 6 }} className="flex items-center justify-center">
            <img src={icon} alt="item" />
          </div>
        )}
      </div>
    </label>
  );
};

export default CheckBoxField;
