import { createAsyncThunk } from "@reduxjs/toolkit";
import { paymentChannelService } from "../services/payment-channel";

export const getListPayment = createAsyncThunk(
    "payment/getListPaymentChannel",
    async (params, { dispatch }) => {
        const _data = await paymentChannelService.getListPaymentChannels(params);
        return _data;
    }
);

export const createPChannel = createAsyncThunk(
    "payment/createPaymentChannel",
    async (params, { dispatch }) => {
        try {
            const _data = await paymentChannelService.createPaymentChannel(params);
            return _data;
        } catch (error) { return error }
    }
);

export const deletePChannel = createAsyncThunk(
    "payment/deletePaymentChannel",
    async (params, { dispatch }) => {
        try {
            const _data = await paymentChannelService.deletePaymentChannel(params);
            return _data;
        } catch (error) { return error }
        // async (params, { dispatch }) => {
        //     const _data = await paymentChannelService.deletePaymentChannel(params);
        //     return _data;
        // }
    }
);

export const createQR = createAsyncThunk(
    "payment/createQR",
    async (params, { dispatch }) => {
        const _data = await paymentChannelService.createQR(params);
        return _data;
    }
);