import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { sellRegisterFree } from "../../../../actions";
import { sell } from "../../../../assets";
import CustomButton from "../../../../components/buttons/CustomButton";
import { history } from "../../../../helpers";
import { CustomToastMessage } from "../../../../utils/customToastMsg";
import ModalLoading from "./ModalLoading";
import Process from "./Process";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const FORM = {
  IS_STARTED: "is_started",
  IS_STARTED2: "is_started2",
  IS_STARTED3: "is_started3",
};

const Register = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    is_started: "",
  });

  const handleSkip = () => {
    if (step == 3) {
      setStep(4);
      setTimeout(() => history.push("/sell-customer"), 3200);
    } else setStep((step) => step + 1);
  };

  const handleSkipAll = () => {
    setStep(4);
    setTimeout(() => history.push("/sell-customer"), 3200);
  };

  const handleNext = () => {
    setStep((step) => {
      return step === 3 ? handleSubmit() : step + 1;
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    console.log(payload);

    setTimeout(() => {
      setIsLoading(false);
      setStep(4);
      setTimeout(() => history.push("/sell-customer"), 3200);
    }, 1000);

    // try {
    //   const res = await dispatch(sellRegisterFree(payload));

    //   if (res?.meta?.requestStatus === "fulfilled") {
    //     CustomToastMessage.success("Success");
    //     history.push("/");
    //     setStep(4);
    //   } else CustomToastMessage.error("thất bại");
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  return (
    <>
      {isLoading && <ModalLoading />}

      <div>
        <div className="mt-8 mb-2 w-full flex flex-col justify-center items-center rounded-2xl p-10 bg-white">
          {step < 4 && <Process step={step} />}

          {step === 1 && (
            <Step1 name={FORM.IS_STARTED} setPayload={setPayload} />
          )}
          {step === 2 && <Step2 setPayload={setPayload} />}
          {step === 3 && <Step3 setPayload={setPayload} />}
          {step === 4 && <Step4 setPayload={setPayload} />}

          {step < 4 && (
            <div className="flex items-center self-end  gap-4">
              <CustomButton
                outline
                onClick={handleSkipAll}
                title="Bỏ qua All"
              />
              <CustomButton outline onClick={handleSkip} title="Bỏ qua" />
              <CustomButton onClick={handleNext} title="Tiếp" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
