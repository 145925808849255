import React, { useEffect, useState } from "react";
import { TbChevronLeftPipe, TbChevronLeft, TbChevronRight, TbChevronRightPipe } from "react-icons/tb";
import "./pagination.css";
import PropTypes from "prop-types";
import { theme } from "../../configTheme";
function Pagination(props) {
  const { pageCount, canPreviousPage, previousPage, pageOptions, pageIndex, canNextPage, gotoPage, nextPage } = props;
  
  const [first3Page, setFirst3Page] = useState([0, 1, 2]);
  const [last3Page, setLast3Page] = useState([pageCount - 3, pageCount - 2, pageCount - 1]);
  useEffect(() => {
    setFirst3Page([0, 1, 2]);
    setLast3Page([pageCount - 3, pageCount - 2, pageCount - 1]);
  }, [pageCount]);
  
  useEffect(() => {
    // console.log('>>> page index in pagination: ', pageIndex)
    const disPrev = pageIndex - first3Page[2];
    const disNext = last3Page[0] - pageIndex;
    if (first3Page.includes(pageIndex) && pageIndex != 0 && disNext >= 3) {
      setFirst3Page([pageIndex - 1, pageIndex, pageIndex + 1]);
    } else if (last3Page.includes(pageIndex) && pageIndex != pageOptions[pageCount - 1] && disPrev >= 3) {
      setLast3Page([pageIndex - 1, pageIndex, pageIndex + 1]);
    }
  }, [pageIndex]);

  return (
    <div className="flex items-center gap-2">
      <TbChevronLeftPipe
        cursor={canPreviousPage ? "pointer" : "default"}
        className="w-5 h-5 md:w-6 md:h-6"
        onClick={() => {
          if (canPreviousPage) {
            setFirst3Page([0, 1, 2]);
            gotoPage(0);
          }
        }}
        color={canPreviousPage ? "#6C6C6C" : "#ABB2C2"}
      />
      <TbChevronLeft
        cursor={canPreviousPage ? "pointer" : "default"}
        className="w-5 h-5 md:w-6 md:h-6"
        onClick={() => {
          if (canPreviousPage) previousPage();
        }}
        color={canPreviousPage ? theme.primary : "#ABB2C2"}
      />

      {pageCount <= 7 ? (
        <div className="flex gap-1 px-2">
          {pageOptions.map((page) => (
            <div
              onClick={() => gotoPage(page)}
              key={page}
              style={{ padding: "6px 12px", color: pageIndex == page ? "white" : "#222020", background: page == pageIndex ? 'linear-gradient(36.25deg,#2B3DB9 2.93%,#0CD6F7 102.74%)' : '', }}
              className={`rounded-lg text-sm leading-5 font-normal cursor-pointer`}
            >
              {page + 1}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex gap-1 px-2">
          {[...first3Page, first3Page[2] + 1, ...last3Page].map((page, index) => {
            return (
              <div
                onClick={() => {
                  if ((pageIndex - first3Page[2] <= 3 && last3Page[0] - pageIndex <= 3) || index != 3) gotoPage(page);
                }}
                key={index}
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  color: page == pageIndex ? "white" : "#222020",
                  background: page == pageIndex ? 'linear-gradient(36.25deg,#2B3DB9 2.93%,#0CD6F7 102.74%)': '',
                  transition: "all 0.3s linear",
                }}
                className={`px-2 md:px-3 rounded-lg text-sm leading-5 font-normal cursor-pointer`}
              >
                {index != 3
                  ? page + 1
                  : pageIndex - first3Page[2] <= 3 && last3Page[0] - pageIndex <= 3
                    ? page + 1
                    : "..."}
              </div>
            );
          })}
        </div>
      )}

      <TbChevronRight
        cursor={canNextPage ? "pointer" : "default"}
        className="w-5 h-5 md:w-6 md:h-6"
        onClick={() => {
          if (canNextPage) nextPage();
        }}
        color={canNextPage ? theme.primary : "#ABB2C2"}
      />
      <TbChevronRightPipe
        cursor={canNextPage ? "pointer" : "default"}
        className="w-5 h-5 md:w-6 md:h-6"
        onClick={() => {
          if (canNextPage) {
            setLast3Page([pageCount - 3, pageCount - 2, pageCount - 1]);
            gotoPage(pageCount - 1);
          }
        }}
        color={canNextPage ? "#6C6C6C" : "#ABB2C2"}
      />
    </div>
  );
}

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  previousPage: PropTypes.func,
  pageOptions: PropTypes.any,
  pageIndex: PropTypes.any,
  canNextPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  nextPage: PropTypes.func,
};

export default Pagination;
