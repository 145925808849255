import { createSlice } from "@reduxjs/toolkit";
import { getListNoti } from "../actions/noti.actions";

const initialState = {
  listNoti: [],
  listNotiOrder: [],
  listNotiPay: [],
  listNotiFood: [],
  isLoading: false,
};

const notiSlice = createSlice({
  name: "notiSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListNoti.fulfilled, (state, action) => {
        state.listNoti = action.payload.data;
        const res = action.payload.data.reduce((acc, noti, index) => {
          acc[noti.notification_type] = acc[noti.notification_type] || []
          acc[noti.notification_type].push(noti)
          return acc
        }, {})
        state.listNotiOrder = res['0'] || []
        state.listNotiPay = res['1'] || []
        state.listNotiFood = res['3'] || []
      })
      .addCase(getListNoti.rejected, (state, action) => {
        state.listNoti = [];
        state.listNotiOrder = [];
        state.listNotiPay = [];
        state.listNotiFood = [];
      })
});

export const selectListNoti = (state) => state.noti.listNoti;
export const selectListNotiOrder = (state) => state.noti.listNotiOrder;
export const selectListNotiPay = (state) => state.noti.listNotiPay;
export const selectListNotiFood = (state) => state.noti.listNotiFood;
export const selectIsLoading = (state) => state.noti.isLoading;

export default notiSlice.reducer;