import React, { Fragment, useEffect, useState } from "react";
import SelectVaField from "../../../components/fields/SelectVaField";
import CheckBoxCard from "./CheckBoxCard";
import Ewallet from "./Ewallet";
import appClient from "../../../services/axios/app-client";

const PaymentMethod = ({
  form,
  register,
  watch,
  setValue,
  errors,
  paymentMethod,
  setPaymentMethod,
}) => {
  const QRId = "QR";
  const EwalletId = "Ewallet";
  const [posId, setPosId] = useState(watch(form.POS));

  const handleSelect = (event) => {
    const { value, checked: isChecked, name } = event.target;

    setPaymentMethod((prev) =>
      prev.map((item) => {
        return item.id === Number(value)
          ? { ...item, active: isChecked }
          : item;
      })
    );
  };

  useEffect(() => {
    const onGetPaymentMethod = async () => {
      try {
        const { data } = await appClient.get("/payment-config");
        if (data) {
          const dataFilter = data.filter(
            (item) => item.group.toLowerCase() === "ewallet"
          );
          const dataFilterQr = data.filter(
            (item) => item.group.toLowerCase() === "qr"
          );

          if (!dataFilter || !dataFilterQr) return;

          const dataMomo = dataFilter[0].data;
          const dataQr = dataFilterQr[0].data;

          setValue(form.URL, dataMomo?.url);
          setValue(form.ASSESS_KEY, dataMomo?.access_key);
          setValue(form.SECRET_KEY, dataMomo?.secret_key);
          setValue(form.PARTNER_CODE, dataMomo?.partner_code);
          setValue(form.POS, dataQr?.channel_id);
          setPosId(dataQr?.channel_id);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const interval = onGetPaymentMethod();
    return () => clearInterval(interval);
  }, [setValue, posId]);

  return (
    <div className="flex flex-col gap-4">
      <p className="text-blackgray font-bold leading-6 text-base">
        Phương thức thanh toán
      </p>

      {paymentMethod?.length > 0 &&
        paymentMethod.map((item, index) => (
          <Fragment key={index}>
            <CheckBoxCard
              id={item.id}
              checked={item.active}
              onChange={(e) => handleSelect(e)}
              title={item.name}
            />
            {/* POS */}
            {item.group === QRId && item.active && (
              <SelectVaField
                register={register}
                name={form.POS}
                setValue={setValue}
                errors={errors}
                state={errors?.type ? "error" : " "}
                initialValue={posId}
              />
            )}
            {/* Momo */}
            {item.group === EwalletId && item.active && (
              <Ewallet
                id={item.id_mongo}
                register={register}
                setValue={setValue}
                errors={errors}
                form={form}
                watch={watch}
                state={errors?.type ? "error" : " "}
              />
            )}
          </Fragment>
        ))}
    </div>
  );
};

export default PaymentMethod;
