import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoIosAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../../components/section-title";
import Datatable, { StatusCell } from "../../components/tsdatatable/datatables";
import Widget from "../../components/widget";
import { formatCurrency } from "../../utils/lib";
import { getListTransQRCode } from "../../actions";

import { useHistory } from "react-router-dom";
import IcPen from "../../assets/images/employee/ic_pen.svg";
import { getListStaff } from "../../actions/metadata/metadata.actions";
import { selectListStaff } from "../../reducers/metadata/metadata.reducer";
import IcTrash from "../../assets/images/payment/trash.svg";
import { history } from "../../helpers";
import ModalDelete from "../../components/modals/ModalDelete";
import { toastMessage } from "../../utils/constants";
import { ERROR_CODE } from "../../utils/errorCode";
import { deleteEmployeeById } from "../../actions/employee/employee.actions";
import CustomButton from "../../components/buttons/CustomButton";

const DatatableComponent = ({
  customData,
  filters,
  numOfPage,
  setFilters,
  hasPagination,
  setEmployee,
  setIsOpenModal,
}) => {
  const history = useHistory();
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Employee code",
        accessor: "staff_id",
        Cell: ({ value, row }) => {
          return <span>{value || "None"}</span>;
        },
      },
      {
        Header: "Full name",
        accessor: "full_name",
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Phone number",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    `/user-management/update?id=${row?.original?.id}`
                  );
                }}
              />
              <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setEmployee(row?.original);
                  setIsOpenModal(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    [history, setEmployee, setIsOpenModal]
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      setFilters={setFilters}
      filters={filters}
      hasPagination={hasPagination}
    />
  );
};

export default function UserManagement() {
  const dispatch = useDispatch();
  const data = useSelector(selectListStaff);

  const [numOfPage, setNumOfPage] = useState("1");
  const [employee, setEmployee] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const history = useHistory();

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const customData = useMemo(() => {
    if (!data?.data) return [];
    const result = data?.data;

    if (!result) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return result;
  }, [data]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
          return {
            ...e,
            request_amount: formatCurrency(e.request_amount),
            original_transaction_date: moment(
              new Date(e.original_transaction_date * 1000)
            ).format("HH:mm:ss DD-MM-YYYY"),
          };
        })
      : [];
  };

  const handleDelete = useCallback(async () => {
    try {
      const res = await dispatch(
        deleteEmployeeById({ data: { id: employee?.id } })
      );
      if (res?.meta?.requestStatus === "fulfilled") {
        // toastDeleteBankSuccess({
        //   bank_code: bank?.bank_code,
        //   bank_account_no: formatBankAccount(bank?.account_no),
        // });
        toastMessage.success(res?.payload?.data);
        dispatch(getListStaff());
        setIsOpenModal(false);
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        } else if (res?.payload?.msg) {
          msg = res?.payload?.msg;
        }
        toastMessage.error(msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [employee, dispatch]);

  useEffect(() => {
    dispatch(getListStaff(filters));
  }, [dispatch, filters]);

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle
          title="BUSINESS INFORMATION"
          subtitle="Employee Management"
        />
        <CustomButton
          type="add"
          outline
          title={"Add"}
          onClick={() => history.push("/user-management/create")}
        />
      </div>

      <div className="mt-6">
        <DatatableComponent
          customData={formatData(customData)}
          filters={filters}
          numOfPage={numOfPage}
          data={data}
          setFilters={setFilters}
          setEmployee={setEmployee}
          setIsOpenModal={setIsOpenModal}
        />
      </div>

      {isOpenModal && (
        <ModalDelete
          setIsOpenModal={setIsOpenModal}
          title="Delete employee"
          desc={`Are you sure you want to delete the employee ${employee?.full_name} with the employee code ${employee?.staff_id} ?`}
          item={employee}
          handleAction={handleDelete}
        />
      )}
    </section>
  );
}
