import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import * as actions from "../../actions";
import { history } from "../../helpers";

const GetProfile = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  useEffect(() => {
    const userToken = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : false;
    if (userToken) {
      dispatch(actions.authGetProfile());
    } else {
      if (
        !["/login", "signup", "/forgot-password", "/reset-password", "/verify-email"].includes(
          location.pathname,
        )
      ) {
        history.push("/login");
      }
    }
  }, []);
  return <></>;
};

export default GetProfile;
