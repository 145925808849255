import React from "react";

import momo from "../../../assets/images/sell-setting/momo.png";
import InputField from "../../../components/fields/InputField";

const Ewallet = ({ form, id, register, setValue, errors, watch }) => {
  const listWallet = [{ name: "Momo", logo: momo }];

  return (
    <div className="w-full flex flex-col gap-4 bg-white">
      {listWallet.map((wallet) => {
        return (
          <div key={wallet.name} className="w-full space-y-2">
            <div className="flex items-center gap-2 w-full rounded-lg border border-gray-primary px-4 py-2">
              <img src={wallet.logo} alt="logo" width={24} />
              <p className="text-sm leading-5 font-semibold">{wallet.name}</p>
            </div>

            <InputField
              isRequired
              setValue={setValue}
              placeholder={`${wallet.name.toUpperCase()}_ACCESS_KEY="9S9Z7yWo8h4bSfl3`}
              id={form.ASSESS_KEY}
              name={form.ASSESS_KEY}
              value={watch(form.ASSESS_KEY)}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              isRequired
              setValue={setValue}
              placeholder={`${wallet.name.toUpperCase()}_URL`}
              id={form.SECRET_KEY}
              name={form.SECRET_KEY}
              value={watch(form.SECRET_KEY)}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              isRequired
              setValue={setValue}
              placeholder={`${wallet.name.toUpperCase()}_URL`}
              id={form.URL}
              name={form.URL}
              value={watch(form.URL)}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              isRequired
              setValue={setValue}
              placeholder={`${wallet.name.toUpperCase()}_URL`}
              id={form.PARTNER_CODE}
              name={form.PARTNER_CODE}
              value={watch(form.PARTNER_CODE)}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Ewallet;
