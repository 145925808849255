import React, { memo, useEffect, useState } from "react";
import search from "../../assets/images/icon/ic-search.svg";
import useDebounce from "../../hooks/useDebounce";

const SearchFieldParams = ({ id, extraClass, placeholder, setSearchQuery, setFilters }) => {
  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 800);

  useEffect(() => {
    setSearchQuery(searchDebounce ? searchDebounce : null);
    setFilters((prev) => setFilters({ ...prev, page: 1 }));
  }, [searchDebounce, setFilters, setSearchQuery]);

  return (
    <div className={`flex items-center overflow-hidden rounded-lg bg-grey-100 ${extraClass}`}>
      <label htmlFor={id} className='flex items-center justify-center pl-3'>
        <img src={search} alt='ic_search' className='h-4 w-4' />
      </label>
      <input
        id={id}
        type='text'
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ backgroundColor: "transparent" }}
        className=' flex-1 border-none py-2 pl-10px pr-3 text-sm text-grey-9 outline-none placeholder:text-sm placeholder:text-grey-9 focus:outline-none'
        placeholder={placeholder}
      />
    </div>
  );
};

export default memo(SearchFieldParams);
