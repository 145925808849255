import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteBankVam, getTableBank } from "../../actions/bank/bank.actions";
import trash from "../../assets/images/payment/trash.svg";
import CustomButton from "../../components/buttons/CustomButton";
import ModalDelete from "../../components/modals/ModalDelete";
import SectionTitle from "../../components/section-title";
import Datatable from "../../components/tsdatatable/datatables";
import { selectListBankVam } from "../../reducers/bank/bank.reducer";
import { toastDeleteBankFail, toastDeleteBankSuccess } from "../../utils/constants";
import { ERROR_CODE } from "../../utils/errorCode";
import { formatBankAccount } from "../../utils/lib";
import ModalOTP from "../../components/modals/otp/ModalOTP";
import ModalCreateBank from "./ModalCreateBank";

const DataTableComponent = ({ filters, setFilters, numOfPage, data, setBank, setIsOpenModal }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Bank name",
        accessor: "logo_url",
        Cell: ({ value, row }) => {
          return (
            <div className="flex items-center gap-3">
              {/* <img src={value == 'VCCB' ? 'https://s3-hn-2.cloud.cmctelecom.vn/test-s3/logo/qr/VCCB.png' : getBankLogo(value)} alt="logo" width={60} /> */}
              <img src={value} alt="logo" width={60} />
              <span className="text-14px text-dark-primary leading-5">
                {row.original.bank_code}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Account number",
        accessor: "account_no",
        Cell: ({ value, row }) => {
          return <span className="text-14px text-dark-primary">{value}</span>;
        },
      },
      {
        Header: "Account name",
        accessor: "holder_name",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          return (
            <img
              src={trash}
              alt="trash"
              style={{ width: 16, cursor: "pointer" }}
              onClick={() => {
                setBank(row?.original);
                setIsOpenModal(true);
              }}
            />
          );
        },
      },
    ],
    [setBank, setIsOpenModal],
  );

  return (
    <Datatable
      columns={columns}
      data={data}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
      textLeft
    />
  );
};

const Bank = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(selectListBankVam);
  const [bank, setBank] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isOpenModalAddBank, setIsOpenModalAddBank] = useState(false);
  const [isOpenModalOtp, setIsOpenModalOtp] = useState(false);

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const customData = useMemo(() => {
    if (!data?.data) return [];
    const result = data?.data?.data;
    if (!result) return [];
    setNumOfPage(result?.total_page ? result?.total_page : 1);
    return result;
  }, [data]);

  const handleDeleteBank = useCallback(async () => {
    try {
      const res = await dispatch(deleteBankVam({ data: { id: bank?.id } }));
      if (res?.meta?.requestStatus === "fulfilled") {
        toastDeleteBankSuccess({
          bank_code: bank?.bank_code,
          bank_account_no: formatBankAccount(bank?.account_no),
        });
        dispatch(getTableBank());
        setIsOpenModalDelete(false);
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        } else if (res?.payload?.msg) {
          msg = res?.payload?.msg;
        }
        toastDeleteBankFail(msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [bank, dispatch]);

  const handleSuccessStep1 = useCallback(async () => {
    setIsOpenModalAddBank(false);
    setIsOpenModalOtp(true);
  }, []);

  const handleSuccessStep2 = useCallback(async () => {
    setIsOpenModalOtp(false);
    dispatch(getTableBank());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTableBank(filters));
  }, [filters]);

  return (
    <>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title="Pay" subtitle="Bank" />
        <CustomButton
          type="add"
          outline
          title={"Add"}
          onClick={() => setIsOpenModalAddBank(true)}
          className={"gap-2 px-4"}
          // onClick={() => history.push("/bank/create")}
        />
      </div>

      <div className="mt-6">
        <DataTableComponent
          filters={filters}
          data={customData}
          numOfPage={numOfPage}
          setFilters={setFilters}
          setBank={setBank}
          setIsOpenModal={setIsOpenModalDelete}
        />
      </div>
      {isOpenModalAddBank && (
        <ModalCreateBank
          handleSuccess={handleSuccessStep1}
          handleClose={() => setIsOpenModalAddBank(false)}
        />
      )}

      {isOpenModalOtp && (
        <ModalOTP
          isOpen={isOpenModalOtp}
          handleSuccess={handleSuccessStep2}
          onClose={() => setIsOpenModalOtp(false)}
        />
      )}

      {isOpenModalDelete && (
        <ModalDelete
          setIsOpenModal={setIsOpenModalDelete}
          title="Delete bank"
          desc={`Are you sure you want to delete account number ${formatBankAccount(
            bank?.account_no,
          )} ${bank?.holder_name} ${bank?.bank_code} bank?`}
          item={bank}
          handleAction={handleDeleteBank}
        />
      )}
    </>
  );
};

export default Bank;
