export const share = {
  logo: require("./images/Logo.svg"),
};

export const checkout = {
  payment1: require("./images/checkout/payment1.svg"),
  payment2: require("./images/checkout/payment2.svg"),
  qrcode: require("./images/checkout/qrcode.svg"),
};

export const vietqr = {
  calendar: require("./images/vietqr/calendar.svg"),
  search: require("./images/vietqr/search.svg"),
};

export const sell = {
  wesap: require("./images/sell-register/wesap.png"),
  icCloth: require("./images/sell-register/icCloth.png"),
  icConvert: require("./images/sell-register/icConvert.svg"),
  icDigital: require("./images/sell-register/icDigital.svg"),
  icService: require("./images/sell-register/icService.svg"),
  icTag: require("./images/sell-register/icTag.svg"),
  icBlog: require("./images/sell-register/icBlog.svg"),
  icCart: require("./images/sell-register/icCart.svg"),
  icCartOnline: require("./images/sell-register/icCartOnline.svg"),
  icMedia: require("./images/sell-register/icMedia.svg"),
  icCommunication: require("./images/sell-register/icCommunication.svg"),
  icTransport: require("./images/sell-register/icTransport.svg"),
  icSuccess: require("./images/sell-register/icSuccess.svg"),
  step4: require("./images/sell-register/step4.png"),
  icLoading: require("./images/sell-register/icLoading.svg"),

  icFire: require("./images/sell-register/register/icFire.svg"),
  cardFree: require("./images/sell-register/register/card-free.png"),
  cardVip: require("./images/sell-register/register/card-vip.png"),
  cardQr: require("./images/sell-register/register/card-qr.png"),
  icCheck: require("./images/sell-register/register/icCheck.svg"),
  bgCardFree: require("./images/sell-register/register/bg-card-free.png"),
  bgCardVip: require("./images/sell-register/register/bg-card-vip.png"),
  bgCardQr: require("./images/sell-register/register/bg-card-qr.png"),
};

export const sellSetting = {
  download: require("./images/sell-setting/ic_download.svg"),
};
