import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { history } from "../../../helpers";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "../../../actions/product-manager.actions";
import { formatCurrency } from "../../../utils/lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import CustomButton from "../../../components/buttons/CustomButton";
import SectionTitle from "../../../components/section-title";
import TableWrapper from "../../company-profile/TableWrapper";
import InputField from "../../../components/fields/InputField";
import UploadProductField from "../../../components/fields/upload/UploadProductField";
import SelectCateField from "../../../components/fields/SelectCateField";

const FORM = {
  NAME: "name",
  CODE: "code",
  IMG: "image_url",
  PRICE: "price",
  COST: "cost",
  TAX: "tax",
};

const schema = yup.object().shape({
  [FORM.NAME]: yup
    .string()
    .required("Vui lòng nhập tên sản phẩm")
    .matches(/\S/, "Không được có khoảng trống"),
  [FORM.CODE]: yup
    .string()
    .required("Vui lòng nhập mã sản phẩm")
    .matches(/\S/, "Không được có khoảng trống")
    .matches(/^[A-Za-z0-9]+$/, "Mã sản phẩm là chữ và số"),
  [FORM.PRICE]: yup.string().required("Vui lòng nhập giá sản phẩm"),
});

export const CateCell = ({ value, color }) => {
  const colors = {
    red: { bg: "#FFF1F0", border: "#FFA39E", text: "#F5222D" },
    green: { bg: "#EBFAEF", border: "#B3EBC5", text: "#2EB553" },
    yellow: { bg: "#FEFFE6", border: "#FFFB8F", text: "#D4B106" },
    purple: { bg: "#F9F0FF", border: "#D3ADF7", text: "#722ED1" },
  };
  const [active, setActive] = useState(false);

  return (
    <button
      type="button"
      style={{
        padding: "10px 12px",
        background: colors[color]?.bg,
        border: `1px solid ${colors[color]?.border}`,
      }}
      onClick={() => setActive(!active)}
      className={`rounded-3xl border flex items-center gap-1 ${
        !active && "opacity-30"
      }`}
    >
      <p
        style={{ color: colors[color]?.text }}
        className="text-sm leading-5 font-medium"
      >
        {value}
      </p>
    </button>
  );
};

const CreateProduct = () => {
  const [imgUrl, setImgUrl] = useState("");
  const [listCate, setListCate] = useState([]);
  const dispatch = useDispatch();
  const [typeInput, setTypeInput] = useState("number");
  const priceRef = useRef("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      [FORM.NAME]: "",
      [FORM.CODE]: "",
      [FORM.PRICE]: "",
    },
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(
        createProduct({
          ...value,
          img: imgUrl,
          cost: 0,
          tax: 0,
          price:
            typeof priceRef.current !== "number"
              ? Number(priceRef.current)
              : priceRef.current,
          category_ids: listCate,
        })
      );

      if (res?.payload?.code === 201) {
        CustomToastMessage.success("Add sản phẩm Success");
        history.push("/sell-product");
      } else CustomToastMessage.error("Add sản phẩm thất bại");
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(FORM.NAME) || !watch(FORM.CODE) || !watch(FORM.PRICE) || !imgUrl;

  const onBlur = useCallback(() => {
    setTypeInput("text");
    setValue(
      FORM.PRICE,
      watch(FORM.PRICE) ? formatCurrency(watch(FORM.PRICE)) : watch(FORM.PRICE)
    );
  }, [setValue, watch]);

  const onFocus = useCallback(() => {
    setTypeInput("number");
    setValue(FORM.PRICE, priceRef.current);
  }, [setValue]);

  const onUpdate = useCallback((formName, value) => {
    priceRef.current = formName === "price" ? value : priceRef.current;
  }, []);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle title="Quản lý sản phẩm" subtitle="Add sản phẩm" />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col gap-6">
            <UploadProductField
              label="Hình ảnh sản phẩm"
              isRequired
              id={FORM.IMG}
              name={FORM.IMG}
              imgUrl={imgUrl}
              setImgUrl={setImgUrl}
            />
            {/* <div className="space-y-2">
              <p className="text-text-primary text-sm leading-5 font-semibold">Trạng thái<span className="text-red-required">*</span></p>
              <div className="flex gap-4">
                <div className="cursor-pointer" onClick={() => setStatus(0)}>
                  <StatusCell color={status === 0 ? 'green' : 'grey'} title='Còn hàng' />
                </div>
                <div className="cursor-pointer" onClick={() => setStatus(1)}>
                  <StatusCell color={status === 1 ? 'red' : 'grey'} title='Hết hàng' />
                </div>
              </div>
            </div> */}
          </div>

          <div className="flex flex-col gap-6">
            <InputField
              isRequired
              setValue={setValue}
              label="Mã sản phẩm"
              placeholder="Nhập Mã sản phẩm"
              id={FORM.CODE}
              name={FORM.CODE}
              value={watch(FORM.CODE)}
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              isRequired
              setValue={setValue}
              label="Tên sản phẩm"
              placeholder="Nhập tên sản phẩm"
              id={FORM.NAME}
              name={FORM.NAME}
              value={watch(FORM.NAME)}
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              isRequired
              setValue={setValue}
              label="Giá Bán"
              placeholder="Nhập giá bán"
              id={FORM.PRICE}
              name={FORM.PRICE}
              value={watch(FORM.PRICE)}
              onBlur={onBlur}
              onFocus={onFocus}
              onUpdate={onUpdate}
              type={typeInput}
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>
        </div>

        <SelectCateField listCate={listCate} setListCate={setListCate} />

        <div className="flex items-center justify-end gap-4 mt-4">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/sell-product")}
              title="Quay lại"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Add"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateProduct;
