import appClient from "../axios/app-client";

import {
  GET_LIST_BANK,
  GET_LIST_LINKED_VA,
  GET_LIST_STAFF,
  GET_LIST_STORE,
  POST_CRYPTO,
  POST_FIAT,
  SYNC_DATA,
  UPLOAD_IMAGE,
} from "../endpoint";

export const metadataServices = {
  getListStore: (params) => {
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    const urlWithQuery = `${GET_LIST_STORE}?${queryString}`;
    return appClient.get(urlWithQuery);
  },

  getListStaff: (params) => {
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    const urlWithQuery = `${GET_LIST_STAFF}?${queryString}`;
    return appClient.get(urlWithQuery);
  },

  getListLinkedVa: (params) => {
    return appClient.get(`${GET_LIST_LINKED_VA}`, { params });
  },

  syncData: (params) => {
    return appClient.post(`${SYNC_DATA}`, params);
  },

  getListBank: (params) => {
    return appClient.get(`${GET_LIST_BANK}`, { params });
  },
  uploadImage: (params) => {
    return appClient.put(`${UPLOAD_IMAGE}`, params);
  },
  postCrypto: (params) => {
    return appClient.post(`${POST_CRYPTO}`, params);
  },
  postFiat: (params) => {
    return appClient.post(`${POST_FIAT}`, params);
  },
};
