import { createSlice } from "@reduxjs/toolkit";
import {
  getListStore,
  getListStaff,
  getListLinkedVa,
} from "../../actions/metadata/metadata.actions";
import { getListSettingTx } from "../../actions/setting-tx.actions";

const initialState = {
  listStore: [],
  listStaff: [],
  listSettingTx: [],
  listLinkedVa: [],
};

const metadataSlice = createSlice({
  name: "metadataSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListStore.fulfilled, (state, action) => {
        state.listStore = action.payload.data;
      })
      .addCase(getListStore.rejected, (state, action) => {
        state.listStore = [];
      })

      .addCase(getListStaff.fulfilled, (state, action) => {
        state.listStaff = action.payload.data;
      })
      .addCase(getListStaff.rejected, (state, action) => {
        state.listStaff = [];
      })

      .addCase(getListSettingTx.fulfilled, (state, action) => {
        state.listSettingTx = action.payload.data;
      })
      .addCase(getListSettingTx.rejected, (state, action) => {
        state.listSettingTx = [];
      })

      .addCase(getListLinkedVa.fulfilled, (state, action) => {
        state.listLinkedVa = action.payload.data;
      })
      .addCase(getListLinkedVa.rejected, (state, action) => {
        state.listLinkedVa = [];
      }),
});
export default metadataSlice.reducer;

export const selectListStore = (state) => state.metadata.listStore;
export const selectListStaff = (state) => state.metadata.listStaff;
export const selectListSettingTx = (state) => state.metadata.listSettingTx;
export const selectListLinkedVa = (state) => state.metadata.listLinkedVa;
