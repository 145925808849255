import React from "react";
import { theme } from "../../../configTheme";

const BtnBuy = () => {
  return (
    <button
      type="submit"
      style={{
        borderRadius: 8,
        boxShadow: `1px 1px 2px 0px rgba(255, 255, 255, 0.24) inset, 0px 16px 32px -8px ${
          theme.primary + "7a"
        }, 0px 4px 8px 0px ${theme.primary + "1f"}, 0px 2px 6px 0px ${
          theme.primary + "3d"
        }, 0px 1px 3px 0px ${theme.primary + "3d"}`,
        padding: "16px 24px",
        height: 56,
        width: "100%",
      }}
      className="flex items-center justify-center space-x-3 bg-primary"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
      >
        <path
          d="M0.5 12.7554C0.5 17.7554 4.52944 21.7554 9.5 21.7554C14.4706 21.7554 18.5 17.7554 18.5 12.7554C18.5 5.70772 12.9812 1.72608 10.5868 0.330258C10.2855 0.154598 10.1348 0.0667678 9.9683 0.0867878C9.8348 0.102828 9.6864 0.194078 9.6119 0.305898C9.5188 0.445488 9.5295 0.624858 9.5509 0.983608C9.7471 4.27612 10.2852 10.7554 6.92857 10.7554C4.77186 10.7554 4.42403 9.03576 4.36793 6.31781C4.35825 5.84901 4.35342 5.61461 4.22461 5.47526C4.12106 5.36324 3.9374 5.29805 3.78639 5.31972C3.59856 5.34669 3.46098 5.50911 3.18582 5.83397C2.05088 7.17387 0.5 9.58486 0.5 12.7554Z"
          fill="white"
        />
      </svg>
      <span
        style={{ color: "#fff" }}
        className="text-base font-medium leading-6"
      >
        Buy
      </span>
    </button>
  );
};

export default BtnBuy;
