import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { verifyBankWithOTP } from "../../../actions/bank/bank.actions";
import useWindowSize from "../../../hooks/useWindowSize";
import { selectBankAccount } from "../../../reducers/bank/bank.reducer";
import {
  selectIsOtpTextFail,
  selectOtpLoading,
  setIsTextOtpFail,
} from "../../../reducers/bank/verifyBankOtp.reducer";
import { toastAddBankSuccess } from "../../../utils/constants";
import { formatBankAccount } from "../../../utils/lib";
import CustomButton from "../../buttons/CustomButton";
import BtnSend from "./BtnSend";
import InputOtp from "./InputOtp";

const ModalOTP = ({ isOpen, onClose, handleSuccess }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectOtpLoading);
  const bankAccount = useSelector(selectBankAccount);
  const isTextFail = useSelector(selectIsOtpTextFail);
  const [valueOTP, setValueOTP] = useState("");
  const [isTimeout, setIsTimeout] = useState(false);
  const { isMd } = useWindowSize();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "16px",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.8)",
      zIndex: 10001,
    },
  };

  const handleClose = () => {
    setIsTimeout(false);
    dispatch(setIsTextOtpFail(false));
    setValueOTP("");
    onClose();
  };

  const handleSubmit = async () => {
    try {
      const res = await dispatch(verifyBankWithOTP({ ...bankAccount, otp: valueOTP }));
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data) {
        handleClose();
        handleSuccess();
        // history.push("/bank");
        toastAddBankSuccess({
          bank_code: bankAccount?.bank_code,
          bank_account_no: formatBankAccount(bankAccount?.bank_account_no),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles}>
        <div style={{ width: isMd ? 408 : "90%" }} className="p-5 font-monte relative">
          <button onClick={handleClose} className="absolute right-0 top-0">
            <IoCloseSharp size={20} />
          </button>
          <div style={{ maxWidth: "472px" }} className="w-full">
            <h4 className="text-2xl font-bold leading-8 text-dark-primary">OTP authentication</h4>
            <p className="mb-2 mt-2 w-full max-w-[440px] text-14px leading-5 text-grey-8">
              Please check your email to receive the OTP code.
            </p>
            <p className="self-stretch font-monte text-sm font-normal italic text-grey-8">
              {`Please provide the <OTP> code to ATOM by entering it in the box below if you agree and confirm authorization for ATOM to request Capital One to process your personal data, receive notification information. available for “Payment Channels” and data processing to transmit data to devices that you have created and integrated into ATOM's system through this website. Please visit `}
              <a
                href="https://atomsolution.vn/vi/privacy-policy"
                style={{ color: "#096DD9" }}
                className="cursor-pointer underline"
              >
                https://atomsolution.vn/vi/privacy-policy
              </a>{" "}
              for more details about ATOM's "privacy policy".
            </p>
            <InputOtp setValueOTP={setValueOTP} disabled={isLoading} />

            <div style={{color: '#313131'}} className="flex gap-x-2 text-14px font-normal leading-5">
              {isTextFail ? (
                <span>OTP code is incorrect.</span>
              ) : (
                <span>Haven't received the code yet?</span>
              )}
              <BtnSend setIsTimeout={setIsTimeout} />
            </div>

            <CustomButton
              title="Confirm OTP"
              disabled={valueOTP.length < 6 || isLoading || isTimeout}
              onClick={handleSubmit}
              style={{ backgroundImage: "linear-gradient(36deg, #2B3DB9 2.93%, #0CD6F7 102.74%)" }}
              className="flex mt-8 w-full justify-center"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalOTP;
