import { createSlice } from "@reduxjs/toolkit";
import { getEmployeeById } from "../../actions/employee/employee.actions";

const initialState = {
  employee: {
    email: "",
    full_name: "",
    phone: "",
    password: "",
    store_id: "",
    staff_id: "",
  },
  isLoading: false,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getEmployeeById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEmployeeById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employee = action.payload.data;
      })
      .addCase(getEmployeeById.rejected, (state, action) => {
        state.isLoading = false;
      }),
});
export default employeeSlice.reducer;

export const selectEmployee = (state) => state.employee.employee;
export const selectIsLoadingEmployee = (state) => state.employee.isLoading;
