import React from 'react'

const Price = ({price}) => {
  return (
    <div>
          <p style={{
              color: '#262626',
              fontSize: 36,
              fontWeight: 700,
              lineHeight: '44px'
          }} className='text-center'>{price} VND</p>
    </div>
  )
}

export default Price
