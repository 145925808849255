import React from "react";
import { Fade, Menu, MenuItem } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import arrowDown from "../../../assets/images/fields/arrowDown.svg";
import search from "../../../assets/images/fields/search.svg";

const SelectField = ({
  data,
  listTitle = [],
  formField,
  placeholder,
  hideSearch = false,
  searchPlaceholder = "Search placeholder",
  control,
  visibleIcon = false,
  className,
  required,
  errorText,

  isDefault = false,
  defaultLabel,
  errors,
  setValue,
  bgTransparent = false,
  large = false,
  readOnly = false,
  widthFit = false,
  initialValue = false,

  bgPlaceholderTransparent = false,
}) => {
  const ref = useRef();
  const [multiArr, setMultiArr] = useState(false);
  const [showDropdown, setShowDropdown] = useState(null);
  const open = Boolean(showDropdown);
  const value = useWatch({ control, name: formField });

  const [label, setLabel] = useState();
  const [subTitle, setSubTitle] = useState();
  const [icon, setIcon] = useState();
  const [searchTerm, setSearchTemp] = useState("");
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setLabel(data.find((item) => item.value == value)?.label);
      setSubTitle(data.find((item) => item.value == value)?.subTitle);
      setIcon(data.find((item) => item.value == value)?.icon);

      if (
        data.length > 0 &&
        typeof data[0] == "object" &&
        data[0].length >= 0
      ) {
        setMultiArr(true);
      }
    } else if (
      (value === undefined || value === null || value === "") &&
      data &&
      data.length > 0
    ) {
      if (initialValue) {
        setLabel(data[0].value);
        setIcon(data[0].icon);
        setSubTitle(data[0].subTitle);
        setValue(formField, data[0].value);
      } else {
        setLabel("");
        setIcon("");
        setSubTitle("");
        setValue(formField, "");
      }
    }
    setLabel(label);
  }, [value]);

  useEffect(() => {
    if (label === undefined) {
      setLabel(defaultLabel);
    }
  }, [label]);

  const handleSearch = (e) => {
    e.stopPropagation();
    setSearchTemp(e.target.value);

    const filteredData = data.filter((item) =>
      item.label.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setNotFound(filteredData.length === 0);
  };
  useEffect(() => {
    if (isDefault) {
      if (data && data.length > 0) {
        if (value === undefined || value === null || value === "") {
          setLabel(data[0].value);
          setIcon(data[0].icon);
          setSubTitle(data[0].subTitle);
          setValue(formField, data[0].value);
        } else {
          setLabel(data.find((item) => item.value == value)?.label);
          setIcon(data.find((item) => item.value == value)?.icon);
          setSubTitle(data.find((item) => item.value == value)?.subTitle);
        }
      }
    }
  }, [data]);

  return (
    <div className="">
      <Controller
        name={formField}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <div className="max-h-[56px] w-full">
            <div
              onClick={(e) => {
                e.stopPropagation();
                !readOnly && setShowDropdown(e.currentTarget);
              }}
              ref={ref}
              className={`relative flex   cursor-pointer items-center justify-between rounded border p-4  ${className} ${
                bgTransparent ? "bg-transparent rounded-lg " : "bg-white "
              } `}
              style={{
                border:
                  errors && errors[formField] && !value
                    ? "1px solid #FF0000"
                    : "1px solid #DEDEDE",
                width: widthFit ? "188px" : "100%",
                height: bgTransparent ? "44px" : "56px",
              }}
            >
              <div
                style={{
                  color:
                    errors && errors[formField] && !value ? "red" : "#6A6A6C",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: value ? 2 : 1,
                }}
                className={`absolute  transition-all 
                          ${
                            showDropdown || label
                              ? `left-2 top-0 px-2 text-xs ${
                                  bgPlaceholderTransparent
                                    ? "via-white] bg-gradient-to-b from-transparent to-white"
                                    : "bg-white"
                                }`
                              : "left-4 top-4 text-sm"
                          }`}
              >
                <div
                  className={`relative
                          font-normal 
                        `}
                >
                  <p
                    className="relative"
                    style={{ zIndex: 1, color: "#313131" }}
                  >
                    {placeholder}
                  </p>
                  <div
                    style={{
                      position: "absolute",
                      top: "calc(50%-0.5px)",
                      height: "1px",
                      transform: "-translate-[calc(50%-0.5px)]",
                    }}
                    className={`${
                      showDropdown || value ? "block" : "hidden"
                    } absolute top-[calc(50%-0.5px)] z-0 h-[1px] w-full -translate-y-[calc(50%-0.5px)] bg-white`}
                  ></div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {visibleIcon && icon && (
                  <div
                    className={`flex ${
                      large === true ? "h-12 w-12" : "h-6 w-6"
                    }  items-center justify-center`}
                  >
                    <img
                      // src={icon}
                      // alt="icon"
                      // width={48}
                      // height={48}
                      // className={`${large === true ? "h-12" : "h-6"} w-auto`}
                      src={icon}
                      alt="icon"
                      width={24}
                      height={24}
                      className="h-full w-full object-contain"
                    />
                  </div>
                )}
                <div>
                  {" "}
                  <div
                    style={{
                      color:
                        label === "Select your industry" ||
                        "Choose a bank" ||
                        "Choose your payment network" ||
                        "Choose your currency"
                          ? "#6A6A6C"
                          : "#313131",
                    }}
                    className={`h-6 font-poppins ${
                      large === true ? "font-semibold" : "font-normal"
                    } text-sm  leading-6 text-text-primary`}
                  >
                    {label}
                  </div>
                  <div
                    style={{ color: "#202124" }}
                    className="text-sm leading-[21px] text-[#202124]"
                  >
                    {subTitle}
                  </div>
                </div>
              </div>

              <img
                src={arrowDown}
                alt="arrowDown"
                sizes="24"
                className={`${
                  showDropdown && "rotate-180"
                } transition-all duration-200`}
              />
            </div>
            {errors && errors[formField] && !value && (
              <p className="m-0 text-xs font-normal text-red-500">
                {errorText}
              </p>
            )}
            <Menu
              id="fade-menu"
              style={{
                maxHeight: "350px",
                maxWidth: ref.current?.clientWidth + 33,
              }}
              className="mt-[12px] max-h-[350px] p-0 custom-scrollbar"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={showDropdown}
              onClose={() => setShowDropdown(null)}
              open={open}
              TransitionComponent={Fade}
            >
              {!hideSearch && (
                <div
                  style={{
                    //width: ref.current?.clientWidth,

                    margin: "12px",
                    gap: "10px",
                    border: "1px solid #E8E8E8 ",
                    borderRadius: "8px",
                  }}
                >
                  <div className="flex w-full items-center p-3 rounded-lg ">
                    <img src={search} alt="search" sizes="24" />
                    <input
                      onChange={handleSearch}
                      type="text"
                      placeholder={searchPlaceholder}
                      style={{ color: "#313131" }}
                      className="w-full bg-transparent placeholder-hints"
                      // readOnly={readOnly}
                      value={searchTerm}
                    />
                  </div>
                </div>
              )}
              {notFound && searchTerm && (
                <MenuItem
                  style={{ width: ref.current?.clientWidth }}
                  className="px-3 py-2 text-sm font-normal text-text-primary "
                >
                  Not found
                </MenuItem>
              )}
              {multiArr
                ? data.map((subArr, index) => (
                    <div key={index}>
                      <p
                        className="px-3 py-2 text-sm font-medium text-text-primary"
                        style={{ color: "#202124", fontWeight: "600" }}
                      >
                        {listTitle[index]}
                      </p>
                      {subArr
                        .filter((raw) =>
                          String(raw.label)
                            .toLowerCase()
                            .includes(searchTerm?.toLowerCase())
                        )
                        .map((item, subIndex) => (
                          <MenuItem
                            key={subIndex}
                            className="flex items-center gap-3 p-3 text-sm font-normal text-text-primary"
                            style={{ width: ref.current?.clientWidth }}
                            onClick={() => {
                              setValue(formField, item.value);
                              setLabel(listTitle[index]);

                              setShowDropdown(null);
                              setNotFound(false);
                            }}
                          >
                            {item.icon && (
                              <div className="flex h-6 w-6 items-center justify-center">
                                <img
                                  src={item?.icon}
                                  alt="icon"
                                  width={24}
                                  height={24}
                                  className="h-full w-full object-contain"
                                />
                              </div>
                            )}
                            {item.label}
                          </MenuItem>
                        ))}
                    </div>
                  ))
                : data
                    ?.filter((raw) =>
                      String(raw.label)
                        .toLowerCase()
                        .includes(String(searchTerm)?.toLowerCase())
                    )
                    ?.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-3 p-3 text-base  cursor-pointer   "
                        style={{
                          width: ref.current?.clientWidth,
                          color: "#313131",
                          fontWeight: "400",
                        }}
                        onClick={() => {
                          setValue(formField, item.value);
                          setLabel(item.label);

                          setShowDropdown(null);
                          setNotFound(false);
                        }}
                      >
                        {item.icon && (
                          <div className="flex h-6 w-6 items-center justify-center">
                            <img
                              src={item?.icon}
                              alt="icon"
                              sizes="24"
                              width={24}
                              height={24}
                              className="h-full w-full object-contain"
                            />
                          </div>
                        )}
                        {item.label}
                      </div>
                    ))}
            </Menu>
          </div>
        )}
      />
    </div>
  );
};

export default SelectField;
