import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionTitle from "../../components/section-title";
import Datatable from "../../components/tsdatatable/datatables";
import { StatusCell, ReceiptCell } from "../../components/tsdatatable/datatables";
import { selectTransCard } from "../../reducers/transaction.reducer";
import moment from "moment";
import { formatBankAccount, formatCurrency, formatDateTxn } from "../../utils/lib";
import { getListTransCard } from "../../actions";
import { TbFilterDollar } from "react-icons/tb";

import CustomButton from "../../components/buttons/CustomButton";
import Filters from "../../components/filters/Filters";
import useWindowSize from "../../hooks/useWindowSize";

const DatatableComponent = ({ customData, filters, numOfPage, data, total_amount, setFilters }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Time",
        accessor: "created_at",
        Cell: ({ value, row }) => <p>{formatDateTxn(value)}</p>,
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Invoice code",
        accessor: "bill_id",
        Cell: ({ value, row }) => <span>{value || "0000"}</span>,
      },
      {
        Header: "Card number",
        accessor: "card_id",
        Cell: ({ value, row }) => <span>{value ? formatBankAccount(value) : "***8673"}</span>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value, row }) => <span>{formatCurrency(value)}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => <StatusCell data={row} />,
      },
      {
        Header: "Bank",
        accessor: "bank_code",
        Cell: ({ value, row }) => <span>{value || "MB"}</span>,
      },
      {
        Header: "Invoice",
        accessor: "bill_url",
        Cell: ({ value, row }) => <ReceiptCell data={row} />,
      },
    ],
    [],
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
      total_amount={total_amount}
    />
  );
};

const TransactionCard = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectTransCard);
  const [showFilter, setShowFilter] = useState(false);
  const [numOfPage, setnumOfPage] = useState("1");
  const { isMd } = useWindowSize();

  // GMT +7
  const currentTime = new Date();
  const [y, m, d] = [currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate()];
  const startTime = new Date(y, m, d);
  const endTime = new Date(y, m, d, 23, 59, 59);

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
    status: "all",
    start_time: "",
    end_time: "",
    bill_id: "",
    customer_id: "",
  });

  const [tempFilters, setTempFilters] = useState({
    page: 1,
    limit: 10,
    status: "all",
    start_time: "",
    end_time: "",
    bill_id: "",
    store_id: "",
  });

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setnumOfPage(data?.total_page);
    return data?.data;
  }, [data?.data, data?.total_page]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
        return {
          ...e,
          request_amount: formatCurrency(e.request_amount),
          original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
            "HH:mm:ss DD-MM-YYYY",
          ),
        };
      })
      : [];
  };

  useEffect(() => {
    dispatch(getListTransCard(filters));
  }, [dispatch, filters]);

  return (
    <div className="relative">
      <div className="flex items-center justify-between">
        <SectionTitle title="Report" subtitle="Card transactions" />
        <CustomButton
          id="button-filter"
          onClick={() => setShowFilter(!showFilter)}
          className="block md:hidden"
          outline
          leftIcon={<TbFilterDollar size={16} />}
        />
      </div>
      <div
        id="filter"
        style={{
          border: "1px solid #F5F7FA",
          boxShadow: "0px 4px 20px 0px rgba(96, 111, 134, 0.06)",
          top: !isMd && 32,
          zIndex: showFilter || isMd ? 10 : -10,
        }}
        className="absolute right-0 md:relative flex gap-4 md:gap-0 flex-col md:flex-row md:justify-between items-end bg-white rounded-xl mt-6 p-6"
      >
        <Filters filters={tempFilters} setFilters={setTempFilters} />
        <div className="grid grid-cols-2 w-full md:w-auto gap-4">
          <CustomButton
            filters={filters}
            setFilters={(params) => {
              setFilters(params);
              setTempFilters(params);
            }}
            type="reset"
            title={"Reset"}
          />
          <CustomButton
            filters={tempFilters}
            setFilters={setFilters}
            type="apply"
            title={"Apply"}
          />
        </div>
      </div>

      <div className="mt-6">
        <DatatableComponent
          customData={formatData(customData)}
          filters={filters}
          setFilters={setFilters}
          numOfPage={numOfPage}
          data={data}
          total_amount={data.total_amount}
        />
      </div>
    </div>
  );
};
export default TransactionCard;
