/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { SlArrowLeft } from "react-icons/sl";
import { IoClose } from "react-icons/io5";
import { useLocation, Link } from "react-router-dom/cjs/react-router-dom";
import { theme } from "../../configTheme";
import { useDispatch, useSelector } from "react-redux";
import { getMerchant } from "../../actions";
import { selectMerchant } from "../../reducers/sass.reducer";

import SidebarItem from "./SidebarItem";

import overview from "../../assets/images/sidebar/overview.svg";
import onboarding from "../../assets/images/sidebar/onboarding.svg";
import report from "../../assets/images/sidebar/report.svg";
import payment from "../../assets/images/sidebar/payment.svg";
import business from "../../assets/images/sidebar/business.svg";
import edc from "../../assets/images/sidebar/edc.svg";
import setting from "../../assets/images/sidebar/setting.png";
import error from "../../assets/images/sidebar/error.svg";
import store from "../../assets/images/sidebar/store.svg";
import invoice from "../../assets/images/sidebar/invoice.png";
import logout from "../../assets/images/sidebar/logout.svg";
import atomLogo from "../../assets/images/sidebar/atomLogo.svg";
import esolLogo from "../../assets/images/sidebar/esolLogo.png";
import useWindowSize from "../../hooks/useWindowSize";

const Sidebar = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const route = useLocation().pathname.split("/")[1];
  const [activedItem, setActivedItem] = useState("/" + (route || "dashboard"));

  // console.log(">>> check route: ", activedItem);
  useEffect(() => setActivedItem("/" + route), [route]);
  const [open, setOpen] = useState(true);
  const [openGlobalSubItems, setOpenGlobalSubItems] = useState(null);
  const merchant = useSelector(selectMerchant);
  const { isMd } = useWindowSize();

  useEffect(() => {
    dispatch(getMerchant());
  }, []);

  useEffect(() => {
    setShow(isMd);
    setOpen(true);
  }, [isMd]);

  const check_new = localStorage.getItem("is_new");

  console.log(">>> check_new", check_new);

  const subMenuSell =
    Object.keys(merchant).length === 0
      ? [{ title: "Đăng kí bán hàng", link: "/sell-register" }]
      : [
          { title: "Quản lý khách hàng", link: "/sell-customer" },
          { title: "Quản lý sản phẩm", link: "/sell-product" },
          { title: "Quản lý đơn hàng", link: "/sell-order" },
          // { title: "Quản lý hóa đơn", link: "/sell-invoice" },
          { title: "Quản lý bàn", link: "/sell-table" },
          { title: "Thiết lập", link: "/sell-setting" },
        ];

  const sidebarItems = [
    {
      icon: overview,
      title: "Tổng quan",
      title_eng: "Overview",
      link: "/dashboard",
      subItems: [],
    },
    {
      icon: onboarding,
      title: "Onboarding",
      title_eng: "Onboarding",
      link: "/onboarding",
      disable: check_new == 1 ? false : true,
      subItems: [],
    },
    {
      icon: report,
      title: "Báo cáo",
      title_eng: "Report",
      link: "",
      subItems: [
        {
          title: "Giao dịch On-chain",
          title_eng: "On-chain transactions",
          link: "/tx-onchain",
        },
        {
          title: "Giao dịch thẻ",
          title_eng: "Card transactions",
          link: "/tx-card",
          disable: false,
        },
        {
          title: "Giao dịch E-com",
          title_eng: "E-com transactions",
          link: "/tx-ecom",
          disable: false,
        },
        {
          title: "Giao dịch VA",
          title_eng: "VA transactions",
          link: "/tx-va",
          disable: false,
        },
        {
          title: "Giao dịch ví",
          title_eng: "Wallet transactions",
          link: "/tx-momo",
          disable: false,
        },
        {
          title: "Giao dịch tiền mặt",
          title_eng: "Cash transactions",
          link: "/tx-cash",
          disable: false,
        },
        {
          title: "Giao dịch trả góp",
          title_eng: "Installment transactions",
          link: "/tx-installment",
          disable: false,
        },
      ],
    },
    {
      icon: payment,
      title: "ShopQR",
      title_eng: "Pay",
      link: "",
      subItems: [
        {
          title: "Tài khoản ngân hàng",
          title_eng: "Bank",
          link: "/bank",
          disable: false,
        },
        {
          title: "Quản lý ShopQR",
          title_eng: "VA Bank",
          link: "/payment",
          disable: false,
        },
      ],
    },

    // {
    //   icon: store,
    //   title: "Sales Manager",
    //   link: "",
    //   subItems: subMenuSell,
    // },
    // {
    //   icon: invoice,
    //   title: "Hóa đơn điện tử",
    //   link: "/bill-digital",
    //   subItems: [],
    // },

    {
      icon: business,
      title: "Thông tin doanh nghiệp",
      title_eng: "Enterprise",
      link: "",
      subItems: [
        {
          title: "Quản lý nhân viên",
          title_eng: "Employee manager",
          link: "/company-employee",
          disable: false,
        },
        {
          title: "Hồ sơ doanh nghiệp",
          title_eng: "Business profile",
          link: "/company-profile",
          disable: false,
        },
        {
          title: "Quản lý cửa hàng",
          title_eng: "Store Management",
          link: "/store-manager",
          disable: false,
        },
        // { title: "Thiết lập phương thức thanh toán", link: "/setting-company" },
      ],
    },
    {
      icon: edc,
      title: "Quản lý máy thanh toán thẻ",
      title_eng: "Blockchain management on EDC",
      link: "/edc",
      disable: false,
      subItems: [],
    },
    {
      icon: error,
      title: "Báo lỗi",
      title_eng: "Error",
      link: "/feedback",
      disable: true,
      subItems: [],
    },
    {
      icon: setting,
      title: "Thiết lập",
      link: "/setting-tx",
      disable: true,
      subItems: [],
    },
    // {
    //   icon: billDigital,
    //   title: "Gói hóa đơn điện tử",
    //   link: "/bill-digital",
    //   subItems: [],
    // },
    {
      icon: setting,
      title: "Cài đặt chủ đề",
      link: "/setting-theme",
      disable: true,
      subItems: [],
    },
  ];

  return (
    <div
      onClick={() => setShow(false)}
      className={`absolute ${
        show
          ? "z-40 bg-blur w-full md:bg-transparent md:w-auto"
          : "z-10 bg-transparent w-0"
      } md:relative`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          height: "calc(100vh - 0px)",
          width: open ? 320 : 112,
          transition: "all 0.3s linear",
          background: theme.sidebar,
          transform: !show && "translateX(-100%)",
        }}
        className={`px-4 py-8 flex flex-col md:rounded-r-3xl`}
      >
        {/* Brand Logo */}
        <div
          className="w-full h-8 flex items-center ml-4 relative"
          style={{ paddingLeft: !open && 0, transition: "all 0.3s linear" }}
        >
          <img
            src={open ? theme.sidebarLogo : theme.sideBarLogoScaleDown}
            alt="logo"
            style={{
              maxHeight: 32,
              height: open ? 32 : "auto",
              width: "auto",
              transition: "all 0.3s linear",
              objectFit: "contain",
            }}
          />
          <div
            onClick={() => setOpen(!open)}
            style={{
              zIndex: 41,
              left: "95%",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="absolute hidden md:flex cursor-pointer w-8 h-8 border border-primary bg-white rounded-lg justify-center items-center"
          >
            <SlArrowLeft
              size={16}
              color={theme.primary}
              style={{
                transition: "all 0.3s linear",
                transform: !open && "rotate(180deg)",
              }}
            />
          </div>
          <div
            onClick={() => setShow(false)}
            style={{ zIndex: 41, right: "10%", top: -16 }}
            className="absolute md:hidden cursor-pointer w-8 h-8 flex justify-center items-center"
          >
            <IoClose size={24} color={"white"} />
          </div>
        </div>

        {theme.discountWeb && !isMd && (
          <a
            href={theme.discountWeb}
            target="_blank"
            rel="noopener noreferrer"
            style={{ boxShadow: "0 0 6px 2px rgba(0, 0, 0, 0.05)" }}
            className="rounded-md overflow-hidden mt-4 -mb-4"
          >
            <img
              src={theme.banner}
              alt="banner"
              style={{ width: "100%", height: 50, objectFit: "cover" }}
            />
          </a>
        )}

        {/* Sidebar Items */}
        <div
          style={{
            margin: open ? "32px 0 24px" : "32px 12px 24px",
            transition: "all 0.3s linear",
            maxHeight: "calc(100vh - 212.667px - 40px)",
            overflow: open && "auto",
          }}
          className="custom-scrollbar"
        >
          {sidebarItems
            .filter((item) => {
              // KFC
              if (theme.primary == "#ED1C24")
                return (
                  item.title != "Sales Manager" &&
                  item.title != "Cài đặt chủ đề"
                );
              // OCB
              else if (theme.primary == "#008F3C")
                return (
                  item.title != "Thiết lập" && item.title != "Cài đặt chủ đề"
                );
              // VPBANK
              else if (theme.primary == "#00B74F")
                return (
                  item.title != "Thiết lập" && item.title != "Cài đặt chủ đề"
                );
              // BVB
              else
                return (
                  item.title != "Thiết lập" && item.title != "Cài đặt chủ đề"
                );
            })
            .map((item, index) => {
              const { icon, title, title_eng, link, subItems, disable } = item;
              return (
                <div key={index}>
                  <SidebarItem
                    disable={disable}
                    icon={icon}
                    title={title_eng}
                    link={link}
                    subItems={subItems}
                    openSidebar={open}
                    activedItem={activedItem}
                    setActivedItem={setActivedItem}
                    openGlobalSubItems={openGlobalSubItems}
                    setOpenGlobalSubItems={setOpenGlobalSubItems}
                    setShowSidebar={setShow}
                  />
                </div>
              );
            })}
        </div>

        {/* Logout */}
        <div
          style={{
            flex: 1,
            borderTop: "1px solid #E8E8E8",
            margin: !open && "0 12px",
            transition: "all 0.3s linear",
          }}
          className="pt-6"
        >
          <Link
            to="/login"
            className="flex items-center rounded-xl hover:bg-sidebar-hover sidebar-item"
            style={{
              padding: open ? "12px 10px" : "14px 20px",
              gap: 10,
              transition: "all 0.3s linear",
            }}
          >
            <img src={logout} alt="logout" style={{ width: 16 }} />
            {open && (
              <span
                style={{ flex: 1 }}
                className="text-sm text-white leading-5 font-medium truncate"
              >
                Log out
              </span>
            )}
          </Link>
        </div>

        {/* Atom Logo */}
        <div
          className={`mt-5 flex ${
            !open && "flex-col"
          } justify-center items-center gap-1`}
        >
          <p className="text-white text-xs leading-4 font-medium truncate">
            Powered by
          </p>
          <img src={esolLogo} alt="esolLogo" className="h-5 w-auto" />
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
