import { createSlice } from "@reduxjs/toolkit"
import { getTransactionOnchain } from "../../actions/report/onchain-tx.action";

const initialState = {
    listTxOnchain: []
}

const OnchainTxSlice = createSlice({
    name: 'OnchainTxSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTransactionOnchain.fulfilled, (state, action) => {
            state.listTxOnchain = action.payload.data;
        })
    }
})

export default OnchainTxSlice.reducer;
export const selectListOnchainTx = state => state.OnchainTxSlice.listTxOnchain;