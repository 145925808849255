import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getListStore } from "../../actions/metadata/metadata.actions";
import SearchField from "./SearchField";
import SelectFieldContent from "./select/SelectFieldContent";
import SelectFieldItem from "./select/SelectFieldItem";
import SelectFieldWrapper from "./select/SelectFieldWrapper";
import "./style.css";

const SelectStoreField = (props) => {
  const {
    register,
    name,
    setValue,
    errors,
    extra,
    state,
    isRequired,
    initValue,
  } = props;

  const [list, setList] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const handleSelectBox = (data) => {
    setCurrentItem(data);
    setValue(name, data?.id);
    setShowContent(false);
  };

  // Function to filter the array based on the 'name' and 'display_name' property
  function filterByName() {
    if (searchValue.trim().length === 0) return list;

    const normalizedFilter = searchValue.toLowerCase().trim();
    return list.filter((item) =>
      item?.name.toLowerCase().includes(normalizedFilter)
    );
  }

  const CurrentItemComp = () => {
    return (
      <p
        style={{ fontWeight: "500", color: "#313131" }}
        className="text-14px leading-5 font-medium"
      >
        {currentItem?.name}
      </p>
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getListStore({ page_size: 100000 }));

        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data?.data;

          setList(data);
          setCurrentItem(data.find((item) => item?.id === initValue));
        }
      } catch (err) {}
    };
    getData();
  }, [dispatch, initValue]);

  return (
    <div>
      <SelectFieldWrapper
        {...props}
        className={" px-4"}
        showContent={showContent}
        setShowContent={setShowContent}
        label={"Store"}
        isRequired={true}
        initialText="Choose store"
        currentValue={currentItem}
        currentItemComp={<CurrentItemComp />}
      >
        <SearchField
          searchValue={searchValue}
          placeholder="Search store"
          setSearchValue={setSearchValue}
          id="search-store-input"
          extraClass="m-4 mb-1"
        />
        <SelectFieldContent>
          {filterByName().map((item, index) => {
            const active = currentItem?.id === item?.id;
            return (
              <SelectFieldItem
                key={`${item?.id}-${index}`}
                onClick={() => handleSelectBox(item)}
                active={active}
              >
                {item?.name}
              </SelectFieldItem>
            );
          })}
        </SelectFieldContent>
      </SelectFieldWrapper>
    </div>
  );
};

export default SelectStoreField;
