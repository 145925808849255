import { authService, userService } from "../../services";
import { LocalStorageService, history } from "../../helpers";
import { authTypes } from "../../types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  verifyAccountFailure,
  verifyAccountRequest,
  verifyAccountSuccess,
  verifySendEmailFailure,
  verifySendEmailRequest,
  verifySendEmailSuccess,
} from "../../reducers/verify.reducer";

// export const authLogin = (dataReq) => async (dispatch, getState) => {
//   if (dataReq) {
//     const state = getState();

//     const { domain } = state.auth;

//     try {
//       dispatch(request(dataReq));
//       authService.login({
//         ...dataReq,
//         //domain
//       })
//         .then(
//           async (res) => {
//             const { data } = res;
//             if (data && data.access_token) {
//               LocalStorageService.setToken(data);
//               LocalStorageService.setRefreshToken(data);

//               await dispatch(success(data));
//               await dispatch(authGetProfile());
//               history.push(`/dashboard`);
//             }
//             return data;
//           },
//           (error) => {
//             dispatch(failure(error));
//           }
//         );
//     } catch (error) {
//       dispatch(failure(error));
//     }

//     function request(data) {
//       return { type: authTypes.AUTH_LOGIN_REQUEST, payload: data };
//     }
//     function success(data) {
//       return { type: authTypes.AUTH_LOGIN_SUCCESS, payload: data };
//     }
//     function failure(error) {
//       return { type: authTypes.AUTH_LOGIN_FAILURE, error };
//     }
//   }
// };

export const authLogin = createAsyncThunk(
  "",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await authService.login(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authRegister = createAsyncThunk(
  "",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await authService.register(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const verifySendEmail = async (dataReq, dispatch) => {
  console.log("🚀 ~ verifySendEmail ~ dataReq:", dataReq);
  dispatch(verifySendEmailRequest());
  try {
    const res = await authService.verifySendEmail(dataReq);
    dispatch(verifySendEmailSuccess(res));
  } catch (error) {
    console.log(">>> error: ", error);
    dispatch(verifySendEmailFailure(error));
  }
};
export const verifyAccount = async (dataReq, dispatch) => {
  dispatch(verifyAccountRequest());
  try {
    const res = await authService.verifyAccount(dataReq);
    dispatch(verifyAccountSuccess(res));
  } catch (error) {
    console.log(">>> error: ", error);
    dispatch(verifyAccountFailure(error));
  }
};

export const authLogout = () => async (dispatch, getState) => {
  LocalStorageService.clearToken();
  await dispatch({ type: authTypes.AUTH_LOGOUT, payload: null });
};

export const authGetProfile = (dataReq) => async (dispatch, getState) => {
  const state = getState();

  const { domain } = state.auth;

  try {
    dispatch(request(dataReq));
    userService
      .getProfile({
        ...dataReq,
        // domain
      })
      .then(
        (res) => {
          const { data } = res;
          if (data) {
            dispatch(success(data));
          }
          return data;
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  } catch (error) {
    dispatch(failure(error));
  }

  function request(data) {
    return { type: authTypes.AUTH_GET_PROFILE_REQUEST, payload: data };
  }
  function success(data) {
    return { type: authTypes.AUTH_GET_PROFILE_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: authTypes.AUTH_GET_PROFILE_FAILURE, error };
  }
};

export const getSubDomainURI =
  ({ subDomain }) =>
  async (dispatch, getState) => {
    dispatch(success(subDomain));
    function success(data) {
      return { type: authTypes.GET_SUBDOMAIN_SUCCESS, payload: data };
    }
  };
