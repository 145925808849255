import React, { useState } from "react";
import ModalWrapper from "../../../components/modals/ModalWrapper";
import { IoCloseSharp } from "react-icons/io5";
import InputField from "../../../components/fields/InputField";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/buttons/CustomButton";
import axiosClient2 from "../../../services/client_axios2";
import { useDispatch } from "react-redux";
import { ERROR_CODE } from "../../../utils/errorCode";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import { exportInvoice } from "../../../actions/invoice-manager.actions";

const FORM = {
  TAX_CODE: "tax_code",
  ENTERPRISE: "enterprise",
  ADDRESS: "address",
  PHONE_NUMBER: "phoneNumber",
  EMAIL: "email",
  NAME: "name",
};

const ModalExport = ({ setShowModal, selectedItem, setShowPopupSuccess }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  // const searchDebounce = useDebounce(watch(FORM.TAX_CODE), 800);
  const [disabledField, setDisableField] = useState(true);
  const disabled =
    !watch(FORM.TAX_CODE) || !watch(FORM.ENTERPRISE) || !watch(FORM.ADDRESS) || !watch(FORM.EMAIL);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onFormSubmit = async (value) => {
    const raw = {
      order_id: Number(selectedItem.order_id),
      tax_info: value,
    };

    try {
      const res = await dispatch(exportInvoice(raw));
      console.log(res);
      if (res?.meta?.requestStatus === "fulfilled") {
        handleCloseModal();
        setShowPopupSuccess(true);
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        }
        CustomToastMessage.error("Thất bại", msg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const resetValue = () => {
    setValue(FORM.ENTERPRISE, "");
    setValue(FORM.ADDRESS, "");
    setValue(FORM.EMAIL, "");
    setValue(FORM.PHONE_NUMBER, "");
  };

  const onGetDataEnterprise = async () => {
    // tax code test = "8836558803"
    if(!watch(FORM.TAX_CODE)) return
    try {
      const res = await axiosClient2.get(
        `https://e-invoice.atomsolution.vn/v1/api/common/party?tax_code=${watch(FORM.TAX_CODE)}`
      );
      const { data } = res;
      if (data) {
        setValue(FORM.ENTERPRISE, data?.[FORM.ENTERPRISE] || "");
        setValue(FORM.ADDRESS, data?.[FORM.ADDRESS] || "");
        setValue(FORM.EMAIL, data?.[FORM.EMAIL] || "");
        setValue(FORM.PHONE_NUMBER, data?.[FORM.PHONE_NUMBER] || "");

        setError(FORM.TAX_CODE, { type: "custom", message: "" });
      }
    } catch (error) {
      console.log(error);
      resetValue();
      setError(FORM.TAX_CODE, { type: "custom", message: error?.msg });
    } finally {
      setDisableField(false);
    }
  };

  return (
    <ModalWrapper className="p-6 rounded-2xl">
      <IoCloseSharp
        className="absolute cursor-pointer hover:opacity-75"
        style={{ right: 16, top: 16 }}
        onClick={handleCloseModal}
        size={20}
      />
      <form
        style={{ width: 632 }}
        onSubmit={handleSubmit(onFormSubmit, onErrors)}
        className="flex flex-col items-center gap-6"
      >
        <p className="text-black text-sm font-semibold leading-5 ">Xuất hoá đơn</p>
        <div className="w-full grid grid-cols-2 gap-4">
          <div style={{ gap: 6 }} className="flex items-end gap-1">
            <InputField
              isRequired
              setValue={setValue}
              type="text"
              variant={"tax_no"}
              label="Mã số thuế"
              placeholder="Nhập mã số thuế"
              id={FORM.TAX_CODE}
              name={FORM.TAX_CODE}
              extra="flex-1"
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
              itemRight={
                <button
                  type="button"
                  disabled={!watch(FORM.TAX_CODE)}
                  style={{ height: 44 }}
                  onClick={onGetDataEnterprise}
                  className={`${!watch(FORM.TAX_CODE) ? 'opacity-50' : 'opacity-100 '} p-3 border-primary border text-primary text-sm font-semibold leading-5 rounded-lg`}
                >
                  <span style={{ width: 97 }} className=" flex items-center justify-center">
                    Lấy thông tin
                  </span>
                </button>
              }
            />
          </div>
          <InputField
            disabled={disabledField}
            isRequired
            setValue={setValue}
            type="email"
            label="Email"
            placeholder="Nhập mã email"
            id={FORM.EMAIL}
            name={FORM.EMAIL}
            value={watch(FORM.EMAIL)}
            extra="flex-1"
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            disabled={disabledField}
            isRequired
            setValue={setValue}
            label="Tên Công ty/ Doanh nghiệp"
            id={FORM.ENTERPRISE}
            name={FORM.ENTERPRISE}
            value={watch(FORM.ENTERPRISE)}
            extra="flex-1"
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            disabled={disabledField}
            setValue={setValue}
            type="number"
            label="Số điện thoại"
            placeholder="Nhập số điện thoại"
            id={FORM.PHONE_NUMBER}
            name={FORM.PHONE_NUMBER}
            value={watch(FORM.PHONE_NUMBER)}
            extra="flex-1"
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            disabled={disabledField}
            isRequired
            setValue={setValue}
            label="Địa chỉ"
            id={FORM.ADDRESS}
            name={FORM.ADDRESS}
            value={watch(FORM.ADDRESS)}
            extra="flex-1"
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            disabled={disabledField}
            setValue={setValue}
            label="Tên người thực hiện giao dịch"
            placeholder="Nhập tên người thực hiện giao dịch"
            id={FORM.NAME}
            name={FORM.NAME}
            value={watch(FORM.NAME)}
            extra="flex-1"
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
        </div>
        <CustomButton
          disabled={disabled}
          type="submit"
          title="Xuất hoá đơn tạm"
          className={"w-full"}
        />
      </form>
    </ModalWrapper>
  );
};

export default ModalExport;
