import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../fields/InputField";
import CustomButton from "../buttons/CustomButton";
import SelectField from "../fields/select-address/SelectField";
import sidepic from "../../assets/images/onboarding/frame.png";
import taxIcon from "../../assets/images/onboarding/tax_icon.svg";
import InputCustom from "../fields/InputCustom";
import { history } from "../../helpers";
import { getListIndustry } from "../../actions/industry/industry.action";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  representative_name: yup.string(),

  referral_code: yup.string(),
  tax_code: yup.string(),
  reprensentative_phone_number: yup.string(),
  industry: yup.string(),
});

const Merchant = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      representative_name: "",

      referral_code: "",
      reprensentative_phone_number: "",
      tax_code: "",
      industry: "",
    },
    resolver: yupResolver(schema),
  });

  // const dataIndustry = [
  //   { label: "industry 1", value: "industry 1" },
  //   { label: "industry 2", value: "industry 2" },
  // ];

  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (
      watch("representative_name") === "" ||
      watch("reprensentative_phone_number") === "" ||
      watch("industry") === "" ||
      watch("tax_code") === ""
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    watch("representative_name"),
    watch("reprensentative_phone_number"),
    watch("industry"),
    watch("tax_code"),
  ]);

  const dispatch = useDispatch();

  const [dataIndustry, setDataIndustry] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getListIndustry({}));

        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data;

          const dataInput = data?.map((item) => ({
            label: item?.name,
            value: item?.name,
          }));
          setDataIndustry(dataInput);
        }
      } catch (err) {}
    };
    getData();
  }, []);

  const onFormSubmit = async (value) => {
    try {
      localStorage.setItem("dataOnboarding", JSON.stringify(value));
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);
  let dataOnboarding = JSON.parse(localStorage.getItem("dataOnboarding"));

  useEffect(() => {
    if (dataOnboarding !== null) {
      setValue("referral_code", dataOnboarding?.referral_code);
      setValue(
        "reprensentative_phone_number",
        dataOnboarding?.reprensentative_phone_number
      );
      setValue("industry", dataOnboarding?.industry);
      setValue("representative_name", dataOnboarding?.representative_name);
      setValue("tax_code", dataOnboarding?.tax_code);
    }
  }, []);

  return (
    <div
      className="flex   justify-between "
      style={{
        height: "100vh",
        minHeight: "900px",
        backgroundColor: "#FAFAFA",
      }}
    >
      <div
        className="flex flex-col h-full w-full    "
        style={{
          width: "62%",
          marginTop: "60px",
          gap: "60px",
        }}
      >
        <div className="w-full">
          <div
            className="flex w-full items-center justify-end "
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              color: "#6E6D7A",
            }}
          >
            <button
              onClick={() => {
                history.push(`/dashboard`);
                localStorage.removeItem("dataOnboarding");
                localStorage.removeItem("crypto");
                localStorage.removeItem("fiat");
                localStorage.removeItem("logo");
              }}
              className="text-sm font-sans "
              style={{ fontWeight: "700" }}
            >
              Skip the tour
            </button>
          </div>
        </div>
        <div
          className="w-full"
          style={{ paddingLeft: "60px", paddingRight: "60px" }}
        >
          <div className="w-full flex flex-col " style={{ gap: "0.5rem" }}>
            <div
              className="text-2xl"
              style={{ fontWeight: "700", color: "#202124" }}
            >
              Merchant Onboarding
            </div>
            <div
              className="text-sm"
              style={{ fontWeight: "400", color: "#4D4D50" }}
            >
              Please enter all information to create an account!
            </div>
          </div>
          <div className="mt-12">
            <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
              <div style={{ width: "532px" }}>
                <div style={{ gap: "0.5rem" }} className="flex flex-col ">
                  <InputCustom
                    isRequired
                    variant=""
                    setValue={setValue}
                    label="Full name of representative"
                    placeholder="Enter the representative's full name"
                    id="representative_name"
                    name="representative_name"
                    value={watch("representative_name")}
                    extra=""
                    pattern={
                      /^(?!\s+$)(^\s*$|^[^\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$)/
                    }
                    register={register}
                    errors={errors}
                    state={errors?.type ? "error" : " "}
                  />
                  <div className="flex flex-col gap-2">
                    <div
                      className="text-sm "
                      style={{ fontWeight: "600", color: "#313131" }}
                    >
                      Industry<span style={{ color: "#F5222D" }}>*</span>
                    </div>
                    <SelectField
                      data={dataIndustry}
                      defaultLabel={
                        dataOnboarding?.industry || "Select your industry"
                      }
                      visibleIcon
                      searchPlaceholder="Search industry"
                      formField="industry"
                      required
                      errors={errors}
                      control={control}
                      setValue={setValue}
                    />
                  </div>

                  <InputCustom
                    isRequired
                    type="number"
                    variant=""
                    setValue={setValue}
                    label="Representative phone number"
                    placeholder="Enter the representative's phone number"
                    id="reprensentative_phone_number"
                    name="reprensentative_phone_number"
                    value={watch("reprensentative_phone_number")}
                    extra=""
                    register={register}
                    errors={errors}
                    state={errors?.type ? "error" : " "}
                  />
                  <InputCustom
                    variant=""
                    setValue={setValue}
                    label="Referral code"
                    placeholder="Enter referral code"
                    id="referral_code"
                    name="referral_code"
                    value={watch("referral_code")}
                    extra=""
                    register={register}
                    errors={errors}
                    state={errors?.type ? "error" : " "}
                  />
                  <InputCustom
                    variant=""
                    setValue={setValue}
                    isRequired
                    label="Tax code"
                    placeholder="0123456789-001"
                    id="tax_code"
                    name="tax_code"
                    value={watch("tax_code")}
                    extra=""
                    register={register}
                    errors={errors}
                    icon={taxIcon}
                    state={errors?.type ? "error" : " "}
                  />
                </div>
              </div>
              <div className="flex justify-end items-center mt-8">
                <CustomButton
                  disabled={disable}
                  type="submit"
                  onClick={() => {
                    const url = `/onboarding?step=2`;
                    window.location.href = url;
                  }}
                  style={{
                    width: "182px",
                    background:
                      "linear-gradient(36.25deg, #2B3DB9 2.93%, #0CD6F7 102.74%)",
                  }}
                  className="px-8 py-3 "
                  title="Continue"
                />
              </div>
            </form>
          </div>

          {/* <div>
           
          </div> */}
        </div>
      </div>
      <div
        className="flex  h-full  flex-col items-center justify-center"
        style={{
          width: "38%",
          backgroundImage: "url(/bg-merchant.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <img src={sidepic} alt="side" />
      </div>
    </div>
  );
};

export default Merchant;
