import { LocalStorageService } from "../helpers";
import axiosClient2 from "./client_axios2";
import { SELL_REGISTER } from "./endpoint";

export const sellManagerServices = {
  getMerchant: () => {
    const query = new URLSearchParams({
      token: LocalStorageService.getAccessToken(),
    });
    return axiosClient2.get(`${SELL_REGISTER}/merchant?${query}`);
  },

  sellRegisterFree: (params) => {
    return axiosClient2.post(`${SELL_REGISTER}/subscription/register/free`, params);
  },

  sellRegisterCode: (params) => {
    return axiosClient2.post(`${SELL_REGISTER}/subscription/register/code`, params);
  },

  sellRegisterPaymentQr: (params) => {
    return axiosClient2.post(`${SELL_REGISTER}/payment/qr`, params);
  },

  updateMerchant: (params) => {
    return axiosClient2.put(`${SELL_REGISTER}/merchant`, params);
  },
};
