import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import {
  updateCustomer,
  getCustomerById,
  getListProvince,
  getListDistrict,
  getListWard,
} from "../../../actions/customer-manager.actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { selectListProvince } from "../../../reducers/customer-management.reducer";
import * as yup from "yup";

import CustomButton from "../../../components/buttons/CustomButton";
import InputField from "../../../components/fields/InputField";
import SectionTitle from "../../../components/section-title";
import TableWrapper from "../../company-profile/TableWrapper";
import SelectInPaymentField from "../../../components/fields/SelectInPaymentField";

const FORM = {
  CUSTOMER_ID: "customer_id",
  CUSTOMER_CODE: "code",
  NAME: "name",
  PHONE_NUMBER: "phone_number",
  POINT: "point",
  COUNTRY_CODE: "country_code",
  TAX_NO: "tax_no",
  PROVINCE_CODE: "province_code",
  DISTRICT_CODE: "district_code",
  WARD_CODE: "ward_code",
  ADDRESS: "address",
};

const schema = yup.object().shape({
  [FORM.PHONE_NUMBER]: yup
    .string()
    .required("Please enter phonr number")
    .matches(/\S/, "No space")
    // .matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, "Vui lòng nhập đúng số điện thoại"),
    .matches(
      /^(0[3-9][0-9]{8}|05[5|6|7|8|9][0-9]{7}|(84|\+84)[3-9][0-9]{8})$/,
      "Please enter correct phone number"
    ),
});

const UpdateCustomer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = new URLSearchParams(location.search)?.get("id");
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState(null);
  const [ward, setWard] = useState(null);
  const [defaultProvince, setDefaultProvince] = useState(null);
  const [defaultDistrict, setDefaultDistrict] = useState(null);
  const [defaultWard, setDefaultWard] = useState(null);

  const { data: dataProvince } = useSelector(selectListProvince);
  const listProvince = dataProvince?.map((item) => {
    return { label: item.full_name, value: item.code };
  });
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [resetDistrict, setResetDistrict] = useState(false);
  const [resetWard, setResetWard] = useState(false);
  const [docElem, setDocElem] = useState(null);
  document.onclick = (e) => setDocElem(e.target);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (value) => {
    // console.log('>>> payload: ', value)
    try {
      const res = await dispatch(
        updateCustomer({
          ...value,
          province_code: province || defaultProvince,
          district_code: district || defaultDistrict,
          ward_code: ward || defaultWard,
        })
      );
      if (res?.payload) {
        CustomToastMessage.success(
          "Thành công",
          `Bạn đã cập nhật thành công customer với mã ${value?.code}`
        );
        history.push("/sell-customer");
      } else {
        CustomToastMessage.error(
          "Thất bại",
          `Vui lòng cập nhật lại customer với mã ${value?.code}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled = !watch(FORM.NAME) || !watch(FORM.PHONE_NUMBER);

  const getCustomerInfo = async () => {
    const res = await dispatch(getCustomerById(id));
    if (res?.meta?.requestStatus == "fulfilled") {
      setValue(FORM.CUSTOMER_ID, res?.payload?.customer_id);
      setValue(FORM.CUSTOMER_CODE, res?.payload?.code);
      setValue(FORM.NAME, res?.payload?.name);
      setValue(FORM.PHONE_NUMBER, res?.payload?.phone_number);
      setValue(FORM.POINT, res?.payload?.point);
      setValue(FORM.ADDRESS, res?.payload?.address);
      setDefaultProvince(res?.payload?.province_code);
      setDefaultDistrict(res?.payload?.district_code);
      setDefaultWard(res?.payload?.ward_code);
      const resDis = await dispatch(
        getListDistrict(res?.payload?.province_code)
      );
      setListDistrict(
        resDis?.payload?.data?.map((item) => {
          return { label: item.full_name, value: item.code };
        })
      );
      const resWard = await dispatch(getListWard(res?.payload?.district_code));
      setListWard(
        resWard?.payload?.data?.map((item) => {
          return { label: item.full_name, value: item.code };
        })
      );
    }
  };

  useEffect(() => {
    getCustomerInfo();
    dispatch(getListProvince());
  }, []);

  useEffect(() => {
    (async () => {
      const res = await dispatch(getListDistrict(province));
      if (res?.payload?.data) {
        setResetDistrict(!resetDistrict);
        setResetWard(!resetWard);
        setListDistrict(
          res?.payload?.data?.map((item) => {
            return { label: item.full_name, value: item.code };
          })
        );
      }
    })();
  }, [province]);

  useEffect(() => {
    (async () => {
      const res = await dispatch(getListWard(district));
      if (res?.payload?.data) {
        setResetWard(!resetWard);
        setListWard(
          res?.payload?.data?.map((item) => {
            return { label: item.full_name, value: item.code };
          })
        );
      }
    })();
  }, [district]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="management customer"
        subtitle="Chỉnh sửa thông tin customer"
      />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
          <div className="flex flex-col gap-y-6">
            <InputField
              readOnly
              isRequired
              label="ID customer"
              placeholder="ID customer"
              id={FORM?.CUSTOMER_ID}
              name={FORM?.CUSTOMER_ID}
              value={
                typeof watch(FORM.CUSTOMER_ID) == "number"
                  ? watch(FORM?.CUSTOMER_ID)
                  : " "
              }
              extra="hidden"
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              readOnly
              isRequired
              label="Mã customer"
              placeholder="Mã customer"
              id={FORM?.CUSTOMER_CODE}
              name={FORM?.CUSTOMER_CODE}
              value={watch(FORM?.CUSTOMER_CODE) || " "}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              isRequired
              setValue={setValue}
              label="Họ tên"
              placeholder="Nhập mã customer"
              value={watch(FORM?.NAME) || " "}
              id={FORM?.NAME}
              name={FORM?.NAME}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              type="number"
              isRequired
              setValue={setValue}
              label="Số điện thoại"
              placeholder="Nhập số điện thoại"
              value={watch(FORM?.PHONE_NUMBER) || " "}
              id={FORM?.PHONE_NUMBER}
              name={FORM?.PHONE_NUMBER}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              readOnly
              isRequired
              label="Điểm thưởng"
              id={FORM?.POINT}
              name={FORM?.POINT}
              value={watch(FORM?.POINT) || "0"}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
              disabled
            />
          </div>
          <div style={{ gap: 8 }} className="flex flex-col">
            <p className="text-sm leading-5 font-semibold">Khu vực</p>
            <SelectInPaymentField
              placeHolder="Tỉnh/Thành phố"
              data={[].concat(listProvince)}
              value={province}
              setValue={setProvince}
              defaultValue={defaultProvince}
              docElem={docElem}
            />
            <SelectInPaymentField
              placeHolder="Quận/Huyện"
              data={[].concat(listDistrict)}
              value={district}
              setValue={setDistrict}
              resetFlag={resetDistrict}
              defaultValue={defaultDistrict}
              docElem={docElem}
            />
            <SelectInPaymentField
              placeHolder="Phường/Xã"
              data={[].concat(listWard)}
              value={ward}
              setValue={setWard}
              resetFlag={resetWard}
              defaultValue={defaultWard}
              docElem={docElem}
            />
            <InputField
              setValue={setValue}
              placeholder="Địa chỉ cụ thể"
              id={FORM.ADDRESS}
              name={FORM.ADDRESS}
              value={watch(FORM?.ADDRESS) || ""}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/sell-customer")}
              title="Quay lại"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Cập nhật"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default UpdateCustomer;
