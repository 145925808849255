import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createEmployee } from "../../../actions/employee/employee.actions";
import { getSettingTxById } from "../../../actions/setting-tx.actions";
import CustomButton from "../../../components/buttons/CustomButton";
import SelectBankHandleField from "../../../components/fields/SelectBankHandleField";
import SelectBankReleaseField from "../../../components/fields/SelectBankReleaseField";
import SelectCardField from "../../../components/fields/SelectCardField";
import SelectRuleField from "../../../components/fields/SelectRuleField";
import SectionTitle from "../../../components/section-title";
import { toastMessage } from "../../../utils/constants";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import { ERROR_CODE } from "../../../utils/errorCode";
import TableWrapper from "../../company-profile/TableWrapper";

const FORM = {
  RULE_NAME: "rule_name",
  CARD_TYPE: "card_type",
  ISSUER: "issuer",
  ACQUIRER: "acquirer",
};
const UpdateOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(createEmployee(value));
      console.log(res);
      if (res?.meta?.requestStatus === "fulfilled") {
        history.push("/user");
        toastMessage.success();
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        }
        CustomToastMessage.error("Add nhân viên thất bại", msg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(FORM.RULE_NAME) ||
    !watch(FORM.CARD_TYPE) ||
    !watch(FORM.ISSUER) ||
    !watch(FORM.ACQUIRER);

  useEffect(() => {
    dispatch(getSettingTxById());
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="Quản lý đơn hàng"
        subtitle="Chỉnh sửa thông tin đơn hàng"
      />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-6">
          <SelectRuleField
            isRequired
            register={register}
            name={FORM.RULE_NAME}
            setValue={setValue}
            errors={errors}
            state={errors?.type ? "error" : " "}
            // initValue
          />
          <SelectCardField
            isRequired
            register={register}
            name={FORM.CARD_TYPE}
            setValue={setValue}
            errors={errors}
            state={errors?.type ? "error" : " "}
            // initValue
          />
        </div>
        <SelectBankReleaseField
          isRequired
          register={register}
          name={FORM.ISSUER}
          setValue={setValue}
          errors={errors}
          state={errors?.type ? "error" : " "}
          // initValue
        />
        <SelectBankHandleField
          isRequired
          register={register}
          name={FORM.ACQUIRER}
          setValue={setValue}
          errors={errors}
          state={errors?.type ? "error" : " "}
          // initValue
        />

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/sell-order")}
              title="Quay lại"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Cập nhật"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default UpdateOrder;
