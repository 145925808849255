
import appClient from "../axios/app-client";
import { GET_LIST_EDC } from "../endpoint";

export const edcServices = {
  mapEDC: (params) => {
    return appClient.put("/map-edc-to-va", params);
  },

  getListEDC: (params) => {
    return appClient.get(`${GET_LIST_EDC}`, params);
  },
  // deleteEDCId: (params) => {
  //   return axiosClient.delete(, params);
  // },
};
