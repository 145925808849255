import React, { useEffect, useState } from "react";
import SectionTitle from "../../../components/section-title";
import Datatables from "../../../components/tsdatatable/datatables";
import { useDispatch, useSelector } from "react-redux";
import { select } from "@tailwindcss/custom-forms/src/defaultOptions";
import { selectListCategory } from "../../../reducers/category.reducer";
import { getListCategory } from "../../../actions/category.actions";

const DataTableComponent = ({ customData, filters, setFilters, numOfPage }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Catergory name",
        accessor: "category_name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    []
  );

  return (
    <Datatables
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const TableCategory = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectListCategory);

  const [numOfPage, setNumOfPage] = useState("1");
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  useEffect(() => {
    dispatch(getListCategory(filters));
  }, [dispatch, filters]);

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title="Category Quản lý" subtitle="Category list" />
      </div>
      {data.categories && (
        <div className="mt-6">
          <DataTableComponent
            customData={data.categories}
            filters={filters}
            numOfPage={numOfPage}
            // data={data}
            setFilters={setFilters}
          />
        </div>
      )}
    </section>
  );
};

export default TableCategory;
