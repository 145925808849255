import React from "react";

import mail from "../../assets/images/mail.svg";
import iphone from "../../assets/images/iphone.svg";
import telephone from "../../assets/images/telephone.svg";

const Auth = ({ children }) => {
  const listHelp = [
    {
      icon: iphone,
      title: "Hướng dẫn sử dụng",
      link: "",
    },
    {
      icon: telephone,
      title: "Điện thoại: 1900 3353",
      link: "",
    },
    {
      icon: mail,
      title: "Email:  support@pos25.app",
      link: "",
    },
  ];

  return (
    <div
      className="w-screen h-screen flex flex-col items-center justify-between"
      style={{
        backgroundImage: "url(/bg-login.jpeg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div style={{ flex: 1 }} className="w-full grid grid-cols-2 ">
        {children}
      </div>
    </div>
  );
};

export default Auth;
