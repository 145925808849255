import React from "react";
import CustomButton from "../../components/buttons/CustomButton";
import { themes } from "../../configTheme";

const SettingTheme = () => {
  const setTheme = (theme) => {
    localStorage.setItem("theme", theme);
    window.location.reload();
  };

  const listThemes = Object.keys(themes)

  return (
    <div className="flex flex-col gap-10 items-center">
      {listThemes.map((item, index) =>
        <div className="w-full" key={index}>
          <CustomButton
            outline className='w-full'
            leftIcon={<img width={24} src={`/images/${item}/favIcon.png`} alt="" />}
            title={`Sử dụng ${item.toUpperCase()}`}
            onClick={() => setTheme(item)}
          />
        </div>
      )}
    </div>
  );
};

export default SettingTheme;
