import React, { useEffect, useState } from "react";
// import ic_calendar from '../../assets/images/txcard/ic-calendar.svg'
import ic_search_black from "../../assets/images/txcard/ic-search-black.svg";
import "./fillter.css";
import { useFormContext } from "react-hook-form";

export const Datetime = ({ label, field, field2 }) => {
  const { register } = useFormContext();
  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  var firstDay = new Date(y, m, 2);
  var lastDay = new Date(y, m + 1, 0);

  // console.log('>>>> check firstday', firstDay.toISOString().split('T')[0])
  return (
    <div className="flex flex-col space-y-1 text-sm justify-center items-start">
      <p
        style={{
          color: "#434C76",
          fontWeight: 600,
        }}
        className=" font-sem"
      >
        {label}
      </p>
      <div
        style={{
          height: 44,
        }}
        className="py-3 px-4 border rounded-lg flex gap-4 items-center "
      >
        <input
          type="date"
          defaultValue={firstDay.toISOString().split("T")[0]}
          name={field}
          onChange={(e) => console.log(e.target.value)}
          ref={register}
        />
        -
        <input type="date" defaultValue={lastDay.toISOString().split("T")[0]} name={field2} ref={register} />
      </div>
    </div>
  );
};

export const Inputfield = ({ label, placeholder, field, styleinput, wraperstyle, required, val }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className={`${wraperstyle} flex gap-4 text-sm  items-center`}>
        <p className=" text-grey-atom-grey flex w-32">
          {label}
          {required && <span className=" text-primary pl-1"> *</span>}
        </p>
        <div className={`${styleinput} py-3 px-4 border flex gap-4 items-center rounded-md`}>
          <input
            className="w-full"
            name={field}
            ref={
              required
                ? register({
                    required: "Thông tin bắt buộc. Vui lòng nhập đầy đủ.",
                  })
                : register
            }
            placeholder={placeholder}
            defaultValue={val ? val : ""}
          />
        </div>
      </div>
      {required && (
        <div className=" leading-none">
          {errors[field] ? (
            <>
              {errors[field].type === "required" && <p className="text-primary">{errors[field].message}</p>}
              {errors[field].type === "pattern" && <p className="text-primary">{errors[field].message}</p>}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
export const InputNumber = ({ label, placeholder, field, styleinput, wraperstyle, required }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className={`${wraperstyle} flex space-y-1 text-sm items-center`}>
        <p className=" text-grey-atom-grey flex">
          {label}
          {required && <span className=" text-primary pl-1"> *</span>}
        </p>
        <div className={`${styleinput} py-3 px-4 border flex gap-4 items-center rounded-md`}>
          <input
            ref={
              required
                ? register({
                    required: "Thông tin bắt buộc. Vui lòng nhập đầy đủ.",
                  })
                : register
            }
            className="w-full"
            name={field}
            placeholder={placeholder}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>
      {required && (
        <div className=" leading-none">
          {errors[field] ? (
            <>
              {errors[field].type === "required" && <p className="text-primary">{errors[field].message}</p>}
              {errors[field].type === "pattern" && <p className="text-primary">{errors[field].message}</p>}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export const Optionfield = ({
  label,
  option,
  type,
  field,
  placeholder,
  styleoption,
  wraperstyle,
  required,
  dis,
  onchangeCity,
  hasSearch = false,
  disabled = false,
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const onChangeHandler = (e) => {
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[index];
    // const option = el.getAttribute("id");
    // onchangeCity && onchangeCity(option);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
        className={`flex flex-col space-y-1 text-sm items-start ${disabled && "pointer-events-none opacity-50"}`}
      >
        <p
          style={{
            color: "#434C76",
            fontWeight: 600,
          }}
          className="  flex"
        >
          {label}
          {required && <span className=" text-primary pl-1"> *</span>}
        </p>
        <div style={{ height: 44 }} className={`border py-3 px-4 rounded-lg w-full`}>
          {/* {hasSearch &&
            <>
              This is search
            </>
          } */}
          <select
            className={dis ? "w-full remove_ic_select" : "w-full"}
            name={field}
            ref={register({ required: "Thông tin bắt buộc. Vui lòng chọn." })}
            // onChange={onChangeHandler}
          >
            {type === "json" ? (
              <>
                <option value="" selected disabled hidden>
                  {placeholder}
                </option>
                {Object.keys(option).map((opt, i) => (
                  <option key={i} id={option[opt].code} value={option[opt].name}>
                    {option[opt].name}
                  </option>
                ))}
              </>
            ) : (
              option.map((opt, i) => (
                <option key={i} value={opt.value}>
                  {opt.label}
                </option>
              ))
            )}
          </select>
        </div>
      </div>
      {required && (
        <div className=" leading-none">
          {errors[field] ? (
            <>{errors[field].type === "required" && <p className="text-primary">{errors[field].message}</p>}</>
          ) : null}
        </div>
      )}
    </>
  );
};

export const OptionDistricfield = ({ label, option, field, placeholder, styleoption, wraperstyle, required, city }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className={`${wraperstyle} flex gap-4 text-sm items-center`}>
        <p className=" text-grey-atom-grey flex">
          {label}
          {required && <span className=" text-primary pl-1"> *</span>}
        </p>
        <div className={`${styleoption} border py-3 px-4 rounded-md`}>
          <select className="w-full " name={field} ref={register({ required: "Thông tin bắt buộc. Vui lòng chọn." })}>
            <>
              <option value="" selected disabled hidden>
                {placeholder}
              </option>
              {Object.keys(option).map(
                (opt, i) =>
                  option[opt].parent_code === city && (
                    <option key={i} value={option[opt].name}>
                      {option[opt].name_with_type}
                    </option>
                  )
              )}
            </>
          </select>
        </div>
      </div>
      {required && (
        <div className=" leading-none">
          {errors[field] ? (
            <>{errors[field].type === "required" && <p className="text-primary">{errors[field].message}</p>}</>
          ) : null}
        </div>
      )}
    </>
  );
};
export const Searchfield = ({ label, option, inputwidth, fieldwidth, field }) => {
  const { register, watch } = useFormContext();
  const [inputField, setInputField] = useState(" ");
  const handleOnchange = () => {
    setInputField(" ");
  };

  return (
    <>
      <div className={"w-auto flex gap-1 flex-col text-sm items-start"}>
        <div className="text-grey-atom-grey">
          <p
            style={{
              color: "#434C76",
              fontWeight: 600,
            }}
            className="max-w-max"
          >
            {label}
          </p>
        </div>
        <div
          style={{ height: 44 }}
          className={inputwidth + "w-auto py-3 px-4 border flex gap-4 items-center rounded-lg"}
        >
          {/* <img src={ic_search_black} /> */}
          <input
            className={inputwidth + " w-full placeholder-gray-500"}
            placeholder="Nhập mã hoá đơn"
            name={field}
            ref={register}
            onChange={(e) => setInputField(e.target.value)}
            // defaultValue={inputField}
            value={watch(field)}
          />
        </div>
      </div>
    </>
  );
};

export const InputEmailfield = ({ label, placeholder, field, styleinput, wraperstyle, required }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className={`${wraperstyle} flex gap-4 text-sm  items-center`}>
        <p className=" text-grey-atom-grey flex">
          {label}
          {required && <span className=" text-primary pl-1"> *</span>}
        </p>
        <div className={`${styleinput} py-3 px-4 border flex gap-4 items-center rounded-md`}>
          <input
            className="w-full"
            name="email"
            ref={
              required
                ? register({
                    required: "Thông tin bắt buộc. Vui lòng nhập đầy đủ.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email sai định dạng. Vui lòng nhập lại.",
                    },
                  })
                : register
            }
            placeholder={placeholder}
          />
        </div>
      </div>
      {required && (
        <div className=" leading-none">
          {errors.email ? (
            <>
              {errors.email.type === "required" && <p className="text-primary">{errors.email.message}</p>}
              {errors.email.type === "pattern" && <p className="text-primary">{errors.email.message}</p>}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
export const InputPhoneNumber = ({ label, placeholder, field, styleinput, wraperstyle, required }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className={`${wraperstyle} flex gap-4 text-sm items-center`}>
        <p className=" text-grey-atom-grey flex">
          {label}
          {required && <span className=" text-primary pl-1"> *</span>}
        </p>
        <div className={`${styleinput} py-3 px-4 border flex gap-4 items-center rounded-md`}>
          <input
            ref={
              required
                ? register({
                    required: "Thông tin bắt buộc. Vui lòng nhập đầy đủ.",
                    pattern: {
                      value: /(((\+|)84)|0|02)(3|5|7|8|9)+([0-9]{8})\b/,
                      message: "Số điện thoại sai định dạng. Vui lòng nhập lại.",
                    },
                  })
                : register
            }
            className="w-full"
            name={field}
            placeholder={placeholder}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>
      {required && (
        <div className=" leading-none">
          {errors[field] ? (
            <>
              {errors[field].type === "required" && <p className="text-primary">{errors[field].message}</p>}
              {errors[field].type === "pattern" && <p className="text-primary">{errors[field].message}</p>}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
