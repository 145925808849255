import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";

import CustomButton from "../../../components/buttons/CustomButton";
import InputField from "../../../components/fields/InputField";

import diamond from "../../../assets/images/sell-setting/diamond.gif";

const FORM = { POINT_PERCENT: "point_percent", };

const Reward = ({ merchant, register, handleSubmit, setValue, errors, watch }) => {
  const { isMd } = useWindowSize()
  return (
    <div
      style={{ boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.15)" }}
      className="bg-white rounded-xl p-12 flex flex-col items-center text-blackgray"
    >
      <img src={diamond} alt="diamond" width={178} />
      <p className="text-sm leading-5 font-semibold">
        Tỉ lệ chiết khấu sản phẩm<span className="text-error">*</span>
      </p>
      <div style={{ width: isMd ? 400 : '100%' }}>
        <InputField
          type="number"
          setValue={setValue}
          placeholder="Tỉ lệ %/ tổng giá trị hoá đơn"
          id={FORM.POINT_PERCENT}
          name={FORM.POINT_PERCENT}
          value={watch(FORM.POINT_PERCENT)}
          defaultValue={merchant[FORM.POINT_PERCENT]}
          extra="mt-3 w-full "
          register={register}
          errors={errors}
          state={errors?.type ? "error" : " "}
          inputStyle="text-center"
        />
      </div>

      <p className="text-sm leading-5 font-normal mt-2">(1 điểm = 1 VNĐ)</p>

      <CustomButton type="submit" title='Lưu' className='w-full mt-6' />
    </div>
  );
};

export default Reward;
