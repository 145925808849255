import React, { useEffect } from "react";
import { theme } from "../../configTheme";
import smileIcon from "../../assets/images/onboarding/smileIcon.png";
import confetti from "../../assets/images/onboarding/Confetti.png";
const Finish = () => {
  setTimeout(function () {
    var url = "/";

    window.location.href = url;
  }, 30000);

  useEffect(() => {
    localStorage.setItem("is_new", Number(JSON.stringify(0)));
    localStorage.removeItem("dataOnboarding");
    localStorage.removeItem("crypto");
    localStorage.removeItem("fiat");
    localStorage.removeItem("logo");
  }, []);
  return (
    <div
      className="w-screen h-screen py-10 flex flex-col items-center  "
      style={{
        backgroundImage: "url(/bg-onboarding.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{ flex: 1 }}
        className="flex flex-col items-center mt-12 gap-8"
      >
        <div>
          <img src={theme.loginLogo} alt="logo" className="h-16 w-auto" />
        </div>
        <div
          style={{
            boxShadow: "0px 4px 24px rgba(20, 42, 74, 0.12)",
            paddingTop: "100px",
            paddingBottom: "100px",
            paddingLeft: "40px",
            paddingRight: "40px",
            background: "#FFFFFF66",
          }}
          className="flex flex-col rounded-xl overflow-hidden items-center justify-center gap-4 relative "
        >
          <div style={{ position: "absolute", top: "40px" }}>
            <img src={confetti} alt="confetti" objectFit="cover" />
          </div>
          <div className="h-20 w-20 flex items-center justify-center">
            <img src={smileIcon} alt="icon smile" objectFit="cover" />
          </div>
          <div className="mt-6 w-full text-4xl font-semibold  ">
            <span style={{ color: "#2B4896" }}> Congratulations!</span>

            <span className="text-text-primary"> You're all finished.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finish;
