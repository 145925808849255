// import { INIT_MINUTES, INIT_SECONDS, toastMessage } from "lib/constants";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyBank } from "../../../actions/bank/bank.actions";
import { selectBankAccount } from "../../../reducers/bank/bank.reducer";
import {
  selectIsOtpTextFail,
  setIsTextOtpFail,
} from "../../../reducers/bank/verifyBankOtp.reducer";
import { INIT_MINUTES, INIT_SECONDS } from "../../../utils/constants";
import Timer from "./Timer";

const BtnSend = ({ setIsTimeout }) => {
  const dispatch = useDispatch();
  const bankAccount = useSelector(selectBankAccount);

  const [minutes, setMinutes] = useState(INIT_MINUTES);
  const [seconds, setSeconds] = useState(INIT_SECONDS);
  const isTextFail = useSelector(selectIsOtpTextFail);
  const disabled = !isTextFail && (minutes !== 0 || seconds !== 0);

  const handleClick = async () => {
    dispatch(setIsTextOtpFail(false));
    setIsTimeout(false);

    try {
      const res = await dispatch(verifyBank(bankAccount));
      if (res?.meta?.requestStatus === "fulfilled") {
        setMinutes(INIT_MINUTES);
        setSeconds(INIT_SECONDS);
      } else if (res?.meta?.requestStatus === "rejected") {
        dispatch(setIsTextOtpFail(true));
        setIsTimeout(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        onClick={handleClick}
        className={`${
          disabled
            ? "cursor-default text-grey-10"
            : "cursor-pointer font-medium text-indigo-A800 hover:opacity-80"
        } `}
      >
        Resend OTP
      </button>
      {!isTextFail && (
        <Timer
          minutes={minutes}
          seconds={seconds}
          setMinutes={setMinutes}
          setSeconds={setSeconds}
          setIsTimeout={setIsTimeout}
        />
      )}
    </>
  );
};

export default React.memo(BtnSend);
