/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useForm } from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { sellRegisterCode } from "../../../../actions";
import CustomButton from "../../../../components/buttons/CustomButton";
import InputField from "../../../../components/fields/InputField";
import ModalWrapper from "../../../../components/modals/ModalWrapper";
import { selectMerchant } from "../../../../reducers/sass.reducer";
import { CustomToastMessage } from "../../../../utils/customToastMsg";

const ModalEnterCode = ({ handleNextScreen, setShowModal }) => {
  const CODE = "code";
  const dispatch = useDispatch();
  const merchant = useSelector(selectMerchant);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async () => {
    const raw = {
      code: watch(CODE),
      entity_id: merchant?.id,
      type_id: 2,
    };

    try {
      const res = await dispatch(sellRegisterCode(raw));

      if (res?.meta?.requestStatus === "fulfilled") {
        handleNextScreen();
      } else CustomToastMessage.error("thất bại", res?.payload?.message);
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);
  const disabled = !watch(CODE);

  return (
    <ModalWrapper className="px-6 rounded-xl">
      <IoCloseSharp
        className="absolute cursor-pointer hover:opacity-75"
        style={{ right: 16, top: 16 }}
        onClick={() => {
          setShowModal(false);
        }}
        size={20}
      />
      <p className="text-black text-base font-semibold leading-6 mb-6">Điền mã</p>

      <form style={{ width: 374 }} className="" onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <InputField
          variant=""
          isRequired
          setValue={setValue}
          placeholder="Nhập mã mua trọn gói hoặc mã giới thiệu"
          id={CODE}
          name={CODE}
          value={watch(CODE)}
          extra="w-full mb-6"
          register={register}
          errors={errors}
          state={errors?.type ? "error" : " "}
        />

        <CustomButton disabled={disabled} title={"Áp dụng"} type="submit" className="w-full" />
      </form>
    </ModalWrapper>
  );
};

export default ModalEnterCode;
