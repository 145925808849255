import { QRCodeCanvas } from "qrcode.react";
import React from 'react';
import close from '../../../../assets/images/product/close.svg';
import CustomButton from "../../../../components/buttons/CustomButton";

const PopupQR = ({setShowPopupQR,item}) => {
    // const svgRef = useRef();
    // function downloadBlob(blob, filename) {
    //     const objectUrl = URL.createObjectURL(blob);
     
    //     const link = document.createElement("a");
    //     link.href = objectUrl;
    //     link.download = filename;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
     
    //     setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
    //  }
     
    //  const downloadSVG = useCallback(() => {
    //     const content = svgRef.current.children[0].innerHTML;
    //     const contentWithSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="128" width="128" viewBox="0 0 29 29">
    //  ${content}
    //  </svg>`;
    //     const blob = new Blob([contentWithSvg], { type: "image/svg+xml" });
    //     downloadBlob(blob, `qrcode.svg`);
    //  }, []);

    const downloadQRCode = () => {
        const qrCodeURL = document.getElementById('qrCodeEl')
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        console.log(qrCodeURL)
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QR_Code.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }
    return(
        <div 
            className='w-full h-full flex flex-col justify-center items-center fixed inset-0'
            style={{backgroundColor:'rgba(0,0,0,0.70)', zIndex: '60'}}  
        >
            <div className = 'h-fit rounded-3xl relative bg-white flex flex-col gap-8 items-center p-6' style={{width:'414px', zIndex:'60'}}>
                <img 
                src={close} alt='' 
                className='absolute cursor-pointer' 
                style={{top:'16px',right:'16px'}}
                onClick={() => setShowPopupQR(false)}
                />
                <p className='text-black text-base font-semibold leading-5'>Qr code - Bàn {item.table_name}</p>
                <div className="bg-white rounded-xl flex items-center justify-center" style={{width:'200px', height:'200px'}}> 
                    <QRCodeCanvas 
                        id="qrCodeEl"
                        value={`${process.env.REACT_APP_E_MENU_ENPOINT || 'https://menu.wesap.vn'}/order/${item?.table_id}`} 
                        size={220} 
                    />
                </div>
                <CustomButton
                    title={'Lưu hình ảnh'}
                    onClick={downloadQRCode}
                    className={'w-full'}
                />
            </div>
        </div>
    )}

export default PopupQR

  