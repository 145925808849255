import React from "react";

const Title = ({ title = "", subTitle = "" }) => {
  return (
    <>
      <p className="text-blackgray text-2xl font-semibold leading-8">{title}</p>
      <p className="text-grey-6 text-base font-medium leading-6 mt-3">{subTitle}</p>
    </>
  );
};

export default Title;
