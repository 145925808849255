import React, { useEffect, useState } from "react";
import { getTable } from "../../../../services/inventory-manager.service";
import { Popup, Search } from "../Common";
import CreateTable from "./CreateTable";
import { TableItem } from "./TableItem";
import PopupUpdate from "./PopupUpdate";
import PopupCreateTable from "./PopupCreateTable";
import PopupQR from "./PopupQR";
import SectionTitle from "../../../../components/section-title";
import success from "../../../../assets/images/product/success.gif";

const ListTable = () => {
  const [tables, setTables] = useState([]);
  const [inputSearch, setInputSearch] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [showPopupQR, setShowPopupQR] = useState();
  const [tableUpdate, setTableUpdate] = useState();
  const [resultSearch, setResultSearch] = useState([]);

  const fetchListTable = async () => {
    const res = await getTable();
    setTables(res);
    handleSearch(res);
  };

  const handleSearch = (data, inputSearch) => {
    if (inputSearch) {
      const result = data.filter(
        (item) =>
          item.table_name.toUpperCase().search(inputSearch.toUpperCase()) !== -1
      );
      setResultSearch(result);
    } else {
      setResultSearch(data);
    }
  };

  useEffect(() => {
    if (tables && tables.length > 0) {
      handleSearch(tables, inputSearch);
    }
  }, [inputSearch]);

  useEffect(() => {
    setTimeout(() => {
      setShowPopupSuccess(false);
    }, 1000);
  });

  useEffect(() => {
    fetchListTable();
  }, []);

  return (
    <div>
      <SectionTitle title="Sales Manager" subtitle="List bàn" />
      <div className="flex flex-col gap-10 relative mt-4">
        <Search
          content={"Tìm kiếm (nhập tên bàn)"}
          setInputSearch={setInputSearch}
        />
        <div className="">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
            {resultSearch.map((item, index) => (
              <TableItem
                item={item}
                key={index}
                setTableUpdate={setTableUpdate}
                setShowPopupQR={setShowPopupQR}
              />
            ))}
            <CreateTable setShowPopup={setShowPopup} />
          </div>
        </div>
      </div>
      {showPopup && (
        <PopupCreateTable
          setShowPopup={setShowPopup}
          setShowPopupSuccess={setShowPopupSuccess}
        />
      )}
      {showPopupSuccess && (
        <Popup
          icon={success}
          content={"Tạo bàn Success"}
          setShowPopup={setShowPopupSuccess}
          handleClickBtn={() => setShowPopupSuccess(false)}
        />
      )}
      {tableUpdate && (
        <PopupUpdate
          fetchListTable={fetchListTable}
          setTableUpdate={setTableUpdate}
          item={tableUpdate}
        />
      )}
      {showPopupQR && (
        <PopupQR setShowPopupQR={setShowPopupQR} item={showPopupQR} />
      )}
    </div>
  );
};

export default ListTable;
