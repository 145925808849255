import moment from "moment";
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { usePagination, useSortBy, useTable } from "react-table";
import { useReactToPrint } from "react-to-print";
import "tippy.js/dist/tippy.css";
import Sacombank from "../../assets/images/ic-sacombank.svg";
import IcTrash from "../../assets/images/payment/trash.svg";
import icExport from "../../assets/images/txcard/ic-export.svg";
import { formatDollarsCurrency } from "../../utils/lib";
import CustomButton from "../buttons/CustomButton";
import Widget from "../widget";
import { ComponentToPrint } from "./ComponentToPrint";
import TablePagination from "./pagination";

import print from "../../assets/images/payment/print.svg";
import ic_eye from "../../assets/images/payment/eye.svg";
import upload from "../../assets/images/payment/upload.svg";

import { NFT_LIST_ITEMS } from "../../services/endpoint";

export const RenderStatus = ({ title, color }) => {
  const { bg, text } = color;
  return (
    <div
      style={{ background: bg, width: 140 }}
      className="rounded-full w-full py-1 px-3 flex justify-between gap-2 items-center capitalize"
    >
      <span
        className="w-2 h-2 rounded-full"
        style={{ background: text }}
      ></span>
      <p style={{ color: text, flex: 1 }} className="text-center">
        {title}
      </p>
    </div>
  );
};

export const StatusCell = ({ data, color, title }) => {
  const colors = {
    green: { bg: "#2EB5531A", text: "#2EB553" },
    red: { bg: "#ED1C241A", text: "#F5222D" },
    yellow: { bg: "#EDA61C1A", text: "#FA8C16" },
    blue: { bg: "#0598ED1F", text: "#0598ED" },
    gray: { bg: "#606F861C", text: "#002766" },
  };

  if (data && data.values.response_code) {
    if (data.values.response_code === "00") {
      return <RenderStatus color={colors["green"]} title="Success" />;
    }
    return <RenderStatus color={colors["red"]} title="Fail" />;
  } else if (data && data.values.status) {
    if (
      data.values.status === "pending" ||
      data.values.status === "WAITING_FOR_PAID"
    ) {
      return (
        <RenderStatus color={colors["yellow"]} title={data.values.status} />
      );
    } else if (
      data.values.status === "success" ||
      data.values.status === "PAID"
    ) {
      return (
        <RenderStatus color={colors["green"]} title={data.values.status} />
      );
    }
    return <RenderStatus color={colors["red"]} title="Error" />;
  } else return <RenderStatus color={colors[color]} title={title} />;
};

export const CellBank = ({ title, children, className }) => {
  return (
    <button className={`relative tippy-bank-cover cursor-pointer ${className}`}>
      <div
        style={{
          bottom: "calc(100% + 5px)",
          left: "50%",
          transform: "translateX(-50%)",
          color: "#434C76",
        }}
        className="tippy-bank z-50 absolute  items-center rounded-xl leading-5 border py-2 px-3 bg-white text-sm "
      >
        {title}
      </div>
      {children}
    </button>
  );
};

// VAM BANK CELL
export const VamBankCell = ({ data, onDeleteAction }) => {
  const bank = data?.values;

  const onDelete = useCallback(() => {
    onDeleteAction && onDeleteAction(bank);
  }, [bank, onDeleteAction]);

  return (
    <button onClick={onDelete}>
      <img src={IcTrash} alt="trash" />
    </button>
  );
};

export const ReceiptCell = ({ data }) => {
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const handleAfterPrint = useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    setLoading(true);
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        resolve();
      }, 2000);
    });
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    //onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (image && typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [image]);

  return (
    <div className="flex items-center justify-center space-x-4">
      <img src={ic_eye} alt="eyes" />
      <figure
        onClick={handlePrint}
        className="flex items-center space-x-1 cursor-pointer"
      >
        <img src={upload} alt="print" />
        <span style={{ color: "#8991A3" }}>Print</span>
      </figure>

      <div className="hidden">
        <ComponentToPrint ref={componentRef} image={data?.original?.bill_url} />
      </div>
    </div>
  );
};
export const Organization = ({ data }) => {
  return (
    <div>
      <span className="flex gap-3 justify-center border-b border-bluetext fit">
        <img src={Sacombank} alt="Sacombank" />
        <p>{data.original.Organization}</p>
      </span>
    </div>
  );
};

export const TransactionTypeCell = ({ data }) => {
  // var label = "N/A";
  // if (data.original.transaction_type === "sale") {
  //   if (data.original.response_code !== "00") {
  //     label = "Lỗi";
  //   } else {
  //     label = data.original.is_void === 0 ? "Sale" : "Void";
  //   }
  // }
  return (
    <div>
      <span className="border-b border-bluetext fit capitalize">
        {data.original.transaction_type}
      </span>
    </div>
  );
};

export const ConvertDateTime = ({ data }) => {
  return moment(new Date(data?.values?.trans_date_time * 1000)).format(
    "HH:mm:ss DD-MM-YYYY"
  );
};

export const ConvertStatusBank = ({ data }) => {
  if (data?.values?.status_tranx === "pending") {
    return "Pending";
  } else if (data?.values?.status_tranx === "accept") {
    return "Accept";
  } else {
    return "Reject";
  }
};

export const ConvertPeriod = ({ data }) => {
  return `${data?.values?.period} month`;
};

function Datatable(props) {
  const {
    columns,
    data,
    setFilters,
    filters,
    styleTable,
    pagination,
    total_amount,
    hasPagination = true,
    textLeft = true,
    className,
    styleWrapperTable,
  } = props;
  const isEmpty = !data.length;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      manualPagination: true,
      autoResetPage: false,
      pageCount: pagination.totalPages,
    },
    useSortBy,
    usePagination
  );

  const handlePaginationChange = useCallback(() => {
    // onPaginationChange({ page: pageIndex + 1, pageSize });
    // console.log('>>> page index has changed: ', pageIndex )
    if (pageIndex + 1 !== filters.page) {
      setFilters((current) => setFilters({ ...current, page: pageIndex + 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);
  // console.log('>> filters: ', filters)

  useEffect(() => {
    handlePaginationChange();
  }, [handlePaginationChange, pageIndex]);

  const dispatch = useDispatch();
  const typeTxn = useLocation().pathname.split("-")[1];

  const handleExport = async (filters) => {
    // const res = await dispatch(exportTxnCSV({ ...filters, type_txn: typeTxn }))
    // if (res?.payload) {
    //   // console.log('>>> res export: ', res.payload.split(','))
    //   const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(res?.payload)}`;
    //   const link = document.createElement('a');
    //   link.href = csvContent;
    //   link.download = `report-${typeTxn}.csv`;
    //   link.click();
    // }
    const url = new URL(
      process.env.REACT_APP_API_ENDPOINT + NFT_LIST_ITEMS + "/" + typeTxn
    );
    Object.keys(filters).forEach((key) =>
      url.searchParams.append(key, filters[key])
    );
    url.searchParams.append("export", "True");
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const file = URL.createObjectURL(res);

        const link = document.createElement("a");
        link.href = file;
        // link.download = `report-${typeTxn}.${res.type.split("/")[1]}`;
        link.download = `report-${typeTxn == "momo" ? "wallet" : typeTxn}.xlsx`;
        link.click();
      });
  };

  // Render the UI for your table
  return (
    <>
      <Widget>
        <div className="overflow-x-auto w-full">
          <div className={className} style={styleWrapperTable}>
            <table
              style={{ ...styleTable, color: "#313131" }}
              {...getTableProps()}
              className="table font-montserrat"
            >
              <thead
                className="sticky top-0 bg-white"
                style={{
                  boxShadow: "0 2px 2px -2px rgba(0,0,0,0.08)",
                  color: "#313131",
                }}
              >
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      var isColCenter =
                        // column.Header == "Mã sản phẩm" ||
                        column.Header == "Trạng thái" ||
                        column.Header == "ID" ||
                        column.Header == "Hình"
                          ? false
                          : textLeft;
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div
                            style={{
                              color: "#313131",
                              fontSize: 14,
                              fontWeight: 600,
                              pointerEvents: "none",
                              height: 20,
                            }}
                            className={`flex flex-row items-center ${
                              isColCenter ? "justify-start" : "justify-center"
                            } whitespace-no-wrap`}
                          >
                            <span className="text-left normal-case">
                              {column.render("Header")}
                            </span>
                            {/* Add a sort direction indicator */}
                            {/* <span className='ml-auto'>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FiChevronDown className='stroke-current text-2xs' />
                          ) : (
                            <FiChevronUp className='stroke-current text-2xs' />
                          )
                        ) : (
                          ""
                        )}
                      </span> */}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              {
                <tbody {...getTableBodyProps()}>
                  {data.length > 0 &&
                    page.map((row, i) => {
                      prepareRow(row);
                      const isDisabledRow = false;
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={`${isDisabledRow && "opacity-50"}`}
                        >
                          {row.cells.map((cell) => {
                            var isColCenter =
                              // cell.column.Header == "Mã sản phẩm" ||
                              cell.column.Header == "Trạng thái" ||
                              cell.column.Header == "ID" ||
                              cell.column.Header == "Hình"
                                ? false
                                : textLeft;
                            var isName =
                              cell.column.Header == "Tên sản phẩm"
                                ? true
                                : false;

                            return (
                              <td
                                style={{
                                  color: "#313131",
                                  fontSize: 14,
                                  fontWeight: 500,
                                  width: isName ? "250px" : "",
                                  whiteSpace: isName ? "wrap" : "",
                                }}
                                // className="h-20"
                                {...cell.getCellProps()}
                              >
                                <div
                                  className={`flex items-center ${
                                    isColCenter
                                      ? "justify-start"
                                      : "justify-center"
                                  }`}
                                >
                                  {/* {cell.column.Header == 'Số tiền'
                                    ? formatCurrency(cell.value)
                                    // : cell.column.Header == 'Số thẻ' || cell.column.Header == 'Số tài khoản' || cell.column.Header == 'Tài khoản'
                                    //   ? formatBankAccount(cell.value)
                                    : 
                                    // : cell.render("Cell")
                                } */}
                                  {cell.column.Header == "Hình" ? (
                                    <img
                                      src={cell.value}
                                      alt="product image"
                                      className="w-10 h-10 rounded"
                                    />
                                  ) : cell.column.Header == "STT" ? (
                                    pageIndex * filters.page_size + cell.value
                                  ) : (
                                    <div
                                      style={{
                                        fontWeight: `${
                                          cell.column.Header === "STT"
                                            ? "500"
                                            : "400"
                                        }`,
                                      }}
                                      className="text-left "
                                    >
                                      {cell.render("Cell")}
                                    </div>
                                  )}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              }
            </table>
            {isEmpty && (
              <p className="h-20 w-full flex justify-center items-center">
                No data
              </p>
            )}
          </div>
        </div>

        {!isEmpty && total_amount > 0 && (
          <div className="p-5 flex flex-col-reverse gap-3 md:gap-0 md:flex-row justify-end md:items-center">
            {/* <CustomButton
              onClick={() => handleExport(filters)}
              title="Export"
              leftIcon={<img src={icExport} alt="icExport" width={16} />}
            /> */}
            <div className="text-primary text-lg leading-7">
              <span className="font-semibold">Total:</span>
              <span className="ml-4 font-bold">
                {formatDollarsCurrency(total_amount)}
              </span>
            </div>
          </div>
        )}
      </Widget>
      {hasPagination && !isEmpty && (
        <div
          style={{ margin: "40px 0", boxShadow: "0px 8px 11px -3px #00000014" }}
          className={`flex flex-row flex-wrap items-start gap-4 md:gap-0 md:items-center ${
            isEmpty ? "justify-center" : "justify-between"
          } px-3 py-4 md:p-5 bg-white rounded-lg`}
        >
          <TablePagination
            pagination={pagination}
            setFilters={setFilters}
            pageCount={pageCount}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            // pageIndex={pageIndex}
            pageIndex={filters.page - 1}
            gotoPage={gotoPage}
          />

          <div className="flex items-center gap-2">
            <p className="text-sm font-medium text-input whitespace-no-wrap">
              Rows
            </p>
            <select
              className="form-select text-input text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
              value={pagination?.pageSize}
              style={{
                paddingRight: 25,
                fontWeight: "500",
              }}
              onChange={(e) => {
                setFilters((current) =>
                  setFilters({
                    ...current,
                    page: 1,
                    page_size: Number(e.target.value),
                  })
                );
                gotoPage(0);
              }}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
}
Datatable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  // onPaginationChange: PropTypes.func.isRequired,
  headerClass: PropTypes.string,
  loading: PropTypes.bool,
};
export default memo(Datatable);
