import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../helpers/history.js";
import { formatCurrency } from "../../../utils/lib.js";
import { selectListOrder } from "../../../reducers/order-management.reducer.js";
import { useReactToPrint } from "react-to-print";
import { createOrder } from "../../../services/inventory-manager.service.js";
import moment from "moment";
import useSearchParams from "../customer-manager/useSearchParams.js";

import SectionTitle from "../../../components/section-title/index.js";
import CustomButton from "../../../components/buttons/CustomButton.js";
import Datatable, {
  StatusCell,
} from "../../../components/tsdatatable/datatables.js";
import SearchFieldParams from "../../../components/fields/SearchFieldParams.js";
import ModalDetail from "./ModalDetail.js";
import PrintInvoice from "./PrintInvoice.js";

import { FiEye } from "react-icons/fi";
import print from "../../../assets/images/payment/print.svg";

const DataTableComponent = ({
  customData,
  filters,
  setFilters,
  numOfPage,
  setShowModalDetail,
  setShowPrintInvoice,
  setItem,
}) => {
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("print-content"),
    onAfterPrint: () => setShowPrintInvoice(false),
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Mã đơn hàng",
        accessor: "code",
      },
      {
        Header: "Thời gian",
        accessor: "updated_at",
        Cell: ({ value, row }) => {
          const date = new Date(value * 1000);
          return (
            <span>{moment(date.toISOString()).format("DD/MM/YYYY HH:mm")}</span>
          );
        },
      },
      {
        Header: "Trạng thái",
        accessor: "status",
        Cell: ({ value }) => {
          const status =
            value === 0
              ? "Hoàn thành"
              : value === 1
              ? "Đang xử lý"
              : "Đang chọn món";
          return (
            <StatusCell
              color={
                status == "Đang chọn món"
                  ? "blue"
                  : status == "Hoàn thành"
                  ? "green"
                  : "yellow"
              }
              title={status}
            />
          );
        },
      },
      {
        Header: "Tổng tiền",
        accessor: "total_money",
        Cell: ({ value }) => {
          return <p className={``}>{formatCurrency(value)}</p>;
        },
      },
      {
        Header: "Hành động",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              {/* <img
                src={IcEye}
                alt="IcEye"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row.original);
                  setShowModalDetail(true);
                }}
              /> */}
              <FiEye
                size={16}
                color="#8C8C8C"
                className="cursor-pointer"
                onClick={() => {
                  setItem(row.original);
                  setShowModalDetail(true);
                }}
              />
              <img
                src={print}
                alt="print"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row.original);
                  setShowPrintInvoice(true);
                  setTimeout(handlePrint, 500);
                }}
              />
            </div>
          );
        },
      },
    ],
    [handlePrint, setItem, setShowModalDetail, setShowPrintInvoice]
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const TableOrder = () => {
  const dispatch = useDispatch();
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showPrintInvoice, setShowPrintInvoice] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");
  const { updatePagination, setSearchQuery, filtersParams } = useSearchParams();
  const [filters, setFilters] = useState(filtersParams);

  const data = useSelector(selectListOrder);

  const customData = useMemo(() => {
    if (!data.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const handleCreateOrder = async () => {
    const order = await createOrder({
      customer_id: 0,
    });
    if (order && order.data) {
      history.push(`/sell-table/order/${order.data.order_id}`);
    }
  };

  return (
    <section className=" relative">
      <SectionTitle title="management bán hàng" subtitle="List đơn hàng" />

      <div className="mt-4 mb-2 w-full flex justify-between items-center gap-4">
        <SearchFieldParams
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          id="search-bank-input"
          extraClass="flex-1 bg-white shadow-lg"
          placeholder="Tìm theo mã đơn hàng"
        />
        <CustomButton
          type="add"
          title="Tạo đơn hàng"
          outline
          onClick={handleCreateOrder}
        />
      </div>

      <div className="mt-6">
        <DataTableComponent
          customData={customData}
          filters={filters}
          numOfPage={numOfPage}
          setFilters={setFilters}
          setItem={setSelectedOrder}
          setShowModalDetail={setShowModalDetail}
          setShowPrintInvoice={setShowPrintInvoice}
        />
      </div>

      {showModalDetail && (
        <ModalDetail
          setShowModal={setShowModalDetail}
          selectedItem={selectedOrder}
          setShowPrintInvoice={setShowPrintInvoice}
        />
      )}

      {showPrintInvoice && (
        <div className="absolute" style={{ top: "-500%" }}>
          <PrintInvoice order={selectedOrder} />
        </div>
      )}
    </section>
  );
};

export default TableOrder;
