/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cash from "./Cash/page";
import Credit from "./Credit/page";
import QRScan from "./QRScan/page";
import { getMerchant } from "../../../../services";

const PaymentMethod = ({ type, invoice, onPaymentSuccess, setPrintInvoice, qr, setQr }) => {
  return (
    <>
      {type === 0 && <QRScan qr={qr} setQr={setQr} invoice={invoice} setPrintInvoice={setPrintInvoice} />}
      {type === 1 && <Cash invoice={invoice} setPrintInvoice={setPrintInvoice} />}
      {type === 2 && <Credit invoice={invoice} onPaymentSuccess={onPaymentSuccess} setPrintInvoice={setPrintInvoice} />}
    </>
  );
};

export default PaymentMethod;
