import React from "react";
import Title from "./Title";
import CheckBoxField from "./CheckBoxField";
import { sell } from "../../../../assets";

const ID = {
  checkbox1: "checkbox1",
  checkbox2: "checkbox2",
  checkbox3: "checkbox3",
  checkbox4: "checkbox4",
  checkbox5: "checkbox5",
  checkbox6: "checkbox6",
};

const Step2 = ({ setPayload }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;

    setPayload((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <div className="flex flex-col w-full mb-10">
      <Title
        title="Bạn dự định bán sản phẩm nào trước?"
        subTitle="Chọn sản phẩm bạn muốn bắt đầu bán. Chúng tôi sẽ giúp bạn lưu kho sản phẩm cho cửa hàng."
      />

      <div className="grid grid-cols-2 justify-between items-center gap-4 mt-6 w-full ">
        <CheckBoxField
          title="Cửa hàng trực tuyến"
          subTitle="Tạo trangweb có thể tuỳ chỉnh toàn diện"
          icon={sell.icCartOnline}
          id={ID.checkbox1}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Trực tiếp"
          subTitle="Bán hàng tại cửa hàng bán lẻ, cửa hàng thời vụ hoặc các địa điểm thực tế khác"
          icon={sell.icCart}
          id={ID.checkbox2}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Trang web hoặc blog hiện có "
          subTitle="TAdd Nút Mua vào trang web"
          icon={sell.icBlog}
          id={ID.checkbox3}
        />
        <CheckBoxField
          title="Truyền thông xã hội "
          subTitle="Tiếp cận customer trên Facebook,Instagram, Tiktok, v.v."
          icon={sell.icMedia}
          id={ID.checkbox4}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Các nền tảng mua sắm trực tuyến"
          subTitle="Niêm yết sản phẩm trên Google, Amazon, v.v"
          icon={sell.icCommunication}
          id={ID.checkbox5}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Tôi không chắc"
          id={ID.checkbox6}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  );
};

export default Step2;
