import { combineReducers } from "redux";
import { auth } from "./auth.reducer";
import business from "./business.reducer";
import checkout from "./checkout.reducer";
import config from "./config";
import customerManagement from "./customer-management.reducer";
import dashboard from "./dashboard.reducer";
import edc from "./edc/edc.reducer";
import employee from "./employee/employee.reducer";
import feedback from "./feedback.reducer";
import invoiceManagement from "./invoice-management.reducer";
import leftSidebar from "./left-sidebar";
import metadata from "./metadata/metadata.reducer";
import navigation from "./navigation";
import orderManagement from "./order-management.reducer";
import palettes from "./palettes";
import password from "./password.reducer";
import paymentChannel from "./payment-channel.reducer";
import productManagement from "./product-management.reducer";
import purchaseNoti from "./purchaseNoti.reducer";
import resetPassword from "./reset-password.reducer";
import sass from "./sass.reducer";
import store from "./store.reducer";
import transaction from "./transaction.reducer";
import transInstallment from "./transinstallment.reducer";
import UrlPayment from "./urlpayment.reducer";
import vam from "./bank/bank.reducer";
import verifyBankWithOtp from "./bank/verifyBankOtp.reducer";
import paymentMethod from "./paymentMethod.reducer";
import categoryManagement from "./category.reducer";
import noti from "./noti.reducer";
import verify from "./verify.reducer";
import onchainTxReducer from "./report/onchain-tx.reducer";

const rootReducer = combineReducers({
  verify,
  navigation,
  config,
  leftSidebar,
  palettes,
  auth,
  transaction,
  dashboard,
  password,
  resetPassword,
  UrlPayment,
  checkout,
  transInstallment,
  verifyBankWithOtp,
  vam,
  paymentChannel,
  metadata,
  business,
  employee,
  edc,
  feedback,
  productManagement,
  customerManagement,
  orderManagement,
  invoiceManagement,
  store,
  purchaseNoti,
  sass,
  paymentMethod,
  categoryManagement,
  noti,
  OnchainTxSlice: onchainTxReducer,
});

export default rootReducer;
