import md5 from "md5";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import CustomButton from "../../components/buttons/CustomButton";
import InputField from "../../components/fields/InputField";
import SelectStoreField from "../../components/fields/SelectStoreField";
import SectionTitle from "../../components/section-title";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { ERROR_CODE } from "../../utils/errorCode";
import TableWrapper from "../company-profile/TableWrapper";

const FORM = {
  EDC_ID: "edc_id",
  TID: "tid",
  MID: "mid",
  VA_ID: "va_id",
  STORE_ID: "store_id",
};
const CreateEDC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {};
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(FORM.EDC_ID) ||
    !watch(FORM.TID) ||
    !watch(FORM.MID) ||
    !watch(FORM.VA_ID);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="EDC Information"
        subtitle="Add EDC"
        rightTitle="EDC Manager"
      />
      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* <InputField
            isRequired
            setValue={setValue}
            label="EDC code"
            placeholder="Enter EDC code"
            id={FORM.EDC_ID}
            name={FORM.EDC_ID}
            extra=""
            register={register}
            state={errors?.type ? "error" : " "}
          /> */}

          <InputField
            isRequired
            setValue={setValue}
            label="EDC code"
            placeholder="EDC code"
            name={FORM.EDC_ID}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            isRequired
            setValue={setValue}
            label="TID"
            placeholder="Enter TID"
            id={FORM.TID}
            name={FORM.TID}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <SelectStoreField
            isRequired
            register={register}
            name={FORM.STORE_ID}
            setValue={setValue}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <InputField
            isRequired
            setValue={setValue}
            label="MID"
            placeholder="Enter MID"
            id={FORM.MID}
            name={FORM.MID}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <InputField
            isRequired
            setValue={setValue}
            label="VA_ID"
            placeholder="Enter VA code"
            id={FORM.VA_ID}
            name={FORM.VA_ID}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/edc")}
              title="Back"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Add"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateEDC;
