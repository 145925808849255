import axios from "axios";
import React, { useState } from "react";
import IcUpload from "../../../assets/images/icon/ic_upload.svg";

function UploadProductField({
  extra,
  label,
  disabled,
  variant,
  isRequired,
  imgUrl,
  setImgUrl,
  errors,
  name,
  id = "upload",
}) {
  const [file, setFile] = useState(null);

  const handleFileChange = async (e) => {
    if (file) URL.revokeObjectURL(file);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    axios.post("https://api-dev.wesap.vn/image", formData).then((res) => {
      setImgUrl(res.data);
    });

    setFile(URL.createObjectURL(e.target.files[0]));
    e.target.value = null;
  };

  const Upload = () => {
    return (
      <label
        style={{ width: 111, height: 111 }}
        className={`rounded-xl border border-dashed border-gray-primary flex items-center justify-center cursor-pointer `}
        htmlFor={id}
      >
        <img src={IcUpload} alt="IcUpload" width={23} height={23} className=" " />
      </label>
    );
  };

  return (
    <div className={`${extra} font-monte `}>
      {label && (
        <div className={`mb-2`}>
          <label
            htmlFor={id}
            className={`${
              disabled
                ? "text-hint "
                : variant === "secondary"
                ? "text-text-secondary "
                : "text-text-primary "
            } text-sm leading-5 font-semibold`}
          >
            {label}
          </label>
          {isRequired && <span className="font-semibold text-red-required">*</span>}
        </div>
      )}

      <div
        style={{ gap: 48 }}
        className="flex items-center justify-center p-3 border rounded-xl border-gray-primary"
      >
        {imgUrl ? (
          <div
            style={{ width: 111, height: 111 }}
            className="select-field-wrapper relative cursor-pointer transition border border-gray-primary rounded-xl overflow-hidden"
          >
            <img src={imgUrl} alt="item" className="w-full h-full rounded-xl" />
            <div className={"select-field absolute inset-0 bg-blur z-50 transition"}>
              <Upload />
            </div>
          </div>
        ) : (
          <Upload />
        )}

        <input
          id={id}
          type="file"
          className="hidden"
          onChange={(e) => handleFileChange(e)}
          accept=".png, .jpeg, .jpg"
        />
      </div>
    </div>
  );
}

export default UploadProductField;
