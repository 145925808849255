// Custom components
import React, { useState } from "react";
import { handleToastErr } from "../../utils/lib";
import IcEye from "../../assets/images/icon/ic_eye.svg";
import IcEyeOff from "../../assets/images/icon/ic_eye-off.svg";

function InputFieldPw(props) {
  const {
    register,
    isRequired,
    setValue,
    value,
    type,
    label,
    placeholder,
    pattern,
    setError,
    clearErrors,
    id,
    name,
    extra,
    state,
    errors,
    variant,
    disabled,
    readOnly,
    setValidPassword,
    hideEye = true,
  } = props;
  const [showPw, setShowPw] = useState(false);

  return (
    <div className={`${extra} font-monte`}>
      {label && (
        <div className={`mb-2`}>
          <label
            htmlFor={id}
            className={`${
              disabled
                ? "text-hint "
                : variant === "secondary"
                ? "text-text-secondary "
                : "text-text-primary "
            } text-sm leading-5 font-semibold`}
          >
            {label}
          </label>
          {isRequired && <span className="text-red-required">*</span>}
        </div>
      )}

      <div className="relative">
        <input
          onChange={(e) => {
            // console.log("pattern", pattern);
            setValue(name, e.target.value);
            if (pattern) {
              const rex = new RegExp(pattern);
              if (!rex.test(e.target.value)) {
                setError(`${name}`, {
                  message: `Invalid password `,
                });
                setValidPassword(false);
              } else {
                setValue(name, e.target.value);
                setValidPassword(true);
                clearErrors(name);
              }
            }
          }}
          value={value}
          {...register(name, {
            required: {
              message: handleToastErr(name),
            },
          })}
          readOnly={readOnly}
          disabled={disabled}
          name={name}
          type={showPw ? "text" : "password"}
          id={id}
          placeholder={placeholder}
          onKeyDown={(evt) =>
            type === "number" &&
            ["e", "E", "+", ".", ",", "-"].includes(evt.key) &&
            evt.preventDefault()
          }
          onWheel={(event) => type === "number" && event.currentTarget.blur()}
          style={{ padding: "10px 16px", height: 44 }}
          className={`${disabled || readOnly ? "opacity-75" : ""} ${
            state === "error"
              ? "text-error border-error "
              : variant === "secondary"
              ? "text-grey-atom-grey border-gray-primary "
              : "text-input border-gray-primary "
          }
          ${type === "radio" ? "h-auto cursor-pointer" : ""}
          flex w-full text-14px items-center justify-center rounded-lg border font-medium leading-5 outline-none placeholder-hint`}
        />
        {hideEye === true && (
          <button
            type="button"
            onClick={() => setShowPw(!showPw)}
            style={{ right: 16, top: "50%" }}
            className={` absolute z-10 -translate-y-1/2 transform cursor-pointer`}
          >
            <img
              src={showPw ? IcEye : IcEyeOff}
              alt={`ic_eye${showPw ? "-off" : ""}`}
            />
          </button>
        )}
      </div>

      {errors?.[name] && (
        <p className="text-xs font-normal mt-2 text-error">
          {errors?.[name]?.message}
        </p>
      )}
    </div>
  );
}

export default InputFieldPw;
