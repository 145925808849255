import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderManagerServices } from "../services";

export const getListOrder = createAsyncThunk(
  "store/order-manager/getListOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await orderManagerServices.getListOrder(data);
    return _data
  }
);

export const getOrderById = createAsyncThunk(
  "store/order-manager/getOrderById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await orderManagerServices.getOrderById(data);
    return _data
  }
);

export const createOrder = createAsyncThunk(
  "store/order-manager/createOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await orderManagerServices.createOrder(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "store/order-manager/updateOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await orderManagerServices.updateOrder(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "store/order-manager/deleteOrder",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await orderManagerServices.deleteOrder(data);
    return _data;
  }
);
