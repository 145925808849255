import React, { useState } from 'react';
import close from '../../../../assets/images/product/close.svg';
import { removeTable, updateTable } from '../../../../services/inventory-manager.service';
import { ButtonBorderRed, ButtonRed } from '../Common';

const PopupUpdate = ({fetchListTable,setTableUpdate,item}) => {
    const [name,setName] = useState(item.table_name);
    const handleDeleteTable = async() => {
        await removeTable(item.table_id);
        setTableUpdate(null);
        fetchListTable();
    }
    const handleUpdateTable = async() => {
        const res = await updateTable(item.table_id,{
            table_name: name,
            max_slot: 0,
            available_slot: 0,
            is_available: true
        })
        if(res){
            fetchListTable();
            setTableUpdate(null);
        }
    }

    return (
        <div className="w-full h-full fixed inset-0 flex justify-center items-center"
            style={{backgroundColor:'rgba(0,0,0,0.70)',zIndex:60}}
        >
            <div className="bg-white p-6 flex flex-col gap-8 rounded-xl relative">
                <img 
                src={close} alt='' 
                className='absolute cursor-pointer' 
                style={{top:'16px',right:'16px'}}
                onClick={() => setTableUpdate(false)}
                />
                <p className='text-black text-base font-semibold leading-5 text-center'>Cập nhật thông tin bàn</p>

                <div className="flex flex-col">
                    <p className="text-black text-sm font-semibold leading-5">Tên bàn<span style={{color:'#ED1C24'}}>*</span></p>
                    <input 
                        type="text"
                        plasceholder="Nhập tên bàn"
                        value={name}
                        className="create-form outline-none text-sm font-medium leading-5 rounded-lg mt-2 w-full"
                        style={{padding:'10px 16px 10px 16px',border:'1px solid #E8E8E8',width:'366px'}}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className='flex gap-4 justify-items-center'>
                    <div className='w-full' onClick={()=>handleDeleteTable()}>
                        <ButtonBorderRed content={'Xóa'}/> 
                    </div>
                    <div className='w-full' onClick={()=>handleUpdateTable()}>
                        <ButtonRed content={'Cập nhật'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupUpdate