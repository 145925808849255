import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../components/fields/InputField";
import SectionTitle from "../../../components/section-title";
import PaymentMethod from "./PaymentMethod";
import CustomButton from "../../../components/buttons/CustomButton";
import TableWrapper from "../TableWrapper";
import { history } from "../../../helpers";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import appClient from "../../../services/axios/app-client";

const FORM = {
  LINK_SUCCESS: "link_success",
  LINK_CANCEL: "link_cancel",
  LINK_RESULT: "link_result",
  POS: "pos",

  URL: "url",
  ASSESS_KEY: "access_key",
  SECRET_KEY: "secret_key",
  PARTNER_CODE: "partner_code",
};

const CompanySetting = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm();
  const [paymentMethod, setPaymentMethod] = useState([]);

  const onFormSubmit = async (value) => {
    const formatData = paymentMethod
      .filter((item) => item.active)
      .map((item) => {
        const ewalletData = {
          url: value.url,
          access_key: value.access_key,
          secret_key: value.secret_key,
          partner_code: value.partner_code,
        };

        const qrData = {
          channel_id: value.pos,
        };

        const data =
          item.group.toLowerCase() === "ewallet"
            ? ewalletData
            : item.group.toLowerCase() === "qr"
            ? qrData
            : {};

        return {
          data,
          id: item.id,
        };
      });

    const dataSubmit = {
      ipn_url: value.link_result,
      cancel_url: value.link_cancel,
      redirect_url: value.link_success,
      data: formatData,
    };
    try {
      const { data } = await appClient.post("/payment-method", dataSubmit);
      // console.log('>>> res res: ', res)
      if (data && data === "success") {
        CustomToastMessage.success("Updated payment method success");
      }
    } catch (err) {
      console.log("Log - err:", err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  useEffect(() => {
    const onGetPaymentMethod = async () => {
      try {
        const { data } = await appClient.get("/payment-method");
        setPaymentMethod(data?.payment_method);
        if (data && data.endpoint) {
          setValue(FORM.LINK_SUCCESS, data.endpoint?.redirect_url);
          setValue(FORM.LINK_CANCEL, data.endpoint?.cancel_url);
          setValue(FORM.LINK_RESULT, data.endpoint?.ipn_url);
        }
      } catch (error) {}
    };
    const interval = onGetPaymentMethod();
    return () => clearInterval(interval);
  }, [setValue]);

  const disabled =
    !watch(FORM.LINK_SUCCESS) ||
    !watch(FORM.LINK_CANCEL) ||
    !watch(FORM.LINK_RESULT);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="THÔNG TIN DOANH NGHIỆP"
        subtitle="Thiết lập phương thức thanh toán"
      />
      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <InputField
            isRequired
            setValue={setValue}
            label="Đường dẫn thanh toán Success"
            placeholder="Chuyển hướng sau khi Success"
            id={FORM.LINK_SUCCESS}
            name={FORM.LINK_SUCCESS}
            value={watch(FORM.LINK_SUCCESS)}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            isRequired
            setValue={setValue}
            label="Đường dẫn huỷ thanh toán"
            placeholder="Chuyển hướng huỷ thanh toán"
            id={FORM.LINK_CANCEL}
            name={FORM.LINK_CANCEL}
            value={watch(FORM.LINK_CANCEL)}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <InputField
            isRequired
            setValue={setValue}
            label="Đường dẫn nhận kết quả thanh toán"
            placeholder="API Callback, webhook"
            id={FORM.LINK_RESULT}
            name={FORM.LINK_RESULT}
            value={watch(FORM.LINK_RESULT)}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
        </div>

        <PaymentMethod
          form={FORM}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          register={register}
          watch={watch}
          setValue={setValue}
          errors={errors}
          state={errors?.type ? "error" : " "}
        />

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/user")}
              title="Quay lại"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Cập nhật"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CompanySetting;
