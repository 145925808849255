import React from "react";
import Title from "./Title";
import CheckBoxField from "./CheckBoxField";
import { sell } from "../../../../assets";

const ID = {
  checkbox11: "checkbox11",
  checkbox13: "checkbox13",
  checkbox12: "checkbox12",
  checkbox14: "checkbox14",
  checkbox15: "checkbox15",
  checkbox16: "checkbox16",
};

const Step3 = ({ setPayload }) => {
  const handleChange = (e) => {
    const { name, checked } = e.target;

    setPayload((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  return (
    <div className="flex flex-col w-full mb-10">
      <Title
        title="Bạn dự định bán sản phẩm nào trước?"
        subTitle="Chọn sản phẩm bạn muốn bắt đầu bán. Chúng tôi sẽ giúp bạn lưu kho sản phẩm cho cửa hàng."
      />

      <div className="grid grid-cols-2 justify-between items-center gap-4 mt-6 w-full ">
        <CheckBoxField
          title="Sản phẩm do tôi mua hoặc tự làm"
          subTitle="Do tôi vận chuyển"
          id={ID.checkbox11}
          icon={sell.icTag}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Sản phẩm kỹ thuật số"
          subTitle="Âm nhạc, nghệ thuật kỹ thuật số hoặc NFT"
          id={ID.checkbox12}
          icon={sell.icDigital}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Sản phẩm bán hàng trung gian"
          subTitle="Do bên thứ ba cung ứng vận chuyển"
          icon={sell.icTransport}
          id={ID.checkbox13}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Dịch vụ"
          subTitle="Huấn luyện, dọn nhà, tư vấn"
          icon={sell.icService}
          id={ID.checkbox14}
          onChange={(e) => handleChange(e)}
        />
        <CheckBoxField
          title="Sản phẩm in theo yêu cầu"
          subTitle="Thiết kế của tôi, do bên thứ ba in ấn và vận chuyển"
          icon={sell.icCloth}
          id={ID.checkbox15}
          onChange={(e) => handleChange(e)}
        />

        <CheckBoxField
          title="Tôi không chắc"
          id={ID.checkbox16}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  );
};

export default Step3;
