import { createSlice } from "@reduxjs/toolkit";
import { getTableBank, verifyBank } from "../../actions/bank/bank.actions";

const initialState = {
  vamBanks: [],
  bankAccount: {
    bank_code: "",
    bank_account_no: "",
    holder_name: "",
  },
  isLoading: false,
};

const vamSlice = createSlice({
  name: "vamSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getTableBank.fulfilled, (state, action) => {
        state.vamBanks = action.payload;
      })
      .addCase(verifyBank.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyBank.fulfilled, (state, action) => {
        state.bankAccount = action.meta.arg;
        state.isLoading = false;
      })
      .addCase(verifyBank.rejected, (state, action) => {
        state.isLoading = false;
      }),
});
export default vamSlice.reducer;

export const selectListBankVam = (state) => state.vam.vamBanks;
export const selectBankAccount = (state) => state.vam.bankAccount;
export const selectIsLoadingBankAccount = (state) => state.vam.isLoading;
