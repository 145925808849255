/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { sell } from "../../../../assets";
import ModalWrapper from "../../../../components/modals/ModalWrapper";

const ModalLoading = () => {
  return (
    <ModalWrapper>
      <img
        src={sell.icLoading}
        alt="icLoading"
        width={50}
        height={48}
        style={{ borderRadius: 9 }}
      />
      <p className="text-grey-8 text-base font-medium leading-5 mt-2">
        Vui lòng chờ trong giây lát...
      </p>
    </ModalWrapper>
  );
};

export default ModalLoading;
