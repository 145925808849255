import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import IcWarn from "../../assets/images/icon/ic_warn.svg";
import CustomButton from "../buttons/CustomButton";

const ModalDelete = ({
  setIsOpenModal,
  handleAction,
  title,
  desc,
  item,
  titleBtnLeft = "Cancel",
  titleBtnRight = "Delete",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteBank = async (id) => {
    setIsLoading(true);
    await handleAction(id);
    setIsLoading(false);
    setIsOpenModal(false);
  };

  return (
    <div className="fixed z-50 inset-0 bg-blur overflow-auto flex justify-center items-center">
      <div className="w-96 bg-white rounded-2xl relative">
        <IoCloseSharp
          className="absolute cursor-pointer hover:opacity-75"
          style={{ right: 16, top: 16 }}
          onClick={() => {
            setIsOpenModal(false);
          }}
          size={20}
        />
        <div className=" mx-auto flex max-w-md flex-col gap-8 rounded-2xl px-6 py-8">
          <div className="flex flex-col items-center justify-center gap-6">
            <div>
              <img src={IcWarn} alt="ic_warn" />
            </div>
            <div className="flex flex-col gap-3 text-center text-black font-normal">
              <h1 className="text-xl font-bold">{title}</h1>
              <p className="text-sm font-normal">{desc}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <CustomButton
              outline
              title={titleBtnLeft}
              onClick={() => {
                setIsOpenModal(false);
              }}
            />

            <CustomButton
              title={titleBtnRight}
              disabled={isLoading}
              onClick={() => handleDeleteBank(item?.id)}
              style={{ backgroundImage: "linear-gradient(36deg, #2B3DB9 2.93%, #0CD6F7 102.74%)" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDelete;
