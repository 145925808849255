
import appClient from "./axios/app-client";
import { FEEDBACK } from "./endpoint";

export const feedbackServices = {
  getFeedbackById: (data) => {
    const query = new URLSearchParams(data);
    return appClient.get(`${FEEDBACK}?${query}`);
  },

  getListFeedback: (data) => {
    const query = new URLSearchParams(data);
    return appClient.get(`${FEEDBACK}?${query}`);
  },

  createFeedback: (params) => {
    return appClient.post(FEEDBACK, params);
  },

  updateFeedback: (params) => {
    return appClient.put(FEEDBACK, params);
  },

  deleteFeedback: (params) => {
    return appClient.delete(FEEDBACK, params);
  },
};
