/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { history } from "../../helpers";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getListFeedback } from "../../actions";
import { selectListFeedback } from "../../reducers/feedback.reducer";
import { useDispatch, useSelector } from "react-redux";
import useCheck from "./useCheck";
import useEDC from "./useEDC";
import useOther from "./useOther";

import SectionTitle from "../../components/section-title";
import CustomButton from "../../components/buttons/CustomButton";
import Datatable from "../../components/tsdatatable/datatables";
import ModalDetail from "./ModalDetail";
import ModalDelete from "../../components/modals/ModalDelete";
import useWindowSize from "../../hooks/useWindowSize";

const Feedback = () => {
  const dispatch = useDispatch();
  const tab = useParams().tab || "check";
  const vieTab = tab == "check" ? "Tra soát" : tab == "edc" ? "Báo lỗi EDC" : "Vấn đề khác";
  const [showDetail, setShowDetail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const check = useCheck({ setShowDetail, setShowDelete, setSelectedItem });
  const edc = useEDC({ setShowDetail, setShowDelete, setSelectedItem });
  const other = useOther({ setShowDetail, setShowDelete, setSelectedItem });
  const tabList = [check, edc, other];
  const activedTab = tabList.find((item) => item.type == tab);
  const { isMd } = useWindowSize()

  const data = useSelector(selectListFeedback);
  const [numOfPage, setNumOfPage] = useState("1");
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const customData = useMemo(() => {
    if (!data?.data?.items) return [];
    setNumOfPage(data?.data?.total_page);
    return data?.data?.items;
  }, [data]);

  useEffect(() => {
    dispatch(
      getListFeedback({
        type: tab.toUpperCase(0),
        page: filters.page,
        page_size: filters.page_size,
      })
    );
  }, [tab, filters.page, filters.page_size]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <SectionTitle title="Báo lỗi" subtitle={vieTab} />
        <CustomButton className='blcok md:hidden' type="add" onClick={() => history.push(`/feedback/create/${tab}`)} />
      </div>
      <div className="mt-8 space-y-6">
        {/* Tab List + Add Button */}
        <div className="flex justify-between items-center">
          {/* Tab List */}
          <div
            className="bg-white rounded-xl flex w-full md:w-auto"
            style={{ boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)", padding: 10 }}
          >
            {tabList.map((item) => (
              <button
                key={item.type}
                style={{ width: isMd ? 162 : '33%', transition: "all 0.1s linear" }}
                onClick={() => history.push(`/feedback/${item.type}`)}
                className={`px-6 md:px-8 py-3 rounded text-sm leading-5 font-semibold text-center
                ${item.type == tab ? "bg-primary text-white" : "text-blackgray"}`}
              >
                {item.type == "check"
                  ? "Tra soát"
                  : item.type == "edc"
                    ? "Báo lỗi EDC"
                    : "Vấn đề khác"}
              </button>
            ))}
          </div>
          {/* Add Button */}
          <CustomButton className='hidden md:flex' type="add" onClick={() => history.push(`/feedback/create/${tab}`)} />
        </div>

        {/* Table */}
        <Datatable
          columns={activedTab.columns}
          data={customData}
          pagination={{
            page: filters.page,
            pageSize: filters.page_size,
            totalPages: numOfPage,
          }}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      {showDetail && (
        <ModalDetail
          title={`Chi tiết ${activedTab.formTitle}`}
          setShowModal={setShowDetail}
          selectedItem={{ ...selectedItem, type: tab }}
        />
      )}
      {showDelete && (
        <ModalDelete
          setIsOpenModal={setShowDelete}
          title={`Xóa ${activedTab.formTitle}`}
          desc={`Bạn có chắc muốn xoá ${activedTab.formTitle} này không?`}
          item={selectedItem}
          handleAction={activedTab.handleDelete}
        />
      )}
    </div>
  );
};

export default Feedback;
