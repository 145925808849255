import React from 'react'
import { theme } from '../../../configTheme'

const BtnGiftBank = () => {
  return (
    <button
      style={{
        color: '#B57719',
        fontSize: 16,
        fontWeight: 600,
        leading: 24,
        border: 'solid 2px #C58B1B',
        background: 'linear-gradient(180deg, #FFF4E0 0%, #E8C176 57.6%)',
        backdropFilter: 'blur(50px)',
        borderRadius: 8,
        padding: '8px 16px',
        width: 224,
        height: 48
      }}
      className='mt-4'>
      {`${theme.primary == "#ED1C24"
        ? 'KFC'
        : theme.primary == "#00B74F"
          ? 'VPBank'
          : theme.primary == "#008F3C"
            ? 'OCB'
            : 'BVB'
        } Gift`}
    </button>
  )
}

export default BtnGiftBank
