import { createSlice } from "@reduxjs/toolkit"
import { getListPayment, createPChannel, deletePChannel, createQR } from "../actions/payment-channel"

const initialState = {
    listPayment: [],
    isLoading: false,
}

const paymentChannelSlice = createSlice({
    name: 'paymentChannelSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getListPayment.fulfilled, (state, action) => {
            state.listPayment = action.payload.data;
        })
        .addCase(createPChannel.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(createPChannel.fulfilled, (state, action) => {
            state.isLoading = false
        })
        .addCase(createPChannel.rejected, (state, action) => {
            state.isLoading = false
        })
        .addCase(deletePChannel.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(deletePChannel.fulfilled, (state, action) => {
            state.isLoading = false
        })
        .addCase(deletePChannel.rejected, (state, action) => {
            state.isLoading = false
        })
        .addCase(createQR.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(createQR.fulfilled, (state, action) => {
            state.isLoading = false
        })
        .addCase(createQR.rejected, (state, action) => {
            state.isLoading = false
        })
    }
})

export const selectListPayment = state => state.paymentChannel.listPayment;
export const selectIsLoading = state => state.paymentChannel.isLoading;

export default paymentChannelSlice.reducer;