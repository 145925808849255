import { createAsyncThunk } from "@reduxjs/toolkit";
import { metadataServices } from "../../services/metadata/metadata.service";
import { chainServices } from "../../services/chain/listchain.service";
import { networkServices } from "../../services/network/network.service";

export const getListStore = createAsyncThunk(
  "metadata/getListStore",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListStore(data);
    return _data;
  }
);

export const getListStaff = createAsyncThunk(
  "metadata/getListStaff",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListStaff(data);
    return _data;
  }
);

export const getListLinkedVa = createAsyncThunk(
  "metadata/getListLinkedVa",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListLinkedVa(data);
    return _data;
  }
);

export const syncData = createAsyncThunk(
  "metadata/rule/sync",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await metadataServices.syncData(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListChain = createAsyncThunk(
  "chain/getListChain",
  async (data, { dispatch }) => {
    const _data = await chainServices.getListChain(data);
    return _data;
  }
);

export const getListNetwork = createAsyncThunk(
  "network/getListNetwork",
  async (data, { dispatch }) => {
    const _data = await networkServices.getListNetwork(data);
    return _data;
  }
);

export const getListBank = createAsyncThunk(
  "metadata/getListBank",
  async (data, { dispatch }) => {
    const _data = await metadataServices.getListBank(data);
    return _data;
  }
);

export const uploadImage = createAsyncThunk(
  "user/uploadImage",
  async (data, { dispatch }) => {
    try {
      const _data = await metadataServices.uploadImage(data);
      return _data;
    } catch (error) {
      return error;
    }
  }
);

export const postCrypto = createAsyncThunk(
  "user/postCrypto",
  async (data, { dispatch }) => {
    try {
      const _data = await metadataServices.postCrypto(data);
      return _data;
    } catch (error) {
      return error;
    }
  }
);

export const postFiat = createAsyncThunk(
  "user/postFiat",
  async (data, { dispatch }) => {
    try {
      const _data = await metadataServices.postCrypto(data);
      return _data;
    } catch (error) {
      return error;
    }
  }
);
