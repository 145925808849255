import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListStore } from "../../actions/metadata/metadata.actions";
import SectionTitle from "../../components/section-title";
import Datatable from "../../components/tsdatatable/datatables";
import { selectListStore } from "../../reducers/metadata/metadata.reducer";
import { formatCurrency } from "../../utils/lib";
import { toastMessage } from "../../utils/constants";
import { ERROR_CODE } from "../../utils/errorCode";
import ModalDelete from "../../components/modals/ModalDelete";

import IcPen from "../../assets/images/employee/ic_pen.svg";
import IcTrash from "../../assets/images/payment/trash.svg";
import ShowQR from "./ShowQR";
import { useHistory } from "react-router-dom";

import qr from "../../assets/images/icon/qr.svg";
import redMulti from "../../assets/images/icon/redMulti.svg";
import CustomButton from "../../components/buttons/CustomButton";
import { useCallback } from "react";
import { deleteStore } from "../../actions";

const DatatableComponent = ({
  customData,
  filters,
  numOfPage,
  setFilters,
  setShowQR,
  setIsOpenModal,
  setSelectedStore,
}) => {
  const history = useHistory();
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Store code",
        accessor: "id",
        Cell: ({ value, row }) => {
          return (
            <span
              style={{
                width: 100,
              }}
            >
              {value || "None"}
            </span>
          );
        },
      },
      {
        Header: "Store",
        accessor: "name",
      },
      {
        Header: "VA code",
        Cell: ({ _, row }) => {
          const vaNo = row.original.va_account_no;
          return (
            <img
              className={`${vaNo ? "cursor-pointer" : "pointer-events-none"}`}
              src={vaNo ? qr : redMulti}
              alt="qr"
              width={20}
              onClick={() => {
                setSelectedStore(row.original);
                setShowQR(true);
              }}
            />
          );
        },
      },
      {
        Header: "Store address",
        accessor: "address",
        Cell: ({ value, row }) => {
          return <span>{value || ""}</span>;
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  //history.push(`/store-manager/update?id=${row?.original?.id}`);
                }}
              />
              <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  // setEmployee(row?.original);
                  setSelectedStore(row?.original);
                  setIsOpenModal(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    [setSelectedStore, setShowQR]
  );

  console.log(">>> filter", filters);

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      setFilters={setFilters}
      filters={filters}
    />
  );
};

export default function StoreManagement() {
  const history = useHistory();
  const [showQR, setShowQR] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const data = useSelector(selectListStore);

  const [numOfPage, setNumOfPage] = useState("1");

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const customData = useMemo(() => {
    const result = data?.data;
    if (!result || Object.keys(result).length === 0) return [];
    // setNumOfPage(result?.num_of_page ? result?.num_of_page : 1);
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    //console.log(">>>", data);
    return result;
  }, [data]);

  const formatData = (ls) => {
    return ls && Array.isArray(ls)
      ? ls?.map((e) => {
          return {
            ...e,
            request_amount: formatCurrency(e.request_amount),
            original_transaction_date: moment(
              new Date(e.original_transaction_date * 1000)
            ).format("HH:mm:ss DD-MM-YYYY"),
          };
        })
      : [];
  };
  const handleDelete = useCallback(async () => {
    console.log(">>", selectedStore);
    try {
      const res = await dispatch(
        deleteStore({ data: { id: selectedStore?.id } })
      );
      if (res?.meta?.requestStatus === "fulfilled") {
        // toastDeleteBankSuccess({
        //   bank_code: bank?.bank_code,
        //   bank_account_no: formatBankAccount(bank?.account_no),
        // });
        toastMessage.success(res?.payload?.data);
        //dispatch(getListStaff());
        dispatch(getListStore());
        setIsOpenModal(false);
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        } else if (res?.payload?.msg) {
          msg = res?.payload?.msg;
        }
        toastMessage.error(msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [selectedStore, dispatch]);

  useEffect(() => {
    dispatch(getListStore(filters));
    // dispatch(getListTransQRCode(filters));
  }, [filters]);

  console.log("sotre", selectedStore);

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle
          title="BUSINESS INFORMATION"
          subtitle="Store Management"
        />
        <CustomButton
          type="add"
          outline
          title={"Add"}
          onClick={() => history.push("/store-manager/create")}
        />
      </div>

      <div className="mt-6">
        <DatatableComponent
          customData={formatData(customData)}
          filters={filters}
          numOfPage={numOfPage}
          data={data}
          setFilters={setFilters}
          setShowQR={setShowQR}
          setSelectedStore={setSelectedStore}
          setIsOpenModal={setIsOpenModal}
        />
      </div>

      {isOpenModal && (
        <ModalDelete
          setIsOpenModal={setIsOpenModal}
          title="Delete store"
          desc={`Are you sure you want to delete the store ${selectedStore?.name} with the store code ${selectedStore?.id} ?`}
          item={selectedStore}
          handleAction={handleDelete}
        />
      )}

      {showQR && <ShowQR store={selectedStore} setShow={setShowQR} />}
    </section>
  );
}
