import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getListPayment } from "../../actions/payment-channel";
import { selectListPayment } from "../../reducers/payment-channel.reducer";
import SearchField from "./SearchField";
import SelectFieldContent from "./select/SelectFieldContent";
import SelectFieldItem from "./select/SelectFieldItem";
import SelectFieldWrapper from "./select/SelectFieldWrapper";
import "./style.css";

const SelectVaField = (props) => {
  const {
    initialValue,
    register,
    name,
    setValue,
    errors,
    extra,
    state,
    isRequired,
  } = props;

  const [currentItem, setCurrentItem] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 100,
  });
  const data = useSelector(selectListPayment);

  const handleSelectBox = (data) => {
    setCurrentItem(data);
    setValue(name, data?.id);
    setShowContent(false);
  };

  function filterByName() {
    if (searchValue.trim().length === 0) return data?.data || [];

    const normalizedFilter = searchValue.toLowerCase().trim();
    return data?.data?.filter((item) =>
      item?.va_account_no?.toLowerCase().includes(normalizedFilter)
    );
  }

  const CurrentItemComp = () => (
    <div style={{ gap: 10 }} className="flex items-center ">
      <span className="text-14px leading-5">{currentItem?.va_account_no}</span>
    </div>
  );

  useEffect(() => {
    dispatch(getListPayment(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    const item = data?.data?.find((item) => item.id === initialValue);
    setCurrentItem(item);
  }, [initialValue, data]);

  return (
    <SelectFieldWrapper
      {...props}
      showContent={showContent}
      setShowContent={setShowContent}
      label={"VA account"}
      initialText="Select VA account"
      currentValue={currentItem}
      currentItemComp={<CurrentItemComp />}
    >
      <SearchField
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        id="search-bank-input"
        extraClass="m-4 mb-1"
      />
      <SelectFieldContent>
        {filterByName().map((item, index) => {
          const active = currentItem?.va_account_no === item?.va_account_no;
          return (
            <SelectFieldItem
              key={`${item?.va_account_no}-${index}`}
              onClick={() => handleSelectBox(item)}
              active={active}
            >
              {item?.va_account_no}
            </SelectFieldItem>
          );
        })}
      </SelectFieldContent>
    </SelectFieldWrapper>
  );
};

export default SelectVaField;
