import React from "react";
import { share } from "../../assets";

const BtnCheckout = ({ checkout }) => {
  return (
    <button className="bg-primary p-4 rounded" onClick={checkout}>
      <span className="text-white font-semibold text-base">Pay</span>
    </button>
  );
};

export default BtnCheckout;
