import React from "react";
import { useHistory, useParams } from "react-router-dom";
import close from "../../../../assets/images/product/close.svg";
import user from "../../../../assets/images/product/user.svg";
import { createInvoice } from "../../../../services/inventory-manager.service";
import { formatCurrency } from "../../../../utils/lib";
import { ButtonBorderRed, ButtonRed } from "../Common";
import ProductList from "./ProductList";
import { theme } from "../../../../configTheme";

const OrderDetail = ({
  total,
  pickedItem,
  setShowCart,
  handleNext,
  customer,
  tableName,
}) => {
  const { order_id } = useParams();
  const navigate = useHistory();

  const handlePayment = async () => {
    if (pickedItem.length > 0) {
      const res = await createInvoice({
        order_id: order_id,
      });
      if (res && (res.invoice_id || res.data.invoice_id)) {
        setShowCart(false);
        navigate.push(
          `/sell-table/payment/${res.invoice_id || res.data.invoice_id}`
        );
      }
    }
  };

  return (
    <div
      className="w-full h-full fixed top-0 left-0 flex justify-center items-center"
      style={{ backgroundColor: "#000000B2", zIndex: 60 }}
    >
      <div className="relative flex flex-col gap-6 items-center rounded-2xl bg-white p-10">
        <img
          src={close}
          alt=""
          className="absolute cursor-pointer"
          style={{ top: "16px", right: "16px" }}
          onClick={() => setShowCart(false)}
        />
        <p className="text-black text-xl font-semibold leading-7">
          Chi tiết order
          <span className="text-primary"> {`(${pickedItem.length})`}</span>{" "}
          {tableName}
        </p>
        <div className="flex flex-col gap-4">
          <ProductList pickedItem={pickedItem} />
          <div
            className="flex flex-col gap-3 border-t border-dashed"
            style={{ borderColor: "#E8E8E8" }}
          >
            <div className="flex items-center justify-between w-full py-3 px-2">
              <span
                className="text-sm font-medium"
                style={{ color: "#8C8C8C" }}
              >
                Thành tiền
              </span>
              <span
                className="text-base font-semibold leading-5"
                style={{ color: "#262626" }}
              >
                {formatCurrency(total)}
              </span>
            </div>
            <div className="flex items-center justify-between w-full py-3 px-2">
              <span
                className="text-sm font-medium"
                style={{ color: "#8C8C8C" }}
              >
                customer
              </span>
              {!customer ? (
                <button
                  className={`${
                    pickedItem.length > 0 ? "opacity-100" : "opacity-75"
                  } flex items-center justify-center rounded-lg w-full h-full border`}
                  style={{
                    width: "194px",
                    height: "40px",
                    gap: "10px",
                    borderColor: "#005AA9",
                  }}
                  onClick={() => handleNext()}
                  disabled={pickedItem.length <= 0}
                >
                  <img src={user} alt="" />
                  <span
                    className={` text-sm font-medium`}
                    style={{ color: "#005AA9" }}
                  >
                    Add customer
                  </span>
                </button>
              ) : (
                <div
                  className="py-2 px-3 rounded-lg flex items-center w-fit cursor-pointer border"
                  style={{
                    gap: "10px",
                    borderColor: theme.primary,
                  }}
                  onClick={() => handleNext()}
                >
                  <span
                    className="text-sm font-semibold leading-5"
                    style={{ color: theme.primary }}
                  >
                    {customer?.name}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            className="w-full"
            style={{ height: "1px", backgroundColor: "#E8E8E8" }}
          ></div>
        </div>
        <div className="w-full flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium" style={{ color: "#262626" }}>
              Tổng thanh toán
            </span>
            <span className="text-2xl font-semibold leading-8 text-black">
              {formatCurrency(total)}
            </span>
          </div>
          <div className="flex gap-2 justify-center">
            <div className="w-full" onClick={() => setShowCart(false)}>
              <ButtonBorderRed content={"Add món"} />
            </div>
            <button
              className={`${
                pickedItem.length > 0 ? "opacity-100" : "opacity-75"
              } w-full`}
              onClick={() => handlePayment()}
            >
              <ButtonRed content={"Thanh toán"} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
