import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { state } from "faker/lib/locales/en/address";
import { userService } from "../services/user.service";

const initialState = {
  items: null,
  pending: false,
  status: null,
};

const password = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.items = null;
      state.status = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(resetPassword.pending, (state, action) => {
        state.pending = true;
        state.status = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.items = action.payload;
        state.pending = false;
        state.status = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.items = action.error;
        state.pending = false;
        state.status = false;
      }),
});

export const resetPassword = createAsyncThunk(
  "portal/ResetPassword",
  async (data, { dispatch, getState }, thunkAPI) => {
    try {
      const state = getState();
      const { domain } = state.auth;
      const _data = await userService.resetPassword({
        ...data,
        // domain
      });
      return _data;
    } catch (error) {
      const message = error;
      throw new Error(message.errors || message.msg);
    }
  }
);

export default password.reducer;

export const { resetState } = password.actions;

export const SetForgotPassword = (state) => state.resetPassword.items;

export const Getstatus = (state) => state.resetPassword.status;

export const Getpending = (state) => state.resetPassword.pending;
