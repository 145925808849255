import axiosClient2 from "../../../services/client_axios2";
import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getOrderById } from "../../../actions/order-manager.actions";
import { theme } from "../../../configTheme";
import { formatCurrency, formatPaymentMethod } from "../../../utils/lib";
import { history } from "../../../helpers";

import { TimeCreate } from "../inventory-manager/Payment/Invoice";
import { Popup } from "../inventory-manager/Common";
import CustomButton from "../../../components/buttons/CustomButton";
import Session from "../../company-profile/Session";
import ModalExport from "./ModalExport";
import PrintInvoice from "./PrintInvoice";

import star from "../../../assets/images/icon/star.svg";
import paid from "../../../assets/images/paid.png";
import success from "../../../assets/images/product/success.gif";

const Cell = ({ value, alignRight = false }) => {
  if (!value) return <div></div>;
  if (value.toString().includes("http"))
    return (
      <div
        className={`w-full flex items-center ${
          alignRight && "justify-end"
        } px-4`}
      >
        <img src={value} className="w-10 h-10 rounded" />
      </div>
    );
  return (
    <div
      style={{ color: theme.textPrimary }}
      className={`w-full p-4 flex items-center ${alignRight && "justify-end"}`}
    >
      {value}
    </div>
  );
};

const ModalDetail = ({
  title = "Chi tiết đơn hàng",
  setShowModal,
  selectedItem,
  showPrintInvoice,
  setShowPrintInvoice,
}) => {
  const isPaid = selectedItem.status == 0;
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState({ products: [], info: {} });
  const [exportInvoice, setExportInvoice] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);

  useEffect(() => {
    const getListDetail = async () => {
      if (isPaid) {
        const res = await axiosClient2.get(
          `/invoice/${selectedItem.order_id}?is_orderid=true`
        );
        // console.log('>>> res: ', res)
        if (res?.data)
          setOrderData({
            products: res.data.products_data,
            info: res.data.invoice_data,
          });
      } else {
        const res = await dispatch(getOrderById(selectedItem.order_id));
        // console.log(res)
        if (res?.payload?.data)
          setOrderData({
            products: res.payload.data.products,
            info: {
              ...res.payload.data.order_info,
              total_price: selectedItem.total_money,
            },
          });
      }
    };
    getListDetail();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("print-content"),
    onAfterPrint: () => setShowPrintInvoice(false),
  });

  return (
    <div className="fixed z-50 inset-0 bg-blur flex justify-center overflow-y-scroll">
      <div
        style={{ width: 1000, height: "min-content" }}
        className="my-16 relative bg-white p-12 rounded-2xl flex flex-col gap-6 items-center"
      >
        {/* Close Icon */}
        <IoCloseSharp
          size={24}
          onClick={() => setShowModal(false)}
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
            zIndex: 21,
          }}
        />

        {isPaid && (
          <img
            src={paid}
            alt="paid"
            width={200}
            className="absolute z-20"
            style={{ top: 10, right: 28 }}
          />
        )}

        {/* Title */}
        <h1 className="text-dark-primary text-2xl leading-8 font-bold">
          {title}
        </h1>

        {/* Detail Product */}
        <div className="w-full text-sm leading-5 font-medium">
          {/* Header */}
          <div
            style={{ gridTemplateColumns: "1fr 1fr 3fr 2fr 2fr 2fr" }}
            className="grid w-full font-bold border-b border-gray-primary"
          >
            <Cell value="STT" />
            <Cell value="Hình" />
            <Cell value="Tên sản phẩm" />
            <Cell alignRight value="Số lượng" />
            <Cell alignRight value="Đơn giá" />
            <Cell alignRight value="Thành tiền" />
          </div>

          {orderData.products.map((item, index) => (
            <div
              key={item.product_id}
              style={{ gridTemplateColumns: "1fr 1fr 3fr 2fr 2fr 2fr" }}
              className="grid w-full"
            >
              <Cell value={index + 1} />
              <Cell value={item.img} />
              <Cell value={item.name?.toUpperCase()} />
              <Cell alignRight value={item.amount + " phần"} />
              <Cell alignRight value={formatCurrency(item.price)} />
              <Cell
                alignRight
                value={formatCurrency(item.amount * item.price)}
              />
            </div>
          ))}

          <div className="w-full flex justify-between font-bold border-dashed border-t border-gray-primary mt-4">
            <p
              style={{ color: theme.textSecondary }}
              className="font-semibold px-2 py-3"
            >
              Điểm:
            </p>
            <div className="flex gap-1 px-2 py-3">
              <img src={star} alt="star" width={20} />
              <p
                style={{ color: theme.textPrimary }}
                className="text-base leading-5 font-semibold"
              >
                {orderData.info.point || "0"}
              </p>
            </div>
          </div>

          <div className="w-full flex justify-between font-bold">
            <p
              style={{ color: theme.textSecondary }}
              className="font-semibold px-2 py-3"
            >
              Thành tiền:
            </p>
            <p
              style={{ color: theme.textPrimary }}
              className="text-base leading-5 font-bold px-2 py-3"
            >
              {formatCurrency(selectedItem.total_money)}
            </p>
          </div>
        </div>

        {/* Payment */}
        <div
          className="w-full rounded-xl"
          style={{ boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.16)" }}
        >
          <div className="mx-10 py-4 space-y-4 border-b border-dashed border-grey-5">
            {orderData.info.table_name && (
              <div className="flex justify-between items-center">
                <p className=" text-base leading-6 font-medium">Bàn:</p>
                <p className="text-base leading-6 font-semibold">
                  {orderData.info.table_name}
                </p>
              </div>
            )}
            <div className="flex justify-between items-center">
              <p className=" text-base leading-6 font-medium">customer:</p>
              <CustomButton outline title={orderData.info.name} />
            </div>
            <div className="flex justify-between items-center">
              <p className=" text-base leading-6 font-medium">
                Thời gian tạo đơn:
              </p>
              <TimeCreate date={orderData.info.updated_at * 1000} />
            </div>
          </div>

          <div className="mx-10 py-4 space-y-4">
            <div className="flex justify-between items-center">
              <p className="text-base leading-6 font-medium">
                Phương thức thanh toán:
              </p>
              {isPaid && (
                <p className="text-base leading-6 font-bold">
                  {formatPaymentMethod(orderData.info.payment_method)}
                </p>
              )}
            </div>
            {isPaid && orderData.info.payment_method == 1 && (
              <div className="grid grid-cols-2 gap-4">
                <Session title="Số tiền đã nhận">
                  {orderData.info?.receive_amount != null &&
                    formatCurrency(orderData.info?.receive_amount)}
                </Session>
                <Session title="Tiền thừa">
                  {orderData.info?.return_amount != null &&
                    formatCurrency(orderData.info?.return_amount)}
                </Session>
              </div>
            )}
          </div>
        </div>

        <div className={`flex gap-6`}>
          {!orderData.info.table_name && !isPaid && (
            <CustomButton
              style={{ width: 240 }}
              title="Cập nhật"
              onClick={() =>
                history.push(`/sell-table/order/${selectedItem.order_id}`)
              }
            />
          )}
          <CustomButton
            style={{ width: 240 }}
            title="In đơn hàng"
            onClick={() => {
              setShowPrintInvoice(true);
              setTimeout(handlePrint, 500);
            }}
          />
          {isPaid && (
            <CustomButton
              style={{ width: 240 }}
              title="Xuất hóa đơn"
              outline
              onClick={() => setExportInvoice(true)}
            />
          )}
        </div>
      </div>

      {showPrintInvoice && (
        <div className="absolute" style={{ top: "-500%" }}>
          <PrintInvoice data={orderData} />
        </div>
      )}

      {exportInvoice && (
        <ModalExport
          setShowModal={setExportInvoice}
          setShowPopupSuccess={setShowPopupSuccess}
          selectedItem={selectedItem}
        />
      )}
      {showPopupSuccess && (
        <Popup
          icon={success}
          content={"Xuất hoá đơn tạm Success"}
          showBtn={false}
          setShowPopup={setShowPopupSuccess}
        />
      )}
    </div>
  );
};

export default ModalDetail;
