import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import Session from "../company-profile/Session";
import FullSizeImage from "./FullSizeImage";

const ModalDetail = ({ title = "Chi tiết", setShowModal, selectedItem }) => {
  // console.log(">>> check item: ", selectedItem);
  const [imgFullSize, setImgFullSize] = useState(null);

  return (
    <>
      {imgFullSize && <FullSizeImage src={imgFullSize} setImgFullSize={setImgFullSize} />}

      <div
        style={{ zIndex: 42 }}
        className="fixed inset-0 bg-blur flex justify-center items-center"
      >
        <div
          style={{ width: 800 }}
          className="relative bg-white p-12 rounded-2xl flex flex-col gap-6 items-center"
        >
          {/* Close Icon */}
          <IoCloseSharp
            size={24}
            onClick={() => setShowModal(false)}
            style={{ position: "absolute", top: 16, right: 16, cursor: "pointer" }}
          />

          {/* Title */}
          <h1 className="text-dark-primary text-2xl leading-8 font-bold">{title}</h1>

          {/* Fields */}
          <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-col md:flex-row gap-6">
              <Session title="Cửa hàng" children={selectedItem.store_name} />
              {selectedItem.content && <Session title="Nội dung" children={selectedItem.content} />}
            </div>
            <div className="w-full flex flex-col md:flex-row gap-6">
              <Session title="Mô tả" children={selectedItem.description} />
              {selectedItem.type != "check" && (
                <Session title="Mã EDC" children={selectedItem.serial_number || ""} />
              )}
            </div>
            <div className="space-y-2">
              <div className="text-text-secondary leading-5 font-semibold">Hình ảnh</div>
              <div
                style={{ maxHeight: 140 }}
                className="flex flex-wrap gap-3 p-3 rounded-xl border border-gray-primary overflow-y-scroll custom-scrollbar "
              >
                {selectedItem.images.map((src, index) => (
                  <div key={index}>
                    <div
                      onClick={() => setImgFullSize(src)}
                      style={{ width: 116, height: 116 }}
                      className="flex-shrink-0 hover:opacity-75 transition flex items-center justify-center rounded overflow-hidden border border-gray-primary cursor-pointer"
                    >
                      <img src={src} alt="err img" className=" object-contain " draggable={false} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalDetail;
