import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Centered from "./centered";
import Empty from "./empty";
import LoginApp from "../pages/login";
import Auth from "./auth";
import OnboardingLayout from "./onboarding";
import Layout from "./layout";

const Layouts = ({ children }) => {
  let location = useLocation();
  let { pathname } = { ...location };

  // if (["/contact-us-1", "/logout", "/reset-password", "/forgot-password"].includes(pathname)) {
  //   return <Centered>{children}</Centered>;
  // }

  // if (['/login-test', '/forgot-password-test', '"/reset-password"-test'].includes(pathname)) return <Auth>{children}</Auth>
  if (pathname == "/e-invoice") {
    return <Empty>{children}</Empty>;
  } else if (pathname === "/onboarding") {
    return <OnboardingLayout> {children}</OnboardingLayout>;
  } else if (
    [
      "/login-1",
      "/contact-us-1",
      "/create-account",
      "/email-confirmation",
      "/logout",
      // "/reset-password",
      // "/forgot-password",
      "/lock-screen",
      "/subscribe",
      "/error-page",
      "/coming-soon",
    ].includes(pathname)
  ) {
    return <Centered>{children}</Centered>;
  } else if (
    [
      "/landing",
      "/login",
      "/signup",
      "/verify-email",
      "/forgot-password",
      "/reset-password",
    ].includes(pathname)
  ) {
    return <Auth>{children}</Auth>;
  } else {
    return <Layout>{children}</Layout>;
  }
};

export default Layouts;
