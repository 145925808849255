// Custom components
import React, { useState } from "react";
import { handleToastErr } from "../../utils/lib";

function InputCustom(props) {
  const {
    register,
    isRequired,
    setValue,
    value,
    type,
    label,
    placeholder,
    id,
    name,
    state,
    extra,
    errors,
    variant,
    disabled,
    readOnly,
    onBlur,
    onFocus,
    onUpdate,
    pattern,
    defaultValue,
    inputStyle,
    itemRight,

    icon,
  } = props;

  return (
    <div className={`${extra} font-monte`}>
      {label && (
        <div className={`mb-2 flex items-center gap-1 `}>
          <label
            htmlFor={id}
            style={{ color: "#313131" }}
            className={`${
              disabled
                ? "text-hint "
                : variant === "secondary"
                ? "text-text-secondary "
                : "text-text-label "
            } text-sm leading-5 font-semibold`}
          >
            {label}
          </label>
          {isRequired && (
            <span className="font-semibold " style={{ color: "#F5222D" }}>
              *
            </span>
          )}
          {icon && (
            <span className="">
              <img src={icon} alt="icon" />
            </span>
          )}
        </div>
      )}

      <div style={{ gap: 6 }} className={` relative flex items-center`}>
        <input
          defaultValue={defaultValue}
          onChange={(e) => {
            const value =
              name === "price"
                ? e.target.value.toString().length > 13
                  ? e.target.value.toString().substring(0, 13)
                  : e.target.value
                : e.target.value;

            setValue(name, value);
            onUpdate && onUpdate(name, value);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          {...register(name, {
            required: {
              message: handleToastErr(name),
            },
          })}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          name={name}
          type={type}
          id={id}
          pattern={pattern && pattern}
          placeholder={placeholder}
          onKeyDown={(evt) => {
            if (type === "text" && variant === "tax_no") {
              const regexPattern = /^[0-9-]+$/;

              // Allow certain keys like backspace
              const isAllowedKey =
                evt.key === "Backspace" || regexPattern.test(evt.key);

              // Prevent the default action if the key is not allowed
              !isAllowedKey && evt.preventDefault();
            } else if (type === "number") {
              ["e", "E", "+", ".", ",", "-"].includes(evt.key) &&
                evt.preventDefault();
            }
          }}
          onPaste={(e) => {
            const pastedContent = e.clipboardData.getData("Text");
            type == "percent" &&
              !/^[0-9]\d*\.?\d*$/.test(pastedContent) &&
              e.preventDefault();
          }}
          onWheel={(event) => type === "number" && event.currentTarget.blur()}
          //style={{ color: inputValue ? "#313131" : "#6A6A6C" }}
          style={{ padding: "10px 16px", height: " 56px", color: "#313131" }}
          className={`${
            disabled
              ? "opacity-75 text-hint"
              : state === "error"
              ? "text-error border-error"
              : variant === "secondary"
              ? "text-grey-atom-grey border-gray-primary"
              : "text-dark-primary border-gray-primary"
          }
                    ${readOnly && "opacity-50"}
          ${type === "radio" ? "h-auto cursor-pointer" : ""}
         
         ${inputStyle} flex w-full text-14px  items-center justify-center rounded-md border font-normal leading-5 outline-none placeholder-hints`}
        />
        {itemRight && itemRight}
      </div>
      {errors[name] && (
        <p className="text-xs font-normal mt-2 text-error">
          {errors[name].message}
        </p>
      )}
    </div>
  );
}

export default InputCustom;
