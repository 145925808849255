import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
// import Loader from "../../components/loader";
import Sidebar from "../../components/sidebar";
// import Navbar1 from "../../components/navbar-1";
import "../../css/layouts/layout-1.css";
import Navbar from "../../components/navbar";

const Layout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { config, palettes } = useSelector(
    (state) => ({
      config: state.config,
      palettes: state.palettes,
    }),
    shallowEqual
  );
  const { layout, collapsed } = { ...config };
  let { background, navbar, logo, leftSidebar, rightSidebar } = {
    ...palettes,
  };

  return (
    <div
      data-layout={layout}
      data-collapsed={collapsed}
      data-background={background}
      data-navbar={navbar}
      data-logo={logo}
      data-left-sidebar={leftSidebar}
      data-right-sidebar={rightSidebar}
      className={`${background === "dark" ? "dark-mode" : "default-mode"}`}
    >
      {/* <Loader /> */}
      <div className="flex bg-grey-50">
        <Sidebar show={showSidebar} setShow={setShowSidebar} />

        <div
          style={{ flex: 1, height: "calc(100vh)" }}
          className="bg-grey-50 text-grey-900 dark:bg-grey-900 dark:text-white overflow-auto"
        >
          {/* <div className="shadow-md">
            
          </div> */}
          <Navbar />

          <div
            style={{
              minHeight: "calc(100vh - 92px)",
              height: "calc(100vh - 92px)",
            }}
            className="w-full overflow-y-auto custom-scrollbar-1 pt-6 pb-3 md:pr-6 md:pl-28px"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
