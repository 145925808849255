import { createSlice } from "@reduxjs/toolkit";
import { verifyBankWithOTP } from "../../actions/bank/bank.actions";

const initialState = {
  isLoading: false,
  isTextFail: false,
};

const verifyBankOtpSlice = createSlice({
  name: "verifyBankOtpSlice",
  initialState,
  reducers: {
    setIsTextOtpFail: (state, action) => {
      state.isTextFail = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(verifyBankWithOTP.pending, (state, action) => {
        state.isLoading = true;
        state.isTextFail = false;
      })
      .addCase(verifyBankWithOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isTextFail = false;
      })
      .addCase(verifyBankWithOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isTextFail = true;
      }),
});
export default verifyBankOtpSlice.reducer;

export const selectOtpLoading = (state) => state.verifyBankWithOtp.isLoading;
export const selectIsOtpTextFail = (state) => state.verifyBankWithOtp.isTextFail;
export const { setIsTextOtpFail } = verifyBankOtpSlice.actions;
