import { createAsyncThunk } from "@reduxjs/toolkit";
import { OnchainTXService } from "../../services/report/onchain-tx.service";

export const getTransactionOnchain = createAsyncThunk("onchain-tx/getTransactionOnchain",
    async (data, { dispatch }) => {
        try {
            const _data = await OnchainTXService.getListTxOnchain(data);
            return _data;
        } catch (e) { 
            console.log(e)
        }
       
    }


)