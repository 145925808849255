import { createAsyncThunk } from "@reduxjs/toolkit";
import { inventoryManagerServices } from "../services";

export const getListInventory = createAsyncThunk(
  "store/order-manager/getListInventory",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await inventoryManagerServices.getListInventory(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInventory = createAsyncThunk(
  "store/order-manager/createInventory",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await inventoryManagerServices.createInventory(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInventory = createAsyncThunk(
  "store/order-manager/updateInventory",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await inventoryManagerServices.updateInventory(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInventory = createAsyncThunk(
  "store/order-manager/deleteInventory",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await inventoryManagerServices.deleteInventory(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
