import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListStore } from "../../actions/metadata/metadata.actions";
import { selectListStore } from "../../reducers/metadata/metadata.reducer";

import SelectInPaymentField from "../fields/SelectInPaymentField";
import SelectDateField from "../fields/select-date/SelectDateField";

const Filters = ({
  filters,
  setFilters,
  statusOptions = ["success", "fail", "pending"],
  hasSelectPayment,
}) => {
  const [docElem, setDocElem] = useState(null);
  const [resetFilters, setResetFilters] = useState(false);
  const inputBillRef = useRef(null);

  const dispatch = useDispatch();
  const store = useSelector(selectListStore);
  const setStatus = (status) => setFilters({ ...filters, status });
  const setStore = (store_id) => setFilters({ ...filters, store_id: store_id || "" });
  const setPaymentPlatform = (payment_platform_id) =>
    setFilters({ ...filters, payment_platform: payment_platform_id || "" });

  const dataStatus = ["all"].concat(statusOptions).map((item) => ({
    label: item,
    value: item.toLowerCase(),
  }));

  const dataPaymentPlatform = ["POS", "Checkout", "SoftPOS"].map((item) => ({
    label: item,
    value: item.toLowerCase(),
  }));

  const dataStore = useMemo(() => {
    if (!store?.data) return [];
    console.log("data: ", store?.data);
    return [
      { label: "All", value: "" },
      ...store?.data.map((store) => ({
        label: store.name,
        value: store.id,
      })),
    ];
  }, [store]);

  useEffect(() => {
    dispatch(getListStore({ page_size: 10000 }));
  }, [dispatch]);

  useEffect(() => {
    if (filters.bill_id == undefined) {
      setFilters({ ...filters, bill_id: "" });
      inputBillRef.current.value = "";
      setResetFilters(!resetFilters);
    }
  }, [filters]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      const outside =
        !document.getElementById("button-filter")?.contains(e.target) &&
        !document.getElementById("filter")?.contains(e.target);
      setDocElem(e.target);
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={`lg:grow flex flex-col md:flex-row md:flex-wrap gap-4 min-1620:gap-x-10 relative z-10`}>
      {/* Select Date */}
      <div className="space-y-1">
        <SelectDateField label={"From date - To date"} filters={filters} setFilters={setFilters} />
      </div>

      {/* Select Status */}
      <div
        style={{ width: 170 }}
        className={`space-y-1 ${dataStatus.length == 1 && "pointer-events-none opacity-50"}`}
      >
        <p className="text-blackgray text-sm leading-5 font-semibold">Status</p>
        <SelectInPaymentField
          data={dataStatus}
          placeHolder="All"
          hasSearch={false}
          setValue={setStatus}
          resetFlag={resetFilters}
          docElem={docElem}
        />
      </div>

      {/* Select Store */}
      <div style={{ width: 170 }} className="space-y-1">
        <p className="text-blackgray text-sm leading-5 font-semibold">Name of the store</p>
        <SelectInPaymentField
          data={dataStore}
          placeHolder="All"
          setValue={setStore}
          resetFlag={resetFilters}
          docElem={docElem}
        />
      </div>

      {/* Payment Platform */}
      {hasSelectPayment && (
        <div style={{ width: 271 }} className="space-y-1">
          <p className="text-blackgray text-sm leading-5 font-semibold">Channel</p>
          <SelectInPaymentField
            data={dataPaymentPlatform}
            placeHolder="Select channel"
            setValue={setPaymentPlatform}
            resetFlag={resetFilters}
            docElem={docElem}
          />
        </div>
      )}

      {/* Input Bill ID */}
      <div style={{ width: 210 }} className="space-y-1 w-[210px]">
        <p className="text-blackgray text-sm leading-5 font-semibold">Invoice code</p>
        <input
          ref={inputBillRef}
          onChange={(e) => setFilters({ ...filters, bill_id: e.target.value })}
          style={{ padding: "10px 16px" }}
          placeholder="Enter the invoice code"
          className="rounded-lg border border-gray-primary w-full text-blackgray text-sm leading-6 font-medium placeholder-hint input-invoice-code"
        />
      </div>
    </div>
  );
};

export default Filters;
