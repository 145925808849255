import { createAsyncThunk } from "@reduxjs/toolkit";
import { employeeServices } from "../../services/employee/employee.service";

export const getEmployeeById = createAsyncThunk(
  "employee/getEmployee",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await employeeServices.getEmployeeById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEmployee = createAsyncThunk(
  "employee/getListEmployee",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await employeeServices.createEmployee(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await employeeServices.updateEmployee(data);
      console.log(">>> data: ", _data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEmployeeById = createAsyncThunk(
  "employee/deleteEmployeeById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await employeeServices.deleteEmployeeById(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
