export const themes = {
  kfc: {
    primary: "#ED1C24",
    sidebar: "#AD1112",
    sidebarHover: "#93090B",
    selectHover: "#ED1C241A",
    textPrimary: "#262626",

    textSecondary: "#434C76",
    error: "#FF4D4F",
    favIcon: "/images/kfc/favIcon.png",
    loginBg: "/images/kfc/loginBg.png",
    loginLogo: "/images/kfc/loginLogo.png",
    sidebarLogo: "/images/kfc/sidebarLogo.png",
    banner: "/images/kfc/banner.png",
    discountWeb: "",
  },

  // VPBANK
  vpbank: {
    // primary: "#0A9948",
    // sidebar: "#00B74F",
    primary: "#00B74F",
    sidebar: "linear-gradient(358deg, #015AAD -12.36%, #00B74F 98.12%)",
    sidebarHover: "rgba(0, 0, 0, 0.16)",
    selectHover: "#0A99481A",
    textPrimary: "#434C76",
    textSecondary: "#B9BAC4",
    error: "#F5222D",
    favIcon: "/images/vpbank/favIcon.png",
    loginBg: "/images/vpbank/loginBg.png",
    loginLogo: "/images/vpbank/loginLogo.png",
    sidebarLogo: "/images/vpbank/sidebarLogo.png",
    banner: "/images/vpbank/banner.png",
    discountWeb: "https://www.vpbank.com.vn/uu-dai",
  },

  // OCB
  ocb: {
    primary: "#008F3C",
    sidebar: "linear-gradient(178deg, #0B701D -0.88%, #6BCC48 99.52%)",
    sidebarHover: "#005E28",
    selectHover: "#08813C10",
    textPrimary: "#262626",
    textSecondary: "#BFBFBF",
    error: "#F5222D",
    favIcon: "/images/ocb/favIcon.png",
    loginBg: "/images/ocb/loginBg.png",
    loginLogo: "/images/ocb/loginLogo.png",
    sidebarLogo: "/images/ocb/sidebarLogo.png",
    banner: "/images/ocb/banner.png",
    discountWeb: "https://www.ocb.com.vn/vi/tin-tuc-su-kien/uu-dai#ca-nhan",
  },

  // BVB
  bvb: {
    primary: "#123985",
    sidebar: "#123985",
    sidebarHover: "#0F3070",
    selectHover: "#ECEDF1",
    textPrimary: "#262626",
    textSecondary: "#B9BAC4",
    error: "#F5222D",
    favIcon: "/images/bvb/favIcon.png",
    loginBg: "/images/bvb/loginBg.png",
    loginLogo: "/images/bvb/loginLogo.png",
    sidebarLogo: "/images/bvb/sidebarLogo.png",
    banner: "/images/bvb/banner.png",
    discountWeb: "https://bvbank.net.vn/",
  },

  pos25: {
    primary: "#1B92DB",
    sidebar: "linear-gradient(229.96deg, #2B3DB9 0%, #0CD6F7 136.09%)",
    sidebarHover: "#00000012",
    selectHover: "#ECEDF1",
    textPrimary: "#313131",

    textSecondary: "#B9BAC4",
    error: "#F5222D",
    favIcon: "/images/pos25/favIcon.png",
    loginBg: "/images/pos25/loginBg.png",
    loginLogo: "/images/pos25/loginLogo.png",
    sidebarLogo: "/images/pos25/sidebarLogo.png",
    sideBarLogoScaleDown: "/images/pos25/sideBarLogoScale.svg",
    banner: "/images/pos25/banner.png",
    onboardingLogo: "/images/pos25/onboardingBg",
    discountWeb: "",
  },
};

export const theme =
  themes[localStorage.getItem("theme") || process.env.REACT_APP_DEFAULT_THEME];
