import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TbFilterDollar } from "react-icons/tb";
import { getListTransMoMo } from "../../actions/index.js";
import CustomButton from "../../components/buttons/CustomButton.js";
import Filters from "../../components/filters/Filters.js";
import SectionTitle from "../../components/section-title/index.js";
import Datatable, { StatusCell } from "../../components/tsdatatable/datatables.js";
import { selectTransMoMo } from "../../reducers/transaction.reducer.js";
import { formatCurrency, formatDateTxn } from "../../utils/lib.js";
import useWindowSize from "../../hooks/useWindowSize.js";

const DataTableComponent = ({ customData, filters, numOfPage, data, setFilters, total_amount }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Time",
        accessor: "created_at",
        Cell: ({ value, row }) => <p>{formatDateTxn(value)}</p>,
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Invoice code",
        accessor: "bill_id",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value, row }) => <span>{formatCurrency(value)}</span>,
      },
      {
        Header: "Transaction status",
        accessor: "status",
        Cell: ({ value, row }) => <StatusCell data={row} />,
      },
      {
        Header: "Transaction type",
        accessor: "transaction_type",
        Cell: ({ value, row }) => <span>{value || "Momo"}</span>,
      },
    ],
    [],
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
      total_amount={total_amount}
    />
  );
};

const TransactionsWallet = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectTransMoMo);
  const [showFilter, setShowFilter] = useState(false);
  const [numOfPage, setNumOfPage] = useState("1");
  const { isMd } = useWindowSize();

  // GMT +7
  const currentTime = new Date(); // hardcode the current date to show data
  const [y, m, d] = [currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate()];
  const startTime = new Date(y, m, d);
  const endTime = new Date(y, m, d, 23, 59, 59);
  // console.log('>>>> log check start and end time: ', startTime, '---', endTime)

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
    status: "all",
    // start_time: Math.floor(startTime.getTime() / 1000),
    // end_time: Math.floor(endTime.getTime() / 1000),
    start_time: "",
    end_time: "",
    bill_id: "",
    customer_id: "",
  });

  const [tempFilters, setTempFilters] = useState({
    page: 1,
    page_size: 10,
    status: "all",
    // start_time: Math.floor(startTime.getTime() / 1000),
    // end_time: Math.floor(endTime.getTime() / 1000),
    start_time: 0,
    end_time: 0,
    bill_id: "",
    customer_id: "",
  });

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page);
    return data?.data;
  }, [data]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
          return {
            ...e,
            request_amount: formatCurrency(e.request_amount),
            original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
              "HH:mm:ss DD-MM-YYYY",
            ),
          };
        })
      : [];
  };

  useEffect(() => {
    dispatch(getListTransMoMo(filters));
  }, [dispatch, filters]);

  return (
    <div className="relative">
      <div className="flex items-center justify-between">
        <SectionTitle title="Report" subtitle="Wallet transactions" />
        <CustomButton
          onClick={() => setShowFilter(!showFilter)}
          className="block md:hidden"
          outline
          leftIcon={<TbFilterDollar size={16} />}
        />
      </div>
      <div
        style={{
          border: "1px solid #F5F7FA",
          boxShadow: "0px 4px 20px 0px rgba(96, 111, 134, 0.06)",
          top: !isMd && 32,
          zIndex: showFilter || isMd ? 10 : -10,
        }}
        className="absolute right-0 md:relative flex gap-4 md:gap-0 flex-col md:flex-row md:justify-between items-end bg-white rounded-xl mt-6 p-6"
      >
        <Filters filters={tempFilters} setFilters={setTempFilters} />
        <div className="grid grid-cols-2 w-full md:w-auto gap-4">
          <CustomButton
            filters={filters}
            setFilters={(params) => {
              setFilters(params);
              setTempFilters(params);
            }}
            type="reset"
            title={"Reset"}
          />
          <CustomButton
            filters={tempFilters}
            setFilters={setFilters}
            type="apply"
            title={"Apply"}
          />
        </div>
      </div>

      <div className="mt-6">
        <DataTableComponent
          customData={formatData(customData)}
          filters={filters}
          setFilters={setFilters}
          numOfPage={numOfPage}
          data={data}
          total_amount={data?.total_amount}
        />
      </div>
    </div>
  );
};
export default TransactionsWallet;
