import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import IcWarn from "../../../../../assets/images/icon/ic_warn.svg";
import CustomButton from "../../../../../components/buttons/CustomButton";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import { history } from "../../../../../helpers";

const ModalOffline = ({ setOpenModal }) => {
  return (
    <ModalWrapper>
      <IoCloseSharp
        className="absolute cursor-pointer hover:opacity-75"
        style={{ right: 16, top: 16 }}
        onClick={() => {
          setOpenModal(false);
        }}
        size={20}
      />

      <div className=" mx-auto flex max-w-md flex-col gap-8 rounded-2xl px-6 py-8">
        <div className="flex flex-col items-center justify-center gap-6">
          <div>
            <img src={IcWarn} alt="ic_warn" />
          </div>
          <div style={{ gap: 10 }} className="flex flex-col  text-center text-black font-normal">
            <h1 className="text-xl font-bold">"Mất kết nối"</h1>
            <p className="text-sm text-grey-9 font-normal">
              Vui lòng liên hệ bộ phận hổ trợ kỹ thuật.
            </p>
            <p className="text-sm text-grey-9 font-normal">“1900 3353”</p>
          </div>
        </div>
        <CustomButton
          title={"Trở về màn hình chính "}
          onClick={() => {
            setOpenModal(false);
            history.push("/sell-table");
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default ModalOffline;
