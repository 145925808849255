import axiosClient2 from "./client_axios2";
import axiosClient from "./axios/app-client";
import { INVOICE_MANAGER } from "./endpoint";

export const invoiceManagerServices = {
  getListInvoice: (params) => {
    return axiosClient2.get(INVOICE_MANAGER, { params });
  },

  createInvoice: (params) => {
    return axiosClient2.post(INVOICE_MANAGER, params);
  },

  updateInvoice: (params) => {
    return axiosClient2.put(`${INVOICE_MANAGER}/${params.code}`, params);
  },

  deleteInvoice: (params) => {
    return axiosClient2.delete(`${INVOICE_MANAGER}/${params}`);
  },

  getStatusPos: (params) => {
    const query = new URLSearchParams(params);
    const res = axiosClient.get(`/pos/check-status?${query}`);
    return res;
  },

  exportInvoice: (params) => {
    return axiosClient2.post(`${INVOICE_MANAGER}/export`, params);
  },
};
