import { createSlice } from "@reduxjs/toolkit"
import { getListInvoice, createInvoice, updateInvoice } from "../actions/invoice-manager.actions"

const initialState = {
    listInvoice: [],
    isLoading: false,
}

const invoiceManagementSlice = createSlice({
    name: 'invoiceManagementSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getListInvoice.fulfilled, (state, action) => {
            state.listInvoice = action.payload;
        })
        .addCase(getListInvoice.rejected, (state, action) => {
            state.listInvoice = [];
        })
    }
})

export const selectListInvoice = state => state.invoiceManagement.listInvoice;
export const selectIsLoading = state => state.invoiceManagement.isLoading;

export default invoiceManagementSlice.reducer;