import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { getHolderName, verifyBank } from "../../actions/bank/bank.actions";
import CustomButton from "../../components/buttons/CustomButton";
import InputField from "../../components/fields/InputField";
import SelectBankField from "../../components/fields/SelectBankField";
import useDebounce from "../../hooks/useDebounce";
import { toastConfirmFormBankFail, toastMessage } from "../../utils/constants";
import { ERROR_CODE } from "../../utils/errorCode";

const FORM = {
  BANK_CODE: "bank_code",
  BANK_ACCOUNT_NO: "bank_account_no",
  HOLDER_NAME: "holder_name",
};

const ModalCreateBank = ({ handleClose, handleSuccess }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const debounce = useDebounce(watch(FORM.BANK_ACCOUNT_NO));

  const onFormSubmit = async (value) => {
    setLoading(true);

    try {
      const res = await dispatch(verifyBank(value));
      console.log(res);
      if (res?.meta?.requestStatus === "fulfilled") {
        handleSuccess();
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          toastConfirmFormBankFail();
          return;
        }
        toastMessage.error(msg);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onErrors = (errors) => console.error(errors);

  const getDataHolderName = async () => {
    try {
      const res = await dispatch(
        getHolderName({
          bank_code: watch(FORM.BANK_CODE),
          bank_account_no: debounce,
        }),
      );
      if (res?.meta?.requestStatus === "fulfilled" && res?.payload?.data !== "") {
        setValue(FORM.HOLDER_NAME, res?.payload?.data?.trim());
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!debounce.trim()) {
      setValue(FORM.HOLDER_NAME, "");
      return;
    }
    getDataHolderName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  return (
    <div className="fixed z-50 inset-0 bg-blur overflow-auto flex justify-center items-center">
      <div
        style={{ boxShadow: "0px 8px 11px -3px #00000014" }}
        className="mx-auto relative w-full md:w-128 rounded-2xl bg-white p-12  lg:w-fit "
      >
        <h2 style={{ color: "#313131" }} className="mb-8 text-center text-2xl font-bold ">
          Add a bank account
        </h2>
        <button onClick={handleClose} style={{ top: 16, right: 16 }} className="absolute">
          <IoCloseSharp size={24} />
        </button>
        <form onSubmit={handleSubmit(onFormSubmit, onErrors)} className="col flex flex-col">
          <div className="flex flex-col space-y-4">
            <SelectBankField
              isRequired
              register={register}
              name={FORM.BANK_CODE}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              isRequired
              setValue={setValue}
              type="number"
              label="Account number"
              placeholder="Select account number"
              id={FORM.BANK_ACCOUNT_NO}
              name={FORM.BANK_ACCOUNT_NO}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              readOnly
              isRequired
              setValue={setValue}
              value={watch(FORM.HOLDER_NAME)}
              defaultValue={watch(FORM.HOLDER_NAME)}
              label="Account name"
              placeholder="--"
              id={FORM.HOLDER_NAME}
              name={FORM.HOLDER_NAME}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>

          <div style={{ marginTop: "42px" }} className="grid grid-cols-2 gap-4">
            <CustomButton onClick={handleClose} title="Cancel" outline />

            <CustomButton
              type="submit"
              isLoading={loading}
              disabled={!watch(FORM.BANK_ACCOUNT_NO) || !watch(FORM.HOLDER_NAME)}
              title="Connect"
              style={{ backgroundImage: "linear-gradient(36deg, #2B3DB9 2.93%, #0CD6F7 102.74%)" }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalCreateBank;
