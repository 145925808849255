
import React, { useEffect } from 'react'
import Card from '../../components/digitalbill/Card'
import SectionTitle from '../../components/section-title'
import CardStartup from '../../components/digitalbill/Card/Startup'
import CardPro from '../../components/digitalbill/Card/Pro'
import { history } from '../../helpers'

const DigitalBill = () => {
    useEffect(() => {
        if (window.localStorage.getItem('giftCodeInvoice')) {
            history.push('/e-invoice')
        }
    }, [])

    return (
        <div className='relative w-full h-full'>
            <SectionTitle title={"Gói hoá đơn điện tử"} subtitle={"Gói hoá đơn điện tử"} />
            <div style={{
                background: '#fff',
                padding: 40,
                width: "100%",
                height: "100%",
                display: "flex",
                "justifyContent": "center",
                marginTop: 32
            }}>
                <div className="max-w-[1280px] mx-atuo">

                    <div className='grid md:grid-cols-3 gap-4 lg:gap-10' >
                        <CardStartup />
                        <CardPro />
                        <Card />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default DigitalBill
