import React, { useEffect, useState } from "react";
import { ButtonBorderRed, ButtonRed } from "../Common";
import {
  createCustomer,
  getDistricts,
  getProvinces,
  getWards,
  updateCustomerOfOrder,
} from "../../../../services/inventory-manager.service";
import { isValidPhoneNumber } from "../../../../utils/lib";
import arrow from "../../../../assets/images/product/arrow-down.svg";
import { useParams } from "react-router-dom";
import { CustomToastMessage } from "../../../../utils/customToastMsg";

const Step2 = ({
  phoneNumber,
  setPhoneNumber,
  setStep,
  setCustomer,
  handleBack,
}) => {
  const { order_id } = useParams();
  const [form, setForm] = useState();
  const [name, setName] = useState("");
  const [phoneAlert, setPhoneAlert] = useState(false);
  const [nameAlert, setNameAlert] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [indexExpand, setIndexExpand] = useState(-1);

  const data = [
    {
      id: 0,
      title: "Tỉnh/ Thành phố",
      data: provinces,
    },
    {
      id: 1,
      title: "Quận/ Huyện",
      data: districts,
    },
    {
      id: 2,
      title: "Phường/ Xã",
      data: wards,
    },
  ];

  const handleUpdateCustomer = (item) => {
    const timer = setTimeout(async () => {
      const res = await updateCustomerOfOrder(order_id, item.customer_id);
      if (res) {
        setCustomer(item);
        handleBack();
      }
    }, 2000);
    return () => clearTimeout(timer);
  };

  const fetchProvinces = async () => {
    const res = await getProvinces();
    setProvinces(res);
  };
  const fetchDistricts = async () => {
    const res = await getDistricts(form?.province.code);
    setDistricts(res);
  };

  const fetchWards = async () => {
    const res = await getWards(form?.district.code);
    setWards(res);
  };

  const handleCreateCustomer = async () => {
    if (name !== "" && phoneNumber !== "") {
      if (isValidPhoneNumber(phoneNumber)) {
        const res = await createCustomer({
          name: name,
          phone_number: phoneNumber,
          province_code: form?.province?.code,
          district_code: form?.district?.code,
          ward_code: form?.ward?.code,
          address: form?.address,
        });
        if (res) {
          CustomToastMessage.success("Success", `Tạo customer Success`);
          handleUpdateCustomer(res);
        } else {
          setPhoneAlert("Số điện thoại đã được đăng ký");
        }
      } else {
        setPhoneAlert("Số điện thoại không hợp lệ");
      }
    } else if (name === "") {
      setNameAlert("Tên customer không được bỏ trống");
    } else if (phoneNumber === "") {
      setPhoneAlert("Số điện thoại không được bỏ trống");
    }
  };

  useEffect(() => {
    fetchProvinces();
  }, []);

  useEffect(() => {
    if (form?.province) {
      fetchDistricts();
    }
  }, [form?.province]);

  useEffect(() => {
    if (form?.district) {
      fetchWards();
    }
  }, [form?.district]);

  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //         handleUpdateCustomer()
  //     }, 3000);
  //     return () => clearTimeout(timer);
  // }, []);

  const BoxSelect = ({ item }) => {
    const handleExpand = (id) => {
      setIndexExpand(id);
      if (indexExpand !== -1) {
        setIndexExpand(-1);
      }
    };

    const checkIndex = () => {
      return item.id === 0
        ? form?.province
        : item.id === 1
        ? form?.district
        : form?.ward;
    };

    return (
      <div className="relative">
        <button
          className="p-4 flex justify-between items-center rounded-lg border w-full cursor-pointer"
          style={{ borderColor: "#D9D9D9" }}
          onClick={() => handleExpand(item.id)}
          disabled={
            item.id === 1
              ? !form?.province
              : item.id === 2
              ? !form?.district
              : false
          }
        >
          <span
            className={` text-sm font-normal`}
            style={{ color: checkIndex()?.full_name ? "#262626" : "#8C8C8C" }}
          >
            {checkIndex()?.full_name || item?.title}
          </span>
          <div
            className={`${
              indexExpand === item.id ? "rotate-180" : "rotate-0"
            }  transition-all duration-700`}
          >
            <img src={arrow} alt="" />
          </div>
        </button>
        {item.id === indexExpand && (
          <div
            className="mt-2 border rounded-lg overflow-hidden overflow-y-scroll px-2 w-full bg-white z-30 absolute"
            style={{ borderColor: "#D9D9D9", height: "185px", top: "50px" }}
          >
            {item.data.map((i, index) => (
              <>
                {index !== 0 && (
                  <div
                    className="w-full"
                    style={{ height: "1px", backgroundColor: "#D9D9D9" }}
                  ></div>
                )}
                <div
                  key={index}
                  className="p-2 cursor-pointer"
                  onClick={() => {
                    setForm(
                      item.id === 0
                        ? { ...form, province: i }
                        : item.id === 1
                        ? { ...form, district: i }
                        : { ...form, ward: i }
                    );
                    setIndexExpand(-1);
                  }}
                >
                  {i?.full_name}
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <p className="text-black text-base font-semibold leading-5 text-center">
        Tạo customer mới
      </p>
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-black text-sm font-semibold leading-5">
            Tên customer<span style={{ color: "#ED1C24" }}>*</span>
          </p>
          <input
            type="text"
            placeholder="Nhập tên customer"
            className="create-form outline-none text-sm font-medium leading-5 rounded-2 mt-4 w-full"
            style={{
              padding: "10px 16px 10px 16px",
              border: "1px solid #E8E8E8",
              width: "336px",
            }}
            onChange={(e) => setName(e.target.value)}
          />
          {nameAlert && (
            <p
              className="text-sm font-normal leading-5 text-start mt-2"
              style={{ color: "#ED1C24" }}
            >
              {nameAlert}
            </p>
          )}
        </div>
        <div>
          <p className="text-black text-sm font-semibold leading-5">
            Số điện thoại<span style={{ color: "#ED1C24" }}>*</span>
          </p>
          <input
            type="tel"
            name="phone"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            placeholder="Nhập số điện thoại"
            value={phoneNumber}
            className="create-form outline-none text-sm font-medium leading-5 rounded-2 mt-2 w-full"
            style={{
              padding: "10px 16px 10px 16px",
              border: "1px solid #E8E8E8",
              width: "336px",
            }}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          {phoneAlert && (
            <p
              className="text-sm font-normal leading-5 text-start mt-2"
              style={{ color: "#ED1C24" }}
            >
              {phoneAlert}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-black text-sm font-semibold leading-5">
            Khu vực<span style={{ color: "#ED1C24" }}>*</span>
          </p>
          <>
            {data.map((item, index) => (
              <BoxSelect item={item} key={index} />
            ))}
            <input
              type="text"
              placeholder="Địa chỉ cụ thể"
              className="create-form outline-none text-sm font-medium leading-5 rounded-lg p-4 w-full"
              style={{ border: "1px solid #D9D9D9", width: "336px" }}
              onChange={(e) => setForm({ ...form, address: e.target.value })}
            />
          </>
        </div>

        <div className="flex gap-2">
          <div className="w-full h-12" onClick={() => setStep(0)}>
            <ButtonBorderRed content={"Quay lại"} />
          </div>
          <button
            className="w-full h-12"
            onClick={() => handleCreateCustomer()}
          >
            <ButtonRed content={"Tạo customer"} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Step2;
