import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosAdd } from "react-icons/io";
import { selectListPayment } from "../../reducers/payment-channel.reducer";
import { getListPayment, deletePChannel } from "../../actions/payment-channel";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { ERROR_CODE } from "../../utils/errorCode";

import SectionTitle from "../../components/section-title";
import Datatable from "../../components/tsdatatable/datatables";
import CreatePChannel from "./CreatePChannel";
import ModalDelete from "../../components/modals/ModalDelete";
import DetailPChannel from "./DetailPChannel";

import trash from "../../assets/images/payment/trash.svg";
import moreInfo from "../../assets/images/payment/moreInfo.svg";
import {
  formatBankAccount,
  formatVaType,
  formatDateTxn,
  formatChannel,
} from "../../utils/lib";
import CustomButton from "../../components/buttons/CustomButton";

const DataTableComponent = ({
  filters,
  numOfPage,
  data,
  setFilters,
  setSelectedChannel,
  setShowDetail,
  setShowFormDelete,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "VA",
        accessor: "va_type",
        Cell: ({ value }) => <p>{formatVaType(value)}</p>,
      },
      {
        Header: "VA Code",
        accessor: "va_account_no",
      },
      {
        Header: "Account",
        accessor: "bank_account_no",
        Cell: ({ value }) => <p>{value ? value : ""}</p>,
      },
      {
        Header: "Sale channel",
        accessor: "channel",
        Cell: ({ value }) => <div>{formatChannel(value)}</div>,
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Staff",
        accessor: "staff_id",
        Cell: ({ value }) => <div>{value || ""}</div>,
      },
      {
        Header: "Created date",
        accessor: "created_at",
        Cell: ({ value }) => <div>{formatDateTxn(value, false)}</div>,
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setSelectedChannel(row.original);
                  setShowDetail(true);
                }}
              />
              <img
                src={trash}
                alt="trash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setSelectedChannel(row.original);
                  setShowFormDelete(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    [setSelectedChannel, setShowDetail, setShowFormDelete]
  );

  return (
    <Datatable
      columns={columns}
      data={data}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      setFilters={setFilters}
      filters={filters}
      // total_request_amount={formatCurrency(data?.data?.total_request_amount)}
    />
  );
};

const PaymentChannel = () => {
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [showFormDelete, setShowFormDelete] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const dispatch = useDispatch();

  const data = useSelector(selectListPayment);
  const [numOfPage, setNumOfPage] = useState("1");

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    console.log(data?.data);
    return data?.data;
  }, [data]);

  const handleDelete = async (id) => {
    const res = await dispatch(deletePChannel({ data: { channel_id: id } }));
    if (res?.payload?.data) {
      CustomToastMessage.success(
        "Xoá ShopQR Success",
        `Bạn vừa xoá Success ShopQR ${selectedChannel.store_name}.`
      );
      dispatch(getListPayment());
    } else {
      CustomToastMessage.error(
        "Xoá ShopQR thất bại",
        ERROR_CODE[res.payload.error_code] || res.payload.msg
      );
    }
  };

  useEffect(() => {
    dispatch(getListPayment(filters));
  }, [dispatch, filters]);

  return (
    <>
      <div className="flex w-full justify-between items-center">
        <SectionTitle title="Pay" subtitle="VA Bank" />
        <CustomButton
          type="add"
          outline
          title={"Add"}
          onClick={() => setShowFormCreate(true)}
        />
      </div>

      <div className="mt-6">
        <DataTableComponent
          filters={filters}
          setFilters={setFilters}
          data={customData}
          numOfPage={numOfPage}
          setSelectedChannel={setSelectedChannel}
          setShowDetail={setShowDetail}
          setShowFormDelete={setShowFormDelete}
        />
      </div>

      {showFormCreate && (
        <CreatePChannel
          setShowForm={setShowFormCreate}
          getListPChannel={getListPayment}
        />
      )}
      {showFormDelete && (
        <ModalDelete
          title="Xóa ShopQR"
          desc={`Bạn có chắc chắn muốn xóa ShopQR ${selectedChannel.store_name}?`}
          setIsOpenModal={setShowFormDelete}
          item={selectedChannel}
          handleAction={handleDelete}
        />
      )}
      {showDetail && (
        <DetailPChannel
          selectedChannel={selectedChannel || {}}
          setShowDetail={setShowDetail}
        />
      )}
    </>
  );
};

export default PaymentChannel;
