import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import IcLoading from "../../../../../assets/images/icon/pos_awaiting.gif";
import CustomButton from "../../../../../components/buttons/CustomButton";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import { history } from "../../../../../helpers";

const ModalWaitingPos = ({ invoice, setOpenModal }) => {
  return (
    <ModalWrapper>
      <IoCloseSharp
        className="absolute cursor-pointer hover:opacity-75"
        style={{ right: 16, top: 16 }}
        onClick={() => {
          setOpenModal(false);
        }}
        size={20}
      />

      <div className=" mx-auto flex max-w-md flex-col gap-8 rounded-2xl px-6 py-8">
        <div className="flex flex-col items-center justify-center gap-6">
          <div style={{ width: "200px"}} className="mx-auto">
            <img src={IcLoading} alt="ic_warn" />
          </div>
          <div style={{ gap: 10 }} className="flex flex-col  text-center text-black font-normal">
            <h1 className="text-xl font-bold">"Chờ thanh toán"</h1>
            <p className="text-sm text-grey-3 font-normal">Mã hoá đơn: <span className="text-black font-bold">#IV_${invoice?.invoice_id}</span></p>
          </div>
        </div>

        <CustomButton
          title={"Trở về màn hình chính "}
          onClick={() => {
            setOpenModal(false);
            history.push("/sell-table");
          }}
        />

      </div>
    </ModalWrapper>
  );
};

export default ModalWaitingPos;
