import React from "react";

const Session = ({ title, children }) => {
  return (
    <div className="space-y-2 w-full overflow-hidden">
      <p className="text-blackgray text-sm leading-5 font-semibold">{title}</p>
      <div
        style={{ padding: "10px 16px" }}
        className="flex justify-between items-center rounded-lg border border-gray-primary w-full"
      >
        <div className="h-6 text-blackgray text-sm leading-6 font-medium truncate">{children}</div>
      </div>
    </div>
  );
};

export default Session;
