import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../helpers";
import {
  createCustomer,
  getListDistrict,
  getListProvince,
  getListWard,
} from "../../../actions/customer-manager.actions";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import { selectListProvince } from "../../../reducers/customer-management.reducer";
import * as yup from "yup";

import CustomButton from "../../../components/buttons/CustomButton";
import InputField from "../../../components/fields/InputField";
import SectionTitle from "../../../components/section-title";
import TableWrapper from "../../company-profile/TableWrapper";
import SelectInPaymentField from "../../../components/fields/SelectInPaymentField";

const FORM = {
  CUSTOMER_ID: "customer_id",
  CUSTOMER_CODE: "code",
  NAME: "name",
  PHONE_NUMBER: "phone_number",
  POINT: "point",

  COUNTRY_CODE: "country_code",
  TAX_NO: "tax_no",
  ADDRESS: "address",
  PROVINCE_CODE: "province_code",
  DISTRICT_CODE: "district_code",
  WARD_CODE: "ward_code",
};

const schema = yup.object().shape({
  [FORM.PHONE_NUMBER]: yup
    .string()
    .required("Please enter the phone number")
    .matches(/\S/, "There must be no space")
    .matches(
      /^(0[3-9][0-9]{8}|05[5|6|7|8|9][0-9]{7}|(84|\+84)[3-9][0-9]{8})$/,
      "Please enter the correct phone number"
    ),
});

const CreateCustomer = () => {
  const dispatch = useDispatch();
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState(null);
  const [ward, setWard] = useState(null);

  const { data: dataProvince } = useSelector(selectListProvince);
  const listProvince = dataProvince?.map((item) => {
    return { label: item.full_name, value: item.code };
  });
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [resetDistrict, setResetDistrict] = useState(false);
  const [resetWard, setResetWard] = useState(false);
  const [docElem, setDocElem] = useState(null);
  document.onclick = (e) => setDocElem(e.target);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (value) => {
    // console.log('>>> payload: ', value)
    try {
      const res = await dispatch(
        createCustomer({
          ...value,
          province_code: province,
          district_code: district,
          ward_code: ward,
        })
      );
      if (res?.payload) {
        CustomToastMessage.success(
          "Successfully",
          `You have successfully updated the client with the code ${res.payload?.code}`
        );
        history.push("/sell-customer");
      } else {
        CustomToastMessage.error(
          "Failed",
          `Please update the client again with the code ${value?.code}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled = !watch(FORM.NAME) || !watch(FORM.PHONE_NUMBER);

  useEffect(() => {
    dispatch(getListProvince());
  }, []);

  useEffect(() => {
    (async () => {
      const res = await dispatch(getListDistrict(province));
      if (res?.payload?.data) {
        setResetDistrict(!resetDistrict);
        setListDistrict(
          res?.payload?.data?.map((item) => {
            return { label: item.full_name, value: item.code };
          })
        );
      }
    })();
  }, [province]);

  useEffect(() => {
    (async () => {
      const res = await dispatch(getListWard(district));
      if (res?.payload?.data) {
        setResetWard(!resetWard);
        setListWard(
          res?.payload?.data?.map((item) => {
            return { label: item.full_name, value: item.code };
          })
        );
      }
    })();
  }, [district]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle title="Customer management" subtitle="Add customers" />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
          <div className="flex flex-col gap-y-6">
            <InputField
              isRequired
              setValue={setValue}
              label="Customer name"
              placeholder="Enter the customer's name"
              id={FORM.NAME}
              name={FORM.NAME}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              type="number"
              isRequired
              setValue={setValue}
              label="Phone number"
              placeholder=" Enter your phone number"
              value={watch(FORM?.PHONE_NUMBER) || " "}
              id={FORM?.PHONE_NUMBER}
              name={FORM?.PHONE_NUMBER}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputField
              readOnly
              label="Điểm thưởng"
              id={FORM?.POINT}
              name={FORM?.POINT}
              value={watch(FORM?.POINT) || " "}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
              disabled
            />
          </div>
          <div style={{ gap: 8 }} className="flex flex-col">
            <p className="text-sm leading-5 font-semibold">Area</p>
            <SelectInPaymentField
              placeHolder="Province/City"
              data={[].concat(listProvince)}
              setValue={setProvince}
              docElem={docElem}
            />
            <SelectInPaymentField
              placeHolder="District"
              data={[].concat(listDistrict)}
              setValue={setDistrict}
              resetFlag={resetDistrict}
              docElem={docElem}
            />
            <SelectInPaymentField
              placeHolder="Wards"
              data={[].concat(listWard)}
              setValue={setWard}
              resetFlag={resetWard}
              docElem={docElem}
            />
            <InputField
              setValue={setValue}
              placeholder="Địa chỉ cụ thể"
              id={FORM.ADDRESS}
              name={FORM.ADDRESS}
              value={watch(FORM.ADDRESS) || ""}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/sell-customer")}
              title="Hủy"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Lưu"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateCustomer;
