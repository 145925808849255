import React, { useEffect } from "react";
import ImgFireworks from "../../assets/images/verify/img-fireworks.svg";
import IconVerifyEmail from "../../assets/images/verify/icon-email-verify.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { verifySendEmail } from "../../actions";

const VerifyEmailPage = () => {
    const location = useLocation();
    const email = new URLSearchParams(location.search)?.get("email");
    const dispatch = useDispatch();
    const { isLoading, data, error } = useSelector(
        (state) => state.verify.verifySendEmail
    );

    const handleSendVerificationEmail = async () => {
        const params = {
            email: email,
        };
        if (isLoading) return;
        await verifySendEmail(params, dispatch);
    };

    useEffect(() => {
        if (data) {
            CustomToastMessage.success("Verification email sent successfully");
        } else if (error) {
            CustomToastMessage.error("Failed to send verification email");
        }
    }, [data, error]);

    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <div
                style={{
                    backgroundColor: "#FFFFFF66",
                }}
                className="relative w-full max-w-modal py-16 flex items-center justify-center rounded-2xl "
            >
                <img src={ImgFireworks} alt="Fireworks" className="absolute top-0" />
                <div className="flex flex-col justify-center items-center gap-10">
                    <div className="text-center">
                        <span className="text-blue-blue-primary text-4xl font-semibold font-montserrat leading-10">
                            Almost done!
                        </span>{" "}
                        <br />
                        <span className="text-secondary text-4xl font-semibold font-montserrat leading-10">
                            Check your inbox.
                        </span>
                    </div>

                    <span className="text-grey-grey-400 text-lg font-normal font-montserrat leading-relaxed text-center">
                        Just confirm the link we sent you to{" "}
                        <span className="font-bold">{email}</span> <br />
                        to verify your email address. It can take ~ 2-3 minutes.
                    </span>

                    <img src={IconVerifyEmail} alt="Verify Email" />

                    <span className="text-grey-grey-400 text-base font-normal font-montserrat leading-normal text-center">
                        Didn't receive your confirmation email?
                        <br />
                        <span
                            onClick={handleSendVerificationEmail}
                            className="text-blue-blue-primary hover:font-semibold cursor-pointer"
                        >
                            Click here
                        </span>{" "}
                        to receive a new one
                    </span>

                    <div
                        style={{
                            width: "573px",
                        }}
                        className="flex justify-center items-center gap-3"
                    >
                        <div
                            style={{
                                height: "1px",
                                backgroundColor: "#DEDEDE",
                            }}
                            className="flex-1"
                        ></div>
                        <span
                            style={{
                                lineHeight: "18px",
                            }}
                            className="text-secondary font-normal"
                        >
                            Or
                        </span>
                        <div
                            style={{
                                height: "1px",
                                backgroundColor: "#DEDEDE",
                            }}
                            className="flex-1"
                        ></div>
                    </div>

                    <button
                        style={{
                            borderColor: "#BDC6DE",
                            color: "#6A6A6C",
                            leading: "21px",
                        }}
                        type="button"
                        className="py-3 px-6 rounded-lg border text-sm font-semibold"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmailPage;
