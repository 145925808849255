import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";

const InputOtp = (props) => {
  const { setValueOTP, disabled } = props;

  const [otp, setOtp] = useState("");

  const inputStyle = {
    width: "40px",
    height: "40px",
    outlineColor: "#096DD9",
    border: "solid 1px #B2BCC2",
    borderRadius: "4px",
    margin: "24px 16px 24px 0",
    color: "#096DD9",
  };

  useEffect(() => {
    if (otp.length > 0) {
      setValueOTP(otp);
    }
  }, [otp, setValueOTP]);

  return (
    <OTPInput
      value={otp}
      inputStyle={inputStyle}
      shouldAutoFocus
      inputType="number"
      onChange={setOtp}
      numInputs={6}
      renderInput={(props, index) => <input {...props} disabled={disabled} />}
    />
  );
};

export default InputOtp;
