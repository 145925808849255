import React from "react";
import bgCardVPBank from "../../../../src/assets/images/bill-digital/card.png";
import ic_Gift from "../../../../src/assets/images/bill-digital/icon_gift.svg";
import BtnGiftBank from "../BtnGift";
import FrmStarted from "../FrmStarted";
import Feature from "../Feature";
const Card = () => {
  const list = [
    "No time limit",
    "The easiest software to use: completely online, no installation required",
    "Secure invoice data",
    "Easily integrates with any business's accounting/sales software",
  ];

  return (
    <div
      style={{
        backgroundImage: `url(${bgCardVPBank})`,
        maxWidth: 360,
        height: "100%",
        padding: 40,
        margin: "auto",
        backgroundSize: "100% 100%",
      }}
      className=""
    >
      <div className="flex items-center flex-col">
        <img style={{ width: 72, height: 72 }} src={ic_Gift} alt="icon gift" />
        <BtnGiftBank />
      </div>
      <FrmStarted />
      <div className="mt-10">
        <Feature list={list} />
      </div>
    </div>
  );
};

export default Card;
