import React, { useState } from "react";
import { checkout } from "../../assets";
import BtnCheckout from "../../components/checkout/BtnCheckout";
import PaymentMethod from "../../components/checkout/PaymentMethod";

const PaymentCheckOut = () => {
  const data = {
    name: "BEAN BAKERY",
    code: "334455",
    amount: "30,000 VND",
  };

  const paymentMethod = [
    {
      id: 1,
      icon: checkout.payment1,
      radio: true,
      description: "Thanh toán bằng Cổng thanh toán",
    },
    {
      id: 2,
      icon: checkout.payment2,
      radio: true,
      description: "Thanh toán bằng QR Code tài khoản ngân hàng",
    },
  ];

  const [activeMethod, setActiveMethod] = useState(paymentMethod[0] || null);

  const checkoutOrder = () => {
    console.log("activeMethod", activeMethod);
  };

  return (
    <section className="flex items-center justify-center mt-8">
      <div className="flex flex-col space-y-8 w-1/2 px-6 py-8 bg-white shadow-medium">
        <div className="flex flex-col space-y-6">
          <span className="font-semibold text-lg text-blue-1">
            Thông tin đơn hàng
          </span>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row items-center justify-between">
              <span className="font-medium text-base">
                Đơn vị chấp nhận thanh toán:
              </span>
              <span className="font-bold text-base text-blue-1">
                {data.name}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="font-medium text-base">Invoice code:</span>
              <span className="font-medium text-base text-text-primary">
                {data.code}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="font-medium text-base">Số tiền thanh toán:</span>
              <span className="font-bold text-base text-blue-1">
                {data.amount}
              </span>
            </div>
          </div>
        </div>
        <div className="h-0 border border-dashed border-grey-6"></div>
        <div className="flex flex-col space-y-6">
          <span className="font-semibold text-lg text-blue-1">
            Chọn phương thức thanh toán
          </span>
          {paymentMethod.map((item) => (
            <div
              className={`p-4 border rounded-lg cursor-pointer ${
                activeMethod.id === item.id
                  ? "border-blue-0 bg-blue-active"
                  : "border-grey-5"
              }`}
              onClick={() => setActiveMethod(item)}
            >
              <PaymentMethod item={item} activeMethod={activeMethod} />
            </div>
          ))}
          {activeMethod.id === 2 && (
            <div className="flex items-center justify-center">
              <img
                src={checkout.qrcode}
                alt="qrcode"
                className={`w-24 animate__animated ${
                  activeMethod.id === 2
                    ? "animate__raiseHeightIn"
                    : "animate__raiseHeightOut"
                }`}
                // className={`w-24 animate__animated animate__raiseHeightOut`}
              />
            </div>
          )}
          <BtnCheckout checkout={checkoutOrder} />
        </div>
      </div>
    </section>
  );
};
export default PaymentCheckOut;
