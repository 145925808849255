import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "./pages/login";
import ForgotPassword from "./pages/forgot-password/forgot-password";
import ResetPassword from "./pages/forgot-password/reset-password";

import TxnCard from "./pages/transactions/TxnCard";
import TxnEcom from "./pages/transactions/TxnEcom";
import TxnVA from "./pages/transactions/TxnVA";
import TxnWallet from "./pages/transactions/TxnWallet";
import TxnCash from "./pages/transactions/TxnCash";
import TxnInstallment from "./pages/transactions/TxnInstallment";

import Bank from "./pages/bank/Bank";
import CreateBank from "./pages/bank/CreateBank";

import PaymentChannel from "./pages/payment-channel/PaymentChannel";

import EDC from "./pages/edc/EDC";

import Feedback from "./pages/feedback";
import CreateFeedback from "./pages/feedback/CreateFeedback";
import UpdateFeedback from "./pages/feedback/UpdateFeedback";

import CompanyProfile from "./pages/company-profile/CompanyProfile";
import CreateEmployee from "./pages/employee/CreateEmployee";
import UpdateEmployee from "./pages/employee/UpdateEmployee";
import UserManagement from "./pages/employee/UserManagement";
import StoreManagement from "./pages/store-manager/index";
import CreateStore from "./pages/store-manager/CreateStore";

import SettingTx from "./pages/setting-tx/SettingTx";
import CreateSettingTx from "./pages/setting-tx/CreateSettingTx";
import UpdateSettingTx from "./pages/setting-tx/UpdateSettingTx";

import TableCustomer from "./pages/sell-manager/customer-manager/TableCustomer";
import CreateCustomer from "./pages/sell-manager/customer-manager/CreateCustomer";
import UpdateCustomer from "./pages/sell-manager/customer-manager/UpdateCustomer";

import TableProduct from "./pages/sell-manager/product-manager/TableProduct";
import CreateProduct from "./pages/sell-manager/product-manager/CreateProduct";
import UpdateProduct from "./pages/sell-manager/product-manager/UpdateProduct";

import TableOrder from "./pages/sell-manager/order-manager/TableOrder";
import CreateOrder from "./pages/sell-manager/order-manager/CreateOrder";
import UpdateOrder from "./pages/sell-manager/order-manager/UpdateOrder";

import TableInvoice from "./pages/sell-manager/invoice-manager/TableInvoice";
import CreateBill from "./pages/sell-manager/invoice-manager/CreateBill";

import ListTable from "./pages/sell-manager/inventory-manager/Table/ListTable";
import Products from "./pages/sell-manager/inventory-manager/Product/Products";

import EInvoice from "./pages/e-invoice";

import SettingTheme from "./pages/setting-theme";

import ErrorPage from "./pages/error-page";
// import Index from "./pages/index";
import Logout from "./pages/logout";
// import PaymentCheckOut from "./pages/payment-checkout";
import TsQRcode from "./pages/tsQRcode";
// import UrlPayment from "./pages/urlpayment";
import PaymentPage from "./pages/sell-manager/inventory-manager/Payment/PaymentPage";
// import PaymentEco from "./pages/sell-manager/inventory-manager/Payment/page";
import SellRegister from "./pages/sell-manager/sell-register";
import DigitalBill from "./pages/digital-bill/DigitalBill";
import TableCategory from "./pages/sell-manager/category-manager/TableCategory";
import SellSetting from "./pages/sell-manager/setting";
import CompanySetting from "./pages/company-profile/setting";
import SignUp from "./pages/signup";
import TxOnchain from "./components/onchain-tx";
// import VerifyEmailPage from "./pages/verify-email";
import Dashboard from "./pages/dashboard";
import VerifyEmailPage from "./pages/verify";
import PaymentCheckOut from "./pages/payment-channel/Payment-Checkout";
import Onboarding from "./pages/onboarding/onboarding";
import CreateEDC from "./pages/edc/CreateEDC";
// import Payment from "./pages/sell-manager/inventory-manager/Table/page";

const Routes = () => {
  return (
    <Switch>
      {/* Auth */}
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/verify-email">
        <VerifyEmailPage />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>

      {/* Transactions */}
      <Route path="/tx-onchain">
        <TxOnchain />
      </Route>
      <Route path="/tx-card">
        <TxnCard />
      </Route>
      <Route path="/tx-qrcode">
        <TsQRcode />
      </Route>
      <Route path="/tx-cash">
        <TxnCash />
      </Route>
      <Route path="/tx-momo">
        <TxnWallet />
      </Route>
      <Route path="/tx-installment">
        <TxnInstallment />
      </Route>
      <Route path="/tx-va">
        <TxnVA />
      </Route>
      <Route path="/tx-ecom">
        <TxnEcom />
      </Route>

      {/* Dashboard */}
      <Route exact path={`/`}>
        <Dashboard />
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>

      {/* Payment */}
      <Route exact path="/bank">
        <Bank />
      </Route>
      <Route path="/bank/create">
        <CreateBank />
      </Route>
      <Route path="/payment">
        <PaymentChannel />
      </Route>

      {/* Company */}
      <Route path="/company-profile">
        <CompanyProfile />
      </Route>
      <Route path="/store-manager/create">
        <CreateStore />
      </Route>
      <Route path="/store-manager">
        <StoreManagement />
      </Route>
      <Route path="/user-management/create">
        <CreateEmployee />
      </Route>
      <Route path="/user-management/update">
        <UpdateEmployee />
      </Route>
      <Route path="/company-employee">
        <UserManagement />
      </Route>
      <Route path="/setting-company">
        <CompanySetting />
      </Route>

      {/* EDC */}
      <Route path="/edc/create">
        <CreateEDC />
      </Route>
      <Route path="/edc">
        <EDC />
      </Route>

      {/* Setting Transaction */}
      <Route path="/setting-tx/create">
        <CreateSettingTx />
      </Route>
      <Route path="/setting-tx/update">
        <UpdateSettingTx />
      </Route>
      <Route path="/setting-tx">
        <SettingTx />
      </Route>

      {/* Feedback */}
      <Route path="/feedback/create/:tab">
        <CreateFeedback />
      </Route>
      <Route path="/feedback/update/:tab/:id">
        <UpdateFeedback />
      </Route>
      <Route path="/feedback/:tab?">
        <Feedback />
      </Route>

      {/* Sell Customer */}
      <Route path="/sell-customer/create">
        <CreateCustomer />
      </Route>
      <Route path="/sell-customer/update">
        <UpdateCustomer />
      </Route>
      <Route path="/sell-customer">
        <TableCustomer />
      </Route>

      {/* Category */}
      <Route path="/category">
        <TableCategory />
      </Route>

      {/* Sell Product */}
      <Route path="/sell-product/create">
        <CreateProduct />
      </Route>
      <Route path="/sell-product/update">
        <UpdateProduct />
      </Route>
      <Route path="/sell-product">
        <TableProduct />
      </Route>

      {/* Sell Order */}
      <Route path="/sell-order/create">
        <CreateOrder />
      </Route>
      <Route path="/sell-order/update">
        <UpdateOrder />
      </Route>
      <Route path="/sell-order">
        <TableOrder />
      </Route>

      {/* Sell Invoice */}
      <Route path="/sell-invoice/create">
        <CreateBill />
      </Route>
      <Route path="/sell-invoice">
        <TableInvoice />
      </Route>

      {/* Sell Table */}
      <Route path="/sell-table/order/:table_id/:order_id">
        <Products />
      </Route>
      <Route path="/sell-table/order/:order_id">
        <Products />
      </Route>
      <Route path="/sell-table/payment/:invoice_id">
        <PaymentPage />
      </Route>
      <Route path="/sell-table">
        <ListTable />
      </Route>

      {/* Sell Setting */}
      <Route path="/sell-setting">
        <SellSetting />
      </Route>
      {/* <Route path="/order/paymentEco/:order_id/:total">
        <PaymentEco />
      </Route> */}
      <Route path="/sell-register">
        <SellRegister />
      </Route>

      {/* E-Invoice */}
      <Route path="/e-invoice">
        <EInvoice />
      </Route>

      {/* Bill digital */}
      <Route path="/bill-digital">
        <DigitalBill />
      </Route>

      {/* Setting Theme */}
      <Route path="/setting-theme">
        <SettingTheme />
      </Route>

      {/* Unknown */}
      <Route path="/error-page">
        <ErrorPage />
      </Route>
      <Route path="/payment-checkout">
        <PaymentCheckOut />
      </Route>
      {/* <Route exact path="/url-payment">
        <UrlPayment />
      </Route> */}

      {/* Onboarding */}
      <Route path="/onboarding">
        <Onboarding />
      </Route>

      {/* <Route component={Index} /> */}
    </Switch>
  );
};
export default Routes;
