import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getStatusPos } from "../../../../../actions";
import CustomButton from "../../../../../components/buttons/CustomButton";
import SelectDeviceField from "../../../../../components/fields/SelectDeviceField";
import { StatusCell } from "../../../../../components/tsdatatable/datatables";
import { getInvoice } from "../../../../../services";
import axiosClient2 from "../../../../../services/client_axios2";
import {
  PAYMENT_METHOD,
  STATUS_CONNECT_POS,
  STATUS_INVOICE,
} from "../../../../../utils/constants";
import { CustomToastMessage } from "../../../../../utils/customToastMsg";
import { ERROR_CODE } from "../../../../../utils/errorCode";
import Invoice from "../Invoice";
import ModalOffline from "./ModalOffline";
import ModalWaitingPos from "./ModalWaitingPos";

const limitCheckPosStatus = 150; // 2s call in 5 minutes

const Credit = ({ invoice, setPrintInvoice }) => {
  const SERIAL_NUMBER = "serial_number";
  const [statusPos, setStatusPos] = useState(STATUS_CONNECT_POS.NOT_CONNECTED);
  const [openModal, setOpenModal] = useState(false);
  const [batchNo, setBatchNo] = useState();
  const [invoiceNo, setInvoiceNo] = useState();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [openModalWaiting, setOpenModalWaiting] = useState(false);
  const { invoice_id } = useParams(0);

  const checkPosTimeRef = useRef(1);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(getStatusPos(value));
      // setStatusPos(true)
      if (res?.meta?.requestStatus === "fulfilled") {
        setStatusPos(res?.payload?.data);
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        }
        CustomToastMessage.error("Có lỗi", msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onErrors = (errors) => console.error(errors);

  const handleUpdatePaymentPos = useCallback(async () => {
    if (invoiceNo && batchNo && watch(SERIAL_NUMBER)) {
      try {
        const dataBody = {
          invoice_id: invoice?.invoice_id,
          serial_number: watch(SERIAL_NUMBER),
          batch_no: batchNo,
          invoice_no: invoiceNo,
        };

        const res = await axiosClient2.post(
          `${process.env.REACT_APP_API_ENDPOINT_STORE}/payment/pos`,
          dataBody
        );

        if (res) {
          setPrintInvoice(true);
        }
      } catch (error) {
        console.log("Log - error:", error);
      }
    }
  }, [batchNo, invoice, invoiceNo, setPrintInvoice, watch]);

  const onPayment = useCallback(async () => {
    if (watch(SERIAL_NUMBER) && invoice?.invoice_id) {
      setIsPaymentLoading(true);
      const dataBody = {
        invoice_id: invoice?.invoice_id,
        serial_number: watch(SERIAL_NUMBER),
        payment_method: PAYMENT_METHOD.CARD,
      };

      try {
        const res = await axiosClient2.post(
          `${process.env.REACT_APP_API_ENDPOINT_STORE}/payment/pos`,
          dataBody
        );
        const data = res;
        if (data && data?.invoice_id) {
          setOpenModalWaiting(true);
        } else {
          CustomToastMessage.error("Có lỗi", "Thanh toán không Success!");
        }
      } catch (error) {
        console.log("Log - error:", error);
      } finally {
        setIsPaymentLoading(false);
      }
    }
  }, [invoice.invoice_id, watch]);

  useEffect(() => {
    const checkInvoiceStatus = async () => {
      const res = await getInvoice(invoice_id);
      if (res && res?.invoice_data?.status === STATUS_INVOICE.PAID) {
        // handleUpdateInvoice();
        setPrintInvoice(true);
        localStorage.setItem("evoice", true);
      }
    };
    const intervalId = setInterval(() => checkInvoiceStatus(), 3000);
    return () => clearInterval(intervalId);
  }, [invoice_id, setPrintInvoice]);

  useEffect(() => {
    const serialNumber = watch(SERIAL_NUMBER);
    if (serialNumber && checkPosTimeRef.current <= limitCheckPosStatus) {
      const checkConnectPosStatus = async () => {
        const res = await dispatch(getStatusPos(serialNumber));
        if (res?.meta?.requestStatus === "fulfilled") {
          setStatusPos(res?.payload?.data);
          checkPosTimeRef.current += 1;
        }
      };
      const intervalId = setInterval(() => checkConnectPosStatus(), 2000);
      return () => {
        checkPosTimeRef.current = 1;
        clearInterval(intervalId);
      };
    }
  }, [dispatch, invoice_id, setPrintInvoice, statusPos, watch]);

  const disableBtnCompleteInvoice = useMemo(() => {
    return (
      !batchNo ||
      !invoiceNo ||
      !watch(SERIAL_NUMBER) ||
      statusPos === STATUS_CONNECT_POS.ONLINE ||
      statusPos === STATUS_CONNECT_POS.NOT_CONNECTED
    );
  }, [invoiceNo, batchNo, statusPos, watch]);

  return (
    <>
      {openModal && <ModalOffline setOpenModal={setOpenModal} />}
      {openModalWaiting && (
        <ModalWaitingPos invoice={invoice} setOpenModal={setOpenModalWaiting} />
      )}
      <div className="flex flex-col gap-6 mt-8 mb-6">
        <form
          onSubmit={handleSubmit(onFormSubmit, onErrors)}
          className="flex flex-col items-center justify-center"
        >
          <div className="w-full flex items-center justify-between space-x-4">
            <SelectDeviceField
              isRequired
              register={register}
              name={SERIAL_NUMBER}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <CustomButton
              disabled={!watch(SERIAL_NUMBER)}
              type="submit"
              title={"Kiểm tra kết nối"}
              className={"mt-6"}
            />
          </div>
          {statusPos === STATUS_CONNECT_POS.ONLINE && (
            <div className="flex items-center mt-6 justify-center">
              <StatusCell color={"green"} title={"Đang kết nối"} />
            </div>
          )}
          {statusPos === STATUS_CONNECT_POS.OFFLINE && (
            <>
              <div className="flex items-center mt-6 justify-center">
                <StatusCell color={"red"} title={"Mất kết nối"} />
              </div>
              <div className="italic font-montserrat text-[12px] text-[#595959] mt-2">
                Lưu ý: Nếu kết nối máy pos không được vui lòng liên hệ “1900
                3353”
              </div>
            </>
          )}
        </form>
        <div className="w-full flex items-center justify-center">
          <CustomButton
            disabled={
              isPaymentLoading ||
              !watch(SERIAL_NUMBER) ||
              statusPos === STATUS_CONNECT_POS.OFFLINE ||
              statusPos === STATUS_CONNECT_POS.NOT_CONNECTED
            }
            title={"Thanh toán"}
            className={"mt-4 w-40"}
            onClick={onPayment}
          />
        </div>
        <Invoice invoice={invoice} />
        <div className="flex flex-col space-y-8">
          <div className="flex-1 flex-col flex space-y-2">
            <span
              style={{ color: "#262626" }}
              className="text-sm font-semibold leading-5"
            >
              Số lô
            </span>
            <input
              style={{
                padding: "10px 16px",
                border: "solid 1px #e8e8e8",
                background: "#FAFAFA",
              }}
              className="rounded-lg"
              value={batchNo}
              onChange={(e) => setBatchNo(e.target.value)}
              placeholder="Nhập số lô"
              type="number"
            />
          </div>
          <div className="flex-1 flex-col flex space-y-2">
            <span
              style={{ color: "#262626" }}
              className="text-sm font-semibold leading-5"
            >
              Số hoá đơn
            </span>
            <input
              style={{
                padding: "10px 16px",
                border: "solid 1px #e8e8e8",
                background: "#FAFAFA",
              }}
              value={invoiceNo}
              onChange={(e) => setInvoiceNo(e.target.value)}
              className="rounded-lg"
              placeholder="Nhập số hoá đơn"
              type="text"
            />
          </div>
          <CustomButton
            className="w-64 mx-auto"
            title="Hoàn tất thanh toán"
            onClick={handleUpdatePaymentPos}
            disabled={disableBtnCompleteInvoice}
          />
        </div>
      </div>
    </>
  );
};

export default Credit;
