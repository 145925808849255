import React, { useEffect, useState } from 'react'
import { getOrderById } from '../../../actions/order-manager.actions'
import { useDispatch } from 'react-redux'
import { getProfile } from '../../../actions/profile.action'
import { formatCurrency, formatPaymentMethod } from '../../../utils/lib'
import axiosClient2 from '../../../services/client_axios2'
import moment from 'moment'

const Row = ({ col1, col2, col3, col4, col5, borderBottom }) => {
    return (
        <div
            className='w-full flex justify-between font-medium'
            style={{ borderBottom: borderBottom && '1px solid #8C8C8C', lineHeight: '14px', marginTop: 10, paddingBottom: 10 }}
        >
            <p style={{ width: '9%' }} className='text-left'>{col1}</p>
            <p style={{ width: '22.7%' }} className='text-center'>{col2}</p>
            <p style={{ width: '9%' }} className='text-center'>{col3}</p>
            <p style={{ width: '22.7%' }} className='text-center'>{col4}</p>
            <p style={{ width: '22.7%' }} className='text-right'>{col5}</p>
        </div>
    )
}

const PrintInvoice = ({ order, data, qr }) => {
    const dispatch = useDispatch()
    const [merchantName, setMerchantName] = useState('')
    const [dataInvoice, setDataInvoice] = useState({ products: null, info: null })
    const [date, setDate] = useState([])

    useEffect(() => {
        const getDataInvoice = async () => {
            const merchantInfo = await dispatch(getProfile())
            setMerchantName(merchantInfo?.payload?.data?.full_name)
            if (data) {
                const date = moment((data?.info?.updated_at || data?.invoice_data?.updated_at) * 1000).format("DD/MM/YYYY HH:mm")
                setDataInvoice({
                    products: data?.products_data || data?.products, info: data?.invoice_data || data?.info
                })
                setDate(date.split(' '))
                return
            }
            if (order) {
                const isPaid = order.status == 0
                if (isPaid) {
                    const res = await axiosClient2.get(`/invoice/${order.order_id}?is_orderid=true`)
                    if (res?.data) {
                        const date = moment(order?.updated_at * 1000).format("DD/MM/YYYY HH:mm")
                        setDataInvoice({
                            products: res.data.products_data, info: res.data.invoice_data
                        })
                        setDate(date.split(' '))
                    }
                } else {
                    const res = await dispatch(getOrderById(order.order_id))
                    if (res.payload.data) {
                        const date = moment(order?.updated_at * 1000).format("DD/MM/YYYY HH:mm")
                        setDataInvoice({
                            products: res.payload.data.products, info: { ...res.payload.data.order_info, status: 0 }
                        })
                        setDate(date.split(' '))
                    }
                }
                return
            }
        }
        getDataInvoice()
        // console.log('>>> data invoice', dataInvoice)
    }, [])

    return (
        <div
            style={{ fontSize: 10, width: 300, height: 'min-content', padding: '16px', boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.10)' }}
            id='print-content' className='bg-white rounded-xl flex flex-col items-center overflow-hidden'
        >
            <h1 className='text-sm leading-5 font-bold'>{merchantName}</h1>
            <p style={{ lineHeight: '14px' }} className='mt-2 font-medium'>Đ/c: 28 Trân Não, P.Thủ Thiêm, Q.2, TP.Hồ Chí Minh</p>

            <div className='flex flex-col items-center w-full my-4'>
                <h2 className='text-base leading-6 font-bold'>HOÁ ĐƠN BÁN HÀNG</h2>
                <div style={{ fontSize: 10, lineHeight: '14px' }} className='mt-4 w-full flex justify-between'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>Số hoá đơn:</p>
                        <p className='font-medium'>{dataInvoice?.info?.invoice_id}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>Ngày:</p>
                        <p className='font-medium'>{date[0]}</p>
                    </div>
                </div>
                <div style={{ fontSize: 10, lineHeight: '14px' }} className='mt-3 w-full flex justify-between'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>Bàn:</p>
                        <p className='font-medium'>{dataInvoice?.info?.table_name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>Giờ:</p>
                        <p className='font-medium'>{date[1]}</p>
                    </div>
                </div>
                <div className='w-full flex justify-between mt-3'>
                    <p className='font-semibold'>Trạng thái hóa đơn:</p>
                    <p className='font-medium'>{dataInvoice?.info?.status == 0 ? 'Chưa thanh toán' : 'Đã thanh toán'}</p>
                </div>
                {dataInvoice?.info?.status !== 0 && <div className='w-full flex justify-between mt-3 pb-4' style={{ borderBottom: '1px solid #8C8C8C' }}>
                    <p className='font-semibold'>Phương thức thanh toán:</p>
                    <p className='font-medium'>{formatPaymentMethod(dataInvoice?.info?.payment_method)}</p>
                </div>}

                <Row col1='TT' col2='Tên món' col3='SL' col4='Đơn giá' col5='Thành tiền' borderBottom />
                {[].concat(dataInvoice?.products).map((item, index) =>
                    <div style={{ marginTop: 6 }} className='w-full' key={index}>
                        <Row
                            col1={index + 1} col2={item?.name?.toUpperCase()}
                            col3={item?.amount + ' phần'}
                            col4={formatCurrency(item?.price)}
                            col5={formatCurrency(item?.amount * item?.price)}
                        />
                    </div>
                )}

                <div className='w-full pb-4 flex items-center justify-between' style={{ marginTop: 6, borderBottom: '1px dashed #D9D9D9' }}>
                    <p className='font-semibold'>Tổng cộng:</p>
                    <p className='text-sm leading-5 font-semibold'>{formatCurrency(order ? order?.total_money : dataInvoice?.info?.total_price)}</p>
                </div>

                <div className='w-full mt-4 flex justify-between text-lg leading-7'>
                    <p className='font-bold'>Tổng tiền:</p>
                    <p className='font-bold'>{formatCurrency(order ? order?.total_money : dataInvoice?.info?.total_price)}</p>
                </div>
            </div>

            <div className='text-xs leading-4 font-semibold italic'>{merchantName} xin chân thành cảm ơn!</div>

            {qr && <div className='mt-4 pt-4 w-full border-t border-dashed flex flex-col items-center gap-4'>
                <h1 className='text-base leading-6 font-bold'>Quét mã QR để thanh toán</h1>
                <img src={qr} alt='' style={{ width: 180, borderRadius: 14, border: '1.5px solid #262626' }} />
            </div>}
        </div>
    )
}

export default PrintInvoice