import React from "react";

const SelectFieldContent = ({ children }) => {
  return (
    <div style={{ maxHeight: 200 }} className="flex flex-col gap-1 overflow-y-auto ">
      {children}
    </div>
  );
};

export default SelectFieldContent;
