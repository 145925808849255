import React from "react";

const MultipleSelectWrapper = ({ children }) => {
  return (
    <div style={{ gap: 10 }} className="flex flex-wrap items-center">
      {children}
    </div>
  );
};

export default MultipleSelectWrapper;
