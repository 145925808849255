import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  updateProduct,
  getProductById,
} from "../../../actions/product-manager.actions";
import { useDispatch } from "react-redux";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import { formatCurrency } from "../../../utils/lib";

import { StatusCell } from "../../../components/tsdatatable/datatables";
import CustomButton from "../../../components/buttons/CustomButton";
import SectionTitle from "../../../components/section-title";
import UploadProductField from "../../../components/fields/upload/UploadProductField";
import InputField from "../../../components/fields/InputField";
import TableWrapper from "../../company-profile/TableWrapper";
import SelectCateField from "../../../components/fields/SelectCateField";

const FORM = {
  NAME: "name",
  CODE: "code",
  IMG: "image_url",
  PRICE: "price",
  COST: "cost",
  TAX: "tax",
};

const listStatus = [
  { status: 0, title: "Còn hàng", color: "green" },
  { status: 1, title: "Hết hàng", color: "red" },
  { status: 2, title: "Stop business", color: "gray" },
];

const UpdateProduct = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [imgUrl, setImgUrl] = useState("");
  const [listCate, setListCate] = useState([]);
  const [status, setStatus] = useState(undefined);
  const [typeInput, setTypeInput] = useState("number");
  const priceRef = useRef("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      [FORM.NAME]: "",
      [[FORM.PRICE]]: "",
    },
  });

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(
        updateProduct({
          ...value,
          img: imgUrl,
          cost: 0,
          tax: 0,
          status: status,
          price: priceRef.current,
          category_ids: listCate,
        })
      );

      if (res?.meta?.requestStatus == "fulfilled" && res?.payload) {
        history.push("/sell-product");
        CustomToastMessage.success(
          "Success",
          `Bạn đã chỉnh sửa Success sản phẩm có mã ${id}`
        );
      } else CustomToastMessage.error("Chỉnh sửa sản phẩm thất bại");
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled = !watch(FORM.NAME) || !watch(FORM.PRICE) || !imgUrl;

  const onBlur = useCallback(() => {
    setTypeInput("text");
    setValue(
      FORM.PRICE,
      watch(FORM.PRICE) ? formatCurrency(watch(FORM.PRICE)) : watch(FORM.PRICE)
    );
  }, [setValue, watch]);

  const onFocus = useCallback(() => {
    setTypeInput("number");
    setValue(FORM.PRICE, priceRef.current);
  }, [setValue]);

  const onUpdate = useCallback((formName, value) => {
    priceRef.current = formName === "price" ? value : priceRef.current;
  }, []);

  useEffect(() => {
    const getProdInfo = async () => {
      const res = await dispatch(getProductById(id));
      if (res?.payload && res?.payload?.data) {
        setTypeInput("text");
        priceRef.current = res?.payload?.data?.product?.price;
        setValue(FORM.NAME, res?.payload?.data?.product?.name);
        setValue(
          FORM.PRICE,
          formatCurrency(res?.payload?.data?.product?.price)
        );
        setValue(FORM.CODE, res?.payload?.data?.product?.code);
        setImgUrl(res?.payload?.data?.product?.img);
        setStatus(res?.payload?.data?.product?.status);
        setListCate(res?.payload?.data?.categories.map((cate) => cate?.id));
      }
    };
    getProdInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="Quản lý sản phẩm"
        subtitle="Chỉnh sửa thông tin sản phẩm"
      />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col gap-6">
            <UploadProductField
              label="Hình ảnh sản phẩm"
              isRequired
              id={FORM.IMG}
              name={FORM.IMG}
              imgUrl={imgUrl}
              setImgUrl={setImgUrl}
            />

            <div className="space-y-2">
              <p className="text-text-primary text-sm leading-5 font-semibold">
                Trạng thái<span className="text-red-required">*</span>
              </p>
              <div className="flex gap-4">
                {listStatus.map((item) => (
                  <div
                    onClick={() => setStatus(item.status)}
                    key={item.status}
                    className={`cursor-pointer ${
                      item.status != status && "opacity-30"
                    }`}
                  >
                    <StatusCell title={item.title} color={item.color} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <InputField
              readOnly
              isRequired
              setValue={setValue}
              label="Mã sản phẩm"
              placeholder="Nhập Mã sản phẩm"
              id={FORM.CODE}
              name={FORM.CODE}
              value={watch(FORM.CODE) || ""}
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              isRequired
              setValue={setValue}
              label="Tên sản phẩm"
              placeholder="Nhập tên sản phẩm"
              id={FORM.NAME}
              name={FORM.NAME}
              value={watch(FORM.NAME) || ""}
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputField
              isRequired
              setValue={setValue}
              label="Giá Bán"
              placeholder="Nhập giá bán"
              id={FORM.PRICE}
              name={FORM.PRICE}
              value={watch(FORM.PRICE)}
              onBlur={onBlur}
              onFocus={onFocus}
              onUpdate={onUpdate}
              register={register}
              errors={errors}
              type={typeInput}
              state={errors?.type ? "error" : " "}
            />
          </div>
        </div>

        <SelectCateField listCate={listCate} setListCate={setListCate} />

        <div className="flex items-center justify-end gap-4 mt-4">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/sell-product")}
              title="Quay lại"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Chỉnh Sửa"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default UpdateProduct;
