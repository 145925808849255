import md5 from "md5";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getEmployeeById,
  updateEmployee,
} from "../../actions/employee/employee.actions";
import CustomButton from "../../components/buttons/CustomButton";
import InputField from "../../components/fields/InputField";
import InputFieldPw from "../../components/fields/InputFieldPw";
import SelectStoreField from "../../components/fields/SelectStoreField";
import SectionTitle from "../../components/section-title";
import { toastMessage } from "../../utils/constants";
import { ERROR_CODE } from "../../utils/errorCode";
import TableWrapper from "../company-profile/TableWrapper";

const FORM = {
  EMAIL: "email",
  FULL_NAME: "full_name",
  PHONE: "phone",
  OLD_PASSWORD: "old_password",
  PASSWORD: "password",
  STORE_ID: "store_id",
  STAFF_ID: "staff_id",
};

const UpdateEmployee = () => {
  const history = useHistory();
  const location = useLocation();
  const id = new URLSearchParams(location.search)?.get("id");
  const [validPassword, setValidPassword] = useState(true);

  const dispatch = useDispatch();
  const [employee, setEmployee] = useState({
    id: "",
    email: "",
    full_name: "",
    phone: "",
    password: "",
    old_password: "",
    store_id: "",
    staff_id: "",
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    let raw = { ...value, id: employee.id };

    if (value[FORM.OLD_PASSWORD] && value[FORM.PASSWORD]) {
      raw = {
        ...raw,
        old_password: md5(value?.old_password),
        password: md5(value?.password),
      };
    }

    try {
      const res = await dispatch(updateEmployee(raw));
      if (res?.meta?.requestStatus === "fulfilled") {
        history.push("/company-employee");
        toastMessage.success();
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        }
        toastMessage.error(msg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(FORM.FULL_NAME) || !watch(FORM.PHONE) || !validPassword;

  const getEmployee = async () => {
    const res = await dispatch(getEmployeeById(id));
    if (res?.meta?.requestStatus === "fulfilled") {
      const data = res?.payload?.data;

      setEmployee(data);
      setValue(FORM.EMAIL, data?.email);
      setValue(FORM.FULL_NAME, data?.full_name);
      setValue(FORM.PHONE, data?.phone);
      setValue(FORM.STORE_ID, data?.store_id);
      setValue(FORM.STAFF_ID, data?.staff_id);
    }
  };

  useEffect(() => {
    getEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watch(`${FORM.OLD_PASSWORD}`) === "") {
      clearErrors(`${FORM.OLD_PASSWORD}`);
      // setDisable(true);
    }
    if (watch(`${FORM.PASSWORD}`) === "") {
      clearErrors(`${FORM.PASSWORD}`);
      // setDisable(true);
    }
  }, [watch("old_password"), watch("password")]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="Business Information"
        subtitle="Edit employee information"
        rightTitle="Employee Management"
      />
      <TableWrapper>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <InputField
            disabled
            isRequired
            label="Employee code"
            placeholder="Enter employee code"
            setValue={setValue}
            id={FORM.STAFF_ID}
            name={FORM.STAFF_ID}
            value={watch(FORM.STAFF_ID)}
            defaultValue={employee[FORM.STAFF_ID]}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            isRequired
            label="Full name"
            placeholder="Enter full name"
            id={FORM.FULL_NAME}
            name={FORM.FULL_NAME}
            value={watch(FORM.FULL_NAME)}
            defaultValue={employee[FORM.FULL_NAME]}
            setValue={setValue}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          {employee.store_id && (
            <SelectStoreField
              initValue={employee[FORM.STORE_ID]}
              isRequired
              register={register}
              name={FORM.STORE_ID}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          )}

          <InputField
            //variant="secondary"
            isRequired
            value={watch(FORM.PHONE)}
            defaultValue={employee[FORM.PHONE]}
            setValue={setValue}
            type="number"
            label="Phone number"
            id={FORM.PHONE}
            name={FORM.PHONE}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            disabled
            isRequired
            value={watch(FORM.EMAIL)}
            defaultValue={employee[FORM.EMAIL]}
            setValue={setValue}
            label="Email"
            placeholder="Enter email"
            id={FORM.EMAIL}
            name={FORM.EMAIL}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <InputField
            disabled
            value={watch(FORM.EMAIL)}
            defaultValue={employee[FORM.EMAIL]}
            setValue={setValue}
            label="User name"
            //placeholder="Enter email"
            id={FORM.EMAIL}
            name={FORM.EMAIL}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <div className="flex flex-col ">
            <InputFieldPw
              // variant="secondary"
              setValue={setValue}
              label="Update password"
              setError={setError}
              clearErrors={clearErrors}
              placeholder="Enter old password"
              id={FORM.OLD_PASSWORD}
              name={FORM.OLD_PASSWORD}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <InputFieldPw
              setValue={setValue}
              placeholder="Enter your new password"
              id={FORM.PASSWORD}
              name={FORM.PASSWORD}
              setValidPassword={setValidPassword}
              setError={setError}
              clearErrors={clearErrors}
              pattern={
                /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}|:"<>?]).{8,}$/
              }
              extra="mt-2"
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            <p className="mt-4 font-medium text-14px leading-5 text-input">
              Note: Password must have at least 6 characters
            </p>
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/company-employee")}
              title="Back"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Update"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default UpdateEmployee;
