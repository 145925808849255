// Custom components
import React from "react";
import { handleToastErr } from "../../utils/lib";

function InputField(props) {
  const {
    register,
    isRequired,
    setValue,
    value,
    type,
    label,
    setValid,
    placeholder,
    clearErrors,
    id,
    name,
    state,
    extra,
    errors,
    setError,
    variant,
    disabled,
    readOnly,
    onBlur,
    onFocus,
    onUpdate,
    pattern,
    defaultValue,
    inputStyle,
    itemRight,
  } = props;

  return (
    <div className={`${extra} font-monte`}>
      {label && (
        <div className={`mb-2`}>
          <label
            htmlFor={id}
            className={`${
              disabled
                ? "text-hint "
                : variant === "secondary"
                ? "text-text-secondary "
                : "text-black-2 "
            } text-sm leading-5 font-semibold`}
          >
            {label}
          </label>
          {isRequired && (
            <span className="font-semibold text-red-required">*</span>
          )}
        </div>
      )}

      <div style={{ gap: 6 }} className={` relative flex items-center`}>
        <input
          defaultValue={defaultValue}
          onChange={(e) => {
            // if (e.target.value.trim() === " ") return;
            const value =
              name === "price"
                ? e.target.value.toString().length > 13
                  ? e.target.value.toString().substring(0, 13)
                  : e.target.value
                : e.target.value;

            setValue(name, value);
            //console.log("pattern", value);

            if (pattern) {
              const rex = new RegExp(pattern);
              if (!rex.test(e.target.value)) {
                setError(`${name}`, {
                  message: `Invalid ${label?.replace("*", "")} `,
                });
                setValid(false);
              } else {
                setValue(name, e.target.value);
                clearErrors(name);
                setValid(true);
              }
            }
            onUpdate && onUpdate(name, value);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          {...register(name, {
            required: {
              message: handleToastErr(name),
            },
          })}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          name={name}
          type={type}
          id={id}
          pattern={pattern && pattern}
          placeholder={placeholder}
          onKeyDown={(evt) => {
            if (type === "password" && /[\u0300-\u036f]/.test(evt.key)) {
              evt.preventDefault();
            }
            if (type === "text" && variant === "tax_no") {
              const regexPattern = /^[0-9-]+$/;
              // Allow certain keys like backspace
              const isAllowedKey =
                evt.key === "Backspace" || regexPattern.test(evt.key);

              // Prevent the default action if the key is not allowed
              !isAllowedKey && evt.preventDefault();
            } else if (type === "number") {
              ["e", "E", "+", ".", ",", "-"].includes(evt.key) &&
                evt.preventDefault();
            }
          }}
          onPaste={(e) => {
            const pastedContent = e.clipboardData.getData("Text");
            type == "percent" &&
              !/^[0-9]\d*\.?\d*$/.test(pastedContent) &&
              e.preventDefault();
          }}
          onWheel={(event) => type === "number" && event.currentTarget.blur()}
          style={{ padding: "10px 16px", height: 44 }}
          className={`${
            disabled
              ? "opacity-75 text-hint"
              : state === "error"
              ? "text-error border-error"
              : variant === "secondary"
              ? "text-grey-atom-grey border-gray-primary"
              : "text-input border-gray-primary"
          }
                    ${readOnly && "opacity-50"}
          ${type === "radio" ? "h-auto cursor-pointer" : ""}
         
         ${inputStyle} flex w-full text-14px items-center justify-center rounded border font-medium leading-5 outline-none placeholder-hint`}
        />
        {itemRight && itemRight}
      </div>
      {errors[name] && (
        <p className="text-xs font-normal mt-2 text-error">
          {errors[name].message}
        </p>
      )}
    </div>
  );
}

export default InputField;
