import React from "react";
import search from "../../../assets/images/create-table/search.svg";
import add from "../../../assets/images/product/add.svg";
import arrow_left from "../../../assets/images/product/arrow_left.svg";
import sub from "../../../assets/images/product/sub.svg";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { removeOrderProduct, updateOrder } from "../../../services/inventory-manager.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { theme } from "../../../configTheme";
import CustomButton from "../../../components/buttons/CustomButton";
import { IoCloseSharp } from "react-icons/io5";

export const Search = ({ content, setInputSearch }) => {
  const [input, setInput] = useState("");

  return (
    <div
      className="w-full rounded-lg p-4 flex justify-between items-center bg-white"
      style={{
        boxShadow: "0px 4px 10px 0px rgba(96,111,134,0.06)",
      }}
    >
      <input
        type="text"
        placeholder={content}
        onChange={(e) => setInput(e.target.value)}
        className="bg-inherit outline-none w-full text-[#434C76] text-sm font-normal"
        style={{
          color: "#434C76",
          fontSize: "14px",
          fontWeight: "400",
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") setInputSearch(e.target.value);
        }}
      />
      <img src={search} alt="" onClick={() => setInputSearch(input)} />
    </div>
  );
};

export const ButtonBorderRed = ({ content, children, className }) => {
  return (
    <button
      className={`flex items-center justify-center w-full h-full rounded-lg border `}
      style={{
        gap: "5px",
        padding: "10px 16px 10px 16px",
        borderColor: theme.primary,
      }}
    >
      {children}
      <span
        className="text-[#ED1C24] text-sm font-medium leading-5 text-center"
        style={{ color: theme.primary }}
      >
        {content}
      </span>
    </button>
  );
};

export const ButtonRed = ({ content, children }) => {
  return (
    <div
      className="flex items-center w-full h-full"
      style={{
        gap: "5px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px",
        backgroundColor: theme.primary,
      }}
    >
      {children}
      <span className="text-white text-sm font-medium leading-5 w-full text-center">{content}</span>
    </div>
  );
};

export const ButtonGreen = ({ content, children }) => {
  return (
    <button
      className="flex items-center w-fit"
      style={{
        gap: "5px",
        padding: "10px 16px 10px 16px",
        borderRadius: "4px",
        backgroundColor: "#4DD077",
      }}
    >
      {children}
      <span className="text-white text-sm font-medium leading-5">{content}</span>
    </button>
  );
};

export const Popup = ({
  icon,
  content,
  setShowPopup,
  showBtn = true,
  btnTitle = "Trở về màn hình chính",
  handleClickBtn,
}) => {
  return (
    <div className="fixed inset-0 z-50 bg-blur flex justify-center items-center">
      <div
        style={{ width: 328 }}
        className="relative bg-white px-6 py-8 rounded-2xl flex flex-col items-center"
      >
        <IoCloseSharp
          size={24}
          onClick={() => setShowPopup(false)}
          style={{ position: "absolute", top: 16, right: 16, cursor: "pointer" }}
        />
        <img src={icon} alt="gif" className="h-20 w-auto mb-4" />
        <h2 className="text-text-primary text-base leading-5 font-medium mb-8">{content}</h2>
        {showBtn && <CustomButton title={btnTitle} className="w-full" onClick={handleClickBtn} />}
      </div>
    </div>
  );
};

export const ChangeAmount = ({ product, pickedItem, getOrderProduct }) => {
  const { order_id } = useParams();
  const value = pickedItem?.find((i) => i.product_id === product.product_id)?.amount || 0;

  const handleSubItem = async () => {
    if (value >= 1) {
      await updateOrder({
        order_id: order_id,
        products: [
          {
            ...product,
            amount: -1,
          },
        ],
      });
      if (value === 1) {
        await removeOrderProduct(order_id, product.product_id);
      }
      getOrderProduct();
    }
  };

  const handleAddItem = async () => {
    await updateOrder({
      order_id: order_id,
      products: [
        {
          ...product,
          amount: 1,
        },
      ],
    });
    getOrderProduct();
  };

  return (
    <>
      {
        <div
          className="flex gap-4 items-center rounded-full w-fit"
          style={{ backgroundColor: "#E8E8E8" }}
        >
          <img
            src={sub}
            alt=""
            width={32}
            className="cursor-pointer"
            onClick={() => handleSubItem()}
          />
          <p className="text-base font-semibold" style={{ lineHeight: "28px", color: "#262626" }}>
            {value}
          </p>

          <div onClick={() => handleAddItem()} className="w-fit h-fit relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <circle cx="16" cy="16" r="15" fill={theme.primary} stroke="white" strokeWidth="2" />
            </svg>
            <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M5 1V9"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 5H9"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export const LineInfo = ({ title, handleBack }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <img src={arrow_left} alt="" className="cursor-pointer" onClick={() => handleBack(false)} />
      <span className="text-black text-sm font-semibold leading-5">{title}</span>
      <div className="w-[24px] h-[24px]"></div>
    </div>
  );
};
