import { createAsyncThunk } from "@reduxjs/toolkit";
import { productManagerServices } from "../services";

export const getListProduct = createAsyncThunk(
  "store/product-manager/getListProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await productManagerServices.getListProduct(data);
    return _data
  }
);

export const getProductById = createAsyncThunk(
  "store/product-manager/getProductById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await productManagerServices.getProductById(data);
    return _data
  }
);

export const createProduct = createAsyncThunk(
  "store/product-manager/createProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await productManagerServices.createProduct(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "store/product-manager/updateProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await productManagerServices.updateProduct(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addImageProduct = createAsyncThunk(
  "store/product-manager/addImageProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await productManagerServices.addImageProduct(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "store/product-manager/deleteProduct",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await productManagerServices.deleteProduct(data);
    return _data;
  }
);
