import React from "react";
import { toast } from "react-toastify";
import { history } from "../helpers";
import { theme } from "../configTheme";

import "react-toastify/dist/ReactToastify.css";

import success from "../assets/images/customToast/success.svg";
import error from "../assets/images/customToast/error.svg";
import info from "../assets/images/customToast/info.svg";
import { updateNoti } from "../actions";

const customToast = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  maxWidth: 416,
  height: 90,
  padding: "20px 20px 20px 16px !important",
  margin: 24,
  border: "1px solid #E6E8EC",
  boxShadow: "0px 12px 32px 0px rgba(6, 28, 61, 0.08)",
  borderRadius: 10,
};

const Message = ({ title, desc }) => {
  return (
    <div className="space-y-[6px] font-monte">
      <h3 style={{ color: "#0F1D40" }} className="text-sm font-semibold tracking-[-0.192px] md:text-base">
        {title}
      </h3>
      {desc && (
        <div style={{ color: "#8991A3" }} className="text-xs font-medium md:text-sm">
          {desc}
        </div>
      )}
    </div>
  );
};

export const CustomToastMessage = {
  success: (title, desc) => {
    toast.success(<Message title={title} desc={desc} />, {
      icon: <img src={success} />,
      style: {
        ...customToast,
        borderLeft: "4px solid #0F9918",
      },
    });
  },
  warning: (title, desc) => {
    toast.warning(<Message title={title} desc={desc} />, {
      style: {
        ...customToast,
        borderLeft: "4px solid yellow",
      },
    });
  },
  error: (title, desc) => {
    toast.error(<Message title={title} desc={desc} />, {
      icon: <img src={error} />,
      style: {
        ...customToast,
        borderLeft: "4px solid #E54545",
      },
    });
  },
  info: (title, desc) => {
    toast.info(<Message title={title} desc={desc} />, {
      icon: <img src={info} />,
      style: {
        ...customToast,
        borderLeft: "4px solid #40A9FF",
      },
    });
  },
  noti: (title, desc, icon, item, dispatch) => {
    const toastId = toast.info(
      <div style={{ width: 368 }}>
        <div className="w-full flex justify-between items-center">
          <img src={icon} className="w-9 h-auto" />
          <p className="text-xs leading-5 font-normal">Vừa xong</p>
        </div>
        <h2 className="text-blackgray text-sm leading-5 font-bold mt-1">{title}</h2>
        <p className="text-blackgray text-sm leading-5 font-normal">{desc}</p>
        <div className="flex items-centerg gap-1 mt-2">
          <p className="text-primary text-sm leading-5 font-medium underline">Xem chi tiết</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M12.4892 8.61587L12.7946 5.80397L12.8244 5.52944C12.8641 5.1634 12.5996 4.83445 12.2336 4.7947L9.14714 4.45956" stroke={theme.primary} strokeLinecap="round" />
            <path d="M10.8179 6.53793L5.10303 11.1333" stroke={theme.primary} strokeLinecap="round" />
          </svg>
        </div>
      </div>,
      {
        bodyStyle: {},
        onClick: () => {
          history.push(`/sell-table/order/${item.table_id}/${item.order_id}`)
          dispatch(updateNoti({ ...item, status: 2 }))
          toast.dismiss(toastId)
        },
        closeOnClick: false,
        position: 'top-right',
        style: {
          ...customToast,
          width: 433,
          maxWidth: 433
        },
      }
    );
    return toastId
  },
};
