import React, { useEffect, useState } from "react";
import Emenu from "./Emenu";
import Reward from "./Reward";
import { useForm } from "react-hook-form";
import { updateMerchant } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { CustomToastMessage } from "../../../utils/customToastMsg";
import { ERROR_CODE } from "../../../utils/errorCode";
import { selectMerchant } from "../../../reducers/sass.reducer";

const FORM = {
  BANNER: "banner",
  ADDRESS: "address",
  HOTLINE: "hotline",
  WEBSITE: "website",
  POINT_PERCENT: "point_percent",
};

const SellSetting = () => {
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const merchant = useSelector(selectMerchant);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    console.log('>>> check payload update: ', value)
    try {
      const res = await dispatch(updateMerchant(value));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success("Thành công", res?.payload?.msg);
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        }
        CustomToastMessage.error("Thất bại", msg);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  useEffect(() => {
    setValue(FORM.BANNER, merchant[FORM.BANNER]);
    setValue(FORM.ADDRESS, merchant[FORM.ADDRESS]);
    setValue(FORM.HOTLINE, merchant[FORM.HOTLINE]);
    setValue(FORM.WEBSITE, merchant[FORM.WEBSITE]);
    setValue(FORM.POINT_PERCENT, merchant[FORM.POINT_PERCENT]);
  }, [merchant]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex items-center justify-center py-8">
        {["Menu điện tử", "Điểm thưởng"].map((item, index) => {
          const backgroundTab =
            tab === index ? "bg-primary border-primary " : "border-gray-primary ";
          const borderTab =
            index === 0 ? "rounded-tl-xl rounded-bl-xl " : "rounded-tr-xl rounded-br-xl ";
          const colorText = tab === index ? "text-white " : "text-label-field ";

          return (
            <div
              key={index}
              style={{ padding: "10px 35px" }}
              className={`flex items-center justify-center cursor-pointer border overflow-hidden ${backgroundTab} ${borderTab}`}
              onClick={() => setTab(index)}
            >
              <p className={`text-sm font-semibold leading-5 ${colorText} `}>{item}</p>
            </div>
          );
        })}
      </div>

      {Object.keys(merchant).length !== 0 && (
        <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
          {tab === 0 ? (
            <Emenu
              register={register}
              handleSubmit={handleSubmit}
              setValue={setValue}
              errors={errors}
              watch={watch}
              merchant={merchant}
            />
          ) : (
            <Reward
              register={register}
              handleSubmit={handleSubmit}
              setValue={setValue}
              errors={errors}
              watch={watch}
              merchant={merchant}
            />
          )}
        </form>
      )}
    </div>
  );
};

export default SellSetting;
