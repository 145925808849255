import React, { useEffect } from "react";

const Timer = ({ minutes, seconds, setMinutes, setSeconds, setIsTimeout }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        // Timer has reached 0, you can add your logic here
        clearInterval(timer);
        setIsTimeout(true);
      } else if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer); // Clean up the interval when the component unmounts
    };
  }, [minutes, seconds, setIsTimeout, setMinutes, setSeconds]);

  return (
    <span className="text-[#262626]">
      ({String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")})
    </span>
  );
};

export default React.memo(Timer);
