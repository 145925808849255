/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import IcCloseCircle from "../../../assets/images/icon/ic_close-circle.svg";
import IcUpload from "../../../assets/images/icon/ic_upload.svg";

function UploadField({
  extra,
  label,
  disabled,
  variant,
  isRequired,
  errors,
  name,
  id = "upload",
  filesPayload,
  setFilesPayload,
  oldImages = [],
}) {
  const [files, setFiles] = useState([]);
  const [dataFiles, setDataFiles] = useState([]);
  // console.log(">>> filesPayload", filesPayload);

  useEffect(() => {
    // console.log(">>> avalable", oldImages);
    setDataFiles(oldImages);
  }, [oldImages.length]);

  const handleRemoveNewImage = (item, index) => {
    console.log('>>> filesPayload', index)

    let tempFilesPayloads = [].concat(filesPayload)
    tempFilesPayloads.splice(index, 1)
    setFilesPayload(tempFilesPayloads)

    URL.revokeObjectURL(item.image);
    const tempNewImages = files.filter((it) => it.id !== item.id);
    setFiles(tempNewImages);
    // console.log('>>> filesPayload', item)
  };

  const handleRemoveOldImage = (index) => {
    // console.log(">>> newDataList, index: ", dataFiles, index);
    let tempOldImages = [].concat(dataFiles);
    tempOldImages.splice(index, 1);
    setDataFiles(tempOldImages);
    setFilesPayload(files.concat(tempOldImages));
  };

  const handleFileChange = (e) => {
    setFilesPayload([...filesPayload, e.target.files[0]]);
    const file = URL.createObjectURL(e.target.files[0]);

    const newList = [
      ...files,
      {
        id: uuidv4(),
        image: file,
      },
    ];

    setFiles(newList);

    const newDataList = [
      ...dataFiles,
      file,
    ];

    setFiles(newList);
    e.target.value = null;
  };

  return (
    <div className={`${extra} font-monte `}>
      {label && (
        <div className={`mb-2`}>
          <label
            htmlFor={id}
            className={`${
              disabled
                ? "text-hint "
                : variant === "secondary"
                ? "text-text-secondary "
                : "text-text-primary "
            } text-sm leading-5 font-semibold`}
          >
            {label}
          </label>
          {isRequired && <span className="font-semibold text-red-required">*</span>}
        </div>
      )}

      <div
        style={{ maxHeight: 142 }}
        className="flex flex-wrap items-center gap-3 p-3 border rounded-xl border-gray-primary custom-scrollbar overflow-y-auto w-full"
      >
        <label
          style={{ width: 116, height: 116 }}
          className="flex-shrink-0 group rounded-2xl border border-dashed border-gray-primary flex items-center justify-center cursor-pointer"
          htmlFor={id}
        >
          <img src={IcUpload} alt="IcUpload" width={23} height={23} className=" " />
        </label>
        <input
          id={id}
          type="file"
          className="hidden"
          onChange={(e) => handleFileChange(e)}
          accept=".png, .jpeg, .jpg"
        />
        {[].concat(dataFiles).map((item, index) => (
          <div
            key={index}
            style={{ width: 116, height: 116 }}
            className="flex-shrink-0 flex items-center justify-center relative cursor-pointer transition border border-gray-primary rounded-2xl"
          >
            <div
              onClick={() => handleRemoveOldImage(index)}
              style={{ top: "-5px", right: "-8px" }}
              className="absolute"
            >
              <img src={IcCloseCircle} alt="IcCloseCircle" className="w-5 h-5" />
            </div>
            <div
              style={{ width: 116, height: 116 }}
              className="flex items-center justify-center overflow-hidden rounded-2xl"
            >
              <img src={item} alt="item" className="object-contain " />
            </div>
          </div>
        ))}
        {files.map((item, index) => (
          <div
            key={index}
            style={{ width: 116, height: 116 }}
            className="flex-shrink-0 flex items-center justify-center relative cursor-pointer transition border border-gray-primary rounded-2xl"
          >
            <div
              onClick={() => handleRemoveNewImage(item, index)}
              style={{ top: "-5px", right: "-8px" }}
              className="absolute"
            >
              <img src={IcCloseCircle} alt="IcCloseCircle" className="w-5 h-5" />
            </div>
            <div
              style={{ width: 116, height: 116 }}
              className="flex items-center justify-center overflow-hidden rounded-2xl"
            >
              <img src={item.image} alt="item" className="object-contain " />
            </div>
          </div>
        ))}
      </div>

      {/* {errors[name] && <p className="text-xs font-normal mt-2 text-error">{errors[name].message}</p>} */}
    </div>
  );
}
export default UploadField;
