import React from 'react';
import { ChangeAmount } from '../Common';
import img_product from '../../../../assets/images/product/img-product.png';
import { formatCurrency } from '../../../../utils/lib';
import { theme } from '../../../../configTheme';

const ProductItem = ({pickedItem,getOrderProduct,item}) => {
    return(
        <div className="w-full p-4 rounded-xl bg-white flex gap-5"
            style={{
                boxShadow:'0px 8px 11px -3px rgba(0, 0, 0, 0.08)'
            }}
        >
        <div className="overflow-hidden"
            style={{
                width:'140px',
                height:'140px',
                borderRadius:'8px'
            }}
        >
            <img src={item?.img || img_product} alt="" className='w-full h-full object-cover object-center' />
        </div>
        <div className='flex-1 flex flex-col justify-between '>
            <div>
                <p className='text-base font-semibold' style={{color:'#262626'}}>{item.name}</p>
                <p className='text-sm font-semibold' style={{color:theme.primary,marginTop:'2px'}}>
                    {formatCurrency(item.price)}
                </p>
            </div>
            {<ChangeAmount
                product={item}
                pickedItem={pickedItem}
                getOrderProduct={getOrderProduct}
            />}
        </div>
    </div>

    )
}

export default ProductItem