


import React from 'react'

const Feature = ({list,type}) => {
  return (
    <ul className='flex flex-col space-y-3'>
          {list && list.map((feature, index) => {
              return (
                <li key={index} className='flex items-start space-x-3'>
                      <div style={{width: 22, height:22, minWidth: 22}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                              <path opacity="0.32" d="M11.25 0.754883C5.31294 0.754883 0.5 5.56782 0.5 11.5049C0.5 17.442 5.31294 22.2549 11.25 22.2549C17.1871 22.2549 22 17.442 22 11.5049C22 5.56782 17.1871 0.754883 11.25 0.754883Z" fill="#0A7CFF" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M16.2803 8.47455C16.5732 8.76744 16.5732 9.24232 16.2803 9.53518L10.7803 15.0352C10.6397 15.1759 10.4489 15.2549 10.25 15.2549C10.0511 15.2549 9.8603 15.1759 9.7197 15.0352L7.21967 12.5352C6.92678 12.2423 6.92678 11.7675 7.21967 11.4746C7.51256 11.1817 7.98744 11.1817 8.28033 11.4746L10.25 13.4442L15.2197 8.47455C15.5126 8.18166 15.9874 8.18166 16.2803 8.47455Z" fill="#0A7CFF" />
                          </svg>
                      </div>
                      
                      <span style={{color: !type ? "#fff" : "#000",fontSize:14,fontWeight: 500,}}>{feature}</span>
              </li>
          )
      })}
    </ul>
  )
}

export default Feature;