import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    show: false,
    title: '',
}

const purchaseNotiSlice = createSlice({
    name: 'purchaseNoti',
    initialState,
    reducers: {
        setOpenPopup: (state, action) => {
            state.show = action.payload.isOpen;
            state.title = action.payload.title;
        },
    },
})

export default purchaseNotiSlice.reducer;
export const selectIsOpen = (state) => state.purchaseNoti.show
export const selectTitleModal = (state) => state.purchaseNoti.title
export const { setOpenPopup } = purchaseNotiSlice.actions


