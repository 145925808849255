import { createSlice } from "@reduxjs/toolkit";
import { createStore, getCity, getDistrict, getPresentative, getWard } from "../actions";

const initialState = {
  listCity: [],
  listPresentative: [],
  listDistrict: [],
  listWard: [],

  isLoadingListCity: false,
  isLoadingListPresentative: false,
  isLoadingListDistrict: false,
  isLoadingListWard: false,
};

const storeSlice = createSlice({
  name: "storeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      // city
      .addCase(getCity.fulfilled, (state, action) => {
        state.listCity = action.payload;
      })
      .addCase(getCity.pending, (state, action) => {
        state.isLoadingListCity = true;
      })
      .addCase(getCity.rejected, (state, action) => {
        state.isLoadingListCity = false;
      })

      // district
      .addCase(getDistrict.fulfilled, (state, action) => {
        state.listDistrict = action.payload;
      })
      .addCase(getDistrict.pending, (state, action) => {
        state.isLoadingListDistrict = true;
      })
      .addCase(getDistrict.rejected, (state, action) => {
        state.isLoadingListDistrict = false;
      })

      // getWard
      .addCase(getWard.fulfilled, (state, action) => {
        state.listWard = action.payload;
      })
      .addCase(getWard.pending, (state, action) => {
        state.isLoadingListWard = true;
      })
      .addCase(getWard.rejected, (state, action) => {
        state.isLoadingListWard = false;
      })

      // presentative
      .addCase(getPresentative.fulfilled, (state, action) => {
        state.listPresentative = action.payload;
      })
      .addCase(getPresentative.pending, (state, action) => {
        state.isLoadingListPresentative = true;
      })
      .addCase(getPresentative.rejected, (state, action) => {
        state.isLoadingListPresentative = false;
      }),
});
export default storeSlice.reducer;

export const selectListCity = (state) => state.store.listCity;
export const selectListDistrict = (state) => state.store.listDistrict;
export const selectListWard = (state) => state.store.listWard;
export const selectListPresentative = (state) => state.store.listPresentative;
export const selectIsLoadingStore = (state) => state.store.isLoading;
