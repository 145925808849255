import axios from "axios";
import queryString from "query-string";

// import { history, LocalStorageService } from "../helpers";
import { LocalStorageService, history } from "../../helpers";
import { getSubDomain } from "../../utils/lib";
const localStorageService = LocalStorageService.getService();

const defaultHeader = {
    // "Access-Control-Allow-Origin": "*",
    // "Content-Type": "application/json",
    // Accept: "application/json",
    //'versionapp': localStorage.getItem("versionapp") || '1.0.0',
    // 'clientid': process.env.REACT_APP_CLIENT_ID,
    // 'hashcode': process.env.REACT_APP_CLIENT_HASH,
    // 'versionos': osVersion + ' ' + osName,
    // 'devicename': deviceName
};

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const appClient = axios.create({
    baseURL: API_ENDPOINT,
    //headers: defaultHeader,
    paramsSerializer: (params) => queryString.stringify(params),
});

// Add a request interceptor
appClient.interceptors.request.use(
    (config) => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

//Add a response interceptor
appClient.interceptors.response.use(
    (response) => {
        return handleResponse(response);
    },
    (error) => {
        console.log("--error", error);
        console.log(["TOKEN_EXPIRED", "AUTHEN_FAIL"].includes(error.response.data.error_code))
        const originalRequest = error.config;
        if (
            error.response.status === 403 &&
            ["TOKEN_EXPIRED", "AUTHEN_FAIL"].includes(error.response.data.error_code) &&
            !originalRequest._retry
        ) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        return appClient.request(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = localStorageService.getRefreshToken();
            const accessTokenExp = localStorageService.getAccessToken();
            if (!refreshToken) {
                history.push("/login");
            }

            return new Promise(function (resolve, reject) {
                // const domain = getSubDomain()

                axios
                    .post(
                        `${API_ENDPOINT}/user/refresh-token`,
                        {
                            refresh_token: refreshToken,
                            // access_token: accessTokenExp,
                            // domain
                        },
                        {
                            headers: {
                                ...defaultHeader,
                                Authorization: `Bearer ${accessTokenExp}`,
                            },
                        },
                    )
                    .then((res) => {
                        const { data } = res.data;
                        console.log("---data", data)
                        // 1) put token to LocalStorage
                        localStorageService.setToken(data);
                        localStorageService.setRefreshToken(data);
                        // get information

                        // 2) Change Authorization header
                        axios.defaults.headers.common["Authorization"] = "Bearer " + data.access_token;
                        originalRequest.headers["Authorization"] = "Bearer " + data.access_token;

                        processQueue(null, data.access_token);

                        // 3) return originalRequest object with Axios
                        resolve(appClient.request(originalRequest));
                    })
                    .catch((err) => {
                        console.log("-------err.response", err);
                        const { error_code } = err.response.data;
                        if (
                            err &&
                            (error_code === "AUTHEN_FAIL" ||
                                error_code === "TOKEN_EXPIRED" ||
                                error_code === "TOKEN_INCORRECT" || error_code === "REFRESH_TOKEN_EXPIRED")
                        ) {
                            clearAuthToken();
                        }

                        if (err && err.response) {
                            const { error_code } = err.response?.data;
                            if (error_code === "AUTHEN_FAIL") {
                                clearAuthToken();
                            }
                        }

                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }
        return Promise.reject(handleError(error));
    },
);

const handleResponse = (res) => {
    if (res && res.data) {
        return res.data;
    }

    return res;
};

const handleError = (error) => {
    const { data } = error.response;
    // console.log("--data", data);
    if (data && data.error_code === "USER_LOCKED_USING") {
        // clearAuthToken();
    }

    return data;
};

const clearAuthToken = () => {
    LocalStorageService.clearToken();
    history.push("/login");
};

export default appClient;
