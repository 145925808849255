import { createSlice } from "@reduxjs/toolkit";
import { getListFeedback, getFeedbackById } from "../actions";

const initialState = {
  feedbackItem: null,
  listFeedback: [],
  isLoading: false,
};

const feedbackSlice = createSlice({
  name: "feedbackSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListFeedback.fulfilled, (state, action) => {
        state.listFeedback = action.payload;
        state.isLoading = false;
      })
      .addCase(getListFeedback.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListFeedback.rejected, (state, action) => {
        state.listFeedback = [];
        state.isLoading = false;
      })
      .addCase(getFeedbackById.fulfilled, (state, action) => {
        state.feedbackItem = action.payload;
        state.isLoading = false;
      })
      .addCase(getFeedbackById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFeedbackById.rejected, (state, action) => {
        state.feedbackItem = null;
        state.isLoading = false;
      }),
});
export default feedbackSlice.reducer;

export const selectListFeedback = (state) => state.feedback.listFeedback;
export const selectIsLoading = (state) => state.feedback.isLoading;
export const selectFeedbackItem = (state) => state.feedback.feedbackItem;
