import React, { useEffect} from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layouts from "./layouts";
import Routes from "./Routes";
import "./css/tailwind.css";
import "./css/main.css";
import "./css/animate.css";
import "./css/_components.css";
import "react-tabs/style/react-tabs.css";
import GetProfile from "./components/profile/GetProfile";
import PurchaseNotification from "./components/notifications/PurchaseNoti";
// import SubDomain from "./SubDomain";

import { history } from "./helpers";
import { theme } from './configTheme'

const Wrapper = ({ children }) => {
  return <Layouts>{children}</Layouts>;
};

const App = () => {

  useEffect(() => {
    // console.log('>>> check theme: ', theme)
    document.documentElement.style.setProperty('--primary', theme.primary);
    document.documentElement.style.setProperty('--sidebar', theme.sidebar);
    document.documentElement.style.setProperty('--sidebar-hover', theme.sidebarHover);
    document.documentElement.style.setProperty('--select-hover', theme.selectHover);
    document.documentElement.style.setProperty('--text-primary', theme.textPrimary);
    document.documentElement.style.setProperty('--text-secondary', theme.textSecondary);
    document.documentElement.style.setProperty('--error', theme.error);
    const favicon = document.getElementById('favicon')
    const defaultTheme = localStorage.getItem('theme') || process.env.REACT_APP_DEFAULT_THEME
    favicon.href += `${defaultTheme}/favIcon.png`
  }, [])

  return (
    <div className="relative">
      <PurchaseNotification />
      <Router history={history} basename="/portal">
        <Wrapper>
          <Routes />
        </Wrapper>
        {/* <SubDomain /> */}
        <GetProfile />
        <ToastContainer hideProgressBar closeButton={false} position="bottom-right" autoClose={2400} icon={false} limit={3} />
      </Router>
    </div>
  );
};
export default App;
