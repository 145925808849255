import appClient from "../axios/app-client";
import { STORE } from "../endpoint";

export const storeServices = {
  createStore: async (params) => {
    return appClient.post(STORE, params);
  },

  getCountry: async (params) => {
    return appClient.get("/address/country", params);
  },

  getCity: async () => {
    return appClient.get(`/address/city`);
  },
  getDistrict: async ({ id = "", name = "", city_id = "" }) => {
    const query = new URLSearchParams({
      city_id: city_id.toString(),
    });
    return appClient.get(`/address/district?${query}`);
  },
  getWard: async ({ district_id = "" }) => {
    const query = new URLSearchParams({ district_id: district_id.toString() });
    return appClient.get(`/address/ward?${query}`);
  },

  deleteStore: async (params) => {
    // const query = new URLSearchParams({ page: page.toString(), limit: limit.toString(), name });
    return appClient.delete(STORE, params);
  },
};
