import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import CustomButton from "../buttons/CustomButton";

import SelectField from "../fields/select-address/SelectField";
import VietNam from "../../assets/images/flag/vnFlag.svg";
import ETH from "../../assets/images/onboarding/eth.svg";
import BNB from "../../assets/images/onboarding/bnb.svg";
import SOL from "../../assets/images/onboarding/sol.svg";
import bank from "../../assets/images/onboarding/bank.svg";
import InputCustom from "../fields/InputCustom";
import { history } from "../../helpers";
import {
  getListChain,
  getListNetwork,
  getListBank,
  postCrypto,
  postFiat,
} from "../../actions/metadata/metadata.actions";
import { useDispatch } from "react-redux";
import { Bounce, toast } from "react-toastify";
const dataCountry = [{ label: "VietNam", value: "VietNam", icon: VietNam }];

const ReceiveFund = () => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({});

  const [isCrypto, setIsCrypto] = useState(false);
  const [isFiat, setIsFiat] = useState(false);
  const [typeCurrency, setTypeCurrency] = useState("");
  const [disable, setDisable] = useState(false);

  let fiat = JSON.parse(localStorage.getItem("fiat"));
  let crypto = JSON.parse(localStorage?.getItem("crypto"));

  const dispatch = useDispatch();

  const [dataChain, setDataChain] = useState([]);
  const [dataNetwork, setDataNetwork] = useState([]);
  const [dataBank, setDataBank] = useState([]);

  useEffect(() => {
    const getDataChain = async () => {
      try {
        const res = await dispatch(getListChain({}));
        if (res.meta.requestStatus === "fulfilled") {
          const data_chain = res?.payload?.data;

          console.log(">>>", data_chain);

          const dataInput = data_chain?.map((item) => ({
            label: item?.name,
            value: item?.name,
            icon: item?.logo,
          }));
          setDataChain(dataInput);
        }
      } catch (err) {}
    };
    const getDataNetwork = async () => {
      try {
        const res = await dispatch(getListNetwork({}));
        if (res.meta.requestStatus === "fulfilled") {
          const data_network = res?.payload?.data;

          const dataInputNetwork = data_network?.map((item) => ({
            label: item?.name,
            value: item?.name,
            icon: item?.logo,
          }));
          setDataNetwork(dataInputNetwork);

          // const dataInput = data.map((item) => ({
          //   label: item.name.en_US,
          //   value: item.code,
          // }));
          // setDataIndustry(dataInput);
        }
      } catch (err) {}
    };

    const getDataBank = async () => {
      try {
        const res = await dispatch(getListBank({}));
        if (res.meta.requestStatus === "fulfilled") {
          const data_bank = res?.payload?.data?.data;

          const dataInputBank = data_bank?.map((item) => ({
            label: item?.name,
            value: item?.name,
            icon: item?.logo_url,
          }));
          setDataBank(dataInputBank);
        }
      } catch (err) {}
    };

    getDataChain();
    getDataNetwork();
    getDataBank();
  }, []);

  useEffect(() => {
    if (watch("payment_method") === "Fiat") {
      if (crypto !== null) {
        localStorage.removeItem("crypto");
      }
      setValue("wallet", "");

      setIsFiat(true);
      setIsCrypto(false);
      setTypeCurrency("Fiat");
    } else if (watch("payment_method") === "Crypto") {
      if (fiat !== null) {
        localStorage.removeItem("fiat");
      }
      setValue("account", "");
      setValue("account_number", "");
      setIsCrypto(true);
      setIsFiat(false);
      setTypeCurrency("Crypto");
    }
  }, [watch("payment_method")]);

  useEffect(() => {
    if (fiat !== null) {
      setIsCrypto(false);
      setIsFiat(true);
      setValue("account", fiat?.account);
      setValue("payment_method", fiat?.payment_method);
      setValue("account_number", fiat?.account_number);
      setValue("country", fiat?.country);
      setValue("bank", fiat?.bank);
    } else if (crypto !== null) {
      setIsCrypto(true);
      setIsFiat(false);

      setValue("payment_method", crypto?.payment_method);
      setValue("payment_network", crypto?.payment_network);
      setValue("country", crypto?.country);
      setValue("currency", crypto?.currency);
      setValue("wallet", crypto?.wallet);
    }
  }, [isCrypto, isFiat]);

  useEffect(() => {
    if (isCrypto) {
      if (
        watch("wallet") === undefined ||
        watch("payment_method") === undefined ||
        watch("payment_network") === undefined ||
        watch("country") === undefined ||
        watch("currency") === undefined
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else if (isFiat) {
      if (
        watch("account") === undefined ||
        watch("payment_method") === undefined ||
        watch("account_number") === undefined ||
        watch("country") === undefined ||
        watch("bank") === undefined
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [
    isCrypto,
    isFiat,
    watch("wallet"),
    watch("payment_method"),
    watch("payment_network"),
    watch("country"),
    watch("currency"),
    watch("account"),
    watch("account_number"),
    watch("bank"),
  ]);

  function shortenString(str, startChars, endChars) {
    if (str.length <= startChars + endChars) {
      return str;
    } else {
      return (
        str.substring(0, startChars) +
        "..." +
        str.substring(str.length - endChars)
      );
    }
  }

  const [dataWallet, setDataWallet] = useState("");

  useEffect(() => {
    if (dataWallet?.length < 10) {
      setDataWallet(watch("wallet"));
    } else {
      setDataWallet(shortenString(watch("wallet"), 4, 4));
    }
  }, [watch("wallet")]);

  const onSubmit = async (data) => {
    try {
      if (data?.payment_method === "Fiat") {
        localStorage.setItem("fiat", JSON.stringify(data));

        if (crypto !== null) {
          localStorage.removeItem("crypto");
        }
        const payload = {
          fund_type: "Fiat",
          account_number: watch("account_number"),
          currency: "VND",
          country: watch("country"),
          account: watch("account"),
          bank_code: watch("bank"),
        };

        const res = await dispatch(postCrypto(payload));
        if (res) {
          const url = `/onboarding?step=4`;
          window.location.href = url;
        } else {
          toast.error("Fund setting already exists", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
          });
        }
      } else if (data?.payment_method === "Crypto") {
        localStorage.setItem("crypto", JSON.stringify(data));
        if (fiat !== null) {
          localStorage.removeItem("fiat");
        }
        const payload = {
          fund_type: "Crypto",
          payment_network: watch("payment_network"),
          currency: watch("currency"),
          add_wallet: watch("wallet"),
          country: watch("country"),
        };

        const res = await dispatch(postCrypto(payload));
        if (res) {
          const url = `/onboarding?step=4`;
          window.location.href = url;
        } else {
          toast.error("Fund setting already exists", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="flex   justify-between "
      style={{
        height: "100vh",
        minHeight: "900px",
        backgroundColor: "#FAFAFA",
      }}
    >
      <div
        className="flex flex-col h-full w-full    "
        style={{
          width: "62%",
          marginTop: "60px",
          gap: "60px",
        }}
      >
        <div className="w-full">
          <div
            className="flex w-full items-center justify-end "
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              color: "#6E6D7A",
            }}
          >
            <button
              onClick={() => {
                history.push(`/dashboard`);
                localStorage.removeItem("dataOnboarding");
                localStorage.removeItem("crypto");
                localStorage.removeItem("fiat");
                localStorage.removeItem("logo");
              }}
              className="text-sm font-sans "
              style={{ fontWeight: "700" }}
            >
              Skip the tour
            </button>
          </div>
        </div>
        <div
          className="w-full flex flex-col justify-between "
          style={{ paddingLeft: "60px", paddingRight: "60px" }}
        >
          <div className="flex flex-col">
            <div className="w-full flex flex-col gap-6 ">
              <div
                className="text-2xl"
                style={{ fontWeight: "700", color: "#202124" }}
              >
                Set recieved fund's information
              </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className=" ">
              <div
                className="flex flex-col justify-between"
                style={{ height: "344px" }}
              >
                <div className="mt-10  w-4/5">
                  <div className="flex flex-row gap-6 ">
                    <SelectField
                      data={dataCountry}
                      widthFit
                      visibleIcon
                      hideSearch
                      formField="country"
                      required
                      isDefault
                      errors={errors}
                      control={control}
                      setValue={setValue}
                    />
                    <div style={{ width: "320px" }}>
                      <SelectField
                        data={[
                          { label: "Crypto", value: "Crypto" },
                          { label: "Fiat", value: "Fiat" },
                        ]}
                        searchPlaceholder="Type a Currency"
                        listTitle={["Fiat", "Crypto"]}
                        defaultLabel={
                          crypto?.payment_method || fiat?.payment_method || "--"
                        }
                        formField="payment_method"
                        control={control}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                  {isFiat && (
                    <div
                      style={{ width: "532px" }}
                      className="flex flex-col  gap-4 mt-10"
                    >
                      <div className="grid grid-cols-2 gap-5 ">
                        <InputCustom
                          variant=""
                          setValue={setValue}
                          label="Account"
                          placeholder="Enter Account"
                          id="account"
                          name="account"
                          value={watch("account")}
                          extra=""
                          register={register}
                          errors={errors}
                          state={errors?.type ? "error" : " "}
                        />

                        <InputCustom
                          variant=""
                          setValue={setValue}
                          label="Account Number"
                          placeholder="Enter Account Number"
                          id="account_number"
                          name="account_number"
                          value={watch("account_number")}
                          extra=""
                          register={register}
                          errors={errors}
                          state={errors?.type ? "error" : " "}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <div
                          className="text-sm "
                          style={{ fontWeight: "600", color: "#313131" }}
                        >
                          Bank
                        </div>
                        <SelectField
                          data={dataBank}
                          visibleIcon
                          searchPlaceholder="Search bank"
                          formField="bank"
                          defaultLabel={fiat?.bank || "Choose a bank"}
                          required
                          errors={errors}
                          control={control}
                          setValue={setValue}
                        />
                      </div>
                    </div>
                  )}
                  {isCrypto && (
                    <div className="flex flex-col w-full  gap-4 mt-10">
                      <div className="flex flex-col gap-2">
                        <div
                          className="text-sm "
                          style={{ fontWeight: "600", color: "#313131" }}
                        >
                          Payment network
                        </div>
                        <div style={{ width: "532px" }}>
                          <SelectField
                            data={dataChain}
                            visibleIcon
                            searchPlaceholder="Search payment network"
                            formField="payment_network"
                            defaultLabel={
                              crypto?.payment_network ||
                              "Choose your payment network"
                            }
                            required
                            errors={errors}
                            control={control}
                            setValue={setValue}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div
                          className="text-sm "
                          style={{ fontWeight: "600", color: "#313131" }}
                        >
                          Currency
                        </div>
                        <div style={{ width: "532px" }}>
                          <SelectField
                            data={dataNetwork}
                            visibleIcon
                            searchPlaceholder="Search currency"
                            formField="currency"
                            defaultLabel={crypto?.currency || "Choose currency"}
                            required
                            errors={errors}
                            control={control}
                            setValue={setValue}
                          />
                        </div>
                      </div>
                      <div
                        style={{ width: "532px" }}
                        className="grid grid-cols-1   "
                      >
                        <InputCustom
                          variant=""
                          setValue={setValue}
                          label="Where do you want to receive your payment?"
                          placeholder="Enter your address wallet "
                          id="wallet"
                          name="wallet"
                          value={watch("wallet")}
                          extra=""
                          register={register}
                          errors={errors}
                          state={errors?.type ? "error" : " "}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between mt-48">
                <button
                  className="flex items-center justify-center rounded-md"
                  onClick={() => {
                    const url = `/onboarding?step=2`;
                    window.location.href = url;
                  }}
                  style={{
                    border: "1px solid #1B92DB",
                    width: "180px",
                    height: "44px",
                  }}
                >
                  <div
                    style={{ color: "#1B92DB", fontWeight: "600" }}
                    className="text-sm"
                  >
                    Back
                  </div>
                </button>
                <div>
                  <CustomButton
                    disabled={disable}
                    type="submit"
                    onClick={() => {}}
                    style={{
                      width: "182px",
                      background:
                        "linear-gradient(36.25deg, #2B3DB9 2.93%, #0CD6F7 102.74%)",
                    }}
                    className="px-8 py-3 "
                    title="Continue"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="flex h-full w-full flex-col py-8   "
        style={{
          width: "38%",
          backgroundColor: "white",

          boxShadow: "0px 4px 20px 0px rgba(96, 111, 134, 0.04)",
        }}
      >
        {isCrypto && (
          <div>
            <div className=" flex-1 ">
              <div
                className="flex flex-col gap-4  border-b-2 border-dashed  "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Payment network
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    height: "56px",
                    borderRadius: "8px",
                    width: "236px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-lg"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {watch("payment_network")}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-1 ">
              <div
                className="flex flex-col gap-4  border-b-2 border-dashed  "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Currency
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    height: "56px",
                    borderRadius: "8px",
                    width: "236px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-lg"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {watch("currency")}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-1 ">
              <div
                className="flex flex-col gap-4   "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Address wallet
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    height: "56px",
                    borderRadius: "8px",
                    width: "236px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-lg"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {dataWallet !== undefined && dataWallet?.length < 10
                      ? dataWallet
                      : dataWallet !== undefined
                      ? dataWallet?.slice(0, 4) +
                        "..." +
                        dataWallet?.slice(
                          dataWallet?.length - 4,
                          dataWallet?.length
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isFiat && (
          <div>
            <div className=" flex-1 ">
              <div
                className="flex flex-col gap-4  border-b-2 border-dashed  "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Account
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    height: "56px",
                    borderRadius: "8px",
                    width: "236px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-lg"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {watch("account")}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-1 ">
              <div
                className="flex flex-col gap-4  border-b-2 border-dashed  "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Account Number
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    height: "56px",
                    borderRadius: "8px",
                    width: "236px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-lg"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {watch("account_number")}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-1 ">
              <div
                className="flex flex-col gap-4   "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Bank
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    height: "56px",
                    borderRadius: "8px",
                    width: "236px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-lg"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {watch("bank")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isCrypto && !isFiat && (
          <div>
            <div className=" flex-1  ">
              <div
                className="flex flex-col gap-4    "
                style={{ padding: "60px" }}
              ></div>
            </div>
            <div className=" flex-1 mt-40 ">
              <div
                className="flex flex-col gap-6  border-b-2 border-dashed  "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Country
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    height: "56px",
                    borderRadius: "8px",
                    width: "236px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-xl"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {watch("country")}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-1 ">
              <div
                className="flex flex-col gap-6    "
                style={{ padding: "60px" }}
              >
                <div
                  className="text-base"
                  style={{ fontWeight: "700", color: "#313131" }}
                >
                  Type of Currency
                </div>
                <div
                  style={{
                    background: "#EBEBEB",
                    width: "full",
                    height: "44px",

                    borderRadius: "8px",
                  }}
                  className="flex items-center justify-start px-4"
                >
                  <div
                    className="text-lg"
                    style={{ color: "#6A6A6C", fontWeight: "400" }}
                  >
                    {typeCurrency}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiveFund;
