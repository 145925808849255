import React from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import InputField from "../../../components/fields/InputField";
import UploadImage from "./UploadImage";

const FORM = {
  BANNER: "banner",
  ADDRESS: "address",
  HOTLINE: "hotline",
  WEBSITE: "website",
  POINT_PERCENT: "point_percent",
};

const Emenu = ({ merchant, register, handleSubmit, setValue, errors, watch }) => {
  const disabled =
    !watch(FORM.BANNER) || !watch(FORM.ADDRESS) || !watch(FORM.HOTLINE) || !watch(FORM.WEBSITE);

  return (
    <div className="p-8 rounded-xl bg-white shadow-lg">
      <p className="mb-2 text-blackgray font-bold leading-6 text-base">Banner sản phẩm</p>
      <UploadImage
        register={register}
        initValue={watch(FORM.BANNER)}
        name={FORM.BANNER}
        setValue={setValue}
        isRequired
      />

      <InputField
        isRequired
        setValue={setValue}
        label="Địa chỉ"
        placeholder="Nhập địa chỉ"
        id={FORM.ADDRESS}
        name={FORM.ADDRESS}
        value={watch(FORM.ADDRESS)}
        defaultValue={merchant[FORM.ADDRESS]}
        extra="mt-3 "
        register={register}
        errors={errors}
        state={errors?.type ? "error" : " "}
      />
      <InputField
        isRequired
        setValue={setValue}
        label="Hotline"
        placeholder="Nhập hotline"
        id={FORM.HOTLINE}
        name={FORM.HOTLINE}
        value={watch(FORM.HOTLINE)}
        defaultValue={merchant[FORM.HOTLINE]}
        extra="mt-3 "
        register={register}
        type="number"
        errors={errors}
        state={errors?.type ? "error" : " "}
      />
      <InputField
        isRequired
        setValue={setValue}
        label="Nhập link website"
        placeholder="Nhập link website"
        id={FORM.WEBSITE}
        name={FORM.WEBSITE}
        value={watch(FORM.WEBSITE)}
        defaultValue={merchant[FORM.WEBSITE]}
        extra="mt-3 "
        register={register}
        errors={errors}
        state={errors?.type ? "error" : " "}
      />

      <div className="mt-8">
        <CustomButton disabled={disabled} type="submit" title={"Lưu"} className={"w-full"} />
      </div>
    </div>
  );
};

export default Emenu;
