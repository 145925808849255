import { createSlice } from "@reduxjs/toolkit";
import {
  getListProduct,
  createProduct,
  updateProduct,
} from "../actions/product-manager.actions";

const initialState = {
  listProduct: [],
  isLoading: false,
};

const productManagementSlice = createSlice({
  name: "productManagementSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListProduct.fulfilled, (state, action) => {
        state.listProduct = action.payload;
      })
      .addCase(getListProduct.rejected, (state, action) => {
        state.listProduct = [];
      });
  },
});

export const selectListProduct = (state) => state.productManagement.listProduct;
export const selectIsLoading = (state) => state.productManagement.isLoading;

export default productManagementSlice.reducer;
