
import appClient from "./axios/app-client";
import { Forgot_Password, reset_Password } from "./endpoint";

export const userService = {
  getProfile: (params) => {
    return appClient.get(`/user/info`, { params });
  },
  ForgotPassword: (params) => {
    return appClient.post(Forgot_Password, params);
  },
  resetPassword: (params) => {
    return appClient.put(reset_Password, params);
  },
};
