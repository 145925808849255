import { CustomToastMessage } from "./customToastMsg";

export const BANK_CODE_DEFAULT = "VPB";
export const COUNTRY_ID_DEFAULT = 1;
export const INIT_MINUTES = 4;
export const INIT_SECONDS = 0;

const { success, warning, error } = CustomToastMessage;

export const toastMessage = {
  error: (text) => error("Lỗi", text),
  warning: (text) => warning("Cảnh báo", text),
  success: (text) => success("Success", text),
};

export const toastConfirmFormBankFail = () =>
  error(
    "Xác thực tài khoản thất bại",
    "Vui lòng nhập tài khoản khác để xác thực tài khoản."
  );

export const toastAddBankSuccess = ({ bank_code = "", bank_account_no = "" }) =>
  success(
    "Added bank successfully",
    `You have just successfully added ${bank_code} with account number ${bank_account_no}`
  );

export const toastDeleteBankSuccess = ({
  bank_code = "",
  bank_account_no = "",
}) =>
  success(
    "Successfully deleted bank",
    `You have just successfully deleted ${bank_code} with account number ${bank_account_no}.`
  );

export const toastDeleteBankFail = (err) => error("Failed deleted bank", err);

export const STATUS_CONNECT_POS = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  NOT_CONNECTED: "NOT_CONNECTED",
};

export const STATUS_INVOICE = {
  UNPAID: 0,
  PAID: 1,
};
export const PAYMENT_METHOD = {
  QR: 0,
  CASH: 1,
  CARD: 2,
};
