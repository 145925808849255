import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

const PopupAddCustomer = ({setCustomer,handleBack}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [step, setStep] = useState(0);
    return (
        <div className="w-full h-full fixed inset-0 flex justify-center items-center"
            style={{backgroundColor:'rgba(0,0,0,0.70)',zIndex:60}}
        >
            <div className="bg-white p-6 flex flex-col gap-8 rounded-xl relative">
                {step === 0 ? 
                    <Step1
                        step={step}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setStep={setStep}
                        setCustomer={setCustomer}
                        handleBack={handleBack}
                    />
                    :<Step2
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setStep={setStep}
                        setCustomer={setCustomer}
                        handleBack={handleBack}
                    />
                }
            </div>
        </div>
    )
}

export default PopupAddCustomer
