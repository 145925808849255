import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createEmployee } from "../../actions/employee/employee.actions";
import CustomButton from "../../components/buttons/CustomButton";
import InputField from "../../components/fields/InputField";
import SelectStoreField from "../../components/fields/SelectStoreField";
import SectionTitle from "../../components/section-title";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { ERROR_CODE } from "../../utils/errorCode";
import TableWrapper from "../company-profile/TableWrapper";
import { isValidPhoneNumber } from "../../utils/lib";
import InputFieldPw from "../../components/fields/InputFieldPw";

const FORM = {
  EMAIL: "email",
  FULL_NAME: "full_name",
  PHONE: "phone",
  PASSWORD: "password",
  STORE_ID: "store_id",
  STAFF_ID: "staff_id",
};
const CreateEmployee = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneAlert, setPhoneAlert] = useState();
  const [validPassword, setValidPassword] = useState(true);
  const [valid, setValid] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm();

  const onFormSubmit = async (value) => {
    const raw = { ...value, password: md5(value.password) };
    try {
      if (isValidPhoneNumber(raw?.phone)) {
        const res = await dispatch(createEmployee(raw));
        console.log(res);
        if (res?.meta?.requestStatus === "fulfilled") {
          history.push("/company-employee");
          CustomToastMessage.success(
            "Add employees successfully",
            `You have just successfully added an employee ${value?.full_name}`
          );
        } else {
          let msg = "Bad request";
          if (ERROR_CODE[res?.payload?.error_code]) {
            msg = ERROR_CODE[res?.payload?.error_code];
          }
          CustomToastMessage.error("Add employees fail", msg);
        }
      } else {
        setPhoneAlert("Invalid phone number");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled =
    !watch(FORM.EMAIL) ||
    !watch(FORM.FULL_NAME) ||
    !watch(FORM.PASSWORD) ||
    !watch(FORM.PHONE) ||
    !watch(FORM.STAFF_ID) ||
    !validPassword ||
    !valid ||
    !watch(FORM.STORE_ID);

  useEffect(() => {
    if (watch(`${FORM.EMAIL}`) === "") {
      clearErrors(`${FORM.EMAIL}`);
      // setDisable(true);
    }
    if (watch(`${FORM.PASSWORD}`) === "") {
      clearErrors(`${FORM.PASSWORD}`);
      // setDisable(true);
    }
  }, [watch("email"), watch(`${FORM.PASSWORD}`)]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="BUSINESS INFORMATION"
        subtitle="More staff"
        rightTitle="Employee Management"
      />
      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <InputField
            isRequired
            setValue={setValue}
            label="Employee code"
            placeholder="Enter employee code"
            id={FORM.STAFF_ID}
            name={FORM.STAFF_ID}
            extra=""
            register={register}
            errors={phoneAlert || errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            isRequired
            setValue={setValue}
            label="Full name"
            placeholder="Enter full name"
            id={FORM.FULL_NAME}
            name={FORM.FULL_NAME}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <SelectStoreField
            isRequired
            register={register}
            name={FORM.STORE_ID}
            setValue={setValue}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <div>
            <InputField
              isRequired
              setValue={setValue}
              type="number"
              label="Mobile"
              placeholder="Enter phone number"
              id={FORM.PHONE}
              name={FORM.PHONE}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
            {phoneAlert && (
              <p className="text-xs font-normal mt-2 text-error">
                {phoneAlert}
              </p>
            )}
          </div>

          <InputField
            isRequired
            setValue={setValue}
            setValid={setValid}
            label="Email"
            placeholder="Enter email"
            clearErrors={clearErrors}
            id={FORM.EMAIL}
            name={FORM.EMAIL}
            pattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/}
            extra=""
            register={register}
            setError={setError}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          {/* 
          <InputField
            isRequired
            setValue={setValue}
            label="Password"
            placeholder="Enter password"
            id={FORM.PASSWORD}
            name={FORM.PASSWORD}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          /> */}
          <div>
            <div className="text-sm leading-5 font-semibold text-input">
              Password<span style={{ color: "#FF4D4F" }}>*</span>
            </div>
            <InputFieldPw
              hideEye={false}
              setValue={setValue}
              placeholder="Enter password"
              id={FORM.PASSWORD}
              name={FORM.PASSWORD}
              setValidPassword={setValidPassword}
              setError={setError}
              clearErrors={clearErrors}
              pattern={
                /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}|:"<>?]).{8,}$/
              }
              extra="mt-2"
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/company-employee")}
              title="Back"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton
              type="submit"
              disabled={disabled}
              title="Add"
              className="w-full"
            />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateEmployee;
