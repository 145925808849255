import React from 'react'
import order from '../../../../assets/images/create-table/order.svg'
import plus_black from '../../../../assets/images/create-table/plus-black.svg'
import arrow_top_right from '../../../../assets/images/product/create.svg'

const CreateTable = ({setShowPopup}) => {
    return(
        // <div 
        //     className='w-full  rounded-xl overflow-hidden' 
        //     style={{ paddingLeft: '2px', backgroundColor: '#8C8C8C', height:'180px'}}
        // >
            <div 
                className="w-full p-6 pt-4 flex flex-col-reverse justify-between items-center relative rounded-xl"
                style={{backgroundColor: '#E8E8E8',height:'184px'}}
            >
                <img src={arrow_top_right} alt='' height={222} className='absolute left-0 right-0 mx-auto z-0' style={{top:'-25px'}}/> 
                <button 
                    className="flex items-center justify-center rounded-lg bg-white w-full z-10"
                    style={{padding:'10px 16px 10px 16px', gap:'10px'}}
                    onClick={() => setShowPopup(true)}
                >
                    <img src={plus_black} alt="" />
                    <span className="text-sm font-medium leading-5" style={{color:'#262626'}}>Tạo bàn</span>
                </button>
                <div className="flex-1 flex items-center justify-center z-10">
                    <img src={order} alt="" width={38} />
                </div>
            </div>
        // </div>
    )
}

export default CreateTable