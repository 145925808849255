
import appClient from "./axios/app-client";
import { PAYMENT_CHANNEL } from "./endpoint";

export const paymentChannelService = {
  getListPaymentChannels: (params) => {
    return appClient.get(PAYMENT_CHANNEL, { params });
  },

  createPaymentChannel: (params) => {
    return appClient.post(PAYMENT_CHANNEL, params);
  },

  deletePaymentChannel: (params) => {
    return appClient.delete(PAYMENT_CHANNEL, params);
  },

  createQR: (params) => {
    return appClient.post("/user/qr", params);
  },
};
