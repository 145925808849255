import { createAsyncThunk } from "@reduxjs/toolkit";
import { sellManagerServices } from "../services";

export const getMerchant = createAsyncThunk(
  "store/sass/getMerchant",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await sellManagerServices.getMerchant(data);
      return _data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sellRegisterFree = createAsyncThunk(
  "store/sass/sellRegisterFree",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await sellManagerServices.sellRegisterFree(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sellRegisterCode = createAsyncThunk(
  "store/sass/sellRegisterCode",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await sellManagerServices.sellRegisterCode(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sellRegisterPaymentQr = createAsyncThunk(
  "store/sass/sellRegisterPaymentQr",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await sellManagerServices.sellRegisterPaymentQr(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMerchant = createAsyncThunk(
  "store/sass/updateMerchant",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await sellManagerServices.updateMerchant(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
