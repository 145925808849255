import { createAsyncThunk } from "@reduxjs/toolkit";
import { customerManagerServices } from "../services";

export const getListCustomer = createAsyncThunk(
  "store/customer-manager/getListCustomer",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await customerManagerServices.getListCustomer(data);
    return _data;
  }
);

export const getCustomerById = createAsyncThunk(
  "store/customer-manager/getCustomerById",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const _data = await customerManagerServices.getCustomerById(data);
    return _data;
  }
);

export const createCustomer = createAsyncThunk(
  "store/customer-manager/createCustomer",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerManagerServices.createCustomer(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "store/customer-manager/updateCustomer",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerManagerServices.updateCustomer(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "store/customer-manager/deleteCustomer",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerManagerServices.deleteCustomer(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListProvince = createAsyncThunk(
  "store/customer-manager/getListProvince",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerManagerServices.getListProvince(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListDistrict = createAsyncThunk(
  "store/customer-manager/getListDistrict",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerManagerServices.getListDistrict(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListWard = createAsyncThunk(
  "store/customer-manager/getListWard",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await customerManagerServices.getListWard(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
