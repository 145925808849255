import React from "react";

import bgCardPro from "../../../assets/images/bill-digital/pro.png";
import Price from "../Price";
import BtnBuy from "../BtnBuy";
import Feature from "../Feature";
import ic_pro from "../../../assets/images/bill-digital/ic_pro.svg";

const list = [
  " No time limit",
  "The easiest software to use: completely online, no installation required",
  "Secure invoice data",
  "Easily integrates with any business's accounting/sales software",
];

const CardPro = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgCardPro})`,
        maxWidth: 360,
        height: "100%",
        padding: 40,
        margin: "auto",
        backgroundSize: "100% 100%",
      }}
      className=""
    >
      <div className="flex items-center flex-col">
        <img style={{ width: 72, height: 72 }} src={ic_pro} alt="icon gift" />
        <button
          style={{
            color: "#B57719",
            fontSize: 16,
            fontWeight: 600,
            leading: 24,
            border: "solid 2px #C58B1B",
            background: "linear-gradient(180deg, #FFF4E0 0%, #E8C176 57.6%)",
            backdropFilter: "blur(50px)",
            borderRadius: 8,
            padding: "8px 16px",
            width: 224,
            height: 48,
          }}
          className="mt-4"
        >
          Pro 500 hoá đơn
        </button>
      </div>
      <div className="my-6 flex flex-col space-y-6">
        <Price price={"500.000"} />
        <BtnBuy />
      </div>
      <div className="mt-10">
        <Feature list={list} type="pro" />
      </div>
    </div>
  );
};

export default CardPro;
