import React, { useMemo, useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getListLinkedVa } from "../../actions/metadata/metadata.actions";
import { selectListLinkedVa } from "../../reducers/metadata/metadata.reducer";
import { mapEDC } from "../../actions/edc/edc.actions";
import { selectIsLoading } from "../../reducers/edc/edc.reducer";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { ERROR_CODE } from "../../utils/errorCode";

import SelectInPaymentField from "../../components/fields/SelectInPaymentField";
import CustomButton from "../../components/buttons/CustomButton";

const IntergratePChannel = ({ selectedEDC, setShow, getListEDC }) => {
  const dispatch = useDispatch();
  const { success, error } = CustomToastMessage;
  const [va, setVa] = useState(null);
  const [checkField, setCheckField] = useState(false);
  const isLoading = useSelector(selectIsLoading);
  const dataListLinkedVa = useSelector(selectListLinkedVa);

  const listLinkedVa = useMemo(() => {
    if (!dataListLinkedVa) return [];
    return dataListLinkedVa.map((va) => ({
      // label: va.va_account_no,
      label: (
        <div>
          <p className="">{va.va_account_no}</p>
          <p className="text-blue-2 font-normal italic">{va.store_name}</p>
        </div>
      ),
      value: { accNo: va.va_account_no, name: va.store_name },
    }));
  }, [dataListLinkedVa]);

  const handleMapEDC = async () => {
    if (!va) {
      setCheckField(true);
      return;
    }
    const payload = { va_account_no: va.accNo, pos_id: selectedEDC.pos_id };
    const res = await dispatch(mapEDC(payload));
    if (res?.payload?.data) {
      setShow(false);
      success(
        "Successful integration",
        <div>
          <p>You have just successfully integrated</p>
          <p>{va.accNo}</p>
          <p className="text-blue-2 font-normal italic">{va.name}</p>
        </div>,
      );
      dispatch(getListEDC());
    } else {
      setShow(false);
      error("Integration failed", ERROR_CODE[res?.payload?.error_code] || res?.payload?.msg);
    }
  };

  useEffect(() => {
    dispatch(getListLinkedVa({ store_id: selectedEDC.store_id, page_size: 1000000 }));
  }, [dispatch]);

  return (
    <div className="fixed z-50 inset-0 bg-blur flex items-center justify-center">
      <div
        style={{
          boxShadow: "0px 8px 11px -3px rgba(0, 0, 0, 0.08)",
          width: 550,
        }}
        className="bg-white rounded-2xl p-12 relative"
      >
        <IoCloseSharp
          size={24}
          onClick={() => setShow(false)}
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
          }}
        />
        <h1 className="text-dark-primary text-center text-2xl leading-8 font-bold mb-6">
          Select payment method
        </h1>
        <div style={{ color: "#0A203D" }} className="text-sm leading-5 font-medium mt-2">
          <span className="font-semibold">EDC code: </span>
          {selectedEDC.serial_number}
        </div>
        <div className="mt-2 flex flex-col gap-2">
          <p className="text-text-primary text-sm leading-5 font-semibold">
            Payment channel<span className="text-red-required">*</span>
          </p>
          <SelectInPaymentField
            data={listLinkedVa}
            setValue={setVa}
            placeHolder="Select payment channel"
            checkField={checkField}
          />
        </div>
        <div className="mt-12 grid grid-cols-2 gap-4">
          <CustomButton onClick={() => setShow(false)} title="Cancel" outline />
          <CustomButton onClick={handleMapEDC} title="Intergrate" disabled={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default IntergratePChannel;
