import { createSlice } from "@reduxjs/toolkit";
import { getBusiness } from "../actions/business.actions";

const initialState = {
  business: {
    address: "2-6 Bis Điện Biên Phủ, 70000 Thành phố Hồ Chí Minh, TP Hồ Chí Minh, Vietnam",
    business: "Accounting/Bookkeeping Servicesaccounting_bookkeeping_services",
    email: "lqdat87@gmail.com",
    indentification_no: false,
    merchant_code: "568931",
    mobile: "+84 1900 9001",
    name: "McDonald's",
    phone: "+84 1900 9001",
    position: false,
    representative_name: false,
    tax_no: "HU10624500",
    trademark: "McDonald's",
    website: "https://mcdonalds.vn/",
  },
};

const businessSlice = createSlice({
  name: "businessSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(getBusiness.fulfilled, (state, action) => {
      state.business = action.payload.data;
    }),
});
export default businessSlice.reducer;

export const selectBusiness = (state) => state.business.business;
