import React, { useEffect, useState } from "react";
import SectionTitle from "../../../components/section-title";
import Register from "./register";
import Select from "./select";
import { useSelector } from "react-redux";
import { selectMerchant } from "../../../reducers/sass.reducer";

const SellRegister = () => {
  const [screen, setScreen] = useState(1);
  const merchant = useSelector(selectMerchant);

  const handleNextScreen = () => setScreen(2);

  useEffect(() => {
    if (merchant?.active) handleNextScreen();
  }, [merchant.active]);

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle
          title="Phần mềm Sales Manager"
          subtitle="Phần mềm Sales Manager"
        />
      </div>
      {screen === 1 ? (
        <Select handleNextScreen={handleNextScreen} />
      ) : (
        <Register />
      )}
    </section>
  );
};

export default SellRegister;
