import React from "react";
import { history } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  createFeedback,
  deleteFeedback,
  getListFeedback,
  updateFeedback,
} from "../../actions";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { ERROR_CODE } from "../../utils/errorCode";

import IcPen from "../../assets/images/employee/ic_pen.svg";
import moreInfo from "../../assets/images/payment/moreInfo.svg";
import IcTrash from "../../assets/images/payment/trash.svg";

const useEDC = ({
  setShowDetail = () => {},
  setShowDelete = () => {},
  setSelectedItem = () => {},
}) => {
  const type = "edc";
  const formTitle = "EDC requirements";

  const dispatch = useDispatch();
  const listContent = [
    { label: "Battery error", value: "Battery error" },
    { label: "Network connection error", value: "Network connection error" },
    { label: "Card reading error", value: "Card reading error" },
    { label: "Other error", value: "Other error" },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: "NO",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "EDC code",
        accessor: "serial_number",
      },
      {
        Header: "Content",
        accessor: "content",
        Cell: ({ value }) => <div>{value || ""}</div>,
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => <p>{value ? value : ""}</p>,
      },
      {
        Header: "Image",
        accessor: "images",
        Cell: ({ value }) => (
          <div className="flex flex-wrap" style={{ gap: 10, maxWidth: 126 }}>
            {value.map((img, index) => (
              <img
                key={index}
                src={img}
                alt="err img"
                className="w-6 h-6 rounded"
              />
            ))}
          </div>
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setShowDetail(true);
                  setSelectedItem(row.original);
                }}
              />
              {/* <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(`/feedback/update/${type}/${row.original.id}`);
                }}
              /> */}
              <img
                src={IcTrash}
                alt="IcTrash"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setShowDelete(true);
                  setSelectedItem(row.original);
                }}
              />
            </div>
          );
        },
      },
    ],
    [setSelectedItem, setShowDelete, setShowDetail]
  );

  const handleCreate = async (payload) => {
    const formData = new FormData();
    formData.append("type", type.toUpperCase());
    formData.append("store_id", payload.store_id);
    formData.append("content", payload.content);
    formData.append("serial_number", payload.serial_number);
    formData.append("description", payload.description);
    payload.images.map((image) => formData.append("images", image));

    try {
      const res = await dispatch(createFeedback(formData));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(`Add ${formTitle} successfully`, "");
        setTimeout(
          () => dispatch(getListFeedback({ type: type.toUpperCase() })),
          500
        );
        history.push(`/feedback/${type}`);
      } else {
        CustomToastMessage.error(
          `Add ${formTitle} fail`,
          ERROR_CODE[res?.payload?.error_code] || res?.payload?.msg
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = async (payload) => {
    const formData = new FormData();
    formData.append("id", payload.id);
    formData.append("type", type.toUpperCase());
    formData.append("store_id", payload.store_id);
    formData.append("content", payload.content);
    formData.append("serial_number", payload.serial_number);
    formData.append("description", payload.description);
    const oldImages = [];
    payload.images.map((img) => {
      if (img?.name) formData.append("images", img);
      else oldImages.push(img);
    });
    formData.append("old_images", JSON.stringify(oldImages));

    try {
      const res = await dispatch(updateFeedback(formData));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(`Edit ${formTitle} successfully`, "");
        setTimeout(
          () => dispatch(getListFeedback({ type: type.toUpperCase() })),
          500
        );
        history.push(`/feedback/${type}`);
      } else {
        CustomToastMessage.error(
          `Edit ${formTitle} fail`,
          ERROR_CODE[res?.payload?.error_code] || res?.payload?.msg
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteFeedback({ data: { id } }));
      if (res?.meta?.requestStatus === "fulfilled") {
        CustomToastMessage.success(`Delete ${formTitle} successfully`, "");
        dispatch(getListFeedback({ type: type.toUpperCase() }));
      } else {
        CustomToastMessage.error(
          `Delete ${formTitle} fail`,
          ERROR_CODE[res?.payload?.error_code] || res?.payload?.msg
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    formTitle,
    listContent,
    type,
    columns,
    handleCreate,
    handleUpdate,
    handleDelete,
  };
};

export default useEDC;
