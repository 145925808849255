
import appClient from "./axios/app-client";
import {
  DELETE_SETTING_TX,
  GET_LIST_BANK_ACCQUIRE_SETTING_TX,
  GET_LIST_CARD_SETTING_TX,
  GET_LIST_SETTING_TX,
} from "./endpoint";

export const settingTxServices = {
  getListSettingTx: (params) => {
    return appClient.get(GET_LIST_SETTING_TX, { params });
  },
  getListRule: (params) => {
    return appClient.get(GET_LIST_SETTING_TX, { params });
  },
  getListCard: (params) => {
    return appClient.get(GET_LIST_CARD_SETTING_TX, { params });
  },
  getBankRelease: (params) => {
    return appClient.get(GET_LIST_BANK_ACCQUIRE_SETTING_TX, { params });
  },
  getBankHandle: (params) => {
    return appClient.get(GET_LIST_BANK_ACCQUIRE_SETTING_TX, { params });
  },

  getSettingTxById: (id) => {
    return appClient.get(`${GET_LIST_SETTING_TX}?id=${id}`);
  },

  updateSettingTx: ({
    rule_name = "",
    card_type = "",
    issuer = "",
    acquirer = "",
  }) => {
    return appClient.put(
      `/metadata/rule?rule_name=${rule_name}&card_type=${card_type}&issuer=${issuer}&acquirer=${acquirer}`
    );
  },

  deleteSettingTx: (params) => {
    return appClient.delete(DELETE_SETTING_TX, params);
  },
};
