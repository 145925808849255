import axiosClient2 from "./client_axios2";
import { PRODUCT_MANAGER } from "./endpoint";

export const productManagerServices = {
    getListProduct: (params) => {
        return axiosClient2.get(`${PRODUCT_MANAGER}?${params}`);
    },

    getProductById: (id) => {
        return axiosClient2.get(`${PRODUCT_MANAGER}/${id}`);
    },

    createProduct: (params) => {
        return axiosClient2.post(PRODUCT_MANAGER, params);
    },

    updateProduct: (params) => {
        return axiosClient2.put(`${PRODUCT_MANAGER}/${params.code}`, params);
    },

    addImageProduct: (params) => {
        return axiosClient2.post(`${PRODUCT_MANAGER}/image`, params);
    },

    deleteProduct: (params) => {
        return axiosClient2.delete(`${PRODUCT_MANAGER}/${params}`);
    },
};
