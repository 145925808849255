import React, { useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

const FullSizeImage = ({ src, setImgFullSize }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleZoomIn = () => {
    setScale((scale) => scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((scale) => scale - 0.1);
  };

  return (
    <div className="fixed inset-0 z-50 bg-blur flex items-center justify-center">
      <div
        onClick={(e) => e.stopPropagation()}
        className="mx-auto flex items-center justify-center"
      >
        <img
          style={{
            width: "50vw",
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          }}
          draggable={false}
          src={src}
          alt="img"
          className="object-contain "
        />
      </div>

      <div className="absolute bottom-0 right-0 m-4 flex flex-col gap-1 items-center justify-center">
        {/* icon close */}
        <button
          onClick={() => setImgFullSize(null)}
          className="bg-white p-2 rounded-full shadow-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {/* icon zoom in */}

        <button onClick={handleZoomIn} className="bg-white p-2 rounded-full shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 4v16m8-8H4" />
          </svg>
        </button>
        {/* icon zoom out */}

        <button onClick={handleZoomOut} className="bg-white p-2 rounded-full shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M20 12H4" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FullSizeImage;
