import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getCountry } from "../../../actions";
import { COUNTRY_ID_DEFAULT } from "../../../utils/constants";
import SearchField from "../SearchField";
import SelectFieldContent from "../select/SelectFieldContent";
import SelectFieldItem from "../select/SelectFieldItem";
import SelectFieldWrapper from "../select/SelectFieldWrapper";
import "../style.css";

const SelectCountryField = (props) => {
  const { register, name, setValue, errors, extra, state, isRequired, resetField } = props;

  const [list, setList] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const handleSelectBox = (data) => {
    setCurrentItem(data);
    setValue(name, data?.id);
    setShowContent(false);
    resetField();
  };

  function filterByName() {
    if (searchValue.trim().length === 0) return list;

    const normalizedFilter = searchValue.toLowerCase().trim();
    return list.filter((item) => item.name.toLowerCase().includes(normalizedFilter));
  }

  const CurrentItemComp = () => (
    <div style={{ gap: 10 }} className="flex items-center ">
      <span className="text-14px leading-5">{currentItem?.name}</span>
    </div>
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getCountry());
        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data;
          const item = data.find((item) => item?.id === COUNTRY_ID_DEFAULT);
          setValue(name, item?.id);
          setCurrentItem(item);
          setList(data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [dispatch, name, setValue]);

  return (
    <SelectFieldWrapper
      {...props}
      showContent={showContent}
      setShowContent={setShowContent}
      label={"Address"}
      initialText="Nationality"
      currentValue={currentItem}
      currentItemComp={<CurrentItemComp />}
    >
      <SearchField
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        id="search-bank-input"
        extraClass="m-4 mb-1"
      />
      <SelectFieldContent>
        {filterByName().map((item, index) => {
          const active = currentItem?.id === item?.id;
          return (
            <SelectFieldItem
              key={`${item?.id}-${index}`}
              onClick={() => handleSelectBox(item)}
              active={active}
            >
              {item?.name}
            </SelectFieldItem>
          );
        })}
      </SelectFieldContent>
    </SelectFieldWrapper>
  );
};

export default SelectCountryField;
