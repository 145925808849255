import { createSlice } from "@reduxjs/toolkit";
import { getMerchant } from "../actions";

const initialState = {
  merchant: {},
  isLoading: false,
};

const sassSlice = createSlice({
  name: "sass",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getMerchant.fulfilled, (state, action) => {
        state.merchant = action.payload;
        state.isLoading = false;
      })
      .addCase(getMerchant.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMerchant.rejected, (state, action) => {
        state.isLoading = false;
      }),
});
export default sassSlice.reducer;

export const selectMerchant = (state) => state.sass.merchant;
export const selectIsLoadingMerchant = (state) => state.sass.isLoading;
