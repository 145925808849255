import { createSlice } from "@reduxjs/toolkit"
import { getListCustomer, createCustomer, updateCustomer, getListProvince, getListDistrict, getListWard } from "../actions/customer-manager.actions"

const initialState = {
    listCustomer: [],
    listProvince: [],
    listDistrict: [],
    listWard: [],
    isLoading: false,
}

const customerManagementSlice = createSlice({
    name: 'customerManagementSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCustomer.fulfilled, (state, action) => {
                state.listCustomer = action.payload;
            })
            .addCase(getListCustomer.rejected, (state, action) => {
                state.listCustomer = [];
            })
            .addCase(getListProvince.fulfilled, (state, action) => {
                state.listProvince = action.payload;
            })
            .addCase(getListProvince.rejected, (state, action) => {
                state.listProvince = [];
            })
            .addCase(getListDistrict.fulfilled, (state, action) => {
                state.listDistrict = action.payload;
            })
            .addCase(getListDistrict.rejected, (state, action) => {
                state.listDistrict = [];
            })
            .addCase(getListWard.fulfilled, (state, action) => {
                state.listWard = action.payload;
            })
            .addCase(getListWard.rejected, (state, action) => {
                state.listWard = [];
            })
    }
})

export const selectListCustomer = state => state.customerManagement.listCustomer;
export const selectListProvince = state => state.customerManagement.listProvince;
export const selectListDistrict = state => state.customerManagement.listDistrict;
export const selectListWard = state => state.customerManagement.listWard;
export const selectIsLoading = state => state.customerManagement.isLoading;

export default customerManagementSlice.reducer;