import React, { memo, useEffect, useState } from "react";
import queryString from "query-string";
import Merchant from "../../components/onboarding/Merchant";
import LogoUpload from "../../components/onboarding/LogoUpload";
import ReceiveFund from "../../components/onboarding/ReceiveFund";
import Finish from "../../components/onboarding/Finish";

const Onboarding = () => {
  const parsed = queryString.parse(window.location.search);
  const step = parsed.step;
  const [stepOnboarding, setStepOnboarding] = useState(step || 1);

  useEffect(() => {
    if (step) {
      setStepOnboarding(Number(step));
    }
  }, [step]);

  return (
    <div className="">
      {stepOnboarding === 1 ? (
        <Merchant />
      ) : stepOnboarding === 2 ? (
        <LogoUpload />
      ) : stepOnboarding === 3 ? (
        <ReceiveFund />
      ) : stepOnboarding === 4 ? (
        <Finish />
      ) : (
        ""
      )}
    </div>
  );
};
export default memo(Onboarding);
