import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CiExport } from "react-icons/ci";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../components/section-title";
import Datatable, { StatusCell } from "../components/tsdatatable/datatables";
import { Datetime, Inputfield, Optionfield, Searchfield } from "../components/tsfillter/fillter.js";
import Widget from "../components/widget";

import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import {} from "react-redux";
import { selectTransQR } from "../reducers/transaction.reducer.js";
import { formatCurrency } from "../utils/lib";
import InsightFilter from "../components/insightFilter";
import CustomButton from "../components/buttons/CustomButton";
import { getListTransQRCode } from "../actions/index.js";

const DatatableComponent = ({ customData, filters, numOfPage, data, onPaginationChange }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "STT",
        accessor: (row, i) => i + 1,
        disableSortBy: true,
      },
      {
        Header: "Invoice code",
        accessor: "bill_id",
        Cell: ({ value, row }) => {
          return <span>{value || "None"}</span>;
        },
      },
      {
        Header: "Số tiền",
        accessor: "amount",
      },
      {
        Header: "ShopQR",
        accessor: "channel",
      },
      {
        Header: "Trạng thái",
        accessor: "status",
        Cell: ({ value, row }) => <StatusCell data={row} />,
      },
      {
        Header: "Tài khoản nhận",
        accessor: "va_account_receiver",
      },
      {
        Header: "Tài khoản nguồn",
        accessor: "va_account_customer",
        Cell: ({ value, row }) => {
          return <span>{value || "None"}</span>;
        },
      },
    ],
    [],
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      total_request_amount={100000000}
      onPaginationChange={onPaginationChange}
    />
  );
};
const optionTsStatus = [
  { label: "All", value: "all" },
  { label: "Success", value: "success" },
  { label: "Error", value: "error" },
  { label: "Void", value: "void" },
];

const optionStatus = [
  { label: "Select field", value: "" },
  { label: "transaction_id", value: "transaction_id" },
  { label: "card_number", value: "card_number" },
  { label: "all", value: "all" },
];

const TsQRcode = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const data = useSelector(selectTransQR);
  const [numOfPage, setNumOfPage] = useState("1");
  const formData = useRef();

  const [filters, setFilters] = useState({
    page: 1,
    page_size: 10,
  });
  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(1);
    return data;
  }, [data]);

  const formatData = (ls) => {
    return ls
      ? ls.map((e) => {
          return {
            ...e,
            request_amount: formatCurrency(e.request_amount),
            original_transaction_date: moment(new Date(e.original_transaction_date * 1000)).format(
              "HH:mm:ss DD-MM-YYYY",
            ),
          };
        })
      : [];
  };

  const handlePaginationChange = useCallback((pagination) => {
    setFilters({
      page_size: pagination.pageSize,
      page: pagination.page,
      ...formData.current,
    });
  }, []);

  function removeEmptyFields(data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
      if (!data["query_key"] || !data["query_value"]) {
        delete data["query_key"];
        delete data["query_value"];
      }
    });
  }
  const onSubmit = (data) => {
    removeEmptyFields(data);
    data.from_date = moment(data.from_date).format("DD/MM/YYYY");
    data.to_date = moment(data.to_date).format("DD/MM/YYYY");

    formData.current = data;
    setFilters(formData.current);
  };

  useEffect(() => {
    dispatch(getListTransQRCode(filters));
  }, [dispatch, filters]);

  return (
    <>
      <SectionTitle title="Báo cáo" subtitle="Báo cáo QR Code" />

      <FormProvider {...methods}>
        <Widget flag={true}>
          <div className="flex justify-between items-end flex-wrap space-y-10">
            <InsightFilter filters={filters} setFilters={setFilters} />
            <div className="flex space-x-4 w-fit justify-end">
              <CustomButton type="reset" title={"Đặt lại"} />
              <CustomButton type="apply" title={"Áp dụng"} />
            </div>
          </div>
        </Widget>
      </FormProvider>

      <div className="mt-6">
        <DatatableComponent
          customData={formatData(customData)}
          filters={filters}
          numOfPage={numOfPage}
          data={data}
          onPaginationChange={handlePaginationChange}
        />
      </div>
    </>
  );
};
export default TsQRcode;
