/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ic_check from "../../assets/images/icon/ic_check.svg";

import { useForm } from "react-hook-form";
// import Alert from '../alerts'
import md5 from "md5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  Getpending,
  Getstatus,
  SetForgotPassword,
  resetPassword,
  resetState,
} from "../../reducers/reset-password.reducer";
import CustomButton from "../../components/buttons/CustomButton";
import { theme } from "../../configTheme";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const { handleSubmit, errors, register } = useForm();

  const [data, onSubmit] = useState(null);
  const [message, setMessage] = useState();

  const call = useSelector(SetForgotPassword);
  const status = useSelector(Getstatus);
  const pending = useSelector(Getpending);

  const onSubmitFn = (data) => {
    const raw = {
      token,
      new_password: md5(data.password),
      // new_password: data.password,
    };

    if (onSubmit) {
      onSubmit(raw);
    }
  };
  //reset state
  const reset = () => {
    dispatch(resetState());
  };

  //send resetPassword
  useEffect(() => {
    if (data != null) {
      dispatch(resetPassword(data));
    }
  }, [data]);

  //error alert
  useEffect(() => {
    if (!status && status != null) {
      setMessage(call?.message);
    }
  }, [status]);

  useEffect(() => {
    setMessage("");
  }, [errors["password"], errors["resetPassword"]]);

  //reset state when change page
  useEffect(() => reset, []);

  useEffect(() => {
    if (!token) history.push("/login");
  }, []);

  return (
    <div
      style={{ boxShadow: "0px 4px 24px rgba(20, 42, 74, 0.12)" }}
      className="flex flex-col w-full max-w-md p-8 bg-white rounded-2xl"
    >
      {!status ? (
        <>
          <div className="w-full flex flex-col items-center text-login-color-5">
            <div className="w-8 h-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="40"
                viewBox="0 0 34 40"
                fill="none"
              >
                <path
                  d="M26.3436 14.8961V10.6021C26.3436 5.57609 22.2676 1.50009 17.2416 1.50009C12.2156 1.47809 8.12356 5.53409 8.10156 10.5621V10.6021V14.8961"
                  stroke={theme.primary}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.866 38.4999H9.584C5.396 38.4999 2 35.1059 2 30.9159V22.3379C2 18.1479 5.396 14.7539 9.584 14.7539H24.866C29.054 14.7539 32.45 18.1479 32.45 22.3379V30.9159C32.45 35.1059 29.054 38.4999 24.866 38.4999Z"
                  stroke={theme.primary}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.2266 24.4062V28.8482"
                  stroke={theme.primary}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p
              style={{
                color: "#262626",
                fontSize: "20px",
              }}
              className=" mt-3 font-bold"
            >
              New password
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmitFn)}
            className="form w-full flex flex-col mt-6"
          >
            <div className="form-error text-text-primary font-semibold text-14px mb-3">
              New password <span className="text-red-500">*</span>
            </div>
            <div className="form-input relative flex justify-center items-center p-0">
              <input
                ref={register({ required: true })}
                name="password"
                type="password"
                style={{}}
                className={`w-full rounded pr-10 px-3 py-2  ${
                  errors["password"] ? "border-red-500" : ""
                }`}
                placeholder="Please enter a new password"
              />
              <div
                className={`lds-dual-ring pr-4 ${
                  pending ? "inline-block" : "hidden"
                }`}
              />
            </div>
            <div className="form-error text-red-500 h-4 my-1">
              {errors["password"] ? "Please enter a valid password" : message}
            </div>

            <div className="form-error text-text-primary font-semibold text-14px mb-3">
              Re-enter new password <span className="text-red-500">*</span>
            </div>
            <div className="form-input relative flex justify-center items-center p-0">
              <input
                ref={register({ required: true })}
                name="confirm_password"
                type="password"
                style={{}}
                className={`w-full rounded pr-10 px-3 py-2  ${
                  errors["confirm_password"] ? "border-red-500" : ""
                }`}
                placeholder="Please re-enter new password"
              />
              <div
                className={`lds-dual-ring pr-4 ${
                  pending ? "inline-block" : "hidden"
                }`}
              />
            </div>
            <div className="form-error text-red-500 h-4 my-1">
              {errors["confirm_password"]
                ? "Please enter a valid confirm_password"
                : message}
            </div>

            <div>
              <p className="text-text-primary text-xs leading-4">
                Note: Password conditions
              </p>
              <ul className="list-disc ml-2 mt-2">
                <li className=" text-text-primary text-xs leading-4">
                  The required password has a minimum length of 8-35 characters.
                </li>
                <li className=" text-text-primary text-xs leading-4">
                  Have at least 1 uppercase letter, 1 lowercase letter, and 1
                  number.
                </li>
                <li className=" text-text-primary text-xs leading-4">
                  Passwords that do not use Vietnamese have accents and spaces.
                </li>
                <li className=" text-text-primary text-xs leading-4">
                  After 20 minutes, if the user accesses "Reset password", the
                  system will display the page{" "}
                  <span className="font-semibold">“Not find the request”</span>.
                </li>
              </ul>
            </div>

            <div className="gap-4 flex flex-col mt-8">
              <input
                type="submit"
                value="Submit"
                className="btn btn-default font-semibold text-white rounded-lg py-3 bg-primary"
              />
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="w-full flex flex-col items-center text-login-color-5">
            <img src={ic_check} className="w-12 h-12" />
            <p className="text-xl mt-4 text-text-primary font-semibold">
              Password changed successfully
            </p>
          </div>

          <CustomButton
            title="Return to login"
            onClick={history.push("/login")}
          />
        </>
      )}
    </div>
  );
};

export default ResetPassword;
