
import appClient from "./axios/app-client";
import {
  GET_MERCHANT_KEY_PORTAL,
  GET_MERCHANT_URL,
  ORDER_LIST_ITEMS,
} from "./endpoint";

export const checkoutService = {
  getListOrder: (params) => {
    return appClient.get(ORDER_LIST_ITEMS, { params });
  },

  getMerchantURL: (params) => {
    return appClient.get(GET_MERCHANT_URL, { params });
  },

  updateMerchantURL: (params) => {
    return appClient.put(GET_MERCHANT_URL, params);
  },

  getMerchantKeyReepay: (params) => {
    return appClient.get(GET_MERCHANT_KEY_PORTAL, { params });
  },

  createMerchantKeyReepay: (params) => {
    return appClient.post(GET_MERCHANT_KEY_PORTAL, params);
  },
};
