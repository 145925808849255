import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { createStore } from "../../actions";
import CustomButton from "../../components/buttons/CustomButton";
import InputField from "../../components/fields/InputField";
import SelectCityField from "../../components/fields/select-address/SelectCityField";
import SelectCountryField from "../../components/fields/select-address/SelectCountryField";
import SelectDistrictField from "../../components/fields/select-address/SelectDistrictField";
import SelectWardField from "../../components/fields/select-address/SelectWardField";
import SectionTitle from "../../components/section-title";
import { CustomToastMessage } from "../../utils/customToastMsg";
import { ERROR_CODE } from "../../utils/errorCode";
import TableWrapper from "../company-profile/TableWrapper";

const FORM = {
  STORE_NAME: "store_name",
  COUNTRY_ID: "country_id",
  TAX_NO: "tax_no",
  ADDRESS_LINE_1: "address_line_1",
  CITY_ID: "city_id",
  DISTRICT_ID: "district_id",
  WARD_ID: "ward_id",
};

const schema = yup.object().shape({
  [FORM.TAX_NO]: yup
    .string()
    .required("Please enter the tax code")
    .matches(/\S/, "No spaces allowed")
    .min(10, "Tax code must be at least 10 characters"),
});

const CreateStore = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentItemCity, setCurrentItemCity] = useState(null);
  const [currentItemDistrict, setCurrentItemDistrict] = useState(null);
  const [currentItemWard, setCurrentItemWard] = useState(null);
  const [listCity, setListCity] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) });

  const onFormSubmit = async (value) => {
    try {
      const res = await dispatch(createStore(value));
      if (res?.meta?.requestStatus === "fulfilled") {
        history.push("/store-manager");
        CustomToastMessage.success(
          "Successfully",
          `You have added the store successfully with the tax code: ${watch(FORM.TAX_NO)}`
        );
      } else {
        let msg = "Bad request";
        if (ERROR_CODE[res?.payload?.error_code]) {
          msg = ERROR_CODE[res?.payload?.error_code];
        }
        CustomToastMessage.error("The store addition failed", msg);
      }
    } catch (err) {
      console.log(err);
      CustomToastMessage.error("Failed", err + "");
    }
  };
  const onErrors = (errors) => console.error(errors);

  const resetCountry = () => {
    setValue(FORM.CITY_ID, "");
    setValue(FORM.DISTRICT_ID, "");
    setValue(FORM.WARD_ID, "");
    setCurrentItemCity(null);
    setCurrentItemDistrict(null);
    setCurrentItemWard(null);
    setListCity([]);
    setListDistrict([]);
    setListWard([]);
  };

  const resetCity = () => {
    setValue(FORM.DISTRICT_ID, "");
    setValue(FORM.WARD_ID, "");
    setCurrentItemDistrict(null);
    setCurrentItemWard(null);
    setListDistrict([]);
    setListWard([]);
  };

  const resetDistrict = () => {
    setValue(FORM.WARD_ID, "");
    setCurrentItemWard(null);
    setListWard([]);
  };

  const disabled =
    !watch(FORM.STORE_NAME) ||
    !watch(FORM.TAX_NO) ||
    !watch(FORM.CITY_ID) ||
    !watch(FORM.DISTRICT_ID) ||
    !watch(FORM.COUNTRY_ID) ||
    !watch(FORM.WARD_ID) ||
    !watch(FORM.ADDRESS_LINE_1);

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
      <SectionTitle
        title="BUSINESS INFORMATION"
        subtitle="Addition Store"
        rightTitle="Store manager"
      />

      <TableWrapper>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-6">
          <InputField
            isRequired
            setValue={setValue}
            label="Store Name"
            placeholder="Enter store name"
            id={FORM.STORE_NAME}
            name={FORM.STORE_NAME}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />
          <InputField
            type="number"
            isRequired
            setValue={setValue}
            label="Tax code"
            placeholder="Enter tax code"
            id={FORM.TAX_NO}
            name={FORM.TAX_NO}
            extra=""
            register={register}
            errors={errors}
            state={errors?.type ? "error" : " "}
          />

          <div style={{ gap: 10 }} className="flex flex-col">
            <SelectCountryField
              isRequired
              register={register}
              name={FORM.COUNTRY_ID}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
              resetField={resetCountry}
            />
            <SelectCityField
              isRequired
              register={register}
              name={FORM.CITY_ID}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
              resetField={resetCity}
              currentItem={currentItemCity}
              setCurrentItem={setCurrentItemCity}
              list={listCity}
              setList={setListCity}
            />

            <SelectDistrictField
              isRequired
              register={register}
              name={FORM.DISTRICT_ID}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
              watch={watch(FORM.CITY_ID)}
              resetField={resetDistrict}
              currentItem={currentItemDistrict}
              setCurrentItem={setCurrentItemDistrict}
              list={listDistrict}
              setList={setListDistrict}
            />
            <SelectWardField
              isRequired
              register={register}
              name={FORM.WARD_ID}
              setValue={setValue}
              errors={errors}
              state={errors?.type ? "error" : " "}
              watch={watch(FORM.DISTRICT_ID)}
              currentItem={currentItemWard}
              setCurrentItem={setCurrentItemWard}
              list={listWard}
              setList={setListWard}
            />
            <InputField
              isRequired
              setValue={setValue}
              placeholder="Detail address"
              id={FORM.ADDRESS_LINE_1}
              name={FORM.ADDRESS_LINE_1}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4 mt-8">
          <div style={{ width: 182 }}>
            <CustomButton
              onClick={() => history.push("/store-manager")}
              title="Back"
              outline
              className="w-full"
            />
          </div>
          <div style={{ width: 182 }}>
            <CustomButton type="submit" disabled={disabled} title="Add" className="w-full" />
          </div>
        </div>
      </TableWrapper>
    </form>
  );
};

export default CreateStore;
