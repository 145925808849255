import { createSlice } from "@reduxjs/toolkit";
import { getListEDC, mapEDC } from "../../actions/edc/edc.actions";

const initialState = {
  listEDC: [],
  isLoading: false,
};

const edcSlice = createSlice({
  name: "edcSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getListEDC.fulfilled, (state, action) => {
        state.listEDC = action.payload.data;
      })
      .addCase(getListEDC.rejected, (state, action) => {
        state.listEDC = [];
      })
      .addCase(mapEDC.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(mapEDC.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(mapEDC.pending, (state, action) => {
        state.isLoading = true;
      }),
});

export const selectListEDC = (state) => state.edc.listEDC;
export const selectIsLoading = (state) => state.edc.isLoading;

export default edcSlice.reducer;
