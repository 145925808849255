import { createAsyncThunk } from "@reduxjs/toolkit";
import { invoiceManagerServices } from "../services";

export const getListInvoice = createAsyncThunk(
  "store/invoice-manager/getListInvoice",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceManagerServices.getListInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInvoice = createAsyncThunk(
  "store/invoice-manager/createInvoice",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceManagerServices.createInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "store/invoice-manager/updateInvoice",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceManagerServices.updateInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "store/invoice-manager/deleteInvoice",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceManagerServices.deleteInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStatusPos = createAsyncThunk(
  "store/invoice-manager/getStatusPos",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceManagerServices.getStatusPos(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exportInvoice = createAsyncThunk(
  "store/invoice-manager/exportInvoice",

  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const _data = await invoiceManagerServices.exportInvoice(data);
      return _data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
