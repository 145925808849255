import React, { useState, useEffect, useRef } from "react";
import search from "../../assets/images/icon/ic-search.svg";
import arrowDown from "../../assets/images/icon/ic-arrow-down.svg";

const SelectInPaymentField = ({
  data,
  placeHolder = "Select",
  hasSearch = true,
  value,
  setValue,
  setStoreName = () => { },
  resetFlag,
  iconDropdown,
  rotateIcon = true,
  iconWidth,
  docElem,
  checkField = false,
  defaultValue = null,
}) => {
  const [list, setList] = useState(data);
  const [showData, setShowData] = useState(false);
  const [label, setLabel] = useState(placeHolder);
  const [internalValue, setInternalValue] = useState(null);
  const selectFieldRef = useRef();

  useEffect(() => {
    if (selectFieldRef.current && !selectFieldRef.current.contains(docElem))
      setShowData(false);
  }, [docElem]);

  const filterList = (target) => {
    const filteredList = data.filter((item) => {
      if (typeof item.label === "string")
        return item.label.toLowerCase().includes(target);
      return item?.label?.props?.children.find((child) =>
        child?.props?.children?.toLowerCase()?.includes(target)
      );
    });
    setList(filteredList);
    setValue(target);
  };

  useEffect(() => {
    setList(data);
  }, [data]);

  useEffect(() => {
    setLabel(placeHolder);
  }, [placeHolder]);

  useEffect(() => {
    setLabel(placeHolder);
    setValue(null);
  }, [resetFlag]);

  useEffect(() => {
    if (!value && !internalValue) {
      const item = data.find((item) => item?.value === defaultValue);
      if (item) {
        setLabel(item.value);
        setInternalValue(item.value);
      }
    }
  }, [data, defaultValue]);

  return (
    <div ref={selectFieldRef} className="relative">
      <div
        onClick={() => setShowData(!showData)}
        style={{
          border: showData ? "1px solid #6666FF" : "1px solid #E8E8E8",
          padding: "10px 10px 10px 16px",
        }}
        className="bg-white rounded-lg flex gap-6 justify-between items-center cursor-pointer"
      >
        <p
          style={{ color: "#0F1D40", fontSize: 14 }}
          className="leading-6 capitalize-first font-medium w-full truncate"
        >
          {label}
        </p>
        <img
          src={iconDropdown ? iconDropdown : arrowDown}
          alt="arrowDown"
          width={iconWidth}
          style={{
            transition: "all 0.3s linear",
            transform: showData && rotateIcon && "rotate(180deg)",
          }}
        />
      </div>

      {checkField && !internalValue && (
        <p className="mt-1 text-xs leading-4 text-red-required">
          Please <span className="lowercase">{placeHolder}</span>
        </p>
      )}

      {showData && (
        <div
          style={{
            border: "1px solid #E8E8E8",
            boxShadow: "0px 12px 19px -5px rgba(0, 0, 0, 0.08)",
            top: "calc(100% + 8px)",
          }}
          className="absolute z-20 w-full bg-white rounded-lg flex flex-col overflow-auto"
        >
          {hasSearch && (
            <div
              style={{
                background: "#F5F5F5",
                gap: 10,
                marginBottom: list.length > 0 ? 4 : 16,
              }}
              className="rounded-lg mx-4 mt-4 px-3 py-2 flex"
            >
              <img src={search} alt="search"></img>
              <input
                onChange={(e) => filterList(e.target.value.toLowerCase())}
                style={{
                  fontSize: 14,
                  color: "#0F1D40",
                  background: "#F5F5F5",
                }}
                className="w-full leading-5 font-normal"
                placeholder="Search"
              />
            </div>
          )}
          {list.length > 0 && (
            <div
              className="flex flex-col gap-1"
              style={{ maxHeight: 216, overflow: "auto" }}
            >
              {list.map((item, index) => {
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowData(false);
                      setLabel(
                        item.placeHolder ? item.placeHolder : item.label
                      );
                      if (setStoreName) setStoreName(item.storeName);
                      setValue(item.value);
                      setInternalValue(item.value);
                    }}
                    key={item.value}
                    style={{ fontSize: 14 }}
                    className="px-4 py-2 capitalize leading-6 font-medium cursor-pointer select-item hover:bg-select-hover hover:text-primary "
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectInPaymentField;
