import { toPng } from "html-to-image";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { createQR } from '../../actions/payment-channel';
import CustomButton from '../../components/buttons/CustomButton';
import { theme } from '../../configTheme';

import book from '../../assets/images/payment/book.svg';
import eye from '../../assets/images/payment/eye.svg';
import napas from '../../assets/images/payment/napas.png';

const DetailPChannel = ({ selectedChannel, setShowDetail }) => {
  const [qr, setQR] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const getQR = async (va_account_no) => {
      const res = await dispatch(createQR({ va_account_no }))
      if (res?.payload?.data?.qr_base64) setQR(res?.payload?.data?.qr_base64)
      else console.log(res?.payload?.errors)
    }
    getQR(selectedChannel.va_account_no)
  }, [])

  const ref = useRef(null)
  const handleDownloadQR = useCallback(() => {
    if (ref.current === null) return
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "qrcode.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => console.log(err));
  }, [ref]);

  return (
    <div className='fixed z-50 inset-0 bg-blur overflow-y-scroll flex justify-center py-8 custom-scrollbar'>
      <div style={{ height: 'min-content', width: '91.4%', maxWidth: 1066, color: '#262626', boxShadow: '0 8px 11px -3px rgba(0,0,0,0.08)' }} className="relative rounded-2xl bg-white p-8">
        {/* Close */}
        <IoCloseSharp onClick={() => setShowDetail(false)} style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }} size={24} />
        {/* Title */}
        <h3 className="mb-12 text-2xl font-bold">Channel details</h3>
        {/* Body */}
        <div className="flex w-full flex-col items-center gap-8">
          <div className="flex w-full flex-col gap-12 md:flex-row">
            {/* QR Wrap */}
            <div className="flex flex-col items-center" style={{ height: 'min-content' }}>
              <div className="flex flex-col items-center" ref={ref}>
                {/* Logos */}
                <div className="flex w-full items-center justify-between">
                  <img src={napas} alt="napas" style={{ width: 99 }} />
                  <img src={theme.primary != '#ED1C24' ? theme.loginLogo : '/images/ocb/loginLogo.png'} alt="bank" className='h-6 w-auto' />
                </div>
                {/* QR */}
                <img
                  src={`data:image/png;base64,${qr}`}
                  alt="qr" style={{ width: 217, height: 219, marginTop: 24 }}
                />
                {/* Channel Name */}
                <h3 className="mt-4 text-center text-base leading-5 font-bold text-dark-primary">
                  {selectedChannel.store_name}
                </h3>
                {/* Bank Account */}
                <p style={{ color: '#505B76' }} className="mt-1 items-center font-normal text-sm">
                  {selectedChannel.bank_account_no}
                </p>
              </div>
              {/* Download Button */}
              <CustomButton onClick={handleDownloadQR} className="mt-4 text-white" title="Download QR" />
            </div>

            {/* Seperate Line */}
            <div style={{ background: '#D9DDE5', width: 1 }}></div>

            {/* Info */}
            <div style={{ border: '1px solid #D9DDE5', marginBottom: 14, flex: 1 }} className="space-y-4 rounded-2xl p-6 pointer-events-none opacity-50">
              {/* Header */}
              <div className="flex flex-col items-center justify-between gap-y-3 text-[#262626] md:flex-row">
                <h3 className="text-xl leading-7 font-bold">Payment setup information</h3>
                <div style={{ width: 'max-content', gap: 10, padding: '6px 10px', border: '1px solid #D9DBE9' }} className="flex items-center rounded">
                  <img src={book} alt="book" className="h-6 w-6" />
                  <p className="text-xs leading-6 font-normal text-[#0F1D40]">
                    Integration Guide
                  </p>
                </div>
              </div>

              {["Client id", "Api Key", "Checksum Key"].map((item, index) => {
                return (
                  <div key={index} className="space-y-2">
                    <h4 style={{ fontSize: 14 }} className="font-semibold leading-5">{item}</h4>
                    <div className="flex items-center justify-between rounded-lg border border-[#D9DBE9] p-3">
                      <p style={{ fontSize: 14 }} className="font-normal leading-5">***************************</p>
                      <img src={eye} alt="eye" className="h-5 w-5" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <CustomButton onClick={() => setShowDetail(false)} title="Close" outline />
        </div>
      </div>
    </div>
  )
}

export default DetailPChannel