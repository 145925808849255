import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import * as actions from "../actions";
import { theme } from "../configTheme";
import { LocalStorageService, history } from "../helpers";

import md5 from "md5";
import CustomButton from "../components/buttons/CustomButton";
import InputField from "../components/fields/InputField";
import InputFieldPw from "../components/fields/InputFieldPw";
import useWindowSize from "../hooks/useWindowSize";
import { CustomToastMessage } from "../utils/customToastMsg";
import { getProfile } from "../actions/profile.action";

const FORM = {
  EMAIL: "email",
  PASSWORD: "password",
};

const schema = yup.object().shape({
  [FORM.EMAIL]: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    ),
  [FORM.PASSWORD]: yup.string().required("Password is required"),
});

const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [countError, setCountError] = useState(0);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [FORM.EMAIL]: "",
      [FORM.PASSWORD]: "",
    },
  });

  const { isMd } = useWindowSize();

  const onFormSubmit = async (value) => {
    const formatData = {
      ...value,
      password: md5(value.password),
    };
    try {
      setLoading(true);
      const res = await dispatch(actions.authLogin(formatData));
      if (res?.payload?.data) {
        LocalStorageService.setToken(res?.payload?.data);
        LocalStorageService.setRefreshToken(res?.payload?.data);
        localStorage.removeItem("dataOnboarding");
        localStorage.removeItem("crypto");
        localStorage.removeItem("fiat");
        localStorage.removeItem("logo");

        CustomToastMessage.success("Login success");
        if (res?.payload?.data?.check_first_login) {
          history.push("/onboarding");
          localStorage.setItem("is_new", Number(JSON.stringify(1)));
        } else {
          history.push(`/dashboard`);
          localStorage.setItem("is_new", Number(JSON.stringify(0)));
        }
      } else {
        setCountError(countError + 1);
      }
    } catch (err) {
      console.log(err);
      CustomToastMessage.error(err + "");
    } finally {
      setLoading(false);
    }
  };
  const onErrors = (errors) => console.error(errors);

  const disabled = !watch(FORM.EMAIL) || !watch(FORM.PASSWORD);

  useEffect(() => {
    dispatch(actions.authLogout());
  }, []);

  return (
    <>
      {/* Image */}
      <img
        src={theme.loginBg}
        alt="image"
        width={569}
        className="hidden lg:block m-auto"
      />

      {/* Form */}
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.40)",
          backdropFilter: "blur(50px)",
        }}
        className="h-screen flex flex-col items-center rounded-tl-2xl rounded-bl-2xl"
      >
        <div
          style={{ padding: "60px 60px 82px 60px" }}
          className="flex w-full items-center justify-between"
        >
          <img
            src={theme.loginLogo}
            alt="logo"
            style={{ height: 32 }}
            className="w-auto"
          />
          <div className="flex items-center gap-2">
            <span style={{ color: "#202124" }} className="text-lg leading-7">
              Don’t have an account?
            </span>
            <Link
              to="/signup"
              style={{ color: "#2B4896" }}
              className="font-semibold text-sm leading-5"
            >
              Sign up!
            </Link>
          </div>
        </div>

        <div
          style={{ gap: 6, marginBottom: 60 }}
          className="w-fit flex flex-col items-center "
        >
          <span
            style={{ color: "#202124", lineHeight: "44px" }}
            className="font-semibold text-4xl "
          >
            Welcome Back 👋
          </span>
          <span style={{ color: "#202124" }} className=" text-lg leading-7">
            Log in to your account
          </span>
        </div>

        <form
          style={{ width: 400 }}
          onSubmit={handleSubmit(onFormSubmit, onErrors)}
          className="flex flex-col justify-center items-center gap-10 "
        >
          <div className="w-full space-y-6">
            <InputField
              type="email"
              variant=""
              isRequired
              setValue={setValue}
              placeholder="Email *"
              id={FORM.EMAIL}
              name={FORM.EMAIL}
              value={watch(FORM.EMAIL)}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            <InputFieldPw
              variant=""
              isRequired
              setValue={setValue}
              placeholder="Password *"
              id={FORM.PASSWORD}
              name={FORM.PASSWORD}
              value={watch(FORM.PASSWORD)}
              extra=""
              register={register}
              errors={errors}
              state={errors?.type ? "error" : " "}
            />

            {countError > 0 && (
              <p
                className="text-error text-sm leading-5 font-medium"
                style={{ marginTop: 10 }}
              >
                Username or password is not correct. Try again{" "}
                <span className="font-bold">{countError} times</span>
              </p>
            )}

            <div className="flex justify-between items-center text-sm leading-5 font-medium">
              <div className="ml-2 flex items-center gap-6">
                <label
                  htmlFor="toggle"
                  className="flex items-center cursor-pointer"
                >
                  <div className="relative">
                    <input
                      id="toggle"
                      checked={checked}
                      type="checkbox"
                      className="hidden"
                    />
                    <div className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                  </div>
                </label>
                <label
                  htmlFor="toggle"
                  onClick={() => setChecked(!checked)}
                  style={{ color: "#202124" }}
                  className=" font-poppins "
                >
                  Remember me
                </label>
              </div>

              <a
                href="/forgot-password"
                style={{ color: "#D93F21" }}
                className="font-poppins"
              >
                Recover Password
              </a>
            </div>
          </div>

          <CustomButton
            disabled={disabled || loading}
            isLoading={loading}
            type="submit"
            className="w-full"
            title="Login"
          />
        </form>
      </div>
    </>
  );
};

export default Login;
