import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../helpers/history.js";
import { formatCurrency } from "../../../utils/lib.js";
import {
  getListProduct,
  deleteProduct,
} from "../../../actions/product-manager.actions.js";
import { selectListProduct } from "../../../reducers/product-management.reducer.js";
import { CustomToastMessage } from "../../../utils/customToastMsg.js";
import { ERROR_CODE } from "../../../utils/errorCode.js";

import SectionTitle from "../../../components/section-title/index.js";
import CustomButton from "../../../components/buttons/CustomButton.js";
import Datatable, {
  StatusCell,
} from "../../../components/tsdatatable/datatables.js";
import ModalDelete from "../../../components/modals/ModalDelete.js";

import IcPen from "../../../assets/images/employee/ic_pen.svg";
import stop from "../../../assets/images/payment/stop.svg";
import moreInfo from "../../../assets/images/payment/moreInfo.svg";
import useSearchParams from "../customer-manager/useSearchParams.js";
import SearchFieldParams from "../../../components/fields/SearchFieldParams.js";

const DataTableComponent = ({
  customData,
  filters,
  setFilters,
  numOfPage,
  setShowModalDelete,
  setItem,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Hình",
        accessor: "img",
        disableSortBy: true,
      },
      {
        Header: "Tên sản phẩm",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <span className="uppercase">{value}</span>;
        },
      },
      {
        Header: "Mã sản phẩm",
        accessor: "code",
      },
      {
        Header: "Giá",
        accessor: "price",
        Cell: ({ value, row }) => {
          return <span>{formatCurrency(value)}</span>;
        },
      },
      {
        Header: "Trạng thái",
        accessor: "status",
        Cell: ({ value, row }) => {
          const status = { title: "", color: "" };
          status.title =
            value == 0
              ? "Còn hàng"
              : value == 1
              ? "Hết hàng"
              : "Ngừng kinh doanh";
          status.color = value == 0 ? "green" : value == 1 ? "red" : "gray";
          return <StatusCell title={status.title} color={status.color} />;
        },
      },
      {
        Header: "Hành động",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-4 justify-center items-center">
              {/* <img
                src={moreInfo}
                alt="moreInfo"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  setItem(row?.original);
                  // setShowDetailModal(true);
                }}
              /> */}
              <img
                src={IcPen}
                alt="IcPen"
                style={{ width: 16, cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    `/sell-product/update?id=${row?.original?.code}`
                  );
                }}
              />
              <img
                src={stop}
                alt="stop"
                className={`w-4 ${
                  row?.original?.status == 2
                    ? "opacity-50 pointer-events-none"
                    : "cursor-pointer"
                }`}
                onClick={() => {
                  setItem(row?.original);
                  setShowModalDelete(true);
                }}
              />
            </div>
          );
        },
      },
    ],
    [setItem, setShowModalDelete]
  );

  return (
    <Datatable
      columns={columns}
      data={customData}
      pagination={{
        page: filters.page,
        pageSize: filters.page_size,
        totalPages: numOfPage,
      }}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const TableProduct = () => {
  const dispatch = useDispatch();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [numOfPage, setNumOfPage] = useState("1");
  const { updatePagination, setSearchQuery, filtersParams } = useSearchParams();
  const [filters, setFilters] = useState(filtersParams);

  const data = useSelector(selectListProduct);

  const customData = useMemo(() => {
    if (!data?.data) return [];
    setNumOfPage(data?.total_page ? data?.total_page : 1);
    return data?.data;
  }, [data]);

  useEffect(() => {
    updatePagination(new URLSearchParams(filters));
  }, [dispatch, filters, updatePagination]);

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteProduct(selectedProduct?.code));
      if (res?.payload) {
        CustomToastMessage.success(
          "Thành công",
          `Bạn đã ngừng kinh sản phẩm với mã ${selectedProduct?.code}`
        );
        dispatch(getListProduct(filters));
        setShowModalDelete(false);
      } else CustomToastMessage.error("Thất bại");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section>
      <div className="w-full flex justify-between items-center">
        <SectionTitle title="management bán hàng" subtitle="List sản phẩm" />
      </div>

      <div className="mt-4 mb-2 w-full flex justify-between items-center gap-4">
        <SearchFieldParams
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          id="search-bank-input"
          extraClass="flex-1 bg-white shadow-lg"
          placeholder="Tìm theo mã sản phẩm, tên sản phẩm"
        />
        <CustomButton
          type="add"
          outline
          title={"Thêm"}
          onClick={() => history.push("/sell-product/create")}
        />
      </div>

      <div className="mt-6">
        <DataTableComponent
          customData={customData}
          filters={filters}
          numOfPage={numOfPage}
          data={data}
          setFilters={setFilters}
          setItem={setSelectedProduct}
          setShowModalDelete={setShowModalDelete}
        />
      </div>

      {showModalDelete && (
        <ModalDelete
          setIsOpenModal={setShowModalDelete}
          title=""
          desc={`Bạn có chắc chắn muốn ngừng kinh doanh sản phẩm ${selectedProduct.name} này hay không?`}
          item={selectedProduct}
          handleAction={handleDelete}
          titleBtnLeft="Không"
          titleBtnRight="Có"
        />
      )}
    </section>
  );
};
export default TableProduct;
