import React from "react";

const SectionWrapper = ({ key, title, children }) => {
  return (
    <div key={key} className="flex flex-col gap-6 w-full">
      <p className="text-text-primary text-base leading-6 font-bold">{title}</p>
      <div className="grid md:grid-cols-2 gap-6 w-full">{children}</div>
      {/* <div className='space-y-2'>
                <p className='text-grey-atom-grey text-sm leading-5 font-bold'>{imgTitle}</p>
                <label
                    style={{ width: 360, border: '1px dashed #B1BECF', cursor: 'pointer' }} htmlFor='upload-img'
                    className='bg-white rounded-lg flex flex-col justify-center items-center gap-2 px-4 py-5'
                >
                    <img src={upload} alt='upload' width={32} />
                    <p className='text-grey-9 text-xs leading-4 font-normal'>Không có dữ liệu</p>
                </label>
                <input className='hidden' id='upload-img' type='file' accept='.png, .jpg, .jpeg'/>
            </div> */}
    </div>
  );
};

export default SectionWrapper;
