import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosArrowDown } from "react-icons/io";
import { theme } from "../../configTheme";
import useWindowSize from "../../hooks/useWindowSize";

const SidebarItem = ({
  icon,
  title,
  link,
  disable,
  subItems,
  openSidebar,
  activedItem,
  setActivedItem,
  openGlobalSubItems,
  setOpenGlobalSubItems,
  setShowSidebar,
}) => {
  const navigate = useHistory();
  const [openSubItems, setOpenSubItems] = useState(false);
  const hasSubItems = subItems.length > 0;
  const isActivated = () =>
    activedItem == link ||
    ((!openSubItems || !openSidebar) && subItems.find((item) => activedItem == item.link));

  useEffect(() => {
    setOpenSubItems(false);
  }, [openSidebar]);

  useEffect(() => {
    if (openGlobalSubItems != title) setOpenSubItems(false);
  }, [openGlobalSubItems, title]);
  const { isMd } = useWindowSize();

  return (
    <div className={` relative`}>
      <div
        onMouseOver={() => {
          if (!openSidebar) setOpenSubItems(true);
        }}
        onMouseOut={() => {
          if (!openSidebar) setOpenSubItems(false);
        }}
        onClick={() => {
          if (disable) return;

          if (hasSubItems) {
            if (openSidebar) {
              if (title != openGlobalSubItems) setOpenGlobalSubItems(title);
              setOpenSubItems(!openSubItems);
            }
          } else {
            if(!disable){
              navigate.push(link);
              setActivedItem(link);
              !isMd && setShowSidebar(false);
            }
          }
        }}
        className={`
        ${
          disable
            ? "opacity-50 cursor-default "
            : isActivated()
            ? "bg-sidebar-item cursor-pointer "
            : "bg-sidebar-item-hover cursor-pointer "
        }
        overflow-hidden flex items-center rounded-xl cursor-pointer`}
        style={{
          padding: openSidebar ? "12px 10px" : "14px 20px",
          gap: 10,
          transition: "all 0.2s ease-in",
          maxHeight: 64,
        }}
      >
        <img src={icon} alt="icon" style={{ width: 16 }} />
        {openSidebar && (
          <span style={{ flex: 1 }} className="text-sm text-white leading-5 font-medium">
            {title}
          </span>
        )}
        {hasSubItems && (
          <IoIosArrowDown
            color="white"
            size={16}
            style={{
              transform: openSubItems && "rotate(180deg)",
              transition: "all 0.3s linear",
            }}
          />
        )}
      </div>

      <div
        onMouseOver={() => {
          if (!openSidebar) setOpenSubItems(true);
        }}
        onMouseOut={() => {
          if (!openSidebar) setOpenSubItems(false);
        }}
        className={`${!openSidebar && hasSubItems ? "rounded-lg z-50 p-3" : "overflow-hidden"}`}
        style={{
          background: !openSidebar && hasSubItems && theme.sidebar,
          display: !openSubItems && "none",
          transition: "all 0.3s linear",
          position: !openSidebar && "absolute",
          top: 0,
          left: "100%",
          boxShadow: !openSidebar && "-8px 4px 8px rgba(0, 0, 0, 0.05)",
          width: !openSidebar && "max-content",
        }}
      >
        {subItems.map((item, index) => {
          return (
            <div
              key={index}
              disable={item.disable}
              onClick={() => {
                setActivedItem(item.link);
                navigate.push(link + item.link);
                !isMd && setShowSidebar(false);
              }}
              style={{
                padding: "10px 36px",
                transition: "all 0.2s ease-in",
                maxHeight: 64,
              }}
              className={`text-sm text-white leading-5 font-medium rounded-xl bg-sidebar-item-hover cursor-pointer 
                 ${item.disable && "pointer-events-none opacity-50"} 
                 ${activedItem === item.link && "bg-sidebar-item "} 
                overflow-hidden`}
            >
              {item.title_eng}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SidebarItem;
