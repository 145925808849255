import React from "react";
import { history } from "../../helpers";
import NotiIcon from "./../../assets/images/noti/noti.gif";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsOpen,
  selectTitleModal,
  setOpenPopup,
} from "../../reducers/purchaseNoti.reducer";

const PurchaseNotification = () => {
  const isOpened = useSelector(selectIsOpen);
  const title = useSelector(selectTitleModal);
  const dispatch = useDispatch();

  const handleContinue = () => {
    dispatch(setOpenPopup(false));
    history.push("/e-invoice");
  };

  return (
    <>
      {isOpened ? (
        <div
          className="absolute z-50"
          style={{
            width: "100vw",
            minHeight: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <div
            className="relative"
            style={{
              width: "100vw",
              minHeight: "100vh",
            }}
          >
            <div
              className="bg-white rounded-2xl py-8 px-6 absolute"
              style={{
                width: 610,
                right: "50%",
                transform: "translateX(50%)",
                top: "13.8%",
              }}
            >
              <div className="relative w-full flex flex-col items-center">
                <img alt="noti icon" src={NotiIcon} width={120} height={120} />
                <div
                  className="mb-8 text-lg font-bold text-center"
                  style={{
                    lineHeight: "28px",
                  }}
                >
                  {title}
                </div>
                <button
                  className="text-center bg-primary text-white rounded text-sm font-semibold py-3 px-8"
                  style={{
                    width: 342,
                    lineHeight: "20px",
                  }}
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PurchaseNotification;
