import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import { getWard } from "../../../actions";
import SearchField from "../SearchField";
import SelectFieldContent from "../select/SelectFieldContent";
import SelectFieldItem from "../select/SelectFieldItem";
import SelectFieldWrapper from "../select/SelectFieldWrapper";
import "../style.css";

const SelectWardField = (props) => {
  const {
    register,
    name,
    setValue,
    watch,
    errors,
    extra,
    state,
    isRequired,
    currentItem,
    setCurrentItem,
    list,
    setList,
  } = props;

  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const handleSelectBox = (data) => {
    setCurrentItem(data);
    setValue(name, data?.id);
    setShowContent(false);
  };

  // Function to filter the array based on the 'name' ,'id' and 'full_name' property
  function filterByName() {
    if (searchValue.trim().length === 0) return list;

    const normalizedFilter = searchValue.toLowerCase().trim();
    return list.filter((item) => item.name.toLowerCase().includes(normalizedFilter));
  }

  const CurrentItemComp = () => (
    <div style={{ gap: 10 }} className="flex items-center ">
      <span className="text-14px leading-5">{currentItem?.name}</span>
    </div>
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await dispatch(getWard({ district_id: watch }));
        if (res.meta.requestStatus === "fulfilled") {
          const data = res?.payload?.data;
          setList(data);
          setCurrentItem(null);
        }
      } catch (err) {
        console.log(err);
      }
    };
    watch && getData();
  }, [dispatch, name, setCurrentItem, setList, setValue, watch]);

  return (
    <SelectFieldWrapper
      {...props}
      showContent={showContent}
      setShowContent={setShowContent}
      initialText="Ward"
      currentValue={currentItem}
      currentItemComp={<CurrentItemComp />}
    >
      <SearchField
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        id="search-item-input"
        extraClass="m-4 mb-1"
      />
      <SelectFieldContent>
        {filterByName().map((item, index) => {
          const active = currentItem?.id === item?.id;
          return (
            <SelectFieldItem
              key={`${item?.id}-${index}`}
              onClick={() => handleSelectBox(item)}
              active={active}
            >
              {item?.name}
            </SelectFieldItem>
          );
        })}
      </SelectFieldContent>
    </SelectFieldWrapper>
  );
};

export default SelectWardField;
